import styled, { keyframes } from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import { motion } from "framer-motion";

import { ReactComponent as SuccessIcon } from "../../images/success.svg";

export const rotate = keyframes`
from {
  transform: rotate(0deg);
  transform-origin: center;
}
to {
  transform: rotate(360deg);
  transform-origin: center;
}
`;

export const Container = styled(motion.div)`
  min-height: 100vh;
  background-color: white;
  align-items: center;
`;

export const Content = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  margin: 16px;
`;

export const Heading = styled.h3`
  font-size: 24px;
`;

export const SubHeading = styled.h5`
  font-size: 18px;
  text-transform: capitalize;
`;

export const PlanSwicth = styled(motion.div)`
  margin-top: 16px;
  padding: 4px 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Monthly = styled.span`
  color: ${({ theme, active }) => (active ? theme.colors.pro : "#4d4d4d")};
  font-weight: ${({ active }) => (active ? "600" : "500")};
  text-transform: uppercase;
`;

export const Annually = styled.span`
  display: flex;
  flex-direction: column;
  color: ${({ theme, active }) =>
    active ? theme.pricing.pro.color : "#4d4d4d"};
  font-weight: ${({ active }) => (active ? "600" : "500")};
  text-transform: uppercase;
  & > span {
    font-size: 12px;
    color: ${({ theme, active }) => (active ? "#e60000" : " #4d4d4d")};

    font-weight: ${({ active }) => (active ? "600" : "500")};
  }
`;

export const ToggleSwitch = withStyles((theme) => ({
  root: {
    width: 58,
    height: 25,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(33px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#ffcc00",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 23,
    height: 23,
  },
  track: {
    borderRadius: 33 / 2,
    // border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: "#ffcc00",
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

export const Section = styled(motion.section)`
  display: flex;
  margin-top: 50px;
  width: 100%;
  flex-direction: column;

  @media screen and (min-width: 720px) {
    display: flex;
    flex-direction: row;
    max-width: 900px;
    column-gap: 32px;
  }
`;

export const PriceCard = styled(motion.div)`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 200px;
  margin-bottom: 24px;
 
  border-radius: ${({ theme }) => theme.borderRadius};
  padding: 16px;
  background-color: ${({ pro, theme }) =>
    pro ? theme.pricing.pro.background : theme.pricing.free.background};
  border: ${({ pro, theme }) =>
    pro ? theme.pricing.pro.border : theme.pricing.free.border};
  box-shadow: ${({ pro, theme }) =>
    pro ? theme.pricing.pro.boxShadow : theme.pricing.free.boxShadow};
  -webkit-box-shadow: ${({ pro, theme }) =>
    pro ? theme.pricing.pro.boxShadow : theme.pricing.free.boxShadow};
  -moz-box-shadow: ${({ pro, theme }) =>
    pro ? theme.pricing.pro.boxShadow : theme.pricing.free.boxShadow};
  #loader {
    height: 24px;
    width: 24px;
    font-size: 24px;
    color: ${({ theme }) => theme.colors.pro};
    animation: ${rotate} 0.8s linear infinite;
  }
`;

export const PlanName = styled.h6`
  text-transform: uppercase;
  color: ${({ theme, pro }) =>
    pro ? theme.pricing.pro.color : theme.pricing.free.color};
  letter-spacing: 1.05px;
`;

export const PlanPrice = styled.div`
  font-size: 28px;
  display: flex;
  align-items: baseline;
  font-weight: 600;
  margin-top: 4px;

  #rupeeSymbol {
    margin-right: 4px;
    font-size: 26px;
  }

  & > span {
    font-size: 16px;
    font-weight: 400;
  }
`;

export const PlanFullPrice = styled.h2`
  font-size: 18px;
  margin-left: 4px;
  text-decoration: line-through;
  color: hsl(300, 0%, 70%);
`;

export const Discount = styled.span`
  margin-left: 4px;
  font-size: 12px;
  color: #e60000;
`;

export const Message = styled.p`
  margin: 24px 0px;
  font-weight: 500;

  #special {
    font-weight: 600;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.pro};
  }
`;

export const Features = styled.ul`
  color: #000000;
  list-style-type: none;
`;

export const FeaturesItem = styled.li`
  padding: 8px 4px;
  list-style-position: inside;
  font-weight: 500;

  & > span {
    margin-right: 8px;
  }
`;

export const PlanButton = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  margin-top: 24px;
  color: #ffffff;
  outline: none;
  border: none;
  font-weight: 550;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  border-radius: 4px;
  cursor: pointer;
  background-color: ${({ theme, pro }) =>
    pro ? theme.colors.pro : theme.colors.free};

  p {
    font-size: 14px;
  }

  #loader {
    height: 24px;
    width: 24px;
    font-size: 24px;
    color: white;
    position: absolute;
    animation: ${rotate} 0.8s linear infinite;
  }
`;

export const ActivePlanLayout = styled(motion.div)`
  display: block;
  border: ${({ theme, pro }) => (pro ? `` : theme.pricing.free.border)};
  border-radius: 4px;
  padding: ${({ pro }) => (pro ? "0px" : "16px")};
  min-height: 40px;
  margin-top: 24px;
  color: ${({ theme, pro }) => (pro ? "#000000" : "#1ac6ff")};
  text-align: center;
  text-transform: ${({ pro }) => (pro ? "" : "uppercase")};
  letter-spacing: ${({ pro }) => (pro ? "" : "1.2px")};

  #expire {
    color: red;
  }
`;

export const SuccessCard = styled(motion.div)`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 16px;

  min-height: 200px;
  border: ${({ theme }) => theme.border};
  border-radius: ${({ theme }) => theme.borderRadius};
  padding: 16px;
`;

export const SvgWrapper = styled(motion.div)`
  svg {
    width: 100%;
    height: 150px;
  }
`;

export const SuccessSvg = styled(SuccessIcon)``;

export const SuccessHeading = styled(motion.p)`
  margin-top: 32px;
  font-size: 24px;
  text-align: center;
  text-transform: capitalize;
  color: #00b300;
`;

export const SuccessMessage = styled(motion.p)`
  margin: 8px 0px;
  text-align: center;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.8);

  #pro {
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.pro};
  }
`;

export const DashboardButton = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  margin-top: 24px;
  color: #ffffff;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  border-radius: 4px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.primary};

  p {
    font-size: 14px;
  }
`;
