import React from "react";
import useComponentVisible from "../../hooks/useComponentVisible";
import {
  BottomNavigationItem,
  BottomNavigationLink,
  BottomNavigationOverFlowMenuContainer,
  BottomNavigationOverflowMenuItem,
  Container,
} from "./BottomNavigation.elements";

function BottomNavigation({ location }) {
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  const handleOverFlowMenuClick = () => {
    setIsComponentVisible(!isComponentVisible);
  };

  return (
    <Container>
      <BottomNavigationItem active={location === "/dashboard"}>
        <BottomNavigationLink to="/dashboard">
          <i class="bi bi-person-fill"></i>
        </BottomNavigationLink>
        <span></span>
      </BottomNavigationItem>
      <BottomNavigationItem active={location === "/dashboard/appearance"}>
        <BottomNavigationLink to="/dashboard/appearance">
          <i class="bi bi-palette-fill"></i>
        </BottomNavigationLink>
        <span></span>
      </BottomNavigationItem>
      <BottomNavigationItem active={location === "/dashboard/analytics"}>
        <BottomNavigationLink to="/dashboard/analytics">
          <i class="bi bi-bar-chart-fill"></i>
        </BottomNavigationLink>
        <span></span>
      </BottomNavigationItem>
      <BottomNavigationItem active={location === "/dashboard/settings"}>
        <BottomNavigationLink to="/dashboard/settings">
          <i class="bi bi-gear-fill"></i>
        </BottomNavigationLink>
        <span></span>
      </BottomNavigationItem>
      <BottomNavigationItem
        active={
          isComponentVisible ||
          location === "/dashboard/billing" ||
          location === "/dashboard/promote"
        }
      >
        <BottomNavigationLink onClick={handleOverFlowMenuClick}>
          <i class="bi bi-three-dots"></i>
        </BottomNavigationLink>
        <span></span>
      </BottomNavigationItem>
      {isComponentVisible && (
        <BottomNavigationOverFlowMenuContainer
          onClick={(e) => setIsComponentVisible(false)}
          ref={ref}
        >
          <BottomNavigationOverflowMenuItem
            to={"/dashboard/billing"}
            active={location === "/dashboard/billing"}
          >
            {" "}
            <span>
              <i class="bi bi-lightning"></i>
            </span>
            <p>Billing and Subscription</p>
          </BottomNavigationOverflowMenuItem>
          <BottomNavigationOverflowMenuItem
            to={"/dashboard/promote"}
            active={location === "/dashboard/promote"}
          >
            {" "}
            <span>
              <i class="bi bi-arrow-up-right-square"></i>
            </span>
            <p>Promote your Business</p>
          </BottomNavigationOverflowMenuItem>
        </BottomNavigationOverFlowMenuContainer>
      )}
    </Container>
  );
}

export default BottomNavigation;
