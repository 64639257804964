import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";

import {
  ChatWidget,
  CommunityUserCard,
  Footer,
  Navbar,
} from "../../components";

import {
  Container,
  Content,
  Banner,
  GetStarted,
  CardContainer,
  Section,
  LoginTextLayout,
  CommunityContainer,
  Heading,
  Title,
  GradientSection,
  ReviewContainer,
  ReviewCard,
  MadeInIndiaSection,
  MobileOverViewContainer,
  MobileOverViewGrid,
  MobileOverViewGridItem,
  MadeInIndiaGetStarted,
  SvgWrapper,
  CreateSvg,
  GrowSvg,
  AnalyseSvg,
  GiveSvg,
  AttractSvg,
  ExpandSvg,
  AddSvg,
  ManageSvg,
  ShareSvg,
  BrandsContainer,
  BrandsDummyContainer,
  ReviewUserImage,
  ReviewWrapper,
  ReviewUserName,
  ReviewUserRole,
  ReviewText,
  ReviewImageWrapper,
} from "./Home.elements";

import {
  fadeInVariants,
  whileTap,
} from "../../animations/animation";

import { db } from "../../firebase/firebase";
import { AuthContext } from "../../contexts/AuthContext";

function Home() {
  const history = useHistory();
  const { isAuthenticated, user } = useContext(AuthContext);

  const [background, setBackground] = useState("#4481eb");
  const [navTheme, setNavTheme] = useState("dark");

 



  const [communityUsers, setCommunityUsers] = useState([]);

  const BrandLogos = [
    {
      name: "Behance",
      image:
        "https://firebasestorage.googleapis.com/v0/b/getmyprofiles-fb7d6.appspot.com/o/data%2FprofileLogos%2Fbehance.jpg?alt=media&token=785a0435-4933-419b-9a45-a5494ab8892b",
    },
    {
      name: "Chingari",
      image:
        "https://firebasestorage.googleapis.com/v0/b/getmyprofiles-fb7d6.appspot.com/o/data%2FprofileLogos%2Fchingari.jpg?alt=media&token=ec9a25e7-c270-4e1c-b661-739eef1bd157",
    },
    {
      name: "Clubhouse",
      image:
        "https://firebasestorage.googleapis.com/v0/b/getmyprofiles-fb7d6.appspot.com/o/data%2FprofileLogos%2Fclubhouse.jpg?alt=media&token=65836775-50ca-4e95-b165-79b089970ca1",
    },
    {
      name: "Discord",
      image:
        "https://firebasestorage.googleapis.com/v0/b/getmyprofiles-fb7d6.appspot.com/o/data%2FprofileLogos%2Fdiscord.jpg?alt=media&token=f71b5e16-8881-46ed-b123-47d9afd936f8",
    },
    {
      name: "Dribble",
      image:
        "https://firebasestorage.googleapis.com/v0/b/getmyprofiles-fb7d6.appspot.com/o/data%2FprofileLogos%2Fdribble.jpg?alt=media&token=dd176ae8-f121-4e23-9980-f524850e7a37",
    },
    {
      name: "Facebook",
      image:
        "https://firebasestorage.googleapis.com/v0/b/getmyprofiles-fb7d6.appspot.com/o/data%2FprofileLogos%2Ffacebook.jpg?alt=media&token=25f1149c-da53-4e35-9874-a9c0db94979e",
    },
    {
      name: "Github",
      image:
        "https://firebasestorage.googleapis.com/v0/b/getmyprofiles-fb7d6.appspot.com/o/data%2FprofileLogos%2Fgithub.jpg?alt=media&token=d7485a95-19e7-4609-a20b-6bf935858480",
    },
    {
      name: "Indeed",
      image:
        "https://firebasestorage.googleapis.com/v0/b/getmyprofiles-fb7d6.appspot.com/o/data%2FprofileLogos%2Findeed.jpg?alt=media&token=3073840d-943f-4ba1-b4e5-897229e7d511",
    },
    {
      name: "Instagram",
      image:
        "https://firebasestorage.googleapis.com/v0/b/getmyprofiles-fb7d6.appspot.com/o/data%2FprofileLogos%2Finstagram.jpg?alt=media&token=971b0e02-3452-4293-8e42-d7f2ce1e6f59",
    },
    {
      name: "Josh",
      image:
        "https://firebasestorage.googleapis.com/v0/b/getmyprofiles-fb7d6.appspot.com/o/data%2FprofileLogos%2Fjosh.jpg?alt=media&token=4e6fabb3-0ceb-47cd-aeab-e666f26055bb",
    },
    {
      name: "Koo",
      image:
        "https://firebasestorage.googleapis.com/v0/b/getmyprofiles-fb7d6.appspot.com/o/data%2FprofileLogos%2Fkoo.jpg?alt=media&token=93bc6340-3936-475c-9b8c-cd097a25fa93",
    },
    {
      name: "Linked In",
      image:
        "https://firebasestorage.googleapis.com/v0/b/getmyprofiles-fb7d6.appspot.com/o/data%2FprofileLogos%2Flinkedin.jpg?alt=media&token=a0f04aa6-36e0-40c0-aefc-55cb3ceef95e",
    },
    {
      name: "Taka Tak",
      image:
        "https://firebasestorage.googleapis.com/v0/b/getmyprofiles-fb7d6.appspot.com/o/data%2FprofileLogos%2Fmxtakatak.jpg?alt=media&token=c5bfd97d-0352-44b1-bfa6-aa0fe54cc104",
    },
    {
      name: "SnapChat",
      image:
        "https://firebasestorage.googleapis.com/v0/b/getmyprofiles-test.appspot.com/o/data%2FprofileImages%2Fsnapchat.png?alt=media&token=726448dc-d225-4af8-a361-bd87bdfbcbad",
    },
    {
      name: "Moj",
      image:
        "https://firebasestorage.googleapis.com/v0/b/getmyprofiles-fb7d6.appspot.com/o/data%2FprofileLogos%2Fmoj.jpg?alt=media&token=dd5b30a4-59b7-4137-8c52-3d058d22639e",
    },
    {
      name: "Whatsapp",
      image:
        "https://firebasestorage.googleapis.com/v0/b/getmyprofiles-fb7d6.appspot.com/o/data%2FprofileLogos%2Fwhatsapp.jpg?alt=media&token=8cc5d179-dcc2-4841-86da-d5d6d58d2e98",
    },
    {
      name: "Pinterest",
      image:
        "https://firebasestorage.googleapis.com/v0/b/getmyprofiles-fb7d6.appspot.com/o/data%2FprofileLogos%2Fpinterest.jpg?alt=media&token=60e5f452-8b5f-409d-8330-030e0f9a1de5",
    },
    {
      name: "Twitter",
      image:
        "https://firebasestorage.googleapis.com/v0/b/getmyprofiles-fb7d6.appspot.com/o/data%2FprofileLogos%2Ftwitter.jpg?alt=media&token=f8d92536-c6b9-48d3-b1f8-ca442720bebf",
    },
    {
      name: "Wattpad",
      image:
        "https://firebasestorage.googleapis.com/v0/b/getmyprofiles-fb7d6.appspot.com/o/data%2FprofileLogos%2Fwattpad.jpg?alt=media&token=c2f50c1e-6983-457f-8276-975c4e92c013",
    },
    {
      name: "Youtube",
      image:
        "https://firebasestorage.googleapis.com/v0/b/getmyprofiles-fb7d6.appspot.com/o/data%2FprofileLogos%2Fyoutube.jpg?alt=media&token=1528bdd7-7cf4-4af8-a678-cc03447283fd",
    },
  ];

  const UserReviews = [
    {
      review:
        "I was having very unbalanced followers across my social profiles. Then i came to know about getmyprofiles bio tool. They just helped me to overcome this. Thank You team! All the best for future journey.",
      role: "Influencer",
      name: "Bharath",
      profilePhoto:
        "https://firebasestorage.googleapis.com/v0/b/getmyprofiles-fb7d6.appspot.com/o/data%2FreviewImages%2Finfluencer_picture_male.jpeg?alt=media&token=559b9c8d-a20f-4cd8-a171-c72848ef8070",
    },
    {
      review:
        "I was finding very difficult to navigate my followers to the restaurants i reviewed. with the help of getmyprofiles i just added every link of the place i reviewed and pasted the getmyprofiles link on my bio. Thats it.They just made my job easy.",
      role: "Food Blogger",
      name: "Priyanka",
      profilePhoto:
        "https://firebasestorage.googleapis.com/v0/b/getmyprofiles-fb7d6.appspot.com/o/data%2FreviewImages%2Ffood_blogger_picture_female.jpeg?alt=media&token=c1af7a84-ee69-4d3d-be1a-6d9874faaffa",
    },
    {
      review:
        "I really like this tool.It helped me to put all my streaming channels in one place which helped me to increase my revenue across platforms. I highly recommend this for any new youtubers, creators, businesses or anyone having multiple social profiles or links.",
      role: "Gamer",
      name: "Beast16",
      profilePhoto:
        "https://firebasestorage.googleapis.com/v0/b/getmyprofiles-fb7d6.appspot.com/o/data%2FreviewImages%2Fgamer_picture_male.jpg?alt=media&token=d61156e9-385f-4695-9358-dfaf54698e63",
    },
    {
      review:
        "This is super cool.With the help of them we just expanded our business.Was finding difficult to say our audience wherever we have branches.With the help of getmyprofiles we just linked all of our branches in one place and shared their link to our visitors.",
      role: "Business",
      name: "Dine & Hang",
      profilePhoto:
        "https://firebasestorage.googleapis.com/v0/b/getmyprofiles-fb7d6.appspot.com/o/data%2FreviewImages%2Frestaurant_picture.jpeg?alt=media&token=f7505ab9-5701-41dd-857c-1bff86b80cb7",
    },
    {
      review:
        "From very long time i was looking for an indian alternative, affordable bio link tool.Finally i found them.This is super easy to use and reliable. It's free version works better than other link tools paid version. Highly Recomended.",
      role: "Content Creator",
      name: "Rachael",
      profilePhoto:
        "https://firebasestorage.googleapis.com/v0/b/getmyprofiles-fb7d6.appspot.com/o/data%2FreviewImages%2Finfluencer_picture_female.jpeg?alt=media&token=1e54a339-c0c1-42c7-8634-fdf6d07cd8f7",
    },
  ];

  const handleGetStarted = (e) => {
    e.preventDefault();

    if (!isAuthenticated) {
      history.push("/signup");
    } else {
      history.push("/dashboard");
    }
  };

  const handleLogin = (e) => {
    e.preventDefault();

    if (!isAuthenticated) {
      history.push("/login");
    } else {
      history.push("/dashboard");
    }
  };

  const navigateToDashboard = (e) => {
    e.preventDefault();

    if (!isAuthenticated) {
      history.push("/signup");
    } else {
      history.push("/dashboard");
    }
  };

  const navigateToAnalytics = (e) => {
    e.preventDefault();

    if (!isAuthenticated) {
      history.push("/login");
    } else {
      history.push("/dashboard/analytics");
    }
  };

  const navigateToAppearance = (e) => {
    e.preventDefault();

    if (!isAuthenticated) {
      history.push("/login");
    } else {
      history.push("/dashboard/appearance");
    }
  };

 

  const getCommunityUsers = () => {
    db.collection("app")
      .doc("data")
      .collection("community")
      .orderBy("order")
      .get()
      .then((querySnapshot) => {
        const communityUsersList = [];
        querySnapshot.forEach((doc) => {
          communityUsersList.push(doc.data());
        });
        setCommunityUsers(communityUsersList);
      });
  };

  const listenScrollEvent = (event) => {
    if (window.scrollY < 350) {
      setBackground("#4481eb");
      setNavTheme("light");
    } else {
      setBackground("#ffffff");
      setNavTheme("dark");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
    return () => window.removeEventListener("scroll", listenScrollEvent);
  });

  useEffect(() => {
    getCommunityUsers();
  }, []);

  useEffect(() => {
    document.title = "Home" + " | " + process.env.REACT_APP_TITLE;
  }, []);

  return (
    <Container initial="initial" animate="animate" variants={fadeInVariants}>
      <Navbar navLocation="/" background={background} navTheme={navTheme} />
      <Content>
        <GradientSection background="linear-gradient(to bottom, #4481eb 0%, #04befe 100%)">
          <Banner>
            <h2>One Link All You Need</h2>
            <p>To build and grow your profile.</p>
            <GetStarted whileTap={whileTap} onClick={handleGetStarted}>
              Get started—it’s free
            </GetStarted>
            <LoginTextLayout>
              <span>
                Already have an account?<a onClick={handleLogin}>Login.</a>
              </span>
            </LoginTextLayout>
          </Banner>

          <MobileOverViewContainer>
            <MobileOverViewGrid>
              <MobileOverViewGridItem className="usersGridItem">
                <div>
                  <h6>40000+</h6>
                  <p>Active Users</p>
                </div>
                <span>
                  <i class="bi bi-person"></i>
                </span>
              </MobileOverViewGridItem>
              <MobileOverViewGridItem className="proUsersGridItem">
                <span>
                  <i class="bi bi-currency-dollar"></i>
                </span>
                <div>
                  <h6>2000+</h6>
                  <p>Pro Users</p>
                </div>
              </MobileOverViewGridItem>
              <MobileOverViewGridItem className="clicksGridItem">
                <div>
                  <h6>1L+</h6>
                  <p>Clicks Daily</p>
                </div>
                <span>
                  <i class="bi bi-hand-index"></i>
                </span>
              </MobileOverViewGridItem>
              <MobileOverViewGridItem className="viewsGridItem">
                <span>
                  <i class="bi bi-eye"></i>
                </span>
                <div>
                  <h6>80000+</h6>
                  <p>Visits Daily </p>
                </div>
              </MobileOverViewGridItem>
            </MobileOverViewGrid>
          </MobileOverViewContainer>
        </GradientSection>

        <Section>
          <CardContainer variant="one" onClick={navigateToDashboard}>
            <SvgWrapper variant="one">
              <CreateSvg />
            </SvgWrapper>

            <h4>
              Create <i class="bi bi-arrow-right-circle-fill"></i>
            </h4>
            <p>
              Create your account easily with simple steps in seconds. Help your
              followers discover all of your recent content.
            </p>
          </CardContainer>

          <CardContainer variant="one" onClick={navigateToAnalytics}>
            <SvgWrapper variant="one">
              <AnalyseSvg />
            </SvgWrapper>

            <h4>
              Analyse <i class="bi bi-arrow-right-circle-fill"></i>
            </h4>
            <p>
              Identify and Analyse your audience with the help of our detailed
              analytics report. Get valuable insights into your traffic
              including clicks, pageviews, and sales.
            </p>
          </CardContainer>
          <CardContainer
            variant="one"
            number={"three"}
            onClick={navigateToDashboard}
          >
            <SvgWrapper variant="one">
              <GrowSvg />
            </SvgWrapper>

            <h4>
              Grow <i class="bi bi-arrow-right-circle-fill"></i>
            </h4>
            <p>
              Study the flow of audience to your content and grow your profiles
              accordingly. Explore where your traffic is coming from and grow
              your business accordingly.
            </p>
          </CardContainer>
        </Section>
        <Section>
          <CardContainer variant="two" onClick={navigateToDashboard}>
            <SvgWrapper variant="two">
              <GiveSvg />
            </SvgWrapper>

            <h4>
              Give <i class="bi bi-arrow-right-circle-fill"></i>
            </h4>
            <p>
              Give your audience a super power to choose what they want by
              providing all possible options in single place.
            </p>
          </CardContainer>
          <CardContainer variant="two" onClick={navigateToAppearance}>
            <SvgWrapper variant="two">
              <AttractSvg />
            </SvgWrapper>

            <h4>
              Attract <i class="bi bi-arrow-right-circle-fill"></i>
            </h4>
            <p>
              Attract the audience by customizing your profile page with various
              pre-built Themes and Styles.
            </p>
          </CardContainer>
          <CardContainer variant="two" onClick={navigateToDashboard}>
            <SvgWrapper variant="two">
              <ExpandSvg />
            </SvgWrapper>

            <h4>
              Expand <i class="bi bi-arrow-right-circle-fill"></i>
            </h4>
            <p>
              Expand your buisness in no time. Learn what your audience need ,
              where they go etc. Expand your business accordingly
            </p>
          </CardContainer>
        </Section>
        <Section>
          <CardContainer variant="three" onClick={navigateToDashboard}>
            <SvgWrapper variant="three">
              <AddSvg />
            </SvgWrapper>

            <h4>
              Add <i class="bi bi-arrow-right-circle-fill"></i>
            </h4>
            <p>
              Add all your profiles and links in one place. Help your followers
              or audience discover all of your recent content.
            </p>
          </CardContainer>
          <CardContainer variant="three" onClick={navigateToDashboard}>
            <SvgWrapper variant="three">
              <ManageSvg />
            </SvgWrapper>

            <h4>
              Manage <i class="bi bi-arrow-right-circle-fill"></i>
            </h4>
            <p>
              Easily manage your profiles and links in one place. Use our simple
              drag-and-drop editor and kill switch to effortlessly manage your
              content.
            </p>
          </CardContainer>
          <CardContainer variant="three" onClick={navigateToDashboard}>
            <SvgWrapper variant="three">
              <ShareSvg />
            </SvgWrapper>

            <h4>
              Share <i class="bi bi-arrow-right-circle-fill"></i>
            </h4>
            <p>
              Share your getmyprofiles bio link on any digital or social
              platforms or anywhere you want. Share all your social media
              profiles and important links with just one link.
            </p>
          </CardContainer>
        </Section>

        <GradientSection>
          <Heading>Where our most of </Heading>
          <Title>
            Links are <span id="shared">Shared</span>{" "}
          </Title>

          <BrandsContainer>
            <div className="brandLogos">
              {BrandLogos.map((brandLogo) => (
                <img
                  key={brandLogo.name}
                  src={brandLogo.image}
                  alt={brandLogo.name}
                />
              ))}
            </div>

            <BrandsDummyContainer></BrandsDummyContainer>
          </BrandsContainer>

          <Heading>What our </Heading>
          <Title>
            <span id="users">Users</span> Tell
          </Title>

          <ReviewContainer>
            {UserReviews.map((userReview) => (
              <ReviewCard key={userReview.name}>
                <ReviewImageWrapper>
                  <ReviewUserImage
                    src={userReview.profilePhoto}
                    alt="review_user_photo"
                  />
                </ReviewImageWrapper>

                <ReviewWrapper>
                  <ReviewUserName>{userReview.name}</ReviewUserName>
                  <ReviewUserRole>{userReview.role}</ReviewUserRole>

                  <ReviewText>{userReview.review}</ReviewText>
                </ReviewWrapper>
              </ReviewCard>
            ))}
          </ReviewContainer>

          <Heading>Our</Heading>
          <Title>
            <span id="community">Community</span>
          </Title>

          <CommunityContainer>
            {communityUsers.map((communityUser) => (
              <CommunityUserCard
                key={communityUser.name}
                communityUser={communityUser}
              />
            ))}
          </CommunityContainer>
        </GradientSection>

        <MadeInIndiaSection>
          <Heading light>Made In India</Heading>
          <Title light>For India</Title>
          <MadeInIndiaGetStarted whileTap={whileTap} onClick={handleGetStarted}>
            Get started—it’s free
          </MadeInIndiaGetStarted>
        </MadeInIndiaSection>
      </Content>

      <ChatWidget progress={false} />
      <Footer />
    </Container>
  );
}

export default Home;
