import styled from "styled-components";
import { ReactComponent as ForgotPasswordIcon } from "../../images/forgot_password.svg";
import { motion } from "framer-motion";

export const Container = styled(motion.div)`
  width: 100%;
  display: flex;
  margin: 16px;
  padding: 16px;
  border: ${({ theme }) => theme.border};
  flex-direction: column;
  border-radius: 4px;
  max-width: 750px;
`;

export const Section = styled(motion.div)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const SvgWrapper = styled(motion.div)`
  margin: 24px 0px;
  width: 100%;
  svg {
    width: 100%;
    height: 150px;
  }
`;

// Style the component (treated like styled.svg in this case)
export const ForgotPasswordSvg = styled(ForgotPasswordIcon)`
  width: 500px;
  height: 200px;
`;
