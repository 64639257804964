import React from "react";
import { useHistory } from "react-router-dom";
import { dropInVariants, whileTap } from "../../animations/animation";
import { PlanButton } from "../../pages/pricing/Pricing.elements";
import { Container, Heading, Message, UpgradeSvg } from "./BuyPro.elements";

function BuyPro({ setShowUpgradeToProLayout, type }) {
  const history = useHistory();

  const handleUpgradeButtonClick = () => {
    history.push("/pricing");
  };

  return (
    <Container
      variants={dropInVariants}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <Heading>Upgrade To PRO</Heading>
      <span onClick={() => setShowUpgradeToProLayout(false)} id="buyClose">
        <i class="bi bi-x-lg"></i>
      </span>
      <UpgradeSvg />

      {type === "profiles" && (
        <Message>
          You can only add <span id="limitation">5 profiles</span> in the free
          plan. Upgrade your paln to add{" "}
          <span id="advantage">unlimited profiles</span>
        </Message>
      )}

      {type === "links" && (
        <Message>
          You can only add <span id="limitation">3 Links</span> in the free
          plan. Upgrade your paln to add{" "}
          <span id="advantage">unlimited links</span>
        </Message>
      )}

      {type === "images" && (
        <Message>
          You can only add <span id="limitation">1 Image</span> in the free
          plan. Upgrade your paln to add{" "}
          <span id="advantage">unlimited images</span>
        </Message>
      )}

      {type === "videos" && (
        <Message>
          You can only add <span id="limitation">1 Video</span> in the free
          plan. Upgrade your paln to add{" "}
          <span id="advantage">unlimited videos</span>
        </Message>
      )}

      {type === "fonts" && (
        <Message>
          <span id="advantage">Pro Fonts</span> are only available when you
          upgrade your plan to pro.
        </Message>
      )}

      {type === "themes" && (
        <Message>
          <span id="advantage">Pro Themes</span> are only available when you
          upgrade your plan to pro.
        </Message>
      )}

      {type === "backgroundImage" && (
        <Message>
          You can add <span id="advantage"> Background Image</span> only when
          you upgrade your plan to pro.
        </Message>
      )}

      <PlanButton whileTap={whileTap} pro onClick={handleUpgradeButtonClick}>
        Upgrade To Pro
      </PlanButton>
    </Container>
  );
}

export default BuyPro;
