import React, { useEffect } from "react";
import { Footer, Navbar } from "../../components";
import { Container, Content, Paragraph, Heading } from "./Terms.elements";
import { useHistory } from "react-router-dom";

function Terms() {
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, []);

  return (
    <Container>
      <Navbar navLocation="/terms" />
      <Content>
        <Heading>Account Terms</Heading>
        <Paragraph>
          Accounts registered by automated methods are not permitted and will be
          deleted immediately without notice.
          <br />
          <br />
          You must provide your legal full name, a valid email address, and any
          other pertinent information requested in order to complete the sign-up
          process.
          <br />
          <br />
          You are responsible for maintaining the security of your account and
          password. Getmyprofiles.in cannot and will not be liable for any loss
          of information or security breaches that result from your failure to
          comply with this security obligation.You are hereby considered fully
          responsible for all content posted and any and all activity that
          occurs under your account.
          <br />
          <br />
          You may not use as a username the name of another person or entity or
          that is not lawfully available for use, a name or trademark that is
          subject to any rights of another person or entity other than you
          without appropriate authorization, or a name that is otherwise
          offensive, vulgar or obscene.
          <br />
          <br />
          You may not use the Service for any illegal activities. You must not,
          in your use of the Service, violate any laws in your jurisdiction
          (including but not limited to copyright laws). Should you engage in
          any illegal activities through the Service, you agree to hold
          Getmyprofiles.in blameless and not liable for any damages or crimes
          that may occur as a result. You will also have your account
          immediately shut down, and all data therein deleted.
          <br />
          <br />
          You may not use the Service to sell or distribute offensive or illegal
          content (goods, software, products or material). You agree
          Getmyprofiles.in holds the right to determine what is considered
          "offensive" as pertaining to this agreement. Offensive and illegal
          content includes: things that are sexually-oriented or pornographic,
          drugs, gambling, things that promote hate or violence towards others.
          You will have your account immediately shut down, and all data
          there-in deleted should you sell or distribute illegal or offensive
          content.
          <br />
          <br />
          You understand that the Service uses a third-party payment processor
          Stripe (stripe.com) to handle payment transactions, and that all
          monetary transactions made through your use of the Service are subject
          to any fees Stripe may charge.
          <br />
          <br />
          Additionally, service supports Google's initiative to enable a free
          and open web. Thus, we will have your account immediately shut down,
          and all data there-in deleted should you breach any of the below
          violations.
        </Paragraph>
        <Heading>Refunds</Heading>
        <Paragraph>
          Except when required by law, paid Subscription fees are
          non-refundable.
        </Paragraph>
      </Content>
      <Footer />
    </Container>
  );
}

export default Terms;
