import styled from "styled-components";
import { motion } from "framer-motion";
import { ReactComponent as CreateIcon } from "../../images/home_card_create.svg";
import { ReactComponent as AnalyseIcon } from "../../images/home_card_analyse.svg";
import { ReactComponent as GrowIcon } from "../../images/home_card_grow.svg";

import { ReactComponent as GiveIcon } from "../../images/home_card_give.svg";
import { ReactComponent as AttractIcon } from "../../images/home_card_attract.svg";
import { ReactComponent as ExpandIcon } from "../../images/home_card_expand.svg";

import { ReactComponent as AddIcon } from "../../images/home_card_add.svg";
import { ReactComponent as ManageIcon } from "../../images/home_card_manage.svg";
import { ReactComponent as ShareIcon } from "../../images/home_card_share.svg";

export const Container = styled(motion.div)`
  background-color: white;
`;

export const Content = styled(motion.div)`
  background-color: white;
`;

export const Banner = styled(motion.div)`
  display: flex;
  flex-direction: column;
  min-height: 200px;
  align-items: center;
  justify-content: center;
  color: white;

  & > h2 {
    font-size: 28px;
    margin: 16px 8px 0px 8px;
    text-align: center;
  }

  & > p {
    margin: 0px 16px 0px 16px;
    text-align: center;
  }

  @media (min-width: 768px) {
    & > h2 {
      font-size: 60px;
    }

    & > p {
      font-size: 24px;
    }
  }
`;

export const GetStarted = styled(motion.button)`
  height: 40px;
  padding: 8px 24px 8px 24px;
  background-color: #ffffff;
  color: white;
  border: none;
  border-radius: 4px;
  max-width: 250px;
  margin: 8px 16px 8px 16px;
  outline: none;
  outline-width: 0px;
  cursor: pointer;
  font-size: 16px;
  color: #000000;

  &:hover {
    outline-width: 0px;
  }

  @media (min-width: 768px) {
    font-size: 18px;
  }
`;

export const MobileOverViewContainer = styled(motion.div)`
  width: 100%;
  display: flex;
  justify-content: center;
  color: white;
  @media (min-width: 768px) {
    display: none;
  }
`;

export const MobileOverViewGrid = styled(motion.div)`
  display: grid;
  width: 100%;
  grid-template-columns: auto auto;

  .usersGridItem,
  .clicksGridItem {
    justify-content: flex-end;

    & > div {
      margin-right: 8px;
      text-align: end;
    }
  }

  .proUsersGridItem,
  .viewsGridItem {
    & > div {
      margin-left: 8px;
      text-align: start;
    }
  }

  .usersGridItem {
    border-right: 0.5px solid white;
    border-bottom: 0.5px solid white;
  }

  .proUsersGridItem {
    border-left: 0.5px solid white;
    border-bottom: 0.5px solid white;
  }

  .clicksGridItem {
    border-top: 0.5px solid white;
    border-right: 0.5px solid white;
  }

  .viewsGridItem {
    border-top: 0.5px solid white;
    border-left: 0.5px solid white;
  }
`;

export const MobileOverViewGridItem = styled(motion.div)`
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;

  & > div {
    h6 {
      font-size: 24px;
      font-weight: 400;
    }

    p {
      font-size: 14px;
    }
  }

  & > span {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    font-size: 24px;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 50%;
    text-align: center;
    vertical-align: middle;
    color: #404040;
  }
`;

export const Section = styled(motion.section)`
  background-color: #fffefd;

  @media (min-width: 768px) {
    display: flex;
    flex-direction: row;
    margin: 0px 56px;
  }
`;

export const CardContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 36px 32px;
  background-color: ${({ variant }) =>
    (variant === "one" && "rgba(185, 131, 255,0.04)") ||
    (variant === "two" && "rgba(255, 179, 68,0.04)") ||
    (variant === "three" && "rgba(61, 178, 255,0.04)") ||
    "#000000"};
  box-shadow: 0px 2px 8px rgb(141 123 209 / 20%);
  min-height: 400px;
  border-radius: 4px;
  flex: 1;
  cursor: pointer;
  h4 {
    margin: 16px;
    text-align: center;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    font-size: 18px;

    color: ${({ variant }) =>
      (variant === "one" && "#b983ff") ||
      (variant === "two" && "#ffb344") ||
      (variant === "three" && "#3db2ff") ||
      "#000000"};

    .bi {
      font-size: 18px;
      margin-left: 8px;
    }
  }

  p {
    text-align: center;
    margin: 0px 16px;
    line-height: 20px;
    color: #333333;
  }
`;

export const LoginTextLayout = styled(motion.div)`
  display: flex;
  flex-direction: row;
  justify-content: center;

  span {
    font-size: 14px;
    margin: 0px 24px 24px 24px;

    a {
      font-size: 14px;
      margin-left: 4px;
      font-weight: 600;
      text-decoration: underline;
      cursor: pointer;
      color: #ffffff;
    }
  }

  @media (min-width: 768px) {
    span {
      font-size: 14px;
    }
  }
`;

export const CommunityContainer = styled(motion.div)`
  display: flex;
  flex-direction: row;
  margin: 0px -16px 24px -16px;
  overflow-x: scroll;
  overflow-y: hidden;
  margin-bottom: 24px;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const GradientSection = styled(motion.section)`
  padding: 16px;
  background: ${({ background }) => (background ? background : "#FFFFFF")};
  @media (min-width: 768px) {
    padding: 16px 56px;
  }
`;

export const MadeInIndiaSection = styled(motion.div)`
  padding: 16px;
  background: linear-gradient(140deg, #ff8359, #ffdf40);
  @media (min-width: 768px) {
    padding: 16px 56px;
  }

  h4 {
    font-size: 24px;
  }
`;

export const Heading = styled(motion.h6)`
  font-size: 20px;
  color: ${({ light }) => (light ? "#ffffff" : "#000000")};
`;

export const Title = styled(motion.h2)`
  font-size: 32px;
  color: ${({ light }) => (light ? "#ffffff" : "#000000")};

  #shared {
    color: #a44cd3;
  }

  #users {
    color: #ff6a3d;
  }

  #community {
    color: #61082b;
  }
`;

export const BrandsContainer = styled(motion.div)`
  width: 100%;
  margin: 24px 0px;
  display: flex;
  flex-direction: row;
  & > .brandLogos {
    display: flex;
    flex-wrap: wrap;
    background-color: #a44cd3;
    border-radius: 4px;
    justify-content: space-between;
    padding: 8px;
    img {
      height: 50px;
      width: 50px;
      margin: 16px 8px;
      object-fit: cover;
      border-radius: 4px;
    }
  }
`;

export const BrandsDummyContainer = styled(motion.div)`
  display: none;
  width: 100%;
  background-color: transparent;
  @media (min-width: 768px) {
    display: block;
  }
`;

export const ReviewContainer = styled(motion.div)`
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  overflow-y: hidden;
  margin: 0px -16px 24px -16px;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const ReviewCard = styled(motion.div)`
  display: flex;
  flex-direction: column;
  background-color: #1a2238;
  color: #ffffff;
  border-radius: 4px;
  margin: 16px;
  box-shadow: 0px 2px 8px rgb(26 34 56 / 20%);
  z-index: 22;
`;

export const ReviewImageWrapper = styled.div`
  height: 220px;
  width: 320px;

  @media (min-width: 768px) {
    width: 380px;
  }
`;

export const ReviewUserImage = styled.img`
  height: 100%;
  width: 100%;
  background-color: #ededed;
  object-fit: cover;
  border-radius: 4px 4px 0px 0px;
`;

export const ReviewUserName = styled.h6`
  font-size: 32px;
  color: #ff6a3d;
`;

export const ReviewUserRole = styled.p`
  font-size: 18px;
  font-weight: 550;
  color: #9daaf2;
`;

export const ReviewText = styled.p`
  font-size: 18px;
  ::before {
    content: "“";
    display: block;
    font-style: initial;
    font-size: 5rem;
    line-height: 1;
    text-align: left;
    margin-bottom: -30px;
  }

  ::after {
    content: "”";
    display: block;
    font-style: initial;
    font-size: 5rem;
    line-height: 1;
    text-align: right;
    margin-bottom: -60px;
  }
`;

export const ReviewWrapper = styled.div`
  background-color: #1a2238;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  border-radius: 0px 0px 4px 4px;
`;


export const MadeInIndiaGetStarted = styled(motion.button)`
  height: 50px;
  padding: 8px 24px 8px 24px;
  background-color: white;
  color: white;
  border: none;
  border-radius: 4px;
  max-width: 250px;
  margin: 24px 0px;
  outline: none;
  outline-width: 0px;
  cursor: pointer;
  color: #000000;
  font-size: 18px;
  &:hover {
    outline-width: 0px;
  }
`;

export const SvgWrapper = styled(motion.div)`
  background-color: ${({ variant }) =>
    (variant === "one" && "#b983ff") ||
    (variant === "two" && "#ffb344") ||
    (variant === "three" && "#3db2ff") ||
    ""};
  width: 100%;
  border-radius: 8px 8px 0px 0px;
  svg {
    width: 100%;
    height: 200px;
    padding: 24px;
  }
`;

// Style the component (treated like styled.svg in this case)
export const CreateSvg = styled(CreateIcon)`
  height: 200px;
`;

export const AnalyseSvg = styled(AnalyseIcon)`
  height: 200px;
  color: red;
`;

export const GrowSvg = styled(GrowIcon)`
  height: 200px;
`;

export const GiveSvg = styled(GiveIcon)`
  height: 200px;
`;

export const AttractSvg = styled(AttractIcon)`
  height: 200px;
`;

export const ExpandSvg = styled(ExpandIcon)`
  height: 200px;
`;

export const AddSvg = styled(AddIcon)`
  height: 200px;
`;

export const ManageSvg = styled(ManageIcon)`
  height: 200px;
`;

export const ShareSvg = styled(ShareIcon)`
  height: 200px;
`;
