import React from "react";
import styled from "styled-components";

function ShapeItem({ active, shape, handleClick }) {
  return (
    <Container active={active}>
      <ShapeStyle
        onClick={() => handleClick(shape)}
        shape={shape}
        borderRadius={shape.borderRadius}
      ></ShapeStyle>
    </Container>
  );
}

const Container = styled.div`
  border: ${({ active, theme }) =>
    active ? theme.appearance.active.border : "1px solid transparent"};
  padding: 8px;
  margin: 8px;
  border-radius: 4px;
  background: ${({ active, theme }) =>
    active ? theme.appearance.active.background : ""};
  cursor: pointer;
`;

const ShapeStyle = styled.div`
  height: 50px;
  width: 50px;
  background-color: white;
  border: ${({ theme, active }) =>
    active
      ? theme.appearance.active.itemBorder
      : theme.appearance.border.itemBorder};
  border-top-left-radius: ${({ shape }) => shape.topLeftBorderRadius};
  border-top-right-radius: ${({ shape }) => shape.topRightBorderRadius};
  border-bottom-right-radius: ${({ shape }) => shape.bottomRightBorderRadius};
  border-bottom-left-radius: ${({ shape }) => shape.bottomLeftBorderRadius};
  border-radius: ${({ shape }) => shape.borderRadius};
`;

export default ShapeItem;
