import React, { useState, useEffect, useContext } from "react";
import { useLocation, useHistory } from "react-router-dom";

import { db } from "../../../firebase/firebase";
import { Button, ErrorLayout, SuccessLayout } from "../../../components/index";
import validator from "validator";
import firebase from "firebase";
import {
  Container,
  Content,
  EmailLayout,
  Title,
  Value,
  ChangeEmailLayout,
  Input,
  Label,
  Message,
  ChangePasswordLayout,
  PasswordLayout,
  Section,
  AccountItemContainer,
} from "./Settings.elements";
import { Heading } from "../appearance/Appearance.elements";
import { fadeInVariants, moveUpVariants } from "../../../animations/animation";
import { HorizontalBreak } from "../../../themes/globalStyledComponents";

function Settings({ user, setLocation }) {
  const location = useLocation();
  useEffect(() => {
    setLocation(location.pathname);
  }, []);
  const history = useHistory();

  //Email
  const [showChangeEmailLayout, setShowChangeEmailLayout] = useState(false);
  const [newEmail, setNewEmail] = useState("");
  const [confirmNewEmail, setConfirmNewEmail] = useState("");
  const [emailPassword, setEmailPassword] = useState("");
  const [newEmailError, setNewEmailError] = useState("");
  const [confirmNewEmailError, setConfirmNewEmailError] = useState("");
  const [emailPasswordError, setEmailPasswordError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [emailProgress, setEmailProgress] = useState(false);

  //Password

  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [currentPasswordError, setCurrentPasswordError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [confirmNewPasswordError, setConfirmNewPasswordError] = useState("");
  const [passwordProgress, setPasswordProgress] = useState(false);
  const [passwordSuccess, setPasswordSuccess] = useState("");
  const [showChangePasswordLayout, setShowChangePasswordLayout] =
    useState(false);

  const [passwordResetLinkProgress, setPasswordResetLinkProgress] =
    useState(false);
  const [passwordResetLinkError, setPasswordResetLinkError] = useState("");
  const [passwordResetLinkSuccess, setPasswordResetLinkSuccess] = useState("");

  const [signoutProgress, setSignoutProgress] = useState(false);
  const [signoutError, setSignoutError] = useState("");

  useEffect(() => {
    document.title = "Settings" + " | " + process.env.REACT_APP_TITLE;
  }, []);

  //Email

  const handleNewEmailChange = (e) => {
    e.preventDefault();
    e.currentTarget.value = e.currentTarget.value.replace(/\s/g, "");
    setNewEmail(e.currentTarget.value);
    setNewEmailError("");
    setEmailError("");
  };

  const handleConfirmNewEmailChange = (e) => {
    e.preventDefault();
    e.currentTarget.value = e.currentTarget.value.replace(/\s/g, "");
    setConfirmNewEmail(e.currentTarget.value);
    setConfirmNewEmailError("");
    setEmailError("");
  };

  const handleEmailPasswordChange = (e) => {
    e.currentTarget.value = e.currentTarget.value.replace(/\s/g, "");
    setEmailPassword(e.currentTarget.value);
    setEmailPasswordError("");
  };

  const handleChangeEmailButtonClick = () => {
    if (newEmail === "") {
      setNewEmailError("Enter New Email");
      return;
    }

    if (!validator.isEmail(newEmail) || newEmail === "") {
      setNewEmailError("Invalid Email!");
      return;
    }

    setNewEmailError("");

    if (newEmail !== confirmNewEmail) {
      setConfirmNewEmailError("New Email does not match");
      return;
    }

    setConfirmNewEmailError("");

    if (emailPassword === "") {
      setEmailPasswordError("Enter your account Password");
      return;
    }

    setNewEmailError("");
    setConfirmNewEmailError("");
    setEmailPasswordError("");
    setEmailError("");

    setEmailProgress(true);

    var firebaseUser = firebase.auth().currentUser;
    const credential = firebase.auth.EmailAuthProvider.credential(
      firebaseUser.email,
      emailPassword
    );

    firebaseUser
      .reauthenticateWithCredential(credential)
      .then(() => {
        firebaseUser
          .updateEmail(newEmail)
          .then(() => {
            db.collection("users")
              .doc(firebaseUser.uid)
              .update({
                email: newEmail,
              })
              .then(() => {
                firebaseUser
                  .sendEmailVerification()
                  .then(() => {
                    handleUserSignout();
                  })
                  .catch((error) => {
                    setEmailError(error.message);
                    setEmailProgress(false);
                  });
              })
              .catch((error) => {
                setEmailError(error.message);
                setEmailProgress(false);
              });
          })
          .catch((error) => {
            setEmailError(error.message);
            setEmailProgress(false);
          });
      })
      .catch((error) => {
        setEmailPasswordError(error.message);
        setEmailProgress(false);
      });
  };

  //Password

  const handlePasswordChange = (e) => {
    e.preventDefault();
    e.currentTarget.value = e.currentTarget.value.replace(/\s/g, "");
    setPassword(e.currentTarget.value);
    setPasswordError("");
  };

  const handleNewPasswordChange = (e) => {
    e.preventDefault();
    e.currentTarget.value = e.currentTarget.value.replace(/\s/g, "");
    setNewPassword(e.currentTarget.value);
    setNewPasswordError("");
  };

  const handleConfirmNewPasswordChange = (e) => {
    e.preventDefault();
    e.currentTarget.value = e.currentTarget.value.replace(/\s/g, "");
    setConfirmNewPassword(e.currentTarget.value);
    setConfirmNewPasswordError("");
  };

  const handleChangePasswordButtonClick = () => {
    setPasswordSuccess("");

    if (password === "") {
      setCurrentPasswordError("Enter your account Password");
      return;
    }

    setCurrentPasswordError(false);

    if (newPassword === "") {
      setNewPasswordError("Enter your New Password");
      return;
    }

    if (newPassword.length < 6 || newPassword === "") {
      setNewPasswordError("Password must be at least 6 characters long");
      return;
    }

    setNewPasswordError(false);

    if (confirmNewPassword === "") {
      setConfirmNewPasswordError("Confirm your New Password");
      return;
    }

    if (newPassword !== confirmNewPassword) {
      setConfirmNewPasswordError("Password does not match");
      return;
    }

    setConfirmNewPasswordError("");

    setCurrentPasswordError("");
    setNewPasswordError("");
    setConfirmNewPasswordError("");
    setPasswordError("");
    setPasswordProgress(true);
    setPasswordSuccess("");

    const firebaseUser = firebase.auth().currentUser;
    const credential = firebase.auth.EmailAuthProvider.credential(
      firebaseUser.email,
      password
    );

    firebaseUser
      .reauthenticateWithCredential(credential)
      .then(() => {
        firebaseUser
          .updatePassword(newPassword)
          .then(() => {
            setTimeout(() => {
              setPasswordSuccess("");
            }, 10000);

            setPasswordSuccess("Password Changed SuccessFully");
            setPasswordProgress(false);
            setShowChangePasswordLayout(false);
          })
          .catch((error) => {
            setPasswordError(error.message);
            setPasswordProgress(false);
          });
      })
      .catch((error) => {
        setCurrentPasswordError(error.message);
        setPasswordProgress(false);
      });
  };

  const handleSendPasswordResetLink = (e) => {
    setPasswordResetLinkError("");
    setPasswordResetLinkSuccess("");
    setPasswordResetLinkProgress(true);

    const firebaseUser = firebase.auth().currentUser;
    const emailAddress = firebaseUser.email;
    firebase
      .auth()
      .sendPasswordResetEmail(emailAddress)
      .then(() => {
        setPasswordResetLinkProgress(false);
        setPasswordResetLinkSuccess(
          `Password reset link sent successfully to ${emailAddress}`
        );
      })
      .catch((error) => {
        setPasswordResetLinkError(error.message);
        setPasswordResetLinkProgress(false);
      });
  };

  const handleUserSignout = () => {
    setSignoutProgress(true);
    setSignoutError("");
    firebase
      .auth()
      .signOut()
      .then(() => {
        history.push("/login");
        history.go(0);
      })
      .catch((error) => {
        setSignoutProgress(false);
        setSignoutError(error.message);
      });
  };


  return (
    <Container
      variants={moveUpVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <Content>
        <Heading>Email And Password</Heading>
        <Section>
          <EmailLayout>
            <Title onClick={() => setShowChangeEmailLayout(false)}>Email</Title>
            <Value>
              <p onClick={() => setShowChangeEmailLayout(false)}>
                {" "}
                {user.email}
              </p>

              {!showChangeEmailLayout && (
                <a onClick={() => setShowChangeEmailLayout(true)}>Change</a>
              )}
            </Value>
            {showChangeEmailLayout && (
              <ChangeEmailLayout>
                <Label for="New Email">New Email</Label>
                <Input
                  type="text"
                  id="New Email"
                  name="New Email"
                  placeholder="New Email"
                  autoComplete
                  onChange={handleNewEmailChange}
                />
                {newEmailError && (
                  <ErrorLayout
                    margin="4px 0px 0px 0px"
                    errorMessage={newEmailError}
                  />
                )}
                <Label for="Confirm New Email"> Confirm New Email</Label>
                <Input
                  type="text"
                  id=" Confirm New Email"
                  name=" Confirm New Email"
                  placeholder="New Email"
                  autoComplete="none"
                  onChange={handleConfirmNewEmailChange}
                />
                {confirmNewEmailError && (
                  <ErrorLayout
                    margin="4px 0px 0px 0px"
                    errorMessage={confirmNewEmailError}
                  />
                )}

                <Label for="emailPassword">Password</Label>
                <Input
                  type="password"
                  id="emailPassword"
                  name=" emailPassword"
                  placeholder="Password"
                  autoComplete
                  onChange={handleEmailPasswordChange}
                />

                {emailPasswordError && (
                  <ErrorLayout
                    margin="4px 0px 0px 0px"
                    errorMessage={emailPasswordError}
                  />
                )}

                <Message>
                  By clicking verify a verification link will be sent to your
                  new email addrees {newEmail}. please verify your email and log
                  in back.
                </Message>
                {emailError && (
                  <ErrorLayout
                    margin="16px 0px 0px 0px"
                    padding="16px 8px"
                    errorMessage={emailError}
                  />
                )}
                <Button
                  margin="16px 0px 8px 0px"
                  variant="dashboardAdd"
                  progress={emailProgress}
                  title={"Update Email"}
                  handleClick={handleChangeEmailButtonClick}
                />
              </ChangeEmailLayout>
            )}
          </EmailLayout>

          <PasswordLayout>
            <Title onClick={() => setShowChangePasswordLayout(false)}>
              Password
            </Title>
            <Value>
              <p onClick={() => setShowChangePasswordLayout(false)}>
                {"\u2022 \u2022 \u2022 \u2022 \u2022 \u2022"}
              </p>

              {!showChangePasswordLayout && (
                <a onClick={() => setShowChangePasswordLayout(true)}>Change</a>
              )}
            </Value>
            {showChangePasswordLayout && (
              <ChangePasswordLayout>
                <Label for="Current Password">Current Password</Label>
                <Input
                  type="password"
                  id="Current Password"
                  name="Current Password"
                  placeholder="Current Password"
                  autoComplete
                  onChange={handlePasswordChange}
                />

                {currentPasswordError && (
                  <ErrorLayout
                    margin="4px 0px 0px 0px"
                    errorMessage={currentPasswordError}
                  />
                )}

                <Label for="New Password">New Password</Label>
                <Input
                  type="password"
                  id="New Password"
                  name="New Password"
                  placeholder="New Password"
                  autoComplete="none"
                  onChange={handleNewPasswordChange}
                />
                {newPasswordError && (
                  <ErrorLayout
                    margin="4px 0px 0px 0px"
                    errorMessage={newPasswordError}
                  />
                )}
                <Label for="Confirm New Password"> Confirm New Password</Label>
                <Input
                  type="password"
                  id=" Confirm New Password"
                  name=" Confirm New Password"
                  placeholder="New Password"
                  autoComplete="none"
                  onChange={handleConfirmNewPasswordChange}
                />
                {confirmNewPasswordError && (
                  <ErrorLayout
                    margin="4px 0px 0px 0px"
                    errorMessage={confirmNewPasswordError}
                  />
                )}

                {passwordError && (
                  <ErrorLayout
                    margin="16px 0px 0px 0px"
                    padding="16px 8px"
                    errorMessage={passwordError}
                  />
                )}

                <Button
                  margin="16px 0px 0px 0px"
                  variant="dashboardAdd"
                  progress={passwordProgress}
                  title={"Update Password"}
                  handleClick={handleChangePasswordButtonClick}
                />
              </ChangePasswordLayout>
            )}

            {passwordSuccess && (
              <SuccessLayout
                margin="16px 0px 0px 0px"
                padding="8px"
                successMessage={passwordSuccess}
              >
                <p>{passwordSuccess}</p>
              </SuccessLayout>
            )}
          </PasswordLayout>
        </Section>

        <Heading>Account</Heading>
        <Section>
          <AccountItemContainer
            margin="0px 0px 8px 0px"
            onClick={handleSendPasswordResetLink}
          >
            {passwordResetLinkProgress ? (
              <span id="loader">
                <i class="fas fa-circle-notch"></i>
              </span>
            ) : (
              <span>
                <i class="bi bi-envelope"></i>
              </span>
            )}

            <p>
              {" "}
              {passwordResetLinkProgress
                ? " Sending Password Reset Link..."
                : "Send Password Reset Link"}
            </p>
          </AccountItemContainer>

          {passwordResetLinkError && (
            <ErrorLayout
              margin="0px 0px 8px 0px"
              padding="8px"
              errorMessage={passwordResetLinkError}
            />
          )}

          {passwordResetLinkSuccess && (
            <SuccessLayout
              margin="0px 0px 8px 0px"
              padding="8px"
              successMessage={passwordResetLinkSuccess}
            />
          )}

          <HorizontalBreak />

          <AccountItemContainer
            onClick={handleUserSignout}
            margin="8px 0px 0px 0px"
          >
            {signoutProgress ? (
              <span id="loaderSignout">
                <i class="fas fa-circle-notch"></i>
              </span>
            ) : (
              <span id="signout">
                <i class="bi bi-box-arrow-left"></i>
              </span>
            )}

            <p id="signout">
              {signoutProgress ? "Signing Out..." : "Sign Out"}
            </p>
          </AccountItemContainer>

          {signoutError && (
            <ErrorLayout
              margin="0px 0px 8px 0px"
              padding="8px"
              errorMessage={signoutError}
            />
          )}
        </Section>
      </Content>
    </Container>
  );
}

export default Settings;
