export const shapes = [
  {
    shape: "square",
    borderRadius: "0px",
  },

  {
    shape: "square4px",
    borderRadius: "4px",
  },

  {
    shape: "square25%",
    borderRadius: "25%",
  },

  {
    shape: "squareLeaf",
    borderRadius: "4px 16px",
  },
  {
    shape: "circle",
    borderRadius: "50%",
  },
];

export const linkStyles = [
  {
    style: "outlinedRectangle",
    borderRadius: "0px",
    type: "outlined",
    background: "",
  },
  {
    style: "outlinedRectangle4px",
    borderRadius: "4px",
    type: "outlined",
    background: "",
  },

  {
    style: "outlinedRectangle25%",
    borderRadius: "12px",
    type: "outline",
    background: "",
  },
  {
    style: "outlinedRectangleLeaf",
    borderRadius: "4px 16px",
    type: "outline",
    background: "",
  },
  {
    style: "outlinedRectangle360",
    borderRadius: "25px",
    type: "outline",
    background: "",
  },
  {
    style: "filledRectangle",
    borderRadius: "0px",

    type: "filled",
    background: "#ededed",
  },
  {
    style: "filledRectangle4px",
    borderRadius: "4px",
    background: "#ededed",
    type: "filled",
  },

  {
    style: "filledRectangle25%",
    borderRadius: "12px",
    background: "#ededed",
    type: "filled",
  },
  {
    style: "filledRectangleLeaf",
    borderRadius: "4px 16px",
    background: "#ededed",
    type: "filled",
  },
  {
    style: "filledRectangle360px",
    borderRadius: "25px",
    type: "filled",
    background: "#ededed",
  },
];

export const fontStyles = [
  {
    fontName: "Rubik",
    fontFamily: "'Rubik', sans-serif",
    isPro: false,
  },

  {
    fontName: "Satisfy",
    fontFamily: "'Satisfy', cursive",
    isPro: false,
  },
  {
    fontName: "Bree Serif",
    fontFamily: "'Bree Serif', serif ",
    isPro: false,
  },

  {
    fontName: "Lobster Two",
    fontFamily: "'Lobster Two', cursive",
    isPro: true,
  },

  {
    fontName: "Pacifico",
    fontFamily: "'Pacifico', cursive",
    isPro: true,
  },

  {
    fontName: "Shadows Into Light",
    fontFamily: "'Shadows Into Light', cursive",
    isPro: true,
  },

  {
    fontName: "Akaya Telivigala",
    fontFamily: "'Akaya Telivigala', cursive",
    isPro: true,
  },

  {
    fontName: "Big Shoulders Stencil Display",
    fontFamily: "'Big Shoulders Stencil Display', cursive",
    isPro: true,
  },
  {
    fontName: "Kirang Haerang",
    fontFamily: "'Kirang Haerang', cursive",
    isPro: true,
  },
  {
    fontName: "Merienda",
    fontFamily: "'Merienda', cursive",
    isPro: true,
  },

  {
    fontName: "Roboto",
    fontFamily: "'Roboto', sans-serif",
    isPro: true,
  },
  {
    fontName: "Teko",
    fontFamily: "'Teko', sans-serif",
    isPro: true,
  },
  {
    fontName: "Yatra One",
    fontFamily: "'Yatra One', cursive",
    isPro: true,
  },
  {
    fontName: "Atkinson",
    fontFamily: "'Atkinson Hyperlegible', sans-serif",
    isPro: true,
  },
  {
    fontName: "Courgette",
    fontFamily: "'Courgette', cursive",
    isPro: true,
  },

  {
    fontName: "Gloria Hallelujah",
    fontFamily: "'Gloria Hallelujah', cursive",
    isPro: true,
  },
  {
    fontName: "Indie Flower",
    fontFamily: "'Indie Flower', cursive",
    isPro: true,
  },
  {
    fontName: "Palette",
    fontFamily: "'Palette Mosaic', cursive",
    isPro: true,
  },
];
