import styled from "styled-components";
import { motion } from "framer-motion";

import { ReactComponent as UpgradeIcon } from "../../images/upgrade.svg";

export const Container = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 25%;
  left: 24px;
  right: 24px;
  padding: 16px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 0px 8px 4px rgba(0, 0, 0, 0.2);
  z-index: 16;

  #buyClose {
    position: absolute;
    top: 16px;
    right: 16px;
    font-size: 18px;
  }

  @media (min-width: 968px) {
    left: 320px;
    right: 320px;
  }
`;

export const Heading = styled(motion.h5)`
  text-transform: uppercase;
  letter-spacing: 1.1px;
`;

export const UpgradeSvg = styled(UpgradeIcon)`
  width: 100%;
  height: 150px;
  margin-top: 16px;
`;

export const Message = styled.p`
  margin-top: 24px;
  text-align: center;
  line-height: 22px;

  span {
    text-transform: uppercase;
    letter-spacing: 1.1px;
  }

  #limitation {
    color: ${({ theme }) => theme.colors.free};
  }

  #advantage {
    color: ${({ theme }) => theme.colors.pro};
  }
`;
