import styled, { css } from "styled-components";
import { motion } from "framer-motion";

export const Container = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: ${({ theme }) => theme.font.fontFamily};
  min-height: 100vh;
  height: 100%;
  transition: all 1s ease-in-out;
  width: 100%;
  -webkit-background-size: cover;
  background-size: cover;
  ${({ theme }) =>
    (theme.background.type === "Up" &&
      css`
        background: linear-gradient(
            to top,
            ${theme.background.backgroundColor},
            ${theme.background.backgroundSecondColor}
          )
          no-repeat center center fixed;
      `) ||
    (theme.background.type === "Down" &&
      css`
        background: linear-gradient(
            to bottom,
            ${theme.background.backgroundColor},
            ${theme.background.backgroundSecondColor}
          )
          no-repeat center center fixed;
      `) ||
    (theme.background.type === "Right" &&
      css`
        background: linear-gradient(
            to right,
            ${theme.background.backgroundColor},
            ${theme.background.backgroundSecondColor}
          )
          no-repeat center center fixed;
      `) ||
    (theme.background.type === "Left" &&
      css`
        background: linear-gradient(
            to left,
            ${theme.background.backgroundColor},
            ${theme.background.backgroundSecondColor}
          )
          no-repeat center center fixed;
      `) ||
    (theme.background.type === "Flat" &&
      css`
        background: ${theme.background.backgroundColor} no-repeat center center
          fixed;
      `) ||
    (theme.background.type === "Image" &&
      css`
        background: url(${theme.background.image}) no-repeat center center fixed;
        -webkit-background-size: cover;
        background-size: cover;
      `)}
`;

export const Content = styled(motion.div)`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
  max-width: 600px;
  margin: 0px auto;
`;

export const UserInfoContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;
  padding: 24px 16px 16px 16px;

  img {
    display: ${({ theme }) => (theme.user.isProfilePhoto ? "block" : "none")};
    height: 100px;
    width: 100px;
    border-radius: ${({ theme }) => theme.user.profilePhoto.borderRadius};
    object-fit: cover;
  }

  #name {
    display: ${({ theme }) => (theme.user.isName ? "block" : "none")};
    margin-top: 16px;
    color: ${({ theme }) => theme.user.nameColor};
    font-size: 18px;
    font-weight: 500;
  }
  span {
    display: ${({ theme }) => (theme.user.isUsername ? "block" : "none")};
    font-size: 14px;
    color: ${({ theme }) => theme.user.usernameColor};
    margin-top: ${({ theme }) => (theme.user.isName ? "0px" : "8px")};
  }

  p {
    display: ${({ theme }) => (theme.user.isBio ? "block" : "none")};
    text-align: center;
    word-wrap: break-word;
    word-break: break-all;
    margin-top: 8px;
    font-size: 14px;
    font-weight: 400;
    color: ${({ theme }) => theme.user.bioColor};
  }
`;

export const ProfilesContainer = styled(motion.div)`
  display: flex;
  margin: 0px 16px;

  div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
`;

export const LinksContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
  width: 100%;
`;

export const VideosContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
  width: 100%;
`;

export const ImagesContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
  width: 100%;
`;

export const LogoContainer = styled(motion.div)`
  display: flex;
  flex-direction: row;
  justify-self: flex-end;
  align-self: center;
  align-items: center;
  justify-content: center;
  height: 50px;
  padding: 4px 16px;
  margin: 0px 50px 16px 50px;
  font-size: 24px;
  cursor: pointer;
  font-family: "Poppins", "Hind", sans-serif;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1.1px;
  color: ${({ logoColor }) => (logoColor ? logoColor : "#FFFFFF")};

  & > p::first-letter {
    font-size: 36px;
  }
`;
