import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { SketchPicker } from "react-color";
import useComponentVisible from "../../hooks/useComponentVisible";

function ColorPickerItem({ color, title, handleChange }) {
  const [textColor, setTextColor] = useState("#000");
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  const handleColorChange = (changedColor) => {
    handleChange(changedColor.hex);
    getContrastYIQ(changedColor.hex);
  };

  useEffect(() => {
    getContrastYIQ(color);
  }, [color]);

  const getContrastYIQ = (color) => {
    if (color) {
      color = color.replace("#", "");
      var r = parseInt(color.substr(0, 2), 16);
      var g = parseInt(color.substr(2, 2), 16);
      var b = parseInt(color.substr(4, 2), 16);
      var yiq = (r * 299 + g * 587 + b * 114) / 1000;

      if (yiq >= 128) {
        setTextColor("#000000");
      } else {
        setTextColor("#ffffff");
      }
    }
  };

  return (
    <Container>
      <Content ref={ref} onClick={(e) => setIsComponentVisible(true)}>
        <span>{title}</span>
        <ColorBlock
          onClick={(e) => {
            e.stopPropagation();

            setIsComponentVisible(!isComponentVisible);
          }}
          color={color}
          textColor={textColor}
        >
          <span>{color}</span>
        </ColorBlock>

        {isComponentVisible && (
          <ColorPickerLayout>
            <SketchPicker
              color={color}
              disableAlpha
              onChangeComplete={handleColorChange}
            />
          </ColorPickerLayout>
        )}
      </Content>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  margin: 8px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  & > span {
    margin: 4px 0px;
    font-weight: 500;
  }
`;

const ColorBlock = styled.div`
  width: 180px;
  height: 40px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  margin-top: 2px;
  border-radius: 4px;
  border: ${({ theme, color }) =>
    (color === "#ffffff" && theme.appearance.border.itemBorder) || ""};
  color: ${({ textColor }) => (textColor ? textColor : "#000")};
  background-color: ${({ color }) => (color ? color : "#000")};
  text-transform: uppercase;
  cursor: pointer;

  & > span {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1.2px;
  }
`;

const ColorPickerLayout = styled.div`
  display: flex;
  position: absolute;
  top: 70px;
  right: 0;
  left: 0;
  z-index: 2;
`;

export default ColorPickerItem;
