import styled from "styled-components";
import { Link } from "react-router-dom";

export const NavLogoContainer = styled(Link)`
  color: black;
  justify-self: flex-start;
  cursor: pointer;
  text-decoration: none;
  font-size: 18px;
  display: flex;
  align-items: center;
  font-weight: 600;
  color: ${({ navTheme }) => (navTheme === "dark" && "#FFFFFF") || "#000000"};
  @media screen and (min-width: 960px) {
    font-size: 24px;
  }

  & > p {
    font-family: "Poppins", "Hind", sans-serif;
    letter-spacing: 1.1px;
  }

  & > p::first-letter {
    font-size: 36px;
  }
`;

// export const NavIcon = styled(FaMagento)`
//   margin-right: 0.5rem;
// `;
