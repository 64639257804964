import styled from "styled-components";
import { motion } from "framer-motion";

export const AnimationWrapper = styled(motion.div)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 0px;
  color: ${({ theme, active }) =>
    active ? theme.colors.animation : "#000000"};
  margin: 4px 0px;
  cursor: pointer;

  & > span {
    font-size: 8px;
    margin-right: 8px;
  }

  & > p {
    font-size: 16px;
    text-transform: capitalize;
  }
`;
