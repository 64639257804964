import React, { useState, useEffect } from "react";
import { fadeInVariants } from "../../animations/animation";
import { ThemeProvider } from "styled-components";

import {
  MainContainer,
  Container,
  Content,
  UserInfoContainer,
  ProfilesContainer,
  LinksContainer,
  LogoContainer,
  ImagesContainer,
  VideosContainer,
} from "./MobileScreen.elements";
import { db } from "../../firebase/firebase";
import MobileScreenViewLinkItem from "../MobileScreenViewLinkItem";
import MobileScreenViewProfileItem from "../MobileScreenViewProfileItem";
import { MobileViewImageItem, MobileViewVideoItem } from "..";

function MobileScreen({ userId }) {
  const [links, setLinks] = useState([]);
  const [profiles, setProfiles] = useState([]);
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [user, setUser] = useState("");
  const [theme, setTheme] = useState("");
  const [logoColor, setLogoColor] = useState("");

  const getUser = () => {
    db.collection("users")
      .doc(userId)
      .onSnapshot((doc) => {
        setUser(doc.data());
      });
  };

  const getLinks = () => {
    db.collection("users")
      .doc(userId)
      .collection("links")
      .orderBy("order", "asc")
      .onSnapshot((querySnapshot) => {
        const linkValues = [];
        querySnapshot.forEach((doc) => {
          if (doc.data().isActive) {
            linkValues.push(doc.data());
          }
        });
        setLinks(linkValues);
      });
  };

  const getProfiles = () => {
    db.collection("users")
      .doc(userId)
      .collection("profiles")
      .orderBy("order", "asc")
      .onSnapshot((querySnapshot) => {
        const profileValues = [];
        querySnapshot.forEach((doc) => {
          if (doc.data().isActive) {
            profileValues.push(doc.data());
          }
        });
        setProfiles(profileValues);
      });
  };

  const getImages = () => {
    db.collection("users")
      .doc(userId)
      .collection("images")
      .orderBy("order", "asc")
      .onSnapshot((querySnapshot) => {
        const imageValues = [];
        querySnapshot.forEach((doc) => {
          if (doc.data().isActive) {
            imageValues.push(doc.data());
          }
        });
        setImages(imageValues);
      });
  };

  const getVideos = () => {
    db.collection("users")
      .doc(userId)
      .collection("videos")
      .orderBy("order", "asc")
      .onSnapshot((querySnapshot) => {
        const videoValues = [];

        querySnapshot.forEach((doc) => {
          if (doc.data().isActive) {
            videoValues.push(doc.data());
          }
        });
        setVideos(videoValues);
      });
  };

  const getCurrentTheme = () => {
    db.collection("users")
      .doc(userId)
      .collection("themes")
      .doc("theme")
      .onSnapshot((doc) => {
        setTheme(doc.data());
      });
  };

  const getLogoColor = (color) => {
    if (color) {
      color = color.replace("#", "");
      var r = parseInt(color.substr(0, 2), 16);
      var g = parseInt(color.substr(2, 2), 16);
      var b = parseInt(color.substr(4, 2), 16);
      var yiq = (r * 299 + g * 587 + b * 114) / 1000;

      if (yiq >= 128) {
        setLogoColor("#000000");
      } else {
        setLogoColor("#ffffff");
      }
    }
  };

  useEffect(() => {
    if (theme) {
      getLogoColor(theme.background.backgroundColor);
    }
  }, [theme]);

  useEffect(() => {
    getUser();
    getProfiles();
    getLinks();
    getImages();
    getVideos();
    getCurrentTheme();
  }, [userId]);

  return (
    <MainContainer
      initial="initial"
      animate="animate"
      variants={fadeInVariants}
    >
      {user && theme && profiles && links && images && videos ? (
        <ThemeProvider theme={theme}>
          <Container>
            <Content>
              <UserInfoContainer>
                <img src={user.profilePhoto} alt="profilePhoto" />
                <p id="name">{user.name}</p>
                <span>@{user.username}</span>
                <p>{user.bio}</p>
              </UserInfoContainer>
              <ProfilesContainer>
                <div>
                  {profiles.map((profile) => (
                    <MobileScreenViewProfileItem
                      key={profile.profileId}
                      profile={profile}
                    />
                  ))}
                </div>
              </ProfilesContainer>

              <LinksContainer>
                {links.map((link) => (
                  <MobileScreenViewLinkItem key={link.linkId} link={link} />
                ))}
              </LinksContainer>

              <ImagesContainer>
                {images.map((image) => (
                  <MobileViewImageItem key={image.imageId} image={image} />
                ))}
              </ImagesContainer>

              <VideosContainer>
                {videos.map((video) => (
                  <MobileViewVideoItem key={video.videoId} video={video} />
                ))}
              </VideosContainer>
            </Content>
            <LogoContainer logoColor={logoColor}>
              <p>Getmyprofiles.in</p>
            </LogoContainer>
          </Container>
        </ThemeProvider>
      ) : (
        <span id="loader">
          <i class="fas fa-circle-notch"></i>
        </span>
      )}
    </MainContainer>
  );
}

export default MobileScreen;
