import styled, { keyframes } from "styled-components";
import { motion } from "framer-motion";

export const rotate = keyframes`
from {
  transform: rotate(0deg);
  transform-origin: center;
}
to {
  transform: rotate(360deg);
  transform-origin: center;
}
`;

export const Container = styled(motion.div)`
  display: block;
  height: 50px;
  width: 50px;
  border-radius: 29px 8px 29px 29px;
  background-color: ${({ theme, variant }) =>
    (variant === "dashboard" && "#e91e63") || theme.colors.primary};
  position: fixed;
  bottom: ${({ variant }) => (variant === "dashboard" && "70px") || "16px"};
  right: 16px;
  z-index: 25;
  font-size: 22px;
  color: white;

  @media (min-width: 768px) {
    bottom: 16px;
  }
`;

export const Content = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  #loader {
    height: 40.2px;
    width: 38px;
    font-size: 38px;
    color: white;
    animation: ${rotate} 0.8s linear infinite;
  }

  #checkMark {
    height: 30px;
    width: 30px;
    font-size: 30px;
    color: white;
  }
`;
