import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
import { useHistory, useParams } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import ViewLinkItem from "../../components/ViewLinkItem";
import ViewProfileItem from "../../components/ViewProfileItem";
import { db } from "../../firebase/firebase";
import {
  Container,
  Content,
  UserInfoContainer,
  ProfilesContainer,
  LinksContainer,
  VideosContainer,
  ImagesContainer,
  LogoContainer,
} from "./ViewProfile.elements";
import { fadeInVariants } from "../../animations/animation";
import UserNotFound from "../../components/user-not-found/UserNotFound";
import { LoadingPage, ViewVideoItem, ViewImageItem } from "../../components";
import { openProfile, openURL } from "../../utils/functions";

function ViewProfile() {
  //Parameter
  const { username } = useParams();
  const history = useHistory();

  //Use States

  const [links, setLinks] = useState([]);
  const [profiles, setProfiles] = useState([]);
  const [videos, setVideos] = useState([]);
  const [images, setImages] = useState([]);
  const [user, setUser] = useState("");
  const [theme, setTheme] = useState("");
  const [logoColor, setLogoColor] = useState("");

  const [isUserFetched, setIsUserFetched] = useState(false);

  const [error, setError] = useState("");

  const onLogoClick = () => {
    history.push("/");
    ReactGA.event({
      category: "View Profile",
      action: "Logo Click",
      label: user.userId,
    });
  };

  const getUser = () => {
    const users = [];

    db.collection("users")
      .where("username", "==", username)
      .limit(1)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          users.push(doc.data());
        });
        setIsUserFetched(true);

        if (users === undefined || users.length === 0) {
        } else {
          setUser(users[0]);
          getLinks(users[0].userId);
        }
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  const getLinks = (userId) => {
    db.collection("users")
      .doc(userId)
      .collection("links")
      .orderBy("order", "asc")
      .onSnapshot((querySnapshot) => {
        const linkValues = [];
        querySnapshot.forEach((doc) => {
          if (doc.data().isActive) {
            linkValues.push(doc.data());
          }
        });
        setLinks(linkValues);
      });
  };

  const getProfiles = (userId) => {
    db.collection("users")
      .doc(userId)
      .collection("profiles")
      .orderBy("order", "asc")
      .onSnapshot((querySnapshot) => {
        const profileValues = [];
        querySnapshot.forEach((doc) => {
          if (doc.data().isActive) {
            profileValues.push(doc.data());
          }
        });
        setProfiles(profileValues);
      });
  };

  const getImages = (userId) => {
    db.collection("users")
      .doc(userId)
      .collection("images")
      .orderBy("order", "asc")
      .onSnapshot((querySnapshot) => {
        const imageValues = [];

        querySnapshot.forEach((doc) => {
          if (doc.data().isActive) {
            imageValues.push(doc.data());
          }
        });
        setImages(imageValues);
      });
  };

  const getVideos = (userId) => {
    db.collection("users")
      .doc(userId)
      .collection("videos")
      .orderBy("order", "asc")
      .onSnapshot((querySnapshot) => {
        const videoValues = [];

        querySnapshot.forEach((doc) => {
          if (doc.data().isActive) {
            videoValues.push(doc.data());
          }
        });
        setVideos(videoValues);
      });
  };

  const getCurrentTheme = (userId) => {
    db.collection("users")
      .doc(userId)
      .collection("themes")
      .doc("theme")
      .onSnapshot((doc) => {
        setTheme(doc.data());
      });
  };

  const handleDeepLink = (user) => {
    if (user) {
      if (user.deepLink.link !== "") {
        if (user.deepLink.type === "profile") {
          openProfile(user.deepLink.link);

          ReactGA.event({
            category: user.userId,
            action: "Clicks",
            label: user.deepLink.link,
          });

          ReactGA.event({
            category: user.userId + "_PROFILES",
            action: "Clicks",
            label: user.deepLink.link,
          });
        } else if (user.deepLink.type === "link") {
          openURL(user.deepLink.link);
          ReactGA.event({
            category: user.userId,
            action: "Clicks",
            label: user.deepLink.link,
          });

          ReactGA.event({
            category: user.userId + "_LINKS",
            action: "Clicks",
            label: user.deepLink.link,
          });
        } else if (user.deepLink.type === "image") {
          openURL(user.deepLink.link);
          ReactGA.event({
            category: user.userId,
            action: "Clicks",
            label: user.deepLink.link,
          });

          ReactGA.event({
            category: user.userId + "_IMAGES",
            action: "Clicks",
            label: user.deepLink.link,
          });
        } else if (user.deepLink.type === "video") {
          openURL(user.deepLink.link);
          ReactGA.event({
            category: user.userId,
            action: "Clicks",
            label: user.deepLink.link,
          });

          ReactGA.event({
            category: user.userId + "_VIDEOS",
            action: "Clicks",
            label: user.deepLink.link,
          });
        }
      }
    }
  };

  const reportProfileViews = (userId) => {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID);
    ReactGA.pageview("/" + userId);
  };

  const reportProfileClicks = (profile) => {
    const profileURL = profile.URL + profile.value;

    if (profileURL === "") {
      return;
    }

    ReactGA.event({
      category: user.userId,
      action: "Clicks",
      label: profile.URL + profile.value,
    });

    ReactGA.event({
      category: user.userId + "_PROFILES",
      action: "Clicks",
      label: profile.URL + profile.value,
    });
  };

  const reportLinkClicks = (link) => {
    if (link.link === "") {
      return;
    }

    ReactGA.event({
      category: user.userId,
      action: "Clicks",
      label: link.link,
    });

    ReactGA.event({
      category: user.userId + "_LINKS",
      action: "Clicks",
      label: link.link,
    });
  };

  const reportImageClicks = (image) => {
    if (image.visitURL === "") {
      return;
    }

    ReactGA.event({
      category: user.userId,
      action: "Clicks",
      label: image.visitURL,
    });

    ReactGA.event({
      category: user.userId + "_IMAGES",
      action: "Clicks",
      label: image.visitURL,
    });
  };

  const reportVideoClicks = (video) => {
    if (video.videoURL === "") {
      return;
    }

    ReactGA.event({
      category: user.userId,
      action: "Clicks",
      label: video.videoURL,
    });

    ReactGA.event({
      category: user.userId + "_VIDEOS",
      action: "Clicks",
      label: video.videoURL,
    });
  };

  const getLogoColor = (color) => {
    if (color) {
      color = color.replace("#", "");
      var r = parseInt(color.substr(0, 2), 16);
      var g = parseInt(color.substr(2, 2), 16);
      var b = parseInt(color.substr(4, 2), 16);
      var yiq = (r * 299 + g * 587 + b * 114) / 1000;

      if (yiq >= 128) {
        setLogoColor("#000000");
      } else {
        setLogoColor("#ffffff");
      }
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    if (theme) {
      getLogoColor(theme.background.backgroundColor);
    }
  }, [theme]);

  useEffect(() => {
    handleDeepLink(user);
    getProfiles(user.userId);
    getVideos(user.userId);
    getImages(user.userId);
    getCurrentTheme(user.userId);

    if (user) {
      document.title =
        "@" + user.username + " | " + process.env.REACT_APP_TITLE;
    }
  }, [user.userId]);

  useEffect(() => {
    if (user.userId !== null) {
      reportProfileViews(user.userId);
    }
  }, [user.userId]);

  return (
    <>
      {isUserFetched ? (
        <>
          {user ? (
            <>
              {theme && profiles && links ? (
                <ThemeProvider theme={theme}>
                  <Container
                    initial="initial"
                    animate="animate"
                    variants={fadeInVariants}
                  >
                    <Content>
                      <UserInfoContainer>
                        <img src={user.profilePhoto} alt="profilePhoto" />

                        <p id="name">{user.name}</p>
                        <span>@{user.username}</span>
                        <p>{user.bio}</p>
                      </UserInfoContainer>
                      <ProfilesContainer>
                        <div>
                          {profiles.map((profile) => (
                            <ViewProfileItem
                              key={profile.profileId}
                              profile={profile}
                              reportProfileClicks={reportProfileClicks}
                            />
                          ))}
                        </div>
                      </ProfilesContainer>

                      <LinksContainer>
                        {links.map((link) => (
                          <ViewLinkItem
                            key={link.linkId}
                            link={link}
                            reportLinkClicks={reportLinkClicks}
                          />
                        ))}
                      </LinksContainer>

                      <ImagesContainer>
                        {images.map((image) => (
                          <ViewImageItem
                            key={image.imageId}
                            image={image}
                            reportImageClicks={reportImageClicks}
                          />
                        ))}
                      </ImagesContainer>

                      <VideosContainer>
                        {videos.map((video) => (
                          <ViewVideoItem
                            key={video.videoId}
                            video={video}
                            reportVideoClicks={reportVideoClicks}
                          />
                        ))}
                      </VideosContainer>
                    </Content>
                    <LogoContainer logoColor={logoColor} onClick={onLogoClick}>
                      <p> Getmyprofiles.in</p>
                    </LogoContainer>
                  </Container>
                </ThemeProvider>
              ) : (
                <LoadingPage />
              )}
            </>
          ) : (
            <UserNotFound />
          )}
        </>
      ) : (
        <LoadingPage />
      )}
    </>
  );
}

export default ViewProfile;
