import React from "react";

import {
  Nav,
  NavContainer,
  NavMenu,
  NavItem,
  NavLink,
} from "./DashboardNavbar.elements";

function DashboardNavbar({ location }) {
  return (
    <Nav>
      <NavContainer>
        <NavMenu>
          <NavItem active={location === "/dashboard"}>
            <NavLink active={location === "/dashboard"} to="/dashboard">
              Profile
            </NavLink>
          </NavItem>
          <NavItem active={location === "/dashboard/appearance"}>
            <NavLink
              active={location === "/dashboard/appearance"}
              to="/dashboard/appearance"
            >
              Appearance
            </NavLink>
          </NavItem>
          <NavItem active={location === "/dashboard/analytics"}>
            <NavLink
              active={location === "/dashboard/analytics"}
              to="/dashboard/analytics"
            >
              Analytics
            </NavLink>
          </NavItem>
          <NavItem active={location === "/dashboard/settings"}>
            <NavLink
              active={location === "/dashboard/settings"}
              to="/dashboard/settings"
            >
              Settings
            </NavLink>
          </NavItem>

          <NavItem active={location === "/dashboard/billing"}>
            <NavLink
              active={location === "/dashboard/billing"}
              to="/dashboard/billing"
            >
              Billing
            </NavLink>
          </NavItem>

          <NavItem active={location === "/dashboard/promote"}>
            <NavLink
              active={location === "/dashboard/promote"}
              to="/dashboard/promote"
            >
              Promote
            </NavLink>
          </NavItem>
        </NavMenu>
      </NavContainer>
    </Nav>
  );
}

export default DashboardNavbar;
