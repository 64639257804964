import styled from "styled-components";
import { motion } from "framer-motion";
export const Container = styled(motion.div)`
  background: #f1f5f8;
  color: #172126;
`;

export const Content = styled(motion.div)`
  padding: 16px;

  label {
    display: inline-block;
    margin-bottom: 4px;
    font-size: 14px;
  }
`;

export const Input = styled.input`
  margin-bottom: 16px;
  height: 50px;
  width: 100%;
  padding-left: 4px;
  outline: none;
  border: none;
  font-size: 16px;
  border-radius: 4px;
  border: ${({ theme }) => theme.border};
`;
