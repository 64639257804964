export default function makeid() {
  const CHARS =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  let autoId = "";

  for (let i = 0; i < 20; i++) {
    autoId += CHARS.charAt(Math.floor(Math.random() * CHARS.length));
  }
  return autoId;
}

export const getFormattedDate = (date) => {
  let year = date.getFullYear();
  let month = (1 + date.getMonth()).toString().padStart(2, "0");
  let day = date.getDate().toString().padStart(2, "0");

  return month + "/" + day + "/" + year;
};

export const openURL = (URL) => {
  if (
    !/^https?:\/\//i.test(URL) &&
    !/^http?:\/\//i.test(URL) &&
    !URL.startsWith("mailto:")
  ) {
    URL = "https://" + URL;
  }

  if (URL === "https://" || URL === "http://") {
    return;
  }

  const newWindow = window.open(URL, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

export const openProfile = (URL) => {
  if (
    !/^https?:\/\//i.test(URL) &&
    !/^http?:\/\//i.test(URL) &&
    !URL.startsWith("mailto:")
  ) {
    URL = "https://" + URL;
  }

  if (URL === "https://" || URL === "http://") {
    return;
  }

  const newWindow = window.open(URL, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};
