import styled from "styled-components";
import { motion } from "framer-motion";

import { ReactComponent as ErrorIcon } from "../../images/error.svg";

export const SvgWrapper = styled(motion.div)`
  margin: 32px;
  svg {
    width: 100%;
    height: 150px;
  }
`;

export const ErrorSvg = styled(ErrorIcon)`
  height: 200px;
`;
