import React from "react";

import styled from "styled-components";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

function VisibilityItem({ isVisible, title, toggleVisibility }) {
  return (
    <Container active={isVisible}>
      <div onClick={() => toggleVisibility(!isVisible)}>
        {isVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
      </div>
      <span>{title}</span>
    </Container>
  );
}

const Container = styled.div`
  max-width: 60px;
  padding: 8px;
  margin: 8px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  & > div {
    width: 40px;
    height: 40px;
    border: ${({ theme, active }) =>
      active
        ? theme.appearance.icons.iconBorder
        : theme.appearance.icons.iconBorderInActive};
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;

    .MuiSvgIcon-root {
      color: ${({ theme, active }) =>
        active
          ? theme.appearance.icons.active
          : theme.appearance.icons.inActive};
    }
  }

  & > span {
    margin-top: 8px;
    font-size: 12px;
    text-align: center;
    /* word-wrap: break-word;
    word-break: break-all; */
    /* font-weight: 500; */
  }
`;

export default VisibilityItem;
