import React, { useState, useEffect, useRef } from "react";
import validator from "validator";
import {
  AnimationItem,
  DeepLinkItem,
  DeleteItem,
  ResponsivePlayer,
  UploadLinkPhotoItem,
  VisitItem,
} from "..";
import { moveRightVariants } from "../../animations/animation";
import { db, storage } from "../../firebase/firebase";
import { openURL } from "../../utils/functions";

import {
  MainContainer,
  Container,
  DragIconWrapper,
  ContentWrapper,
  SwitchWrapper,
  ActiveSwitch,
  ActionLayoutWrapper,
  ActionIconWrapper,
  InputWrapper,
  ActionProceedLayout,
} from "../profile-item/ProfileItem.elements";
import {
  Content,
  TitleInputWrapper,
  ImageInformation,
  ImageInformationWrapper,
  DescriptionInputWrapper,
  ImageWrapper,
} from "./ImageItem.elements";

function ImageItem({
  image,
  user,
  setShowProgressLayout,
  progressTimeout,
  setDeepLink,
  deepLinkId,
  setDeepLinkId,
}) {
  const [title, setTitle] = useState(image.title);
  const [titleError, setTitleError] = useState("");

  const [description, setDescription] = useState(image.description);

  const [imageSrc, setImageSrc] = useState(image.image);
  const [imageId, setImageId] = useState(image.imageId);
  const [imageUploadProgress, setImageUploadProgress] = useState(false);
  const [imageUploadProgressValue, setImageUploadProgressValue] = useState(0);
  const [showChangeImageLayout, setShowChangeImageLayout] = useState(false);

  const [imageVisitURL, setImageVisitURL] = useState(image.visitURL);
  const [imageVisitURLError, setImageVisitURLError] = useState("");

  const [isActive, setIsActive] = useState(image.isActive);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");
  const [activeError, setActiveError] = useState("");
  const [isTitleChanged, setIsTitleChanged] = useState(false);
  const [isDescriptionChanged, setIsDescriptionChanged] = useState(false);
  const [isImageVisitURLChanged, setIsImageVisitURLChanged] = useState(false);

  const [deepLinkProgress, setDeeplinkProgress] = useState(false);
  const [deleteImageProgress, setDeleteImageProgress] = useState(false);

  const [showTitleEditIcon, setShowTitleEditIcon] = useState(true);
  const [showDescriptionEditIcon, setShowDescriptionEditIcon] = useState(true);
  const [showURLEditIcon, setShowURLEditIcon] = useState(true);

  const [showDeleteLayout, setShowDeleteLayout] = useState(false);

  const [showAnimationLayout, setShowAnimationLayout] = useState(false);

  const [imageAnimation, setImageAnimation] = useState(image.animation);

  const [showDeepLinkLayout, setShowDeepLinkLayout] = useState(false);
  const [showVisitLayout, setShowVisitLayout] = useState(false);

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
    setIsTitleChanged(true);
  };

  const updateNewTitle = () => {
    setShowTitleEditIcon(true);

    if (!isTitleChanged) {
      return;
    }
    setShowProgressLayout(true);
    db.collection("users")
      .doc(user.userId)
      .collection("images")
      .doc(image.imageId)
      .update({
        title: title,
      })
      .then(() => {
        setSuccess(true);
        setShowProgressLayout(false);
        setIsTitleChanged(false);
        handleCancelClick();
        setTimeout(() => {
          setSuccess(false);
        }, progressTimeout);
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
    setIsDescriptionChanged(true);
  };

  const updateNewDescription = () => {
    setShowDescriptionEditIcon(true);
    if (!isDescriptionChanged) {
      return;
    }

    setShowProgressLayout(true);

    db.collection("users")
      .doc(user.userId)
      .collection("images")
      .doc(image.imageId)
      .update({
        description: description,
      })
      .then(() => {
        setSuccess(true);
        setShowProgressLayout(false);
        setIsDescriptionChanged(false);
        handleCancelClick();
        setTimeout(() => {
          setSuccess(false);
        }, progressTimeout);
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  const handleImageVisitURLChange = (e) => {
    setImageVisitURL(e.target.value);
    setIsImageVisitURLChanged(true);
    setImageVisitURLError("");
  };

  const updateNewImageVisitURL = () => {
    setShowURLEditIcon(true);
    if (imageVisitURLError || !isImageVisitURLChanged) {
      return;
    }

    if (imageVisitURL !== "" && !validator.isURL(imageVisitURL)) {
      setImageVisitURLError("Invalid visit url");
      return;
    }

    setShowProgressLayout(true);

    db.collection("users")
      .doc(user.userId)
      .collection("images")
      .doc(image.imageId)
      .update({
        visitURL: imageVisitURL,
      })
      .then(() => {
        setIsImageVisitURLChanged(false);
        if (deepLinkId === imageId) {
          handleAddDeepLink();
        } else {
          setSuccess(true);
          setShowProgressLayout(false);
          handleCancelClick();
          setTimeout(() => {
            setSuccess(false);
          }, progressTimeout);
        }
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  const handleActiveStatusChange = (e) => {
    e.preventDefault();
    setIsActive(e.target.checked);
    setActiveError("");
    setShowProgressLayout(true);

    db.collection("users")
      .doc(user.userId)
      .collection("images")
      .doc(image.imageId)
      .update({
        isActive: e.target.checked,
      })
      .then(() => {
        setSuccess(true);
        setShowProgressLayout(false);
        setTimeout(() => {
          setSuccess(false);
        }, progressTimeout);
      })
      .catch((error) => {
        setActiveError(error.message);
      });
  };

  const onDeleteIconClick = () => {
    setShowDeleteLayout(!showDeleteLayout);
    setShowVisitLayout(false);
    setShowAnimationLayout(false);
    setShowDeepLinkLayout(false);
    setShowChangeImageLayout(false);
    blurInputs();
  };

  const handleDeleteImage = () => {
    setError(false);
    setDeleteImageProgress(true);
    setImageUploadProgress(true);
    setImageUploadProgressValue(0);
    setShowProgressLayout(true);
    var imageRef = storage.refFromURL(imageSrc);
    imageRef
      .delete()
      .then(() => {
        db.collection("users")
          .doc(user.userId)
          .collection("images")
          .doc(imageId)
          .delete()
          .then(() => {
            if (deepLinkId === imageId) {
              handleRemoveDeepLink();
            }
            setImageUploadProgress(false);
            setImageUploadProgressValue(100);
            setShowProgressLayout(false);
            setDeleteImageProgress(false);
            handleCancelClick();
          })
          .catch((error) => {
            setError(true);
            setDeleteImageProgress(false);
            setImageUploadProgress(false);
            setImageUploadProgressValue(0);
          });
      })
      .catch((error) => {
        setError(true);
        setDeleteImageProgress(false);
        setImageUploadProgress(false);
        setImageUploadProgressValue(0);
      });
  };

  const onAnimationIconClick = () => {
    setShowAnimationLayout(!showAnimationLayout);
    setShowDeleteLayout(false);
    setShowVisitLayout(false);
    setShowDeepLinkLayout(false);
    setShowChangeImageLayout(false);
    blurInputs();
  };

  const handleAddAnimation = (animation) => {
    setShowProgressLayout(true);
    if (animation !== imageAnimation) {
      setImageAnimation(animation);
      handleCancelClick();
      db.collection("users")
        .doc(user.userId)
        .collection("images")
        .doc(image.imageId)
        .update({
          animation: animation,
        })
        .then(() => {
          setSuccess(true);
          setShowProgressLayout(false);

          setTimeout(() => {
            setSuccess(false);
          }, progressTimeout);
        })
        .catch((error) => {
          setError(error.message);
        });
    } else {
      setImageAnimation("");
      handleCancelClick();
      db.collection("users")
        .doc(user.userId)
        .collection("images")
        .doc(image.imageId)
        .update({
          animation: "",
        })
        .then(() => {
          setSuccess(true);
          setShowProgressLayout(false);
          setTimeout(() => {
            setSuccess(false);
          }, progressTimeout);
        })
        .catch((error) => {
          setError(error.message);
        });
    }
  };

  const onDeepLinkIconClick = () => {
    setShowDeepLinkLayout(!showDeepLinkLayout);
    setShowVisitLayout(false);
    setShowDeleteLayout(false);
    setShowAnimationLayout(false);
    setShowChangeImageLayout(false);
    blurInputs();
  };

  const handleAddDeepLink = () => {
    if (imageVisitURL === "") {
      setImageVisitURLError("Invalid Image url");
      return;
    }

    setDeeplinkProgress(true);
    setShowProgressLayout(true);

    db.collection("users")
      .doc(user.userId)
      .update({
        "deepLink.link": imageVisitURL,
        "deepLink.type": "image",
        "deepLink.id": image.imageId,
      })
      .then(() => {
        setSuccess(true);
        handleCancelClick();
        setShowProgressLayout(false);
        setDeepLink(imageVisitURL);
        setDeepLinkId(imageId);
        setTimeout(() => {
          setSuccess(false);
          setDeeplinkProgress(false);
          handleCancelClick();
        }, progressTimeout);
      })
      .catch((error) => {
        setError(error.message);
        setDeeplinkProgress(false);
      });
  };

  const handleRemoveDeepLink = () => {
    setDeeplinkProgress(true);
    setShowProgressLayout(true);
    db.collection("users")
      .doc(user.userId)
      .update({
        "deepLink.link": "",
        "deepLink.type": "",
        "deepLink.id": "",
      })
      .then(() => {
        setSuccess(true);
        setShowProgressLayout(false);
        setIsImageVisitURLChanged(false);
        setDeepLink("");
        setDeepLinkId("");
        handleCancelClick();
        setTimeout(() => {
          setSuccess(false);

          setDeeplinkProgress(false);
        }, progressTimeout);
      })
      .catch((error) => {
        setError(error.message);
        setDeeplinkProgress(false);
      });
  };

  const onVisitIconClick = () => {
    setShowVisitLayout(!showVisitLayout);
    setShowDeepLinkLayout(false);
    setShowDeleteLayout(false);
    setShowAnimationLayout(false);
    setShowChangeImageLayout(false);

    blurInputs();
  };

  const handleVisitLink = () => {
    if (imageVisitURL === "") {
      setImageVisitURLError("Invalid Image URL");
      document.getElementById(`${image.imageId}URL`).focus();
      return;
    }
    openURL(imageVisitURL);
  };

  const handleImageClick = (e) => {
    if (imageSrc) {
      setShowChangeImageLayout(!showChangeImageLayout);
      setShowDeleteLayout(false);
      setShowVisitLayout(false);
      setShowAnimationLayout(false);
      setShowDeepLinkLayout(false);
    }
  };

  const handleImageChange = (e) => {
    e.preventDefault();
    const changedImage = e.target.files[0];

    if (changedImage) {
      setError(false);
      setImageUploadProgress(true);
      setImageUploadProgressValue(0);
      handleCancelClick();
      setShowProgressLayout(true);

      const uploadTask = storage
        .ref(`users/${user.userId}/images/${imageId}.jpg`)
        .put(changedImage);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setImageUploadProgressValue(progress);
        },
        (error) => {
          setError(true);
          setImageUploadProgress(false);
          setImageUploadProgressValue(0);
        },
        () => {
          storage
            .ref(`users/${user.userId}/images/${imageId}.jpg`)
            .getDownloadURL()
            .then((imageUrl) => {
              setImageSrc(imageUrl);
              db.collection("users")
                .doc(user.userId)
                .collection("images")
                .doc(imageId)
                .update({
                  image: imageUrl,
                })
                .then(() => {
                  setError(false);
                  setSuccess(true);
                  setShowProgressLayout(false);
                  handleCancelClick();
                  setTimeout(() => {
                    setSuccess(false);
                    setImageUploadProgress(false);
                  }, progressTimeout);
                })
                .catch((error) => {
                  setImageUploadProgress(false);
                  setError(true);
                  setImageUploadProgressValue(0);
                });
            });
        }
      );
    }
  };

  const handleCancelClick = () => {
    setShowDeleteLayout(false);
    setShowAnimationLayout(false);
    setShowDeepLinkLayout(false);
    setShowVisitLayout(false);
    setShowChangeImageLayout(false);
  };

  const blurInputs = () => {
    document.getElementById(`${image.imageId}title`).blur();
    document.getElementById(`${image.imageId}description`).blur();
    document.getElementById(`${image.imageId}URL`).blur();
  };

  return (
    <MainContainer
      success={success}
      error={titleError || imageVisitURLError || error || activeError}
      variants={moveRightVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <Container
        success={success}
        error={titleError || imageVisitURLError || error || activeError}
      >
        <DragIconWrapper>
          <i class="bi bi-grip-vertical"></i>
        </DragIconWrapper>
        <ContentWrapper>
          <Content>
            <ImageWrapper onClick={handleImageClick}>
              <img src={imageSrc} alt="image" />
              {imageUploadProgress && (
                <span id="loader">
                  <i class="fas fa-spinner"></i>
                </span>
              )}

              {imageUploadProgress && (
                <span id="progressText">{imageUploadProgressValue}</span>
              )}
            </ImageWrapper>

            <ImageInformationWrapper>
              <ImageInformation>
                <TitleInputWrapper>
                  <input
                    id={`${image.imageId}title`}
                    value={title}
                    onChange={handleTitleChange}
                    type="text"
                    placeholder="Image title here"
                    onBlur={updateNewTitle}
                    onFocus={() => setShowTitleEditIcon(false)}
                  />
                  {showTitleEditIcon && (
                    <span>
                      <i class="bi bi-pencil"></i>
                    </span>
                  )}
                </TitleInputWrapper>
                <DescriptionInputWrapper>
                  <input
                    id={`${image.imageId}description`}
                    value={description}
                    onChange={handleDescriptionChange}
                    type="text"
                    placeholder="Image description here"
                    onBlur={updateNewDescription}
                    spellCheck="false"
                    onFocus={() => setShowDescriptionEditIcon(false)}
                  />
                  {showDescriptionEditIcon && (
                    <span>
                      <i class="bi bi-pencil"></i>
                    </span>
                  )}
                </DescriptionInputWrapper>

                <InputWrapper>
                  <input
                    id={`${image.imageId}URL`}
                    value={imageVisitURL}
                    onChange={handleImageVisitURLChange}
                    type="text"
                    placeholder="Paste visit url here"
                    onBlur={updateNewImageVisitURL}
                    onFocus={() => setShowURLEditIcon(false)}
                  />
                  {showURLEditIcon && (
                    <span>
                      <i class="bi bi-pencil"></i>
                    </span>
                  )}
                </InputWrapper>
              </ImageInformation>
              <SwitchWrapper>
                <ActiveSwitch
                  checked={isActive}
                  onChange={handleActiveStatusChange}
                />
              </SwitchWrapper>
            </ImageInformationWrapper>
          </Content>
          <ActionLayoutWrapper onClick={blurInputs}>
            <ActionIconWrapper
              active={showVisitLayout}
              onClick={onVisitIconClick}
              type="visit"
            >
              <i class="bi bi-box-arrow-up-right"></i>
            </ActionIconWrapper>

            <ActionIconWrapper
              active={showDeepLinkLayout || imageId === deepLinkId}
              onClick={onDeepLinkIconClick}
              type="deepLink"
            >
              <i class="bi bi-lightning"></i>
            </ActionIconWrapper>

            <ActionIconWrapper
              onClick={onAnimationIconClick}
              active={showAnimationLayout || imageAnimation !== ""}
              type="animation"
            >
              <i class="bi bi-bounding-box-circles"></i>
            </ActionIconWrapper>

            <ActionIconWrapper
              type="delete"
              active={showDeleteLayout}
              onClick={onDeleteIconClick}
            >
              <i class="bi bi-trash2"></i>
            </ActionIconWrapper>
          </ActionLayoutWrapper>
        </ContentWrapper>
      </Container>
      {showDeleteLayout && (
        <ActionProceedLayout
          success={success}
          error={imageVisitURLError || error || activeError}
        >
          <DeleteItem
            type={"Image"}
            progress={deleteImageProgress}
            handleCancelClick={handleCancelClick}
            handleDeleteClick={handleDeleteImage}
          />
        </ActionProceedLayout>
      )}

      {showAnimationLayout && (
        <ActionProceedLayout
          success={success}
          error={imageVisitURLError || error || activeError}
        >
          <AnimationItem
            type={"image"}
            handleCancelClick={handleCancelClick}
            linkAnimation={imageAnimation}
            setLinkAnimation={setImageAnimation}
            handleAddAnimation={handleAddAnimation}
          />
        </ActionProceedLayout>
      )}

      {showDeepLinkLayout && (
        <ActionProceedLayout
          success={success}
          error={imageVisitURLError || error || activeError}
        >
          <DeepLinkItem
            handleCancelClick={handleCancelClick}
            active={imageId === deepLinkId}
            handleAddDeepLink={handleAddDeepLink}
            handleRemoveDeepLink={handleRemoveDeepLink}
            progress={deepLinkProgress}
            type={"Image"}
          />
        </ActionProceedLayout>
      )}

      {showVisitLayout && (
        <ActionProceedLayout
          success={success}
          error={imageVisitURLError || error || activeError}
        >
          <VisitItem
            type="Image"
            handleCancelClick={handleCancelClick}
            handleVisitLink={handleVisitLink}
          />
        </ActionProceedLayout>
      )}
      {showChangeImageLayout && (
        <ActionProceedLayout
          success={success}
          error={imageVisitURLError || error || activeError}
        >
          <UploadLinkPhotoItem
            type={"Image"}
            handleCancelClick={handleCancelClick}
            handleLinkImageChange={handleImageChange}
            linkId={imageId}
          />
        </ActionProceedLayout>
      )}
    </MainContainer>
  );
}

export default ImageItem;
