import React from "react";
import { NavbarButton, NavbarLogo } from "..";
import { Nav, NavbarContainer } from "../navbar/Navbar.elements";

function NavbarType1({ title, handleButtonClick }) {
  return (
    <Nav >
      <NavbarContainer>
        <NavbarLogo />
        <NavbarButton title={title} handleButtonClick={handleButtonClick} />
      </NavbarContainer>
    </Nav>
  );
}

export default NavbarType1;
