import styled, { keyframes } from "styled-components";
import { motion } from "framer-motion";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
    transform-origin: center;
  }
  to {
    transform: rotate(360deg);
    transform-origin: center;
  }
`;

export const Content = styled(motion.div)``;

export const ImageWrapper = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;

  & > img {
    width: 100%;
    min-height: 150px;
    background-color: #ededed;
    border-radius: 4px;
    margin-bottom: 8px;
  }

  #loader {
    height: 46px;
    width: 44px;
    font-size: 44px;
    position: absolute;
    color: #ee5253;
    animation: ${rotate} 0.8s linear infinite;
  }

  #progressText {
    position: absolute;
    font-size: 14px;
    color: white;
  }
`;

export const ImageInformationWrapper = styled(motion.div)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justifycontent: space-between;
`;

export const ImageInformation = styled(motion.div)`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  justify-content: center;
`;

export const TitleInputWrapper = styled(motion.div)`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 4px;

  & > input {
    min-width: 0;
    min-height: 0;
    width: 100%;
    outline: none;
    border: none;
    font-weight: 500;
    padding: 0px;
    font-size: 16px;
    ::placeholder,
    ::-webkit-input-placeholder {
      color: #b3b3b3;
    }
    :-ms-input-placeholder {
      color: red;
    }
  }

  & > span {
    color: ${({ theme }) => theme.colors.iconLight};
    font-size: 14px;
  }
`;

export const DescriptionInputWrapper = styled(motion.div)`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 2px;

  & > input {
    min-width: 0;
    min-height: 0;
    width: 100%;
    outline: none;
    border: none;
    font-weight: 500;
    padding: 0px;
    font-size: 14px;
    ::placeholder,
    ::-webkit-input-placeholder {
      color: #b3b3b3;
    }
    :-ms-input-placeholder {
      color: #b3b3b3;
    }
  }

  & > span {
    color: ${({ theme }) => theme.colors.iconLight};
    font-size: 14px;
  }
`;
