import React from "react";

import { Container, LoadingText } from "./LoadingPage.elements";

function LoadingPage() {
  return (
    <Container>
      <span id="loader">
        <i class="fas fa-circle-notch" />
      </span>

      <LoadingText>Loading</LoadingText>
    </Container>
  );
}

export default LoadingPage;
