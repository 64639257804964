export const routes = [
  "dashboard",
  "login",
  "signup",
  "contactus",
  "pricing",
  "settings",
  "billing",
  "signout",
  "appearance",
  "analytics",
];
