import styled, { keyframes } from "styled-components";
import { motion } from "framer-motion";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
    transform-origin: center;
  }
  to {
    transform: rotate(360deg);
    transform-origin: center;
  }
`;

export const Container = styled(motion.div)`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
`;

export const Content = styled(motion.div)`
  display: flex;
  flex-direction: row;

  @media (min-width: 960px) {
    height: 90vh;
    overflow: hidden;
  }
`;

export const LeftSide = styled(motion.div)`
  background: #f1f5f8;
  display: flex;
  width: 100%;
  flex-direction: column;
  position: relative;

  @media (min-width: 960px) {
    flex: 0.5;
    overflow: scroll;
    overflow-x: hidden;
  }
`;

export const UsernameSection = styled(motion.div)`
  position: relative;
  margin: 8px 16px 16px 16px;
  color: #2b2e4a;

  #name {
    color: #e91e63;
  }
`;

export const UsernameHeadingLight = styled(motion.h3)`
  font-size: 18px;
  color: #6c6d7f;
`;

export const UsernameHeading = styled(motion.h1)`
  font-size: 28px;
`;

export const UsernameWrapper = styled(motion.div)`
  display: block;
  position: relative;
  height: 50px;
  border-radius: 4px;
  margin: 16px 0px 0px 0px;
  background-color: white;
  border: ${({ theme }) => theme.border};
  div {
    display: flex;
    align-items: center;
    height: 50px;
    padding-left: 8px;
    padding-right: 8px;
    cursor: pointer;

    & > span {
      height: 26px;
      width: 24px;
      font-size: 24px;
      cursor: pointer;
      color: ${({ theme }) => theme.colors.iconLight};
    }
  }

  p {
    font-size: 16px;
    flex: 1;
    word-break: break-all;
  }
`;

export const UsernameOverflowContainer = styled(motion.div)`
  min-width: 200px;
  position: absolute;
  background-color: white;
  box-shadow: ${({ theme }) => theme.boxShadows.regular};
  transition: ${({ theme }) => theme.boxShadows.transition};
  margin-top: 8px;
  right: 0;
  padding: 8px;
  border-radius: 4px;
  z-index: 166;
`;

export const UsernameOverflowMenuItem = styled(motion.div)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 0px;
  margin: 4px 0px;
  cursor: pointer;

  & > span {
    margin-right: 16px;
    font-size: 22px;
    color: ${({ theme }) => theme.colors.iconLight};
  }
`;

export const RightSide = styled(motion.div)`
  background-color: white;
  display: none;

  @media (min-width: 960px) {
    display: flex;
    flex-direction: column;
    flex: 0.5;
    align-items: center;
    overflow: none;
  }
`;

export const MobileScreenLayoutContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 300px;
  height: 100vh;
  background-color: white;
  margin: 25px auto 25px auto;
  border: 10px solid black;
  border-radius: 40px;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;
