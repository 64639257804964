import React, { useContext, useState } from "react";
import { db } from "../../firebase/firebase";
import firebase from "firebase";
import validator from "validator";

import {
  Container,
  Content,
  SelectedProfileWrapper,
  AddProfileDialog,
  ProfileSearchBarWrapper,
  ProfileSearchBar,
  DisplayAddProfileItemContainer,
  MissedProfiles,
  AddProfileProfilesLoadingLayout,
  SelectedProfileContainer,
  InputWrapper,
} from "./AddProfileitem.elements";
import Button from "../button/Button";
import { AddProfileItemLists, ErrorLayout } from "..";
import { AuthContext } from "../../contexts/AuthContext";
import { dropDownVariants } from "../../animations/animation";

function AddProfileItem({
  isAllProfileListsFetched,
  allProfiles,
  profiles,
  setShowAddProfileItem,
  scrollToProfileView,
  setShowUpgradeToProLayout,
  setUpgradeType,
}) {
  const { user } = useContext(AuthContext);
  const [profileURL, setProfileURL] = useState("");
  const [profileURLError, setProfileURLError] = useState("");
  const [error, setError] = useState("");
  const [progress, setProgress] = useState(false);

  const [selectedProfile, setSelectedProfile] = useState("");

  const [showAddProfileDialog, setShowAddProfileDialog] = useState(false);
  const [profileSearchTerm, setProfileSearchTerm] = useState("");

  const handleAddProfileItemClick = (selectedProfileValue) => {
    setError("");
    setSelectedProfile(selectedProfileValue);
    setShowAddProfileDialog(false);
  };

  const handleProfileURLChange = (e) => {
    setProfileURLError("");
    setProfileURL(e.target.value);
  };

  const handleAddProfileButtonClick = () => {
    if (selectedProfile === "") {
      setError("Pleas select your Social Profile");
      return;
    }

    if (profileURL === "") {
      setProfileURLError("Please enter valid Profile URL");
      return;
    }

    if (profiles.length >= 5 && !user.isPro) {
      setShowUpgradeToProLayout(true);
      setUpgradeType("profiles");
      return;
    }

    if (
      selectedProfile.profileId === "gmail" &&
      !validator.isEmail(profileURL)
    ) {
      setProfileURLError("Invalid email address");
      return;
    }

    if (
      selectedProfile.profileId === "outlook" &&
      !validator.isEmail(profileURL)
    ) {
      setProfileURLError("Invalid email address");
      return;
    }

    if (
      selectedProfile.profileId === "yahoo" &&
      !validator.isEmail(profileURL)
    ) {
      setProfileURLError("Invalid email address");
      return;
    }

    if (
      selectedProfile.profileId === "whatsapp" &&
      !validator.isMobilePhone(profileURL)
    ) {
      setProfileURLError("Invalid phone number");
      return;
    }

    if (
      selectedProfile.profileId === "signal" &&
      !validator.isMobilePhone(profileURL)
    ) {
      setProfileURLError("Invalid phone number");
      return;
    }

    var modifiedValue = profileURL;

    if (
      selectedProfile.placeholder === "@username" &&
      !modifiedValue.startsWith("@")
    ) {
      modifiedValue = "@" + profileURL;
      setProfileURL(modifiedValue);
    }

    if (
      selectedProfile.profileId === "whatsapp" &&
      modifiedValue.length === 10 &&
      !modifiedValue.startsWith("+91")
    ) {
      modifiedValue = "+91" + profileURL;
      setProfileURL(modifiedValue);
    }

    setProgress(true);

    db.collection("users")
      .doc(user.userId)
      .collection("profiles")
      .doc(selectedProfile.profileId)
      .set({
        name: selectedProfile.name,
        profileId: selectedProfile.profileId,
        placeholder: selectedProfile.placeholder,
        order: profiles.length ? profiles[0].order - 0.095 : 0,
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        URL: selectedProfile.URL,
        value: modifiedValue,
        isActive: true,
        image: selectedProfile.image,
        userId: user.userId,
        animation: "",
        type: "profile",
      })
      .then(() => {
        setProgress(false);
        setSelectedProfile("");
        setShowAddProfileItem(false);
        scrollToProfileView();
      })
      .catch((error) => {
        setError(error.message);
        setProgress(false);
      });
  };

  return (
    <Container
      variants={dropDownVariants}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <Content>
        <SelectedProfileWrapper
          onClick={() => setShowAddProfileDialog(!showAddProfileDialog)}
        >
          {selectedProfile ? (
            <SelectedProfileContainer>
              <img src={selectedProfile.image} alt="" />
              <p>{selectedProfile.name}</p>
            </SelectedProfileContainer>
          ) : (
            <p>Select your Social Profile</p>
          )}

          {showAddProfileDialog ? (
            <span id="icon">
              <i class="bi bi-caret-up"></i>
            </span>
          ) : (
            <span id="icon">
              <i class="bi bi-caret-down"></i>
            </span>
          )}
          {showAddProfileDialog && (
            <AddProfileDialog onClick={(e) => e.stopPropagation()}>
              <ProfileSearchBarWrapper>
                <ProfileSearchBar>
                  <span id="searchIcon">
                    <i class="bi bi-search"></i>
                  </span>
                  <input
                    value={profileSearchTerm}
                    onChange={(e) => setProfileSearchTerm(e.target.value)}
                    type="text"
                    placeholder="Search Profiles"
                  />
                </ProfileSearchBar>
              </ProfileSearchBarWrapper>
              <DisplayAddProfileItemContainer>
                {isAllProfileListsFetched ? (
                  allProfiles
                    .filter((profile) => {
                      if (profileSearchTerm.trim() == "") {
                        return profile;
                      } else if (
                        profile.name
                          .toLowerCase()
                          .includes(profileSearchTerm.toLowerCase())
                      ) {
                        return profile;
                      }
                    })
                    .map((profile) => (
                      <AddProfileItemLists
                        key={profile.profileId}
                        profile={profile}
                        handleClick={handleAddProfileItemClick}
                      />
                    ))
                ) : (
                  <AddProfileProfilesLoadingLayout>
                    <span id="loader">
                      <i class="fas fa-circle-notch"></i>
                    </span>
                  </AddProfileProfilesLoadingLayout>
                )}

                <MissedProfiles>
                  <p>Did we missed any Profiles?</p>
                  <span>
                    <a href="/contact">Tell us</a> what we missed.
                  </span>
                </MissedProfiles>
              </DisplayAddProfileItemContainer>
            </AddProfileDialog>
          )}
        </SelectedProfileWrapper>

        <InputWrapper
          value={profileURL}
          onChange={handleProfileURLChange}
          type="text"
          placeholder={
            selectedProfile.placeholder
              ? selectedProfile.placeholder
              : "Paste your link here"
          }
        />

        {profileURLError && (
          <ErrorLayout
            margin="4px 0px 0px 0px"
            errorMessage={profileURLError}
          />
        )}

        {error && <ErrorLayout margin="4px 0px 0px 0px" errorMessage={error} />}
        <Button
          variant="dashboardAdd"
          margin="16px  0px 0px 0px "
          title={
            selectedProfile.placeholder
              ? `Add ${selectedProfile.name}`
              : "Add New Profile"
          }
          progress={progress}
          handleClick={handleAddProfileButtonClick}
        />
      </Content>
    </Container>
  );
}

export default AddProfileItem;
