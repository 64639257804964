import styled from "styled-components";
import { motion } from "framer-motion";

export const Container = styled(motion.div)`
  margin-bottom: 16px;
  padding: 8px;
  border-radius: ${({ theme }) => theme.video.style.borderRadius};
  background-color: ${({ theme }) =>
    theme.video.style.type === "filled"
      ? theme.video.style.backgroundColor
      : "transparent"};
  border: ${({ theme }) => `1px solid ${theme.video.style.borderColor}`};
  cursor: pointer;
`;

export const Title = styled(motion.p)`
  display: ${({ theme }) => (theme.video.isVideoTitle ? "block" : "none")};
  color: ${({ theme }) => theme.video.videoTitleColor};
  margin-top: 8px;
  font-size: 18px;
  word-break: break-all;
`;

export const VideoURL = styled(motion.span)`
  display: ${({ theme }) => (theme.video.isVideoURL ? "block" : "none")};
  color: ${({ theme }) => theme.video.videoURLColor};
  font-size: 12px;
  margin-top: ${({ theme }) => (theme.video.isVideoTitle ? "2px" : "8px")};
  word-break: break-all;
`;
