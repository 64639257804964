import React, { useState } from "react";
import {
  CheckBoxContainer,
  Container,
  Divider,
  FooterMainContainer,
} from "./TopLayoutFooter.elements";

function TopLayoutFooter({
  type,
  handleInclude,
  handleExclude,
  showAll,
  handleShowMoreClick,
  handleShowLessClick,
  length,
}) {
  const [isChecked, setIsChecked] = useState(false);

  const handleOnChange = (e) => {
    setIsChecked(!isChecked);

    if (e.target.checked === true) {
      handleInclude();
    } else {
      handleExclude();
    }
  };

  return (
    <Container>
      <Divider></Divider>
      <FooterMainContainer>
        <CheckBoxContainer>
          <input
            type="checkbox"
            id="topping"
            name="topping"
            value="Paneer"
            checked={isChecked}
            onChange={handleOnChange}
          />
          <p>Include deleted {type}</p>
        </CheckBoxContainer>

        {!showAll && length >= 5 && (
          <p onClick={handleShowMoreClick}>
            Show more
            <span>
              <i class="fas fa-caret-down"></i>
            </span>
          </p>
        )}

        {showAll && length >= 5 && (
          <p onClick={handleShowLessClick}>
            Show less
            <span>
              <i class="fas fa-caret-up"></i>
            </span>
          </p>
        )}
      </FooterMainContainer>
    </Container>
  );
}

export default TopLayoutFooter;
