import styled from "styled-components";
import { motion } from "framer-motion";

export const Container = styled(motion.div)`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.container.background};
  background-color: white;
  box-sizing: border-box;
`;

export const Content = styled(motion.div)`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  align-items: center;
  margin: 16px 0px;
`;

export const LeftSide = styled(motion.div)`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  max-width: 750px;
  width: 100%;
`;

export const Section = styled(motion.div)`
  border: ${({ theme }) => theme.border};
  margin: 16px;
  padding: 16px;
  border-radius: 4px;
`;

export const ConnectContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  margin: 24px 16px;
  border: ${({ theme }) => theme.border};
  padding: 16px;
  border-radius: 4px;
`;

export const SocialContainer = styled(motion.div)`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const SocialMedia = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  cursor: pointer;

  & > img {
    height: 50px;
    width: 50px;
    border-radius: 4px;
  }

  & > span {
    font-size: 14px;
    margin-top: 8px;
  }
`;

export const SecondConnectContainer = styled(motion.div)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media screen and (max-width: 960px) {
    flex-wrap: wrap;
  }
`;

export const ConnectCard = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px;
  margin: 16px;
  border: ${({ theme }) => theme.border};
  background-color: white;
  border-radius: 4px;
  width: 100%;
  height: 200px;

  & > span {
    background-color: #ededed;
    align-items: center;
    justify-content: center;
    padding: 16px;
    border-radius: 50%;
    height: 70px;
    width: 70px;
    text-align: center;
  }

  #location {
    background-color: rgba(74, 169, 108, 0.2);
    color: rgb(74, 169, 108);
  }

  #email {
    background-color: rgba(245, 92, 71, 0.2);
    color: rgb(245, 92, 71);
  }

  .bi {
    font-size: 32px;
  }

  & > p {
    margin-top: 16px;
    text-align: center;
    line-height: 1.5;

    #special {
      text-transform: uppercase;
      letter-spacing: 1.2px;
      font-size: 16px;
      font-weight: 550;
      color: rgb(74, 169, 108); 
    }
  }
`;
