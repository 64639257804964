import styled from "styled-components";
import { Link } from "react-router-dom";

export const Nav = styled.nav`
  display: none;
  width: 100%;
  @media (min-width: 960px) {
    display: block;
  }
`;

export const NavContainer = styled.div`
  width: 100%;
`;

export const NavMenu = styled.ul`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  list-style-type: none;
  padding: 8px;
`;

export const NavItem = styled.li`
  margin: 0px 4px;
  padding: 8px 4px;
  text-decoration: none;
`;

export const NavLink = styled(Link)`
  text-decoration: none;
  color: #2b2e4a;
  opacity: ${({ active }) => (active ? "1" : " 0.4")};
  font-weight: 600;

  &:hover {
    opacity: 1;
    transition: opacity 0.2s ease;
  }
`;
