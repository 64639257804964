import React from "react";
import styled from "styled-components";
function FontItem({ fontStyle, active, handleClick }) {
  return (
    <Container active={active}>
      <FontStyle
        active={active}
        onClick={(e) => handleClick(fontStyle)}
        fontStyle={fontStyle}
      >
        <p>Font Style</p>
      </FontStyle>
      {fontStyle.isPro && <span>Pro</span>}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  padding: 8px;
  margin: 4px;
  border: ${({ active, theme }) =>
    active ? theme.appearance.active.border : "1px solid transparent"};
  background-color: ${({ active, theme }) =>
    active ? theme.appearance.active.background : ""};
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  justify-content: center;

  span {
    font-size: 10px;
    display: flex;
    position: absolute;
    bottom: -1px;
    margin-left: auto;
    margin-right: auto;
    background-color: ${({ theme }) => theme.colors.pro};
    color: white;
    padding: 4px 12px;
    border-radius: 4px;
    letter-spacing: 1.2px;
    font-weight: 600;
    text-transform: uppercase;
  }
`;

const FontStyle = styled.div`
  height: 50px;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: #ededed;
  font-family: ${({ fontStyle }) => fontStyle.fontFamily};
`;

export default FontItem;
