import styled, { keyframes } from "styled-components";
import { motion } from "framer-motion";

export const rotate = keyframes`
from {
  transform: rotate(0deg);
  transform-origin: center;
}
to {
  transform: rotate(360deg);
  transform-origin: center;
}
`;

export const Container = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${({ marginTop }) => marginTop};
  border: 1px solid #808080;
  width: 100%;
  min-height: 50px;
  background-color: #ffffff;
  border-radius: 4px;
  cursor: pointer;
  padding: 4px 16px;

  #loader {
    height: 26px;
    width: 24px;
    font-size: 24px;
    position: absolute;
    color: #000000;
    animation: ${rotate} 0.8s linear infinite;
  }
`;

export const GoogleButtonLayout = styled(motion.div)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: ${({ theme }) => theme.button.fontSize};
  color: #000000;

  transition: all ease-in 0.3s;

  img {
    height: 26px;
    width: 26px;
    margin: 0px 16px 0px 8px;
  }
`;
