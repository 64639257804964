import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import {
  Container,
  Content,
  Section,
  Header,
  ThemeLayout,
  Title,
  SubTitle,
  PersonalInfoLayout,
  ShapesLayout,
  FontColorsLayout,
  VisibilityLayout,
  ProfileLayout,
  LinkStylesLayout,
  LinkLayout,
  BackgroundColorsLayout,
  FontLayout,
  BackgroundLayout,
  Heading,
  ProfileNameLayout,
  ProfilePhotoLayout,
  ProfilePhotoImageLayout,
  ProfilePhotoUploadButton,
  NameInputWrapper,
  BioTextAreaWrapper,
  UsernameLayout,
  UsernameWrapper,
  UsernameOverflowMenuItem,
  UsernameOverflowContainer,
  LoadingLayout,
} from "./Appearance.elements";
import {
  BackgroundColorItem,
  ErrorLayout,
  FontColorItem,
  FontItem,
  LinkStyleItem,
  ShapeItem,
  ThemeItem,
  VisibilityItem,
  SuccessLayout,
  LoadingPage,
} from "../../../components";
import { fontStyles, linkStyles, shapes } from "../../../styles/styles";
import { db, storage } from "../../../firebase/firebase";
import { customTheme } from "../../../themes/theme";
import useComponentVisible from "../../../hooks/useComponentVisible";
import { routes } from "../../../utils/data";
import { openProfile } from "../../../utils/functions";
import { moveUpVariants, popOutVariants } from "../../../animations/animation";
import { AnimatePresence } from "framer-motion";
import { TextareaAutosize } from "@material-ui/core";

function Appearance({
  user,
  setLocation,
  progressTimeout,
  setShowProgressLayout,
  setShowUpgradeToProLayout,
  setUpgradeType,
  dbUsername,
  setDbUsername,
}) {
  const location = useLocation();

  useEffect(() => {
    setLocation(location.pathname);
  }, []);

  const errorTimeout = 10000;

  const [isPro, setIsPro] = useState(user.isPro);

  //ProfileName Layout
  const [showProfileNameLayout, setShowProfileNameLayout] = useState(true);
  const [profilePhoto, setProfilePhoto] = useState(user.profilePhoto);
  const [showProfilePhotoProgressBar, setShowProfilePhotoProgressBar] =
    useState(false);
  const [profilePhotoProgress, setProfilePhotoProgress] = useState("");
  const [profileNameError, setProfileNameError] = useState("");
  const [name, setName] = useState(user.name);
  const [showNameEditIcon, setShowNameEditIcon] = useState(true);
  const [isNameChanged, setIsNameChanged] = useState(false);

  const [bio, setBio] = useState(user.bio);
  const [showBioEditIcon, setShowBioEditIcon] = useState(true);
  const [isBioChanged, setIsBioChanged] = useState(false);

  //UsernameLayout

  const [showUsernameLayout, setShowUsernameLayout] = useState(true);
  const [showUsernameEditIcon, setShowUsernameEditIcon] = useState(true);
  const [username, setUsername] = useState(user.username);
  const [usernameError, setUsernameError] = useState("");
  const [usernameConstantError, setUsernameConstantError] = useState();
  const [usernameProgress, setUsernameProgress] = useState();
  const [usernameSuccessMessage, setUsernameSuccessMessage] = useState("");
  const [usernameSuccess, setUsernameSuccess] = useState();
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  //Themes Layout
  const [showThemesLayout, setShowThemesLayout] = useState(true);
  const [allThemes, setAllThemes] = useState("");
  const [currentTheme, setCurrentTheme] = useState("");
  const [currentThemeName, setCurrentThemeName] = useState("");
  const [themeError, setThemeError] = useState("");
  const [isCurrentThemeFetched, setIsCurrentThemeFetched] = useState(false);
  const [isAllThemeFetched, setIsAllThemeFetched] = useState(false);

  //Background layout
  const [showBackgroundLayout, setShowBackgroundLayout] = useState(true);
  const [backgroundError, setBackgroundError] = useState("");

  const [backgroundColor, setBackgroundColor] = useState("");
  const [backgroundSecondColor, setBackgroundSecondColor] = useState("");
  const [backgroundPattern, setBackgroundPattern] = useState("");
  const [backgroundImage, setBackgroundImage] = useState("");
  const [backgroundImageSource, setBackgroundImageSource] = useState("");
  const [showBackgroundImageProgressBar, setShowBackgroundImageProgressBar] =
    useState(false);

  const [backgroundImageProgress, setBackgroundImageProgress] = useState(0);
  //Personal Information Layout

  const [showPersonalInformationLayout, setShowPersonalInformationLayout] =
    useState(true);
  const [personalError, setPersonalError] = useState("");

  const [profilePhotoShape, setProfilePhotoShape] = useState("");
  const [nameColor, setNameColor] = useState("");
  const [usernameColor, setUsernameColor] = useState("");
  const [bioColor, setBioColor] = useState("");
  const [isProfilePhoto, setIsProfilePhoto] = useState("");
  const [isName, setIsName] = useState("");
  const [isUsername, setIsUsername] = useState("");
  const [isBio, setIsBio] = useState("");

  //Profiles Layout
  const [showProfilesLayout, setShowProfilesLayout] = useState(true);
  const [profileError, setProfileError] = useState("");
  const [profileLogoShape, setProfileLogoShape] = useState("");
  const [profileLabelColor, setProfileLabelColor] = useState("");
  const [isProfileLabel, setIsProfileLabel] = useState("");

  //Link Styles Layout

  const [showLinkStyleLayout, setShowLinkStyleLayout] = useState(true);
  const [linkStyleError, setLinkStyleError] = useState("");
  const [currentLinkStyle, setCurrentLinkStyle] = useState("");
  const [linkImageShape, setLinkImageShape] = useState("");
  const [linkTitleColor, setLinkTitleColor] = useState("");
  const [linkURLColor, setLinkURLColor] = useState("");
  const [linkBackgroundColor, setLinkBackgroundColor] = useState("");
  const [linkBorderColor, setLinkBorderColor] = useState("");

  const [isLinkImage, setIsLinkImage] = useState("");
  const [isLinkURL, setIsLinkURL] = useState("");

  //Video Styles Layout

  const [showVideoStyleLayout, setShowVideoStyleLayout] = useState(true);
  const [videoStyleError, setVideoStyleError] = useState("");
  const [currentVideoStyle, setCurrentVideoStyle] = useState("");
  const [videoTitleColor, setVideoTitleColor] = useState("");
  const [videoURLColor, setVideoURLColor] = useState("");
  const [VideoBackgroundColor, setVideoBackgroundColor] = useState("");
  const [VideoBorderColor, setVideoBorderColor] = useState("");

  const [isVideoTitle, setIsVideoTitle] = useState("");
  const [isVideoURL, setIsVideoURL] = useState("");

  //Image Styles Layout

  const [showImageStyleLayout, setShowImageStyleLayout] = useState(true);
  const [imageStyleError, setImageStyleError] = useState("");
  const [currentImageStyle, setCurrentImageStyle] = useState("");
  const [imageTitleColor, setImageTitleColor] = useState("");
  const [imageURLColor, setImageURLColor] = useState("");
  const [imageDescriptionColor, setImageDescriptionColor] = useState("");
  const [imageBackgroundColor, setImageBackgroundColor] = useState("");
  const [imageBorderColor, setImageBorderColor] = useState("");

  const [isImageTitle, setIsImageTitle] = useState("");
  const [isImageDescription, setIsImageDescription] = useState("");
  const [isImageURL, setIsImageURL] = useState("");

  //Fonts

  const [showFontLayout, setShowFontLayout] = useState(true);
  const [fontError, setFontError] = useState("");
  const [currentFont, setCurrentFont] = useState("");

  //Profile Name Layout

  const handleProfilePhotoChange = (e) => {
    setProfileNameError("");
    const changedProfileImage = e.currentTarget.files[0];
    if (changedProfileImage) {
      setShowProfilePhotoProgressBar(true);
      setProfilePhotoProgress(0);
      setShowProgressLayout(true);

      const uploadTask = storage
        .ref(`users/${user.userId}/profilePhotos/${user.userId}.jpg`)
        .put(changedProfileImage);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProfilePhotoProgress(progress);
        },
        (error) => {
          setProfileNameError(error.message);
          setShowProfilePhotoProgressBar(false);
        },
        () => {
          storage
            .ref(`users/${user.userId}/profilePhotos/${user.userId}.jpg`)
            .getDownloadURL()
            .then((imageUrl) => {
              setProfilePhoto(imageUrl);
              db.collection("users")
                .doc(user.userId)
                .update({
                  profilePhoto: imageUrl,
                })
                .then(() => {
                  setProfileNameError("");
                  setShowProgressLayout(false);
                  setTimeout(() => {
                    setProfilePhotoProgress(0);
                    setShowProfilePhotoProgressBar(false);
                  }, progressTimeout);
                })
                .catch((error) => {
                  setProfileNameError(error.message);
                  setShowProfilePhotoProgressBar(false);
                });
            });
        }
      );
    }
  };

  const handleNameChange = (e) => {
    setProfileNameError("");
    setName(e.target.value);
    setIsNameChanged(true);
  };

  const updateNewName = () => {
    setShowNameEditIcon(true);
    if (!isNameChanged) {
      return;
    }
    setShowProgressLayout(true);
    db.collection("users")
      .doc(user.userId)
      .update({
        name: name,
      })
      .then(() => {
        setProfileNameError("");
        setShowProgressLayout(false);
        setIsNameChanged(false);
      })
      .catch((error) => {
        setProfileNameError(error.message);
      });
  };

  const handleBioChange = (e) => {
    setProfileNameError("");
    setBio(e.target.value);
    setIsBioChanged(true);
  };

  const updateNewBio = () => {
    setShowBioEditIcon(true);
    if (!isBioChanged) {
      return;
    }
    setShowProgressLayout(true);
    db.collection("users")
      .doc(user.userId)
      .update({
        bio: bio,
      })
      .then(() => {
        setProfileNameError("");
        setShowProgressLayout(false);

        setIsBioChanged(false);
      })
      .catch((error) => {
        setProfileNameError(error.message);
      });
  };

  //Username Layout

  const handleUsernameChange = (e) => {
    setUsernameError("");
    setUsernameSuccessMessage("");
    e.currentTarget.value = e.currentTarget.value.replace(/\s/g, "");
    const changedUsername = e.currentTarget.value;
    setUsername(changedUsername);

    if (e.currentTarget.value === "") {
      setUsernameConstantError("Username is required");
      return;
    }

    const usernameRegex = /^[a-zA-Z0-9._]*$/;

    if (!usernameRegex.test(changedUsername)) {
      setUsernameConstantError(
        "special characters are not allowed except \u0022.\u0022  and \u0022_\u0022 "
      );
      return;
    }

    if (routes.indexOf(e.currentTarget.value) !== -1) {
      setUsernameConstantError("Username already taken");
      return;
    }

    setUsernameConstantError("");
    setUsernameProgress(true);

    db.collection("users")
      .where("username", "==", changedUsername)
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.empty) {
          updateNewUsername(changedUsername);
        } else {
          var userIds = [];
          querySnapshot.forEach((doc) => {
            userIds.push(doc.id);
          });

          if (userIds.indexOf(user.userId) !== -1) {
            setUsernameProgress(false);
          } else {
            setUsernameError("Username does not exist");
            setUsernameProgress(false);
          }
        }
      })
      .catch((error) => {
        setUsernameError(error.message);
        setUsernameProgress(false);
      });
  };

  const updateNewUsername = (newUsername) => {
    setShowProgressLayout(true);
    db.collection("users")
      .doc(user.userId)
      .update({
        username: newUsername,
      })
      .then(() => {
        setUsernameError("");
        setUsernameProgress(false);
        setUsernameSuccess(true);
        setUsernameSuccessMessage("Username changed successfully");
        setDbUsername(newUsername);
        setShowProgressLayout(false);

        setTimeout(() => {
          setUsernameSuccessMessage("");
        }, 15000);
      })
      .catch((error) => {
        setUsernameError(error.message);
        setUsernameProgress(false);
      });
  };

  const handleUsernameOverflowIconClick = () => {
    setIsComponentVisible(!isComponentVisible);
  };

  const handleCopyUsername = () => {
    setIsComponentVisible(false);
    setUsernameSuccessMessage("Profile Link copied to clipboard");
    const profileURL = "getmyprofiles.in/" + dbUsername;

    if (navigator.clipboard && window.isSecureContext) {
      setUsernameSuccessMessage("Profile Link copied to clipboard");
      return navigator.clipboard.writeText(profileURL);
    } else {
      let textArea = document.createElement("textarea");
      textArea.value = profileURL;

      textArea.style.position = "fixed";
      textArea.style.left = "-999999px";
      textArea.style.top = "-999999px";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      return new Promise((res, rej) => {
        // here the magic happens
        document.execCommand("copy") ? res() : rej();
        textArea.remove();
      });
    }
  };

  const handleVisitProfileClick = () => {
    setIsComponentVisible(false);
    const profileURL = "getmyprofiles.in/" + dbUsername;
    openProfile(profileURL);
  };

  const initializeItems = (theme) => {
    setIsCurrentThemeFetched(true);

    setCurrentThemeName(theme.theme);
    setCurrentTheme(theme);

    setBackgroundColor(theme.background.backgroundColor);
    setBackgroundSecondColor(theme.background.backgroundSecondColor);
    setBackgroundPattern(theme.background.type);
    setBackgroundImage(theme.background.image);
    setBackgroundImageSource(theme.background.source);

    setProfilePhotoShape(theme.user.profilePhoto.shape);
    setNameColor(theme.user.nameColor);
    setUsernameColor(theme.user.usernameColor);
    setBioColor(theme.user.bioColor);
    setIsProfilePhoto(theme.user.isProfilePhoto);
    setIsName(theme.user.isName);
    setIsUsername(theme.user.isUsername);
    setIsBio(theme.user.isBio);

    setProfileLogoShape(theme.profile.logo.shape);
    setProfileLabelColor(theme.profile.labelColor);
    setIsProfileLabel(theme.profile.isLabel);

    setCurrentLinkStyle(theme.link.style);
    setLinkImageShape(theme.link.image.shape);
    setLinkTitleColor(theme.link.linkTitleColor);
    setLinkURLColor(theme.link.linkURLColor);
    setLinkBackgroundColor(theme.link.style.backgroundColor);
    setLinkBorderColor(theme.link.style.borderColor);
    setIsLinkImage(theme.link.isLinkImage);
    setIsLinkURL(theme.link.isLinkURL);

    if (theme.video) {
      setCurrentVideoStyle(theme.video.style);
      setVideoTitleColor(theme.video.videoTitleColor);
      setVideoURLColor(theme.video.videoURLColor);
      setVideoBackgroundColor(theme.video.style.backgroundColor);
      setVideoBorderColor(theme.video.style.borderColor);
      setIsVideoTitle(theme.video.isVideoTitle);
      setIsVideoURL(theme.video.isVideoURL);
    }

    if (theme.image) {
      setCurrentImageStyle(theme.image.style);
      setImageTitleColor(theme.image.imageTitleColor);
      setImageURLColor(theme.image.imageURLColor);
      setImageDescriptionColor(theme.image.imageDescriptionColor);
      setImageBackgroundColor(theme.image.style.backgroundColor);
      setImageBorderColor(theme.image.style.borderColor);
      setIsImageTitle(theme.image.isImageTitle);
      setIsImageDescription(theme.image.isImageDescription);
      setIsImageURL(theme.image.isImageURL);
    }

    setCurrentFont(theme.font);
  };

  const uploadUserTheme = (themeValue) => {
    setThemeError("");

    if (themeValue.isPro && !isPro) {
      setShowUpgradeToProLayout(true);
      setUpgradeType("themes");
      return;
    }

    setCurrentThemeName(themeValue.theme);
    initializeItems(themeValue);
    setShowProgressLayout(true);
    db.collection("users")
      .doc(user.userId)
      .collection("themes")
      .doc("theme")
      .set(themeValue)
      .then(() => {
        setShowProgressLayout(false);
      })
      .catch((error) => {
        setThemeError(error.message);
      });
  };

  const uploadCustomTheme = () => {
    setCurrentThemeName("Custom");
    setThemeError("");
    setShowProgressLayout(true);
    db.collection("users")
      .doc(user.userId)
      .collection("themes")
      .doc("theme")
      .update({
        theme: "Custom",
      })
      .then(() => {
        setShowProgressLayout(false);
      })
      .catch((error) => {
        setThemeError(error.message);
      });
  };

  useEffect(() => {
    const getCurrentTheme = () => {
      db.collection("users")
        .doc(user.userId)
        .collection("themes")
        .doc("theme")
        .get()
        .then((doc) => {
          setCurrentTheme(doc.data());
          initializeItems(doc.data());
        });
    };

    const getAllThemes = () => {
      setThemeError("");

      db.collection("app")
        .doc("data")
        .collection("themes")
        .orderBy("isPro")
        .get()
        .then((querySnapshot) => {
          const allThemeValues = querySnapshot.docs.map((doc) => doc.data());
          setAllThemes(allThemeValues);
          setIsAllThemeFetched(true);
        })
        .catch((error) => {
          setThemeError(error.message);
          setIsAllThemeFetched(true);
          setTimeout(() => {
            setThemeError("");
          }, errorTimeout);
        });
    };

    getCurrentTheme();
    getAllThemes();
  }, []);

  //Background

  const handleBackgroundColorChange = (color, secondColor) => {
    setBackgroundColor(color);
    setBackgroundError("");
    setShowProgressLayout(true);
    db.collection("users")
      .doc(user.userId)
      .collection("themes")
      .doc("theme")
      .update({
        "background.backgroundColor": color,
        "background.backgroundSecondColor": secondColor,
        theme: "Custom",
      })
      .then(() => {
        setCurrentThemeName("Custom");
        setBackgroundError("");
        setShowProgressLayout(false);
        if (backgroundImage) {
          handleDeleteImage();
        }
      })
      .catch((error) => {
        setBackgroundError(error.message);
      });
  };

  const handleBackgroundPatternChange = (pattern) => {
    setBackgroundPattern(pattern);
    setBackgroundError("");
    setShowProgressLayout(true);
    db.collection("users")
      .doc(user.userId)
      .collection("themes")
      .doc("theme")
      .update({
        "background.type": pattern,
        theme: "Custom",
      })
      .then(() => {
        setCurrentThemeName("Custom");
        setBackgroundError("");
        setTimeout(() => {
          setShowBackgroundImageProgressBar(false);
        }, progressTimeout);
        setShowProgressLayout(false);

        if (backgroundImage) {
          handleDeleteImage();
        }
      })
      .catch((error) => {
        setBackgroundError(error.message);
      });
  };

  const handleBackgroundImageChange = (e) => {
    if (!isPro) {
      setShowUpgradeToProLayout(true);
      setUpgradeType("backgroundImage");
      return;
    }

    setBackgroundError("");
    const changedImage = e.currentTarget.files[0];
    if (changedImage) {
      setShowBackgroundImageProgressBar(true);
      setBackgroundImageProgress(0);
      setShowProgressLayout(true);
      const uploadTask = storage
        .ref(`users/${user.userId}/themeImages/themeImage.jpg`)
        .put(changedImage);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setBackgroundImageProgress(progress);
        },
        (error) => {
          setBackgroundError(error.message);
          setShowBackgroundImageProgressBar(false);
          setTimeout(() => {
            setBackgroundError("");
          }, errorTimeout);
        },
        () => {
          storage
            .ref(`users/${user.userId}/themeImages/themeImage.jpg`)
            .getDownloadURL()
            .then((imageUrl) => {
              setBackgroundImage(imageUrl);
              setBackgroundPattern("Image");
              db.collection("users")
                .doc(user.userId)
                .collection("themes")
                .doc("theme")
                .update({
                  theme: "Custom",
                  "background.type": "Image",
                  "background.image": imageUrl,
                  "background.source": "Upload",
                })
                .then(() => {
                  setCurrentThemeName("Custom");
                  setBackgroundImageSource("Upload");
                  setTimeout(() => {
                    setShowBackgroundImageProgressBar(false);
                  }, progressTimeout);
                  setShowProgressLayout(false);
                })
                .catch((error) => {
                  setBackgroundError(error.message);
                  setShowBackgroundImageProgressBar(false);
                });
            });
        }
      );
    }
  };

  const handleDeleteImage = () => {
    setBackgroundError("");
    setShowBackgroundImageProgressBar(true);
    setBackgroundImageProgress(0);
    setShowProgressLayout(true);
    if (backgroundImageSource === "Upload") {
      var imageRef = storage.refFromURL(backgroundImage);
      imageRef
        .delete()
        .then(() => {
          db.collection("users")
            .doc(user.userId)
            .collection("themes")
            .doc("theme")
            .update({
              theme: "Custom",
              "background.type": "Flat",
              "background.image": "",
              "background.source": "",
            })
            .then(() => {
              setCurrentThemeName("Custom");
              setBackgroundPattern("Flat");
              setBackgroundImageProgress(100);
              setBackgroundImage("");
              setShowProgressLayout(false);
              setTimeout(() => {
                setShowBackgroundImageProgressBar(false);
              }, progressTimeout);
            })
            .catch((error) => {
              setBackgroundError(error.message);

              setBackgroundImageProgress(0);
              setShowBackgroundImageProgressBar(false);
            });
        })
        .catch((error) => {
          setBackgroundError(error.message);

          setBackgroundImageProgress(0);
          setShowBackgroundImageProgressBar(false);
        });
    } else {
      db.collection("users")
        .doc(user.userId)
        .collection("themes")
        .doc("theme")
        .update({
          theme: "Custom",
          "background.type": "Flat",
          "background.image": "",
        })
        .then(() => {
          setCurrentThemeName("Custom");
          setBackgroundImageProgress(100);
          setBackgroundImage("");
          setBackgroundPattern("Flat");
          setShowProgressLayout(false);
          setTimeout(() => {
            setShowBackgroundImageProgressBar(false);
          }, progressTimeout);
        })
        .catch((error) => {
          setBackgroundError(error.message);

          setBackgroundImageProgress(0);
          setShowBackgroundImageProgressBar(false);
        });
    }
  };

  //Personal Information Layout

  const handleProfilePhotoShapeClick = (shape) => {
    setProfilePhotoShape(shape.shape);
    setPersonalError("");
    setShowProgressLayout(true);
    db.collection("users")
      .doc(user.userId)
      .collection("themes")
      .doc("theme")
      .update({
        "user.profilePhoto.shape": shape.shape,
        "user.profilePhoto.borderRadius": shape.borderRadius,
        theme: "Custom",
      })
      .then(() => {
        setPersonalError("");
        setCurrentThemeName("Custom");
        setShowProgressLayout(false);
      })
      .catch((error) => {
        setPersonalError(error.message);
        setTimeout(() => {
          setPersonalError("");
        }, errorTimeout);
      });
  };

  const handleNameColorChange = (changedColor) => {
    setNameColor(changedColor);
    setPersonalError("");
    setShowProgressLayout(true);
    db.collection("users")
      .doc(user.userId)
      .collection("themes")
      .doc("theme")
      .update({
        "user.nameColor": changedColor,
        theme: "Custom",
      })
      .then(() => {
        setCurrentThemeName("Custom");
        setPersonalError("");
        setShowProgressLayout(false);
      })
      .catch((error) => {
        setPersonalError(error.message);
        setTimeout(() => {
          setPersonalError("");
        }, errorTimeout);
      });
  };

  const handleUsernameColorChange = (changedColor) => {
    setUsernameColor(changedColor);

    setPersonalError("");
    setShowProgressLayout(true);
    db.collection("users")
      .doc(user.userId)
      .collection("themes")
      .doc("theme")
      .update({
        "user.usernameColor": changedColor,
        theme: "Custom",
      })
      .then(() => {
        setPersonalError("");
        setCurrentThemeName("Custom");
        setShowProgressLayout(false);
      })
      .catch((error) => {
        setPersonalError(error.message);
        setTimeout(() => {
          setPersonalError("");
        }, errorTimeout);
      });
  };

  const handleBioColorChange = (changedColor) => {
    setBioColor(changedColor);

    setPersonalError("");
    setShowProgressLayout(true);
    db.collection("users")
      .doc(user.userId)
      .collection("themes")
      .doc("theme")
      .update({
        "user.bioColor": changedColor,
        theme: "Custom",
      })
      .then(() => {
        setPersonalError("");
        setCurrentThemeName("Custom");
        setShowProgressLayout(false);
      })
      .catch((error) => {
        setPersonalError(error.message);
        setTimeout(() => {
          setPersonalError("");
        }, errorTimeout);
      });
  };

  const toggleIsProfilePhoto = (value) => {
    setIsProfilePhoto(value);

    setPersonalError("");
    setShowProgressLayout(true);
    db.collection("users")
      .doc(user.userId)
      .collection("themes")
      .doc("theme")
      .update({
        "user.isProfilePhoto": value,
        theme: "Custom",
      })
      .then(() => {
        setCurrentThemeName("Custom");
        setPersonalError("");
        setShowProgressLayout(false);
      })
      .catch((error) => {
        setPersonalError(error.message);
        setTimeout(() => {
          setPersonalError("");
        }, errorTimeout);
      });
  };

  const toggleIsName = (value) => {
    setIsName(value);

    setPersonalError("");
    setShowProgressLayout(true);
    db.collection("users")
      .doc(user.userId)
      .collection("themes")
      .doc("theme")
      .update({
        "user.isName": value,
        theme: "Custom",
      })
      .then(() => {
        setPersonalError("");
        setCurrentThemeName("Custom");
        setShowProgressLayout(false);
      })
      .catch((error) => {
        setPersonalError(error.message);
        setTimeout(() => {
          setPersonalError("");
        }, errorTimeout);
      });
  };

  const toggleIsUsername = (value) => {
    setIsUsername(value);
    setPersonalError("");
    setShowProgressLayout(true);
    db.collection("users")
      .doc(user.userId)
      .collection("themes")
      .doc("theme")
      .update({
        "user.isUsername": value,
        theme: "Custom",
      })
      .then(() => {
        setPersonalError("");
        setCurrentThemeName("Custom");
        setShowProgressLayout(false);
      })
      .catch((error) => {
        setPersonalError(error.message);
        setTimeout(() => {
          setPersonalError("");
        }, errorTimeout);
      });
  };

  const toggleIsBio = (value) => {
    setIsBio(value);

    setPersonalError("");
    setShowProgressLayout(true);
    db.collection("users")
      .doc(user.userId)
      .collection("themes")
      .doc("theme")
      .update({
        "user.isBio": value,
        theme: "Custom",
      })
      .then(() => {
        setPersonalError("");
        setCurrentThemeName("Custom");
        setShowProgressLayout(false);
      })
      .catch((error) => {
        setPersonalError(error.message);
        setTimeout(() => {
          setPersonalError("");
        }, errorTimeout);
      });
  };

  //Profiles

  const handleProfileLogoShapeClick = (shape) => {
    setProfileLogoShape(shape.shape);

    setProfileError("");
    setShowProgressLayout(true);
    db.collection("users")
      .doc(user.userId)
      .collection("themes")
      .doc("theme")
      .update({
        "profile.logo.shape": shape.shape,
        "profile.logo.borderRadius": shape.borderRadius,
        theme: "Custom",
      })
      .then(() => {
        setCurrentThemeName("Custom");
        setProfileError("");
        setShowProgressLayout(false);
      })
      .catch((error) => {
        setProfileError(error.message);
        setTimeout(() => {
          setProfileError("");
        }, errorTimeout);
      });
  };

  const handleProfileLabelColorChange = (changedColor) => {
    setProfileLabelColor(changedColor);

    setProfileError("");
    setShowProgressLayout(true);
    db.collection("users")
      .doc(user.userId)
      .collection("themes")
      .doc("theme")
      .update({
        "profile.labelColor": changedColor,
        theme: "Custom",
      })
      .then(() => {
        setProfileError("");
        setCurrentThemeName("Custom");
        setShowProgressLayout(false);
      })
      .catch((error) => {
        setProfileError(error.message);
        setTimeout(() => {
          setProfileError("");
        }, errorTimeout);
      });
  };

  const toggleIsProfileLabel = (value) => {
    setIsProfileLabel(value);
    setProfileError("");
    setShowProgressLayout(true);
    db.collection("users")
      .doc(user.userId)
      .collection("themes")
      .doc("theme")
      .update({
        "profile.isLabel": value,
        theme: "Custom",
      })
      .then(() => {
        setProfileError("");
        setCurrentThemeName("Custom");
        setShowProgressLayout(false);
      })
      .catch((error) => {
        setProfileError(error.message);
        setTimeout(() => {
          setProfileError("");
        }, errorTimeout);
      });
  };

  //Link Styles

  const handleLinkStyleClick = (linkStyle) => {
    setCurrentLinkStyle(linkStyle);

    setLinkStyleError("");
    setShowProgressLayout(true);
    db.collection("users")
      .doc(user.userId)
      .collection("themes")
      .doc("theme")
      .update({
        "link.style.style": linkStyle.style,
        "link.style.borderRadius": linkStyle.borderRadius,
        "link.style.type": linkStyle.type,
        theme: "Custom",
      })
      .then(() => {
        setLinkStyleError("");
        setCurrentThemeName("Custom");
        setShowProgressLayout(false);
      })
      .catch((error) => {
        setLinkStyleError(error.message);
        setTimeout(() => {
          setLinkStyleError("");
        }, errorTimeout);
      });
  };

  const handleLinkImageShapeClick = (shape) => {
    setLinkImageShape(shape.shape);
    setLinkStyleError("");
    setShowProgressLayout(true);
    db.collection("users")
      .doc(user.userId)
      .collection("themes")
      .doc("theme")
      .update({
        "link.image.shape": shape.shape,
        "link.image.borderRadius": shape.borderRadius,
        theme: "Custom",
      })
      .then(() => {
        setLinkStyleError("");
        setCurrentThemeName("Custom");
        setShowProgressLayout(false);
      })
      .catch((error) => {
        setLinkStyleError(error.message);
        setTimeout(() => {
          setLinkStyleError("");
        }, errorTimeout);
      });
  };

  const handleLinkTitleColorChange = (changedColor) => {
    setLinkTitleColor(changedColor);

    setLinkStyleError("");
    setShowProgressLayout(true);
    db.collection("users")
      .doc(user.userId)
      .collection("themes")
      .doc("theme")
      .update({
        "link.linkTitleColor": changedColor,
        theme: "Custom",
      })
      .then(() => {
        setLinkStyleError("");
        setCurrentThemeName("Custom");
        setShowProgressLayout(false);
      })
      .catch((error) => {
        setLinkStyleError(error.message);
        setTimeout(() => {
          setLinkStyleError("");
        }, errorTimeout);
      });
  };

  const handleLinkURLColorChange = (changedColor) => {
    setLinkURLColor(changedColor);
    setLinkStyleError("");
    setShowProgressLayout(true);
    db.collection("users")
      .doc(user.userId)
      .collection("themes")
      .doc("theme")
      .update({
        "link.linkURLColor": changedColor,
        theme: "Custom",
      })
      .then(() => {
        setLinkStyleError("");
        setCurrentThemeName("Custom");
        setShowProgressLayout(false);
      })
      .catch((error) => {
        setLinkStyleError(error.message);
        setTimeout(() => {
          setLinkStyleError("");
        }, errorTimeout);
      });
  };

  const handleLinkBackgroundColorChange = (changedColor) => {
    setLinkBackgroundColor(changedColor);

    setLinkStyleError("");
    setShowProgressLayout(true);
    db.collection("users")
      .doc(user.userId)
      .collection("themes")
      .doc("theme")
      .update({
        "link.style.backgroundColor": changedColor,
        theme: "Custom",
      })
      .then(() => {
        setLinkStyleError("");
        setCurrentThemeName("Custom");
        setShowProgressLayout(false);
      })
      .catch((error) => {
        setLinkStyleError(error.message);
        setTimeout(() => {
          setLinkStyleError("");
        }, errorTimeout);
      });
  };

  const handleLinkBorderColorChange = (changedColor) => {
    setLinkBorderColor(changedColor);

    setLinkStyleError("");
    setShowProgressLayout(true);
    db.collection("users")
      .doc(user.userId)
      .collection("themes")
      .doc("theme")
      .update({
        "link.style.borderColor": changedColor,
        theme: "Custom",
      })
      .then(() => {
        setCurrentThemeName("Custom");
        setLinkStyleError("");
        setShowProgressLayout(false);
      })
      .catch((error) => {
        setLinkStyleError(error.message);
        setTimeout(() => {
          setLinkStyleError("");
        }, errorTimeout);
      });
  };

  const toggleIsLinkImage = (value) => {
    setIsLinkImage(value);

    setLinkStyleError("");
    setShowProgressLayout(true);
    db.collection("users")
      .doc(user.userId)
      .collection("themes")
      .doc("theme")
      .update({
        "link.isLinkImage": value,
        theme: "Custom",
      })
      .then(() => {
        setCurrentThemeName("Custom");
        setLinkStyleError("");

        setShowProgressLayout(false);
      })
      .catch((error) => {
        setLinkStyleError(error.message);
        setTimeout(() => {
          setLinkStyleError("");
        }, errorTimeout);
      });
  };

  const toggleIsLinkURL = (value) => {
    setIsLinkURL(value);
    setLinkStyleError("");
    setShowProgressLayout(true);
    db.collection("users")
      .doc(user.userId)
      .collection("themes")
      .doc("theme")
      .update({
        "link.isLinkURL": value,
        theme: "Custom",
      })
      .then(() => {
        setLinkStyleError("");
        setCurrentThemeName("Custom");
        setShowProgressLayout(false);
      })
      .catch((error) => {
        setLinkStyleError(error.message);
        setTimeout(() => {
          setLinkStyleError("");
        }, errorTimeout);
      });
  };

  //Video Style

  const handleVideoStyleClick = (linkStyle) => {
    setCurrentVideoStyle(linkStyle);

    setVideoStyleError("");
    setShowProgressLayout(true);
    db.collection("users")
      .doc(user.userId)
      .collection("themes")
      .doc("theme")
      .update({
        "video.style.style": linkStyle.style,
        "video.style.borderRadius": linkStyle.borderRadius,
        "video.style.type": linkStyle.type,
        theme: "Custom",
      })
      .then(() => {
        setVideoStyleError("");
        setCurrentThemeName("Custom");
        setShowProgressLayout(false);
      })
      .catch((error) => {
        setVideoStyleError(error.message);
        setTimeout(() => {
          setVideoStyleError("");
        }, errorTimeout);
      });
  };

  const handleVideoTitleColorChange = (changedColor) => {
    setVideoTitleColor(changedColor);

    setVideoStyleError("");
    setShowProgressLayout(true);
    db.collection("users")
      .doc(user.userId)
      .collection("themes")
      .doc("theme")
      .update({
        "video.videoTitleColor": changedColor,
        theme: "Custom",
      })
      .then(() => {
        setVideoStyleError("");
        setCurrentThemeName("Custom");
        setShowProgressLayout(false);
      })
      .catch((error) => {
        setVideoStyleError(error.message);
        setTimeout(() => {
          setVideoStyleError("");
        }, errorTimeout);
      });
  };

  const handleVideoURLColorChange = (changedColor) => {
    setVideoURLColor(changedColor);
    setVideoStyleError("");
    setShowProgressLayout(true);
    db.collection("users")
      .doc(user.userId)
      .collection("themes")
      .doc("theme")
      .update({
        "video.videoURLColor": changedColor,
        theme: "Custom",
      })
      .then(() => {
        setVideoStyleError("");
        setCurrentThemeName("Custom");
        setShowProgressLayout(false);
      })
      .catch((error) => {
        setVideoStyleError(error.message);
        setTimeout(() => {
          setVideoStyleError("");
        }, errorTimeout);
      });
  };

  const handleVideoBackgroundColorChange = (changedColor) => {
    setVideoBackgroundColor(changedColor);

    setVideoStyleError("");
    setShowProgressLayout(true);
    db.collection("users")
      .doc(user.userId)
      .collection("themes")
      .doc("theme")
      .update({
        "video.style.backgroundColor": changedColor,
        theme: "Custom",
      })
      .then(() => {
        setVideoStyleError("");
        setCurrentThemeName("Custom");
        setShowProgressLayout(false);
      })
      .catch((error) => {
        setVideoStyleError(error.message);
        setTimeout(() => {
          setVideoStyleError("");
        }, errorTimeout);
      });
  };

  const handleVideoBorderColorChange = (changedColor) => {
    setVideoBorderColor(changedColor);

    setVideoStyleError("");
    setShowProgressLayout(true);
    db.collection("users")
      .doc(user.userId)
      .collection("themes")
      .doc("theme")
      .update({
        "video.style.borderColor": changedColor,
        theme: "Custom",
      })
      .then(() => {
        setCurrentThemeName("Custom");
        setVideoStyleError("");
        setShowProgressLayout(false);
      })
      .catch((error) => {
        setVideoStyleError(error.message);
        setTimeout(() => {
          setVideoStyleError("");
        }, errorTimeout);
      });
  };

  const toggleIsVideoTitle = (value) => {
    setIsVideoTitle(value);

    setVideoStyleError("");
    setShowProgressLayout(true);
    db.collection("users")
      .doc(user.userId)
      .collection("themes")
      .doc("theme")
      .update({
        "video.isVideoTitle": value,
        theme: "Custom",
      })
      .then(() => {
        setCurrentThemeName("Custom");
        setVideoStyleError("");

        setShowProgressLayout(false);
      })
      .catch((error) => {
        setVideoStyleError(error.message);
        setTimeout(() => {
          setVideoStyleError("");
        }, errorTimeout);
      });
  };

  const toggleIsVideoURL = (value) => {
    setIsVideoURL(value);
    setVideoStyleError("");
    setShowProgressLayout(true);
    db.collection("users")
      .doc(user.userId)
      .collection("themes")
      .doc("theme")
      .update({
        "video.isVideoURL": value,
        theme: "Custom",
      })
      .then(() => {
        setVideoStyleError("");
        setCurrentThemeName("Custom");
        setShowProgressLayout(false);
      })
      .catch((error) => {
        setVideoStyleError(error.message);
        setTimeout(() => {
          setVideoStyleError("");
        }, errorTimeout);
      });
  };

  //Images

  const handleImageStyleClick = (linkStyle) => {
    setCurrentImageStyle(linkStyle);

    setImageStyleError("");
    setShowProgressLayout(true);
    db.collection("users")
      .doc(user.userId)
      .collection("themes")
      .doc("theme")
      .update({
        "image.style.style": linkStyle.style,
        "image.style.borderRadius": linkStyle.borderRadius,
        "image.style.type": linkStyle.type,
        theme: "Custom",
      })
      .then(() => {
        setImageStyleError("");
        setCurrentThemeName("Custom");
        setShowProgressLayout(false);
      })
      .catch((error) => {
        setImageStyleError(error.message);
        setTimeout(() => {
          setImageStyleError("");
        }, errorTimeout);
      });
  };

  const handleImageTitleColorChange = (changedColor) => {
    setImageTitleColor(changedColor);

    setImageStyleError("");
    setShowProgressLayout(true);
    db.collection("users")
      .doc(user.userId)
      .collection("themes")
      .doc("theme")
      .update({
        "image.imageTitleColor": changedColor,
        theme: "Custom",
      })
      .then(() => {
        setImageStyleError("");
        setCurrentThemeName("Custom");
        setShowProgressLayout(false);
      })
      .catch((error) => {
        setImageStyleError(error.message);
        setTimeout(() => {
          setImageStyleError("");
        }, errorTimeout);
      });
  };

  const handleImageURLColorChange = (changedColor) => {
    setImageURLColor(changedColor);
    setImageStyleError("");
    setShowProgressLayout(true);
    db.collection("users")
      .doc(user.userId)
      .collection("themes")
      .doc("theme")
      .update({
        "image.imageURLColor": changedColor,
        theme: "Custom",
      })
      .then(() => {
        setImageStyleError("");
        setCurrentThemeName("Custom");
        setShowProgressLayout(false);
      })
      .catch((error) => {
        setImageStyleError(error.message);
        setTimeout(() => {
          setImageStyleError("");
        }, errorTimeout);
      });
  };

  const handleImageDescriptionColorChange = (changedColor) => {
    setImageDescriptionColor(changedColor);
    setImageStyleError("");
    setShowProgressLayout(true);
    db.collection("users")
      .doc(user.userId)
      .collection("themes")
      .doc("theme")
      .update({
        "image.imageDescriptionColor": changedColor,
        theme: "Custom",
      })
      .then(() => {
        setImageStyleError("");
        setCurrentThemeName("Custom");
        setShowProgressLayout(false);
      })
      .catch((error) => {
        setImageStyleError(error.message);
        setTimeout(() => {
          setImageStyleError("");
        }, errorTimeout);
      });
  };

  const handleImageBackgroundColorChange = (changedColor) => {
    setImageBackgroundColor(changedColor);

    setImageStyleError("");
    setShowProgressLayout(true);
    db.collection("users")
      .doc(user.userId)
      .collection("themes")
      .doc("theme")
      .update({
        "image.style.backgroundColor": changedColor,
        theme: "Custom",
      })
      .then(() => {
        setImageStyleError("");
        setCurrentThemeName("Custom");
        setShowProgressLayout(false);
      })
      .catch((error) => {
        setImageStyleError(error.message);
        setTimeout(() => {
          setImageStyleError("");
        }, errorTimeout);
      });
  };

  const handleImageBorderColorChange = (changedColor) => {
    setImageBorderColor(changedColor);

    setImageStyleError("");
    setShowProgressLayout(true);
    db.collection("users")
      .doc(user.userId)
      .collection("themes")
      .doc("theme")
      .update({
        "image.style.borderColor": changedColor,
        theme: "Custom",
      })
      .then(() => {
        setCurrentThemeName("Custom");
        setImageStyleError("");
        setShowProgressLayout(false);
      })
      .catch((error) => {
        setImageStyleError(error.message);
        setTimeout(() => {
          setImageStyleError("");
        }, errorTimeout);
      });
  };

  const toggleIsImageTitle = (value) => {
    setIsImageTitle(value);

    setImageStyleError("");
    setShowProgressLayout(true);
    db.collection("users")
      .doc(user.userId)
      .collection("themes")
      .doc("theme")
      .update({
        "image.isImageTitle": value,
        theme: "Custom",
      })
      .then(() => {
        setCurrentThemeName("Custom");
        setImageStyleError("");

        setShowProgressLayout(false);
      })
      .catch((error) => {
        setImageStyleError(error.message);
        setTimeout(() => {
          setImageStyleError("");
        }, errorTimeout);
      });
  };

  const toggleIsImageURL = (value) => {
    setIsImageURL(value);
    setImageStyleError("");
    setShowProgressLayout(true);
    db.collection("users")
      .doc(user.userId)
      .collection("themes")
      .doc("theme")
      .update({
        "image.isImageURL": value,
        theme: "Custom",
      })
      .then(() => {
        setImageStyleError("");
        setCurrentThemeName("Custom");
        setShowProgressLayout(false);
      })
      .catch((error) => {
        setImageStyleError(error.message);
        setTimeout(() => {
          setImageStyleError("");
        }, errorTimeout);
      });
  };

  const toggleIsImageDescription = (value) => {
    setIsImageDescription(value);
    setImageStyleError("");
    setShowProgressLayout(true);
    db.collection("users")
      .doc(user.userId)
      .collection("themes")
      .doc("theme")
      .update({
        "image.isImageDescription": value,
        theme: "Custom",
      })
      .then(() => {
        setImageStyleError("");
        setCurrentThemeName("Custom");
        setShowProgressLayout(false);
      })
      .catch((error) => {
        setImageStyleError(error.message);
        setTimeout(() => {
          setImageStyleError("");
        }, errorTimeout);
      });
  };

  //Fonts

  const handleFontClick = (fontStyle) => {
    setFontError("");

    if (fontStyle.isPro && !isPro) {
      setShowUpgradeToProLayout(true);
      setUpgradeType("fonts");
      return;
    }

    setCurrentFont(fontStyle);
    setShowProgressLayout(true);
    db.collection("users")
      .doc(user.userId)
      .collection("themes")
      .doc("theme")
      .update({
        "font.fontName": fontStyle.fontName,
        "font.fontFamily": fontStyle.fontFamily,
        theme: "Custom",
      })
      .then(() => {
        setFontError("");
        setCurrentThemeName("Custom");
        setShowProgressLayout(false);
      })
      .catch((error) => {
        setFontError(error.message);
        setTimeout(() => {
          setFontError("");
        }, errorTimeout);
      });
  };

  useEffect(() => {
    document.title = "Appearance" + " | " + process.env.REACT_APP_TITLE;
  }, []);

  return (
    <Container variants={moveUpVariants} initial="hidden" animate="visible">
      <Content>
        <Section>
          <Header
            onClick={() => setShowProfileNameLayout(!showProfileNameLayout)}
          >
            <Heading>Photo, Name & Bio</Heading>
            {showProfileNameLayout ? (
              <ExpandLessIcon
                onClick={(e) => setShowProfileNameLayout(false)}
              />
            ) : (
              <ExpandMoreIcon onClick={(e) => setShowProfileNameLayout(true)} />
            )}
          </Header>

          {showProfileNameLayout && (
            <ProfileNameLayout>
              <ProfilePhotoLayout>
                <ProfilePhotoImageLayout>
                  <label htmlFor="profilePhotoInput">
                    <img
                      id="profile-photo"
                      src={profilePhoto}
                      alt="profile_photo"
                    />
                  </label>

                  <input
                    id="profilePhotoInput"
                    type="file"
                    style={{ display: "none" }}
                    accept="image/*"
                    onChange={handleProfilePhotoChange}
                  />

                  {showProfilePhotoProgressBar && (
                    <span id="loader">
                      <i class="fas fa-spinner"></i>
                    </span>
                  )}

                  {showProfilePhotoProgressBar && (
                    <span id="progressText">{profilePhotoProgress}</span>
                  )}
                </ProfilePhotoImageLayout>

                {!showProfilePhotoProgressBar && (
                  <ProfilePhotoUploadButton>
                    <label htmlFor="profilePhotoInput">Change Photo</label>
                  </ProfilePhotoUploadButton>
                )}
              </ProfilePhotoLayout>
              <label>Name</label>
              <NameInputWrapper>
                <input
                  value={name}
                  onChange={handleNameChange}
                  type="text"
                  onBlur={updateNewName}
                  onFocus={() => setShowNameEditIcon(false)}
                  spellCheck="false"
                  placeholder="Enter your Name"
                />
                {showNameEditIcon && (
                  <span>
                    <i class="bi bi-pencil"></i>
                  </span>
                )}
              </NameInputWrapper>

              <label>Bio</label>

              <BioTextAreaWrapper>
                <textarea
                  value={bio}
                  spellCheck="false"
                  onChange={handleBioChange}
                  onBlur={updateNewBio}
                  type="text"
                  placeholder="Say something about yourself"
                  onFocus={() => setShowBioEditIcon(false)}
                />

                {showBioEditIcon && (
                  <span>
                    <i class="bi bi-pencil"></i>
                  </span>
                )}
              </BioTextAreaWrapper>
            </ProfileNameLayout>
          )}
        </Section>

        <Section>
          <Header onClick={(e) => setShowUsernameLayout(!showUsernameLayout)}>
            <Heading>Username</Heading>
            {showUsernameLayout ? (
              <ExpandLessIcon onClick={(e) => setShowUsernameLayout(false)} />
            ) : (
              <ExpandMoreIcon onClick={(e) => setShowUsernameLayout(true)} />
            )}
          </Header>
          {showUsernameLayout && (
            <UsernameLayout>
              <label>Username</label>
              <UsernameWrapper
                error={usernameError || usernameConstantError}
                success={usernameSuccess}
              >
                <div>
                  <p onClick={handleVisitProfileClick}>getmyprofiles.in/</p>

                  <input
                    id="username"
                    type="text"
                    value={username}
                    placeholder="username"
                    onChange={handleUsernameChange}
                    spellCheck="false"
                    onBlur={() => setShowUsernameEditIcon(true)}
                    onFocus={() => setShowUsernameEditIcon(false)}
                  />

                  {showUsernameEditIcon && (
                    <span id="edit">
                      <i class="bi bi-pencil"></i>
                    </span>
                  )}
                  {!usernameError &&
                    !usernameProgress &&
                    !usernameConstantError && (
                      <span
                        onClick={handleUsernameOverflowIconClick}
                        id="share"
                      >
                        <i class="bi bi-sliders"></i>
                      </span>
                    )}

                  {usernameProgress &&
                    !usernameError &&
                    !usernameConstantError && (
                      <span id="loading">
                        <i class="fas fa-circle-notch"></i>
                      </span>
                    )}

                  {usernameError && (
                    <span id="error">
                      <i class="bi bi-exclamation-lg"></i>
                    </span>
                  )}

                  {!usernameError && usernameConstantError && (
                    <span id="error">
                      <i class="bi bi-exclamation-lg"></i>
                    </span>
                  )}
                </div>
              </UsernameWrapper>
              {isComponentVisible && (
                <AnimatePresence>
                  <UsernameOverflowContainer
                    variants={popOutVariants}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                    ref={ref}
                  >
                    <UsernameOverflowMenuItem onClick={handleCopyUsername}>
                      <span>
                        <i class="bi bi-files"></i>
                      </span>
                      <p>Copy Profile Link</p>
                    </UsernameOverflowMenuItem>
                    <UsernameOverflowMenuItem onClick={handleVisitProfileClick}>
                      <span>
                        <i class="bi bi-box-arrow-up-right"></i>
                      </span>
                      <p>Visit Profile Link</p>
                    </UsernameOverflowMenuItem>
                    <UsernameOverflowMenuItem onClick={handleCopyUsername}>
                      <span>
                        <i class="bi bi-share"></i>
                      </span>
                      <p>Share Profile Link</p>
                    </UsernameOverflowMenuItem>
                  </UsernameOverflowContainer>
                </AnimatePresence>
              )}
              {usernameSuccessMessage && (
                <SuccessLayout
                  margin="8px 0px"
                  successMessage={usernameSuccessMessage}
                />
              )}

              {usernameError && (
                <ErrorLayout margin="8px 0px" errorMessage={usernameError} />
              )}

              {usernameConstantError && !usernameError && (
                <ErrorLayout
                  margin="8px 0px"
                  errorMessage={usernameConstantError}
                />
              )}
            </UsernameLayout>
          )}
        </Section>
        <Section>
          <Header onClick={(e) => setShowThemesLayout(!showThemesLayout)}>
            <Heading>Themes</Heading>
            {showThemesLayout ? (
              <ExpandLessIcon onClick={(e) => setShowThemesLayout(false)} />
            ) : (
              <ExpandMoreIcon onClick={(e) => setShowThemesLayout(true)} />
            )}
          </Header>

          {showThemesLayout && (
            <>
              {isAllThemeFetched ? (
                <ThemeLayout>
                  <ThemeItem
                    active={currentThemeName === "Custom"}
                    themeValue={customTheme}
                    uploadUserTheme={uploadCustomTheme}
                  />

                  {allThemes.map((themeValue) => (
                    <ThemeItem
                      key={themeValue.id}
                      active={currentThemeName === themeValue.theme}
                      themeValue={themeValue}
                      uploadUserTheme={uploadUserTheme}
                    />
                  ))}
                </ThemeLayout>
              ) : (
                <LoadingLayout>
                  {" "}
                  <span id="loader">
                    <i class="fas fa-circle-notch"></i>
                  </span>
                  <p>Loading</p>
                </LoadingLayout>
              )}
            </>
          )}

          {themeError && (
            <ErrorLayout
              margin="8px 0px"
              padding="8px 8px"
              errorMessage={themeError}
            />
          )}
        </Section>

        <Section>
          <Header
            onClick={(e) => setShowBackgroundLayout(!showBackgroundLayout)}
          >
            <Heading>Background</Heading>
            {showBackgroundLayout ? (
              <ExpandLessIcon onClick={(e) => setShowBackgroundLayout(false)} />
            ) : (
              <ExpandMoreIcon onClick={(e) => setShowBackgroundLayout(true)} />
            )}
          </Header>

          {showBackgroundLayout && (
            <>
              {isCurrentThemeFetched ? (
                <BackgroundLayout>
                  <BackgroundColorItem
                    currentColor={backgroundColor}
                    currentSecondColor={backgroundSecondColor}
                    currentPattern={backgroundPattern}
                    image={backgroundImage}
                    handleColorChange={handleBackgroundColorChange}
                    handlePatternChange={handleBackgroundPatternChange}
                    handleImageChange={handleBackgroundImageChange}
                    handleDeleteImage={handleDeleteImage}
                    showBackgroundImageProgressBar={
                      showBackgroundImageProgressBar
                    }
                    backgroundImageProgress={backgroundImageProgress}
                  />

                  {backgroundError && (
                    <ErrorLayout
                      margin="16px 0px 8px 0px"
                      padding="8px 8px"
                      errorMessage={backgroundError}
                    />
                  )}
                </BackgroundLayout>
              ) : (
                <LoadingLayout>
                  {" "}
                  <span id="loader">
                    <i class="fas fa-circle-notch"></i>
                  </span>
                  <p>Loading</p>
                </LoadingLayout>
              )}
            </>
          )}
        </Section>

        <Section>
          <Header
            onClick={(e) =>
              setShowPersonalInformationLayout(!showPersonalInformationLayout)
            }
          >
            <Heading>Personal Information</Heading>
            {showPersonalInformationLayout ? (
              <ExpandLessIcon
                onClick={(e) => setShowPersonalInformationLayout(false)}
              />
            ) : (
              <ExpandMoreIcon
                onClick={(e) => setShowPersonalInformationLayout(true)}
              />
            )}
          </Header>

          {showPersonalInformationLayout && (
            <>
              {isCurrentThemeFetched ? (
                <PersonalInfoLayout>
                  <Title>Styles</Title>
                  <SubTitle>Profile Photo</SubTitle>
                  <ShapesLayout>
                    {shapes.map((shape) => (
                      <ShapeItem
                        active={shape.shape === profilePhotoShape}
                        key={shape.shape}
                        shape={shape}
                        handleClick={handleProfilePhotoShapeClick}
                      />
                    ))}
                  </ShapesLayout>
                  <Title>Font Colors</Title>
                  <FontColorsLayout>
                    <FontColorItem
                      color={nameColor}
                      title="Name"
                      handleChange={handleNameColorChange}
                    />
                    <FontColorItem
                      color={usernameColor}
                      title="Username"
                      handleChange={handleUsernameColorChange}
                    />
                    <FontColorItem
                      color={bioColor}
                      title="Bio"
                      handleChange={handleBioColorChange}
                    />
                  </FontColorsLayout>
                  <Title>Visibility</Title>
                  <VisibilityLayout>
                    <VisibilityItem
                      title="Photo"
                      isVisible={isProfilePhoto}
                      toggleVisibility={toggleIsProfilePhoto}
                    />
                    <VisibilityItem
                      title="Name"
                      isVisible={isName}
                      toggleVisibility={toggleIsName}
                    />
                    <VisibilityItem
                      title="Username"
                      isVisible={isUsername}
                      toggleVisibility={toggleIsUsername}
                    />
                    <VisibilityItem
                      title="Bio"
                      isVisible={isBio}
                      toggleVisibility={toggleIsBio}
                    />
                  </VisibilityLayout>

                  {personalError && (
                    <ErrorLayout
                      margin="16px 0px"
                      padding="8px 8px"
                      errorMessage={personalError}
                    />
                  )}
                </PersonalInfoLayout>
              ) : (
                <LoadingLayout>
                  {" "}
                  <span id="loader">
                    <i class="fas fa-circle-notch"></i>
                  </span>
                  <p>Loading</p>
                </LoadingLayout>
              )}
            </>
          )}
        </Section>

        <Section>
          <Header onClick={(e) => setShowProfilesLayout(!showProfilesLayout)}>
            <Heading>Profiles</Heading>
            {showProfilesLayout ? (
              <ExpandLessIcon onClick={(e) => setShowProfilesLayout(false)} />
            ) : (
              <ExpandMoreIcon onClick={(e) => setShowProfilesLayout(true)} />
            )}
          </Header>

          {showProfilesLayout && (
            <>
              {isCurrentThemeFetched ? (
                <ProfileLayout>
                  <Title>Styles</Title>
                  <SubTitle>Logo</SubTitle>
                  <ShapesLayout>
                    {shapes.map((shape) => (
                      <ShapeItem
                        active={shape.shape === profileLogoShape}
                        key={shape.shape}
                        shape={shape}
                        handleClick={handleProfileLogoShapeClick}
                      />
                    ))}
                  </ShapesLayout>
                  <Title>Font Colors</Title>
                  <FontColorsLayout>
                    <FontColorItem
                      color={profileLabelColor}
                      title="Label Color"
                      handleChange={handleProfileLabelColorChange}
                    />
                  </FontColorsLayout>
                  <Title>Visibility</Title>
                  <VisibilityLayout>
                    <VisibilityItem
                      title="Label"
                      isVisible={isProfileLabel}
                      toggleVisibility={toggleIsProfileLabel}
                    />
                  </VisibilityLayout>

                  {profileError && (
                    <ErrorLayout
                      margin="16px 0px"
                      padding="8px 8px"
                      errorMessage={profileError}
                    />
                  )}
                </ProfileLayout>
              ) : (
                <LoadingLayout>
                  {" "}
                  <span id="loader">
                    <i class="fas fa-circle-notch"></i>
                  </span>
                  <p>Loading</p>
                </LoadingLayout>
              )}
            </>
          )}
        </Section>

        <Section>
          <Header onClick={(e) => setShowLinkStyleLayout(!showLinkStyleLayout)}>
            <Heading>Links</Heading>
            {showLinkStyleLayout ? (
              <ExpandLessIcon onClick={(e) => setShowLinkStyleLayout(false)} />
            ) : (
              <ExpandMoreIcon onClick={(e) => setShowLinkStyleLayout(true)} />
            )}
          </Header>

          {showLinkStyleLayout && (
            <>
              {isCurrentThemeFetched ? (
                <LinkLayout>
                  <Title>Styles</Title>

                  <SubTitle>Style</SubTitle>
                  <LinkStylesLayout>
                    {linkStyles.map((linkStyle) => (
                      <LinkStyleItem
                        key={linkStyle.style}
                        active={linkStyle.style === currentLinkStyle.style}
                        linkStyle={linkStyle}
                        handleClick={handleLinkStyleClick}
                      />
                    ))}
                  </LinkStylesLayout>

                  <SubTitle>Image</SubTitle>
                  <ShapesLayout>
                    {shapes.map((shape) => (
                      <ShapeItem
                        active={shape.shape === linkImageShape}
                        key={shape.shape}
                        shape={shape}
                        handleClick={handleLinkImageShapeClick}
                      />
                    ))}
                  </ShapesLayout>
                  <Title>Font Colors</Title>
                  <FontColorsLayout>
                    <FontColorItem
                      color={linkTitleColor}
                      title="Title"
                      handleChange={handleLinkTitleColorChange}
                    />
                    <FontColorItem
                      color={linkURLColor}
                      title="URL"
                      handleChange={handleLinkURLColorChange}
                    />
                  </FontColorsLayout>

                  <Title>Background</Title>
                  <BackgroundColorsLayout>
                    {currentLinkStyle.type === "filled" && (
                      <FontColorItem
                        color={linkBackgroundColor}
                        title="Background Color"
                        handleChange={handleLinkBackgroundColorChange}
                      />
                    )}
                    <FontColorItem
                      color={linkBorderColor}
                      title="Border Color"
                      handleChange={handleLinkBorderColorChange}
                    />
                  </BackgroundColorsLayout>

                  <Title>Visibility</Title>
                  <VisibilityLayout>
                    <VisibilityItem
                      title="Image"
                      isVisible={isLinkImage}
                      toggleVisibility={toggleIsLinkImage}
                    />
                    <VisibilityItem
                      title="URL"
                      isVisible={isLinkURL}
                      toggleVisibility={toggleIsLinkURL}
                    />
                  </VisibilityLayout>

                  {linkStyleError && (
                    <ErrorLayout
                      margin="16px 0px"
                      padding="8px 8px"
                      errorMessage={linkStyleError}
                    />
                  )}
                </LinkLayout>
              ) : (
                <LoadingLayout>
                  {" "}
                  <span id="loader">
                    <i class="fas fa-circle-notch"></i>
                  </span>
                  <p>Loading</p>
                </LoadingLayout>
              )}
            </>
          )}
        </Section>

        <Section>
          <Header
            onClick={(e) => setShowImageStyleLayout(!showImageStyleLayout)}
          >
            <Heading>Images</Heading>
            {showImageStyleLayout ? (
              <ExpandLessIcon onClick={(e) => setShowImageStyleLayout(false)} />
            ) : (
              <ExpandMoreIcon onClick={(e) => setShowImageStyleLayout(true)} />
            )}
          </Header>

          {showImageStyleLayout && (
            <>
              {isCurrentThemeFetched ? (
                <LinkLayout>
                  <Title>Styles</Title>

                  <SubTitle>Style</SubTitle>
                  <LinkStylesLayout>
                    {linkStyles.map((linkStyle) => (
                      <LinkStyleItem
                        key={linkStyle.style}
                        active={linkStyle.style === currentImageStyle.style}
                        linkStyle={linkStyle}
                        handleClick={handleImageStyleClick}
                      />
                    ))}
                  </LinkStylesLayout>
                  <Title>Font Colors</Title>
                  <FontColorsLayout>
                    <FontColorItem
                      color={imageTitleColor}
                      title="Title"
                      handleChange={handleImageTitleColorChange}
                    />
                    <FontColorItem
                      color={imageDescriptionColor}
                      title="Description"
                      handleChange={handleImageDescriptionColorChange}
                    />
                    <FontColorItem
                      color={imageURLColor}
                      title="Visit Link"
                      handleChange={handleImageURLColorChange}
                    />
                  </FontColorsLayout>

                  <Title>Background</Title>
                  <BackgroundColorsLayout>
                    {currentImageStyle.type === "filled" && (
                      <FontColorItem
                        color={imageBackgroundColor}
                        title="Background Color"
                        handleChange={handleImageBackgroundColorChange}
                      />
                    )}
                    <FontColorItem
                      color={imageBorderColor}
                      title="Border Color"
                      handleChange={handleImageBorderColorChange}
                    />
                  </BackgroundColorsLayout>

                  <Title>Visibility</Title>
                  <VisibilityLayout>
                    <VisibilityItem
                      title="Title"
                      isVisible={isImageTitle}
                      toggleVisibility={toggleIsImageTitle}
                    />
                    <VisibilityItem
                      title="Description"
                      isVisible={isImageDescription}
                      toggleVisibility={toggleIsImageDescription}
                    />
                    <VisibilityItem
                      title="URL"
                      isVisible={isImageURL}
                      toggleVisibility={toggleIsImageURL}
                    />
                  </VisibilityLayout>

                  {imageStyleError && (
                    <ErrorLayout
                      margin="16px 0px"
                      padding="8px 8px"
                      errorMessage={imageStyleError}
                    />
                  )}
                </LinkLayout>
              ) : (
                <LoadingLayout>
                  {" "}
                  <span id="loader">
                    <i class="fas fa-circle-notch"></i>
                  </span>
                  <p>Loading</p>
                </LoadingLayout>
              )}
            </>
          )}
        </Section>

        <Section>
          <Header
            onClick={(e) => setShowVideoStyleLayout(!showVideoStyleLayout)}
          >
            <Heading>Videos</Heading>
            {showVideoStyleLayout ? (
              <ExpandLessIcon onClick={(e) => setShowVideoStyleLayout(false)} />
            ) : (
              <ExpandMoreIcon onClick={(e) => setShowVideoStyleLayout(true)} />
            )}
          </Header>

          {showVideoStyleLayout && (
            <>
              {isCurrentThemeFetched ? (
                <LinkLayout>
                  <Title>Styles</Title>

                  <SubTitle>Style</SubTitle>
                  <LinkStylesLayout>
                    {linkStyles.map((linkStyle) => (
                      <LinkStyleItem
                        key={linkStyle.style}
                        active={linkStyle.style === currentVideoStyle.style}
                        linkStyle={linkStyle}
                        handleClick={handleVideoStyleClick}
                      />
                    ))}
                  </LinkStylesLayout>
                  <Title>Font Colors</Title>
                  <FontColorsLayout>
                    <FontColorItem
                      color={videoTitleColor}
                      title="Title"
                      handleChange={handleVideoTitleColorChange}
                    />
                    <FontColorItem
                      color={videoURLColor}
                      title="URL"
                      handleChange={handleVideoURLColorChange}
                    />
                  </FontColorsLayout>

                  <Title>Background</Title>
                  <BackgroundColorsLayout>
                    {currentVideoStyle.type === "filled" && (
                      <FontColorItem
                        color={VideoBackgroundColor}
                        title="Background Color"
                        handleChange={handleVideoBackgroundColorChange}
                      />
                    )}
                    <FontColorItem
                      color={VideoBorderColor}
                      title="Border Color"
                      handleChange={handleVideoBorderColorChange}
                    />
                  </BackgroundColorsLayout>

                  <Title>Visibility</Title>
                  <VisibilityLayout>
                    <VisibilityItem
                      title="Title"
                      isVisible={isVideoTitle}
                      toggleVisibility={toggleIsVideoTitle}
                    />
                    <VisibilityItem
                      title="URL"
                      isVisible={isVideoURL}
                      toggleVisibility={toggleIsVideoURL}
                    />
                  </VisibilityLayout>

                  {videoStyleError && (
                    <ErrorLayout
                      margin="16px 0px"
                      padding="8px 8px"
                      errorMessage={videoStyleError}
                    />
                  )}
                </LinkLayout>
              ) : (
                <LoadingLayout>
                  {" "}
                  <span id="loader">
                    <i class="fas fa-circle-notch"></i>
                  </span>
                  <p>Loading</p>
                </LoadingLayout>
              )}
            </>
          )}
        </Section>

        <Section>
          <Header onClick={(e) => setShowFontLayout(!showFontLayout)}>
            <Heading>Fonts</Heading>
            {showFontLayout ? (
              <ExpandLessIcon onClick={(e) => setShowFontLayout(false)} />
            ) : (
              <ExpandMoreIcon onClick={(e) => setShowFontLayout(true)} />
            )}
          </Header>

          {showFontLayout && (
            <>
              {isCurrentThemeFetched ? (
                <FontLayout>
                  {fontStyles.map((fontStyle) => (
                    <FontItem
                      key={fontStyle.fontName}
                      active={fontStyle.fontName === currentFont.fontName}
                      fontStyle={fontStyle}
                      handleClick={handleFontClick}
                    />
                  ))}
                </FontLayout>
              ) : (
                <LoadingLayout>
                  <span id="loader">
                    <i class="fas fa-circle-notch"></i>
                  </span>
                  <p>Loading</p>
                </LoadingLayout>
              )}
            </>
          )}
          {fontError && (
            <ErrorLayout
              margin="16px 0px"
              padding="8px 8px"
              errorMessage={fontError}
            />
          )}
        </Section>
      </Content>
    </Container>
  );
}

export default Appearance;
