import React, { useState, useEffect } from "react";
import Button from "../button/Button";
import firebase from "firebase";
import { ErrorLayout, SuccessLayout } from "../index";
import validator from "validator";

import {
  Container,
  Section,
  ForgotPasswordSvg,
  SvgWrapper,
} from "./ForgotPasswordElements";

import {
  Heading,
  SubHeading,
  Input,
  Label,
  ActionBackLayout,
  CounterLayout,
} from "../../themes/globalStyledComponents";
import { fadeInVariants } from "../../animations/animation";

function ForgotPassword({
  setShowForgotPasswordLayout,
  setShowVerificationLayout,
}) {
  const [counter, setCounter] = useState(0);

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [progress, setProgress] = useState(false);

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    if (counter === 0 && email !== "" && error === "" && emailError === "") {
      setDisabled(false);
    }
  }, [counter]);

  const handleEmailChange = (e) => {
    e.preventDefault();

    setEmailError("");
    setError("");
    e.currentTarget.value = e.currentTarget.value.replace(/\s/g, "");
    setEmail(e.currentTarget.value);

    if (validator.isEmail(e.currentTarget.value)) {
      setEmailError("");
      if (counter === 0) {
        setDisabled(false);
      }
    } else {
      setEmailError("Invalid email address");
      setDisabled(true);
    }
  };

  const handleResetButtonClick = (e) => {
    e.preventDefault();

    if (disabled) {
      return;
    }

    if (email === "") {
      setEmailError("Email is required.");
      return;
    }

    if (!validator.isEmail(email)) {
      setEmailError("Invalid email address");

      return;
    }

    setEmailError("");
    setError("");
    setSuccessMessage("");
    setProgress(true);
    setDisabled(true);
    setCounter(60);

    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        setProgress(false);
        setSuccessMessage(`Password reset link sent successfully to ${email}`);
      })
      .catch((error) => {
        setError(error.message);
        setProgress(false);
        setDisabled(false);
      });
  };

  const onActionBackLayoutClick = (e) => {
    setShowForgotPasswordLayout(false);
    setShowVerificationLayout(false);
  };

  return (
    <Container initial="initial" animate="animate" variants={fadeInVariants}>
      <Heading>Forgot Password🤯</Heading>

      <SubHeading>Nothing to worry. We had got your back!!</SubHeading>
      <SvgWrapper>
        <ForgotPasswordSvg />
      </SvgWrapper>

      <Section>
        <Label for="Email">Email</Label>
        <Input
          id="Email"
          name="Email"
          placeholder="Email address"
          onChange={handleEmailChange}
        />
        {emailError && (
          <ErrorLayout margin="4px 0px 0px 0px" errorMessage={emailError} />
        )}

        {error && (
          <ErrorLayout
            margin="16px 0px 0px 0px"
            padding="16px 8px"
            errorMessage={error}
          />
        )}

        {successMessage && (
          <SuccessLayout
            margin="16px 0px 0px 0px"
            padding="16px 8px"
            successMessage={successMessage}
          />
        )}

        <Button
          margin="16px 0px 0px 0px"
          title="Send Password Reset Link"
          progressTitle="Sending Password Reset Link..."
          progress={progress}
          disabled={disabled}
          handleClick={handleResetButtonClick}
        />

        {counter !== 0 && (
          <CounterLayout margin="16px 0px">
            Didn`t recieved reset link ? Resend reset link in {counter} seconds.
          </CounterLayout>
        )}

        <ActionBackLayout
          margin="24px 16px 16px 16px "
          onClick={onActionBackLayoutClick}
        >
          <span>
            <i class="fas fa-long-arrow-alt-left" />
          </span>
          Go back to login page
        </ActionBackLayout>
      </Section>
    </Container>
  );
}

export default ForgotPassword;
