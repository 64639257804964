import styled from "styled-components";

import { motion } from "framer-motion";

export const Container = styled(motion.div)`
  margin: 16px;
  cursor: pointer;
  border-radius: 4px;
  box-shadow: 0px 2px 8px rgb(97 8 43 / 20%);
`;

export const Content = styled(motion.div)`
  display: block;

  .imageContainer {
    height: 200px;
    width: 322px;
    & > img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 4px 4px 0px 0px;
      background-color: #ededed;
      padding: 0px;
      margin: 0px;
    }

    @media (min-width: 768px) {
      width: 380px;
    }
  }
`;

export const CommunityUserInfoContainer = styled(motion.div)`
  display: flex;
  align-items: center;
  padding: 16px 8px;
  border-radius: 0px 0px 4px 4px;
  color: #ffffff;
  background-color: #61082b;

  .profilePhoto {
    height: 50px;
    width: 50px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 12px;
  }

  div {
    p {
      font-size: 18px;
      font-weight: 600;
    }
    span {
      font-size: 16px;
      font-weight: 550;
    }
  }
`;
