import React from "react";
import { animationvariants } from "../../animations/animation";
import { openURL } from "../../utils/functions";
import {
  Container,
  Description,
  ImageVisitURL,
  Title,
} from "./MobileViewImageItem.elements";

function MobileViewImageItem({ image }) {
  const handleImageClick = () => {
    if (image.visitURL === "") {
      return;
    }
    openURL(image.visitURL);
  };

  return (
    <Container
      variants={animationvariants}
      animate={image.animation}
      onClick={handleImageClick}
    >
      <img src={image.image} alt="image" />
      <Title>{image.title}</Title>
      <Description>{image.description}</Description>
      <ImageVisitURL>{image.visitURL}</ImageVisitURL>
    </Container>
  );
}

export default MobileViewImageItem;
