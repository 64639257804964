import React, { useState, useContext } from "react";
import { Button, ErrorLayout } from "..";
import validator from "validator";
import {
  Container,
  Content,
  ImageLayout,
  ImagEmptyWrapper,
  Input,
  MessageLayout,
} from "./AddImageItem.elements";
import { AuthContext } from "../../contexts/AuthContext";
import { db, storage } from "../../firebase/firebase";
import firebase from "firebase";
import { dropDownVariants } from "../../animations/animation";

function AddImageItem({
  images,
  setShowAddImageItem,
  scrollToImageView,
  setShowUpgradeToProLayout,
  setUpgradeType,
}) {
  const { user } = useContext(AuthContext);

  const [image, setImage] = useState("");
  const [imageUploadProgress, setImageUploadProgress] = useState(false);
  const [imageUploadProgressValue, setImageUploadProgressValue] = useState(0);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const [visitURL, setVisitURL] = useState("");
  const [visitURLError, setVisitURLError] = useState("");

  const [showMessageLayout, setShowMessageLayout] = useState(false);

  const [error, setError] = useState("");
  const [progress, setProgress] = useState(false);

  const handleVisitURLChange = (e) => {
    setVisitURLError("");
    setVisitURL(e.target.value);
  };

  const handleOnImageChange = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
      setError("");
      var selectedImageSrc = URL.createObjectURL(e.target.files[0]);
      var imagePreview = document.getElementById("image-preview");
      imagePreview.src = selectedImageSrc;
      imagePreview.style.display = "block";
    }
  };

  const handleNewImageButtonCLick = () => {
    if (visitURLError !== "") {
      return;
    }

    if (!image) {
      setError("Please select image to upload");
      return;
    }

    if (visitURL !== "" && !validator.isURL(visitURL)) {
      setVisitURLError("Invalid url entered");
      return;
    }

    if (images.length >= 1 && !user.isPro) {
      setShowUpgradeToProLayout(true);
      setUpgradeType("images");
      return;
    }

    setError("");
    setProgress(true);
    setImageUploadProgress(true);
    setImageUploadProgressValue(0);

    const documentIdRef = db
      .collection("users")
      .doc(user.userId)
      .collection("images")
      .doc();

    const documentId = documentIdRef.id;

    const uploadTask = storage
      .ref(`users/${user.userId}/images/${documentId}.jpg`)
      .put(image);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setImageUploadProgressValue(progress);
      },
      (error) => {
        setError(error.message);
        setProgress(false);
        setImageUploadProgress(false);
        setImageUploadProgressValue(0);
      },
      () => {
        storage
          .ref(`users/${user.userId}/images/${documentId}.jpg`)
          .getDownloadURL()
          .then((imageUrl) => {
            db.collection("users")
              .doc(user.userId)
              .collection("images")
              .doc(documentId)
              .set({
                imageId: documentId,
                title: title,
                description: description,
                visitURL: visitURL,
                image: imageUrl,
                order: images.length ? images[0].order - 0.095 : 0,
                isActive: true,
                userId: user.userId,
                animation: "",
                timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                type: "image",
              })
              .then(() => {
                setError("");
                setShowAddImageItem(false);
                setProgress(false);
                setImage("");
                setImageUploadProgressValue(0);
                setImageUploadProgress(false);
                scrollToImageView();
              })
              .catch((error) => {
                setError(error.message);
                setProgress(false);
                setImageUploadProgress(false);
                setImageUploadProgressValue(0);
              });
          });
      }
    );
  };

  return (
    <Container
      variants={dropDownVariants}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <Content>
        <ImageLayout>
          <label htmlFor="image">
            {!image && (
              <ImagEmptyWrapper>
                <span>
                  <i class="bi bi-camera"></i>
                </span>
                <p>Select Image</p>
              </ImagEmptyWrapper>
            )}
            <img id="image-preview" alt="pre-view-image" />
          </label>

          {imageUploadProgress && (
            <span id="loader">
              <i class="fas fa-spinner"></i>
            </span>
          )}

          {imageUploadProgress && (
            <span id="progressText">{imageUploadProgressValue}</span>
          )}
          <input
            id="image"
            type="file"
            style={{ display: "none" }}
            accept="image/*"
            onChange={handleOnImageChange}
          />
        </ImageLayout>

        <label htmlFor="title">Title</label>
        <Input
          margin="0px 0px 16px 0px"
          id="title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Image title here"
        />

        <label htmlFor="description">Description</label>
        <Input
          margin="0px 0px 16px 0px"
          id="description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Image description here"
        />

        <label htmlFor="url">Visit URL</label>
        <Input
          id="url"
          value={visitURL}
          onChange={handleVisitURLChange}
          placeholder="Paste your url here"
        />
        {visitURLError && (
          <ErrorLayout margin="8px  0px" errorMessage={visitURLError} />
        )}
        <MessageLayout>
          <span
            onClick={() => setShowMessageLayout(!showMessageLayout)}
            id="icon"
          >
            <i class="bi bi-info-circle"></i>
          </span>
          {showMessageLayout && (
            <span id="message">
              {" "}
              Paste a url where you want to send the visitor when they click on
              this image.
            </span>
          )}
        </MessageLayout>

        {error && <ErrorLayout margin="8px 0px" errorMessage={error} />}
        <Button
          variant={"dashboardAdd"}
          margin="16px 0px 8px 0px "
          title={"Add New Image"}
          handleClick={handleNewImageButtonCLick}
          progress={progress}
        />
      </Content>
    </Container>
  );
}

export default AddImageItem;
