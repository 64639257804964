import React, { useState, useEffect } from "react";
import validator from "validator";
import firebase from "firebase";

import {
  Heading,
  SubHeading,
  Label,
  Input,
  TextArea,
} from "../../themes/globalStyledComponents";

import {
  Button,
  ErrorLayout,
  SuccessLayout,
  Navbar,
  Footer,
} from "../../components/index";

import {
  ConnectCard,
  ConnectContainer,
  Container,
  Content,
  LeftSide,
  Section,
  SecondConnectContainer,
  SocialContainer,
  SocialMedia,
} from "./Contactus.elements";
import { db, auth } from "../../firebase/firebase";
import {
  fadeInVariants,
  whileHover,
  whileTap,
} from "../../animations/animation";
import { openURL } from "../../utils/functions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function Contactus() {
  const history = useHistory();
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, []);

  const [name, setName] = useState("");

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const [message, setMessage] = useState("");
  const [messageError, setMessageError] = useState("");

  const [submitProgress, setSubmitProgress] = useState(false);
  const [submitError, setSubmitError] = useState("");
  const [submitSuccess, setSubmitSuccess] = useState("");

  const onSubmitButtonClick = (e) => {
    setEmailError("");
    setMessageError("");

    if (email === "") {
      setEmailError("Email is required");

      return;
    }

    if (!validator.isEmail(email)) {
      setEmailError("Enter valid email address");
      return;
    }

    if (message === "") {
      setMessageError("Message is required");
      return;
    }

    setEmailError("");
    setMessageError("");
    setSubmitError("");
    setSubmitProgress(true);
    setSubmitSuccess("");

    db.collection("support")
      .add({
        name: name,
        email: email,
        message: message,
        userId:
          auth.currentUser !== null ? auth.currentUser.uid : "Not Signed In",
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        isResolved: false,
      })
      .then(() => {
        setSubmitError("");
        setSubmitSuccess(
          "Your resoponse has been recorded. Sit back and relax. we will get back to you as soon as possible."
        );
        setSubmitProgress(false);
        setName("");
        setEmail("");
        setMessage("");
      })
      .catch((error) => {
        setSubmitError(error.message);
        setSubmitProgress(false);
      });
  };

  useEffect(() => {
    document.title = "Contact Us" + " | " + process.env.REACT_APP_TITLE;
    checkIfUserIsAuthenticated();
  }, []);

  const checkIfUserIsAuthenticated = () => {
    if (auth.currentUser !== null) {
      console.log("User Signed In");
    } else {
      console.log("User Not Signed In");
    }
  };

  return (
    <Container initial="initial" animate="animate" variants={fadeInVariants}>
      <Navbar navLocation="/contact" />
      <Content>
        <LeftSide>
          <Section>
            <Heading>Get In Touch</Heading>
            <SubHeading>Send us a message</SubHeading>

            <Label margin="24px 0px 0px 0px" for="name">
              Name
            </Label>
            <Input
              value={name}
              onChange={(e) => setName(e.target.value)}
              type="text"
              id="name"
              name="name"
              placeholder="Name"
            />
            <Label margin="16px 0px 0px 0px" for="email">
              Email
            </Label>
            <Input
              value={email}
              id="email"
              name="email"
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              placeholder="Email address"
            />
            {emailError && (
              <ErrorLayout margin="4px 0px 0px 0px" errorMessage={emailError} />
            )}
            <Label margin="16px 0px 0px 0px" for="email">
              Message
            </Label>

            <TextArea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Type your message"
              spellCheck="false"
            />
            {messageError && (
              <ErrorLayout
                margin="4px 0px 0px 0px"
                errorMessage={messageError}
              />
            )}

            <Button
              type="button"
              margin="24px 0px 0px 0px"
              title="Submit"
              progressTitle="Sending Message"
              progress={submitProgress}
              handleClick={onSubmitButtonClick}
            />

            {submitError && (
              <ErrorLayout margin="16px 0px " errorMessage={submitError} />
            )}

            {submitSuccess && (
              <SuccessLayout margin="24px 0px" successMessage={submitSuccess} />
            )}
          </Section>

          <ConnectContainer>
            <p>Connect us at</p>
            <SocialContainer>
              <SocialMedia whileHover={whileHover} whileTap={whileTap}>
                <img
                  alt="Facebook"
                  src="https://firebasestorage.googleapis.com/v0/b/getmyprofiles-fb7d6.appspot.com/o/data%2FprofileLogos%2Ffacebook.jpg?alt=media&token=25f1149c-da53-4e35-9874-a9c0db94979e"
                />

                <span>Facebook</span>
              </SocialMedia>
              <SocialMedia
                whileHover={whileHover}
                whileTap={whileTap}
                onClick={() =>
                  openURL("https://instagram.com/getmyprofiles.in")
                }
              >
                <img
                  alt="Instagram"
                  src={
                    "https://firebasestorage.googleapis.com/v0/b/getmyprofiles-fb7d6.appspot.com/o/data%2FprofileLogos%2Finstagram.jpg?alt=media&token=971b0e02-3452-4293-8e42-d7f2ce1e6f59"
                  }
                />
                <span>Instagram</span>
              </SocialMedia>
              <SocialMedia
                whileHover={whileHover}
                whileTap={whileTap}
                onClick={() => openURL("https://twitter.com/getmyprofiles")}
              >
                <img
                  alt="Twitter"
                  src={
                    "https://firebasestorage.googleapis.com/v0/b/getmyprofiles-fb7d6.appspot.com/o/data%2FprofileLogos%2Ftwitter.jpg?alt=media&token=f8d92536-c6b9-48d3-b1f8-ca442720bebf"
                  }
                />
                <span>Twitter</span>
              </SocialMedia>
              <SocialMedia whileHover={whileHover} whileTap={whileTap}>
                <img
                  alt="Linked In"
                  src={
                    "https://firebasestorage.googleapis.com/v0/b/getmyprofiles-test.appspot.com/o/data%2FprofileImages%2Flinkedin%20.png?alt=media&token=ead7cdae-b4b0-4a66-861d-454705483194"
                  }
                />
                <span>Linked In</span>
              </SocialMedia>
            </SocialContainer>
          </ConnectContainer>

          <SecondConnectContainer>
            <ConnectCard whileTap={whileTap} whileHover={whileHover}>
              <span id="location">
                <i class="bi bi-geo"></i>
              </span>
              <p>
                <span id="special"> Kundapur</span>
                <br></br>Karnataka , India.
              </p>
            </ConnectCard>
            <ConnectCard
              onClick={() => openURL("mailto:getmyprofiles@gmail.com")}
              whileTap={whileTap}
              whileHover={whileHover}
            >
              <span id="email">
                <i class="bi bi-envelope"></i>
              </span>
              <p>support@getmyprofiles.in</p>
            </ConnectCard>
          </SecondConnectContainer>
        </LeftSide>
      </Content>
    <Footer/>
    
    </Container>
  );
}

export default Contactus;
