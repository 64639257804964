import styled from "styled-components";
import { motion } from "framer-motion";

export const Container = styled(motion.div)`
  background-color: white;
  margin: 0px 0px 16px 0px;
  border: ${({ theme }) => theme.border};
  border-radius: 4px;
  padding: 8px;
`;

export const Content = styled(motion.div)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Header = styled(motion.div)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  & > p {
    font-size: 18px;
    font-weight: 550;
  }

  & > span {
    font-size: 14px;
    color: #333333;
  }
`;

export const Body = styled(motion.div)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 8px;

  & > div {
    display: flex;
    flex-direction: column;

    & > span {
      font-size: 14px;
      margin-top: 2px;
    }
  }

  & > p {
    font-size: 18px;
    font-weight: 600;
    color: ${({ theme, status }) =>
      (status === "Success" && theme.colors.success) || theme.colors.failure};

    .fas {
      margin-right: 2px;
      font-size: 16px;
    }
  }
`;

export const Footer = styled(motion.div)`
  display: flex;
  justify-content: flex-end;
  margin-top: 4px;

  & > span {
    font-size: 14px;
    color: ${({ theme, status }) =>
      (status === "Success" && theme.colors.success) || theme.colors.failure};
  }
`;
