import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

function AddProfileItemLists({ profile, handleClick }) {
  return (
    <Container>
      <Content onClick={(e) => handleClick(profile)}>
        <img src={profile.image} alt=" " />
        <p>{profile.name}</p>
      </Content>
    </Container>
  );
}

const Container = styled(motion.div)``;

const Content = styled(motion.div)`
  display: flex;
  padding: 8px;
  align-items: center;
  cursor: pointer;

  img {
    height: 30px;
    width: 30px;
    border-radius: 4px;
    background-color: #ededed;
  }

  p {
    margin-left: 8px;
  }

  &:hover {
    background-color: lightgray;
  }
`;

export default AddProfileItemLists;
