import React from "react";

import {
  ActionLayoutContainer,
  ActionLayoutHeader,
  HorizontalBreak,
} from "../../themes/globalStyledComponents";
import { ItemWrapper } from "./UploadPhotoItem.elements";

function UploadPhotoItem({
  handleCancelClick,
  handleDeleteLinkImage,
  linkId,
  handleLinkImageChange,
  type,
}) {
  return (
    <ActionLayoutContainer>
      <ActionLayoutHeader>
        {type === "Image" ? <h6>Change Image</h6> : <h6>Link Image</h6>}
        <span onClick={handleCancelClick}>
          <i class="bi bi-x-lg"></i>
        </span>
      </ActionLayoutHeader>

      <ItemWrapper>
        <label for={linkId}>Change Image</label>
        <input
          id={linkId}
          type="file"
          style={{ display: "none" }}
          accept="image/*"
          onChange={handleLinkImageChange}
        />
      </ItemWrapper>
      {type !== "Image" && <HorizontalBreak />}
      {type !== "Image" && (
        <ItemWrapper type={"delete"} onClick={handleDeleteLinkImage}>
          <label>Delete Image</label>
        </ItemWrapper>
      )}
    </ActionLayoutContainer>
  );
}

export default UploadPhotoItem;
