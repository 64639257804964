import styled from "styled-components";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

export const Container = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #262626 ;
  color: white;
`;

export const Content = styled(motion.div)`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 24px 0px;

  @media (min-width: 768px) {
    margin: 24px 56px;
  }
`;

export const Section = styled(motion.div)`
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;

  & > a {
    margin: 0px 8px;
    text-decoration: underline;
    color: #000000;
    cursor: pointer;
  }
`;

export const FooterListContaier = styled(motion.div)`
  /* background-color: red; */
  min-width: 125px;
  margin: 16px;
`;

export const FooterListHeading = styled(motion.h4)``;

export const FooterList = styled(motion.ul)`
  /* background-color: rebeccapurple; */
  margin-top: 8px;
`;

export const FooterListItem = styled(motion.li)`
  list-style-type: none;
  padding: 8px 8px 8px 0px;
  /* background-color: blue; */

  @media screen and (max-width: 768px) {
    width: 135px;
  }
`;

export const FooterListItemLink = styled(Link)`
  text-decoration: none;
  color: #ffffff;
  word-wrap: break-word;
`;
