import React from "react";
import { moveRightVariants } from "../../animations/animation";
import { HorizontalBreak } from "../../themes/globalStyledComponents";

import {
  Body,
  Container,
  Content,
  Footer,
  Header,
} from "./BillingItem.elements";

function BillingItem({ billing }) {
  return (
    <Container
      variants={moveRightVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <Content>
        <Header>
          <p>{billing.plan}</p>
          <span>#{billing.billingId}</span>
        </Header>
        <Body status={billing.status}>
          <div>
            <span>
              DOP: {billing.dateOfProPurchase.toDate().toDateString()}
            </span>
            {billing.dateOfProExpire !== "-" ? (
              <span>
                DOE: {billing.dateOfProExpire.toDate().toDateString()}
              </span>
            ) : (
              <span>DOE: {billing.dateOfProExpire}</span>
            )}
          </div>
          <p>
            <i id="rupeeSymbol" class="fas fa-rupee-sign" />
            {billing.amount}
          </p>
        </Body>

        <HorizontalBreak />
        <Footer status={billing.status}>
          <span>{billing.status}</span>
        </Footer>
      </Content>
    </Container>
  );
}

export default BillingItem;
