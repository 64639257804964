import styled, { keyframes } from "styled-components";
import { motion } from "framer-motion";

export const Container = styled(motion.div)``;

export const Content = styled(motion.div)`
  display: block;
  padding: 16px;
  color: #172126;
  background: #f1f5f8;

  label {
    display: inline-block;
    font-size: 14px;
  }
`;

export const Input = styled.input`
  margin: 4px 0px 16px 0px;
  height: 50px;
  width: 100%;
  padding-left: 4px;
  outline: none;
  border: none;
  font-size: 16px;
  border-radius: 4px;
  border: ${({ theme }) => theme.border};
`;

export const MessageLayout = styled.p`
  margin-top: -8px;
  font-size: 14px;
  #icon {
    color: #e91e63;
    font-size: 16px;
  }

  #message {
    margin-left: 2px;
    color: #676b6f;
  }
`;
