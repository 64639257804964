import React, { useState, useEffect } from "react";

import {
  Button,
  ErrorLayout,
  GoogleButton,
  NavbarType1,
  ForgotPassword,
  VerifyEmail,
  Footer,
} from "../../components/index";

import { auth, db, provider } from "../../firebase/firebase";
import validator from "validator";

import firebase from "firebase";
import { useHistory } from "react-router";

import { Container, Content, LeftSide } from "./Login.elements";

import {
  Heading,
  SubHeading,
  Label,
  Input,
  AccountActionLayout,
  LoginWithGoogle,
} from "../../themes/globalStyledComponents";
import { fadeInVariants } from "../../animations/animation";
import { viewProfileTheme } from "../../themes/theme";
import makeid from "../../utils/functions";

function Login() {
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, []);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [error, setError] = useState("");

  const [progress, setProgress] = useState(false);
  const [googleProgress, setGoogleProgress] = useState(false);
  const [googleError, setGoogleError] = useState("");

  const [showVerificationLayout, setShowVerificationLayout] = useState(false);
  const [showForgotPasswordLayout, setShowForgotPasswordLayout] =
    useState(false);
  const [user, setUser] = useState(null);

  const handleNavbarButton = () => {
    history.push("/signup");
  };

  const handleEmailChange = (e) => {
    e.preventDefault();

    setError("");

    e.currentTarget.value = e.currentTarget.value.replace(/\s/g, "");
    setEmail(e.currentTarget.value);

    if (e.currentTarget.value === "") {
      setEmailError("Email is required");

      return;
    }

    if (!validator.isEmail(e.currentTarget.value)) {
      setEmailError("Invalid email address");
      return;
    }

    setEmailError("");
  };

  const handlePasswordChange = (e) => {
    e.preventDefault();
    setError("");
    e.currentTarget.value = e.currentTarget.value.replace(/\s/g, "");
    setPassword(e.currentTarget.value);

    if (e.currentTarget.value === "") {
      setPasswordError("Password is required");
      return;
    }
    setPasswordError("");
  };

  const handleLoginButton = (e) => {
    e.preventDefault();
    setEmailError("");
    setPasswordError("");
    setError("");
    setProgress(true);
    if (email === "") {
      setEmailError("Email is required");
      setProgress(false);
      return;
    }

    if (!validator.isEmail(email)) {
      setEmailError("Invalid email address");
      setProgress(false);
      return;
    }

    if (password === "") {
      setPasswordError("Password is required");
      setProgress(false);
      return;
    }
    setEmailError("");
    setPasswordError("");

    handleLogin();
  };

  const handleLogin = () => {
    auth
      .signInWithEmailAndPassword(email, password)
      .then((userCredential) => {
        const signedInUser = userCredential.user;
        setUser(signedInUser);

        if (!signedInUser.emailVerified) {
          signedInUser.sendEmailVerification();
          firebase.auth().signOut();
          setShowVerificationLayout(true);
          setProgress(false);
        } else {
          setProgress(false);
          history.replace("/dashboard");
          history.go(0);
        }
      })
      .catch((error) => {
        setProgress(false);

        switch (error.code) {
          case "auth/invalid-email":
            setEmailError(error.message);
            break;
          case "auth/wrong-password":
            setPasswordError(error.message);
            break;
          default:
            setError(error.message);
        }
      });
  };

  const handleGoogleSignIn = (e) => {
    e.preventDefault();
    setError("");
    setGoogleError("");
    setGoogleProgress(true);

    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        var user = result.user;
        handleGoogleSignedInUser(user);
      })
      .catch((error) => {
        setGoogleError(error.message);
        setGoogleProgress(false);
      });
  };

  const handleGoogleSignedInUser = (user) => {
    db.collection("users")
      .doc(user.uid)
      .get()
      .then((doc) => {
        if (doc.exists) {
          history.replace("/dashboard");
          setGoogleError("");
          setGoogleProgress(false);
        } else {
          createGoogleUsername(user.email.replace("@gmail.com", ""), user);
        }
      })
      .catch((error) => {
        setGoogleError(error.message);
        setGoogleProgress(false);
      });
  };

  const createGoogleUsername = (Username, user) => {
    db.collection("users")
      .where("username", "==", Username)
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.empty) {
          var expiryDateValue = new Date();
          expiryDateValue.setDate(expiryDateValue.getDate() + 16);

          db.collection("users")
            .doc(user.uid)
            .set({
              name: user.displayName,
              username: Username,
              email: user.email,
              profilePhoto: user.photoURL,
              bio: "",
              isPro: true,
              dateOfProPurchase:
                firebase.firestore.FieldValue.serverTimestamp(),
              dateOfProExpire: expiryDateValue,
              deepLink: {
                id: "",
                type: "",
                link: "",
              },
              userId: user.uid,
              accountCreatedOn: firebase.firestore.FieldValue.serverTimestamp(),
            })
            .then(() => {
              db.collection("users")
                .doc(user.uid)
                .collection("themes")
                .doc("theme")
                .set(viewProfileTheme)
                .then(() => {
                  saveBillingDetailsToDatabase(user.uid);
                })
                .catch((error) => {
                  setGoogleError(error.message);
                  setGoogleProgress(false);
                });
            })
            .catch((error) => {
              setGoogleError(error.message);
              setGoogleProgress(false);
            });
        } else {
          createGoogleUsername(Username + "_", user);
        }
      })
      .catch((error) => {
        setGoogleError(error.message);
        setGoogleProgress(false);
      });
  };

  const saveBillingDetailsToDatabase = (userId) => {
    const BillingId = makeid();

    var expiryDateValue = new Date();
    expiryDateValue.setDate(expiryDateValue.getDate() + 16);

    db.collection("users")
      .doc(userId)
      .collection("billings")
      .doc(BillingId)
      .set({
        billingId: BillingId,
        amount: 1499,
        plan: "Free Trial",
        status: "Success",
        userId: userId,
        paymentId: "free_trial_001",
        orderId: "",
        dateOfProPurchase: firebase.firestore.FieldValue.serverTimestamp(),
        dateOfProExpire: expiryDateValue,
      })
      .then(() => {
        setGoogleProgress(false);
        setGoogleError("");
        history.push("/dashboard");
        history.go(0);
      })
      .catch((error) => {
        setGoogleError(error.message);
        setGoogleProgress(false);
      });
  };

  const handleForgotPasswordClick = (e) => {
    e.preventDefault();
    setShowForgotPasswordLayout(true);
    setShowVerificationLayout(false);
  };

  return (
    <Container initial="initial" animate="animate" variants={fadeInVariants}>
      <NavbarType1 title="Get Started" handleButtonClick={handleNavbarButton} />

      <Content>
        {showVerificationLayout && !showForgotPasswordLayout && (
          <VerifyEmail
            heading="Email Not Verified☹️"
            subHeading="Verify Email to continue."
            name={user.name}
            email={user.email}
            user={user}
            from="login"
            setShowVerificationLayout={setShowVerificationLayout}
          />
        )}

        {showForgotPasswordLayout && !showVerificationLayout && (
          <ForgotPassword
            setShowForgotPasswordLayout={setShowForgotPasswordLayout}
            setShowVerificationLayout={setShowVerificationLayout}
          />
        )}

        {!showVerificationLayout && !showForgotPasswordLayout && (
          <LeftSide>
            <Heading>Welcome Back🥳</Heading>
            <SubHeading>Login to get started.</SubHeading>
            <form>
              <Label margin="16px 0px 0px 0px" for="email">
                Email
              </Label>
              <Input
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={handleEmailChange}
                placeholder="Email"
                autoComplete
              />

              {emailError && (
                <ErrorLayout
                  margin="4px 0px 0px 0px"
                  errorMessage={emailError}
                />
              )}

              <Label margin="16px 0px 0px 0px" for="password">
                Password
              </Label>
              <Input
                type="password"
                id="password"
                name="password"
                onChange={handlePasswordChange}
                placeholder="Password"
              />

              {passwordError && (
                <ErrorLayout
                  margin="4px 0px 0px 0px"
                  errorMessage={passwordError}
                />
              )}
              <span onClick={handleForgotPasswordClick}>Forgot Password?</span>
            </form>
            {error && (
              <ErrorLayout
                margin="8px 0px 0px 0px"
                padding="16px 8px"
                errorMessage={error}
              />
            )}
            <Button
              type="button"
              progress={progress}
              margin="16px 0px 0px 0px"
              title="Log In"
              handleClick={handleLoginButton}
            />
            <AccountActionLayout margin="16px">
              <p>
                Don't have an account?{" "}
                <a onClick={handleNavbarButton}>Create one.</a>
              </p>
            </AccountActionLayout>

            <LoginWithGoogle>Or login with your google account</LoginWithGoogle>

            {googleError && (
              <ErrorLayout
                margin="16px 0px 0px 0px"
                padding="16px 8px"
                errorMessage={googleError}
              />
            )}

            <GoogleButton
              progress={googleProgress}
              marginTop="12px"
              handleClick={handleGoogleSignIn}
            />
          </LeftSide>
        )}
      </Content>
      <Footer />
    </Container>
  );
}

export default Login;
