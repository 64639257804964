import styled, { css, keyframes } from "styled-components";
import { motion } from "framer-motion";
import { ReactComponent as ComingSoonIcon } from "../../../images/comingsoon.svg";

export const rotate = keyframes`
from {
  transform: rotate(0deg);
  transform-origin: center;
}
to {
  transform: rotate(360deg);
  transform-origin: center;
}
`;

export const Container = styled(motion.div)`
  background-color: #f1f5f8;
`;

export const SvgWrapper = styled(motion.div)`
  margin: 32px;
  svg {
    width: 100%;
    height: 100px;
  }
`;

export const CominSoonSvg = styled(ComingSoonIcon)`
  height: 200px;
`;

export const Content = styled(motion.div)`
  display: flex;
  flex-direction: column;
  padding: 16px 16px 125px 16px;
  background-color: white;
  border-radius: 24px 24px 0px 0px;
`;

export const AnalyticsErrorContainer = styled(motion.div)`
  padding: 8px;
  background: rgba(255, 26, 26, 0.08);
  border: 1px solid rgb(255, 26, 26);
  border-radius: 4px;
  margin-bottom: 16px;

  p {
    font-size: 14px;
  }
`;

export const AnalyticsInformationContainer = styled(motion.div)`
  padding: 8px;
  border: ${({ theme }) => theme.border};
  border-radius: 4px;
  margin-bottom: 24px;

  p {
    color: #676b6f;
    font-size: 14px;
  }
`;

export const AnalyticsFilterContainer = styled(motion.div)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FilterContainer = styled(motion.div)`
  position: relative;
  cursor: pointer;
  margin-bottom: 16px;
`;

export const FilterText = styled.p`
  font-size: 16px;
  font-weight: 550;
  text-align: right;

  span {
    margin-left: 2px;
    font-size: 14px;
  }
`;

export const Filter = styled(motion.ul)`
  display: flex;
  flex-direction: column;
  width: 150%;
  position: absolute;
  right: 0;
  background-color: white;
  box-shadow: ${({ theme }) => theme.boxShadows.regular};
  transition: ${({ theme }) => theme.boxShadows.transition};
  border-radius: 4px;
  list-style: none;
  z-index: 2;
  margin-top: 12px;
`;

export const FilterItem = styled(motion.li)`
  list-style-position: inside;
  padding: 8px;
`;

export const Title = styled.h3`
  margin-bottom: 16px;
  text-transform: capitalize;
  letter-spacing: 1.1px;
  color: #172126;
`;

export const OverViewLayout = styled(motion.div)`
  margin-bottom: 24px;
  width: 100%;
  position: relative;
`;

export const OverViewSection = styled(motion.div)`
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
`;

export const OverViewCardLayout = styled(motion.div)`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const OverViewCard = styled(motion.div)`
  width: 100%;
  border: ${({ theme }) => theme.border};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 8px;
  margin: 8px;

  #loader {
    height: 30.2px;
    width: 28px;
    font-size: 28px;
    color: #ee5253;
    animation: ${rotate} 0.8s linear infinite;
  }
`;

export const CardCount = styled(motion.p)`
  font-size: 28px;
`;

export const CardTitle = styled(motion.p)`
  margin-top: 16px;
`;

export const FilterValue = styled(motion.span)`
  color: rgba(0, 0, 0, 0.8);
  font-size: 12px;
`;

export const GraphViewLayout = styled(motion.div)`
  margin-bottom: 16px;
  width: 100%;
  position: relative;
`;

export const GraphViewSection = styled(motion.div)`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const GraphViewCardLayout = styled(motion.div)`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
`;

export const GraphViewCard = styled(motion.div)`
  border: ${({ theme }) => theme.border};
  min-height: 200px;
  border-radius: 4px;
  margin: 8px 0px;
  padding: 8px;
  width: 100%;
  text-align: center;
  position: relative;
`;

export const GraphViewCardLoadingLayout = styled(motion.div)`
  border: ${({ theme }) => theme.border};
  min-height: 200px;
  border-radius: 4px;
  margin: 8px 0px 16px 0px;
  padding: 8px;
  width: 100%;
  text-align: center;
  vertical-align: middle;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > span {
    height: 36px;
    width: 34px;
    font-size: 34px;
    color: #ee5253;
    animation: ${rotate} 0.8s linear infinite;
  }

  & > p {
    margin-top: 8px;
    text-align: center;
  }
`;

export const GraphViewHeader = styled(motion.div)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Graph = styled(motion.div)`
  min-height: 200px;
`;

export const LegendContainer = styled(motion.div)`
  display: flex;
  flex-direction: row;
  min-width: 70px;
  width: fit-content;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  padding: 4px;

  & > span {
    font-size: 20px;
    margin-right: 8px;
  }

  ${({ type }) =>
    (type === "1" &&
      css`
        border: 1px solid rgb(153, 102, 255);
        background-color: rgba(153, 102, 255, 0.2);
        color: rgb(153, 102, 255);
      `) ||
    (type === "2" &&
      css`
        border: 1px solid rgb(54, 162, 235);
        background-color: rgba(54, 162, 235, 0.2);
        color: rgb(54, 162, 235);
      `) ||
    (type === "3" &&
      css`
        border: 1px solid rgb(255, 99, 132);
        background-color: rgba(255, 99, 132, 0.2);
        color: rgb(255, 99, 132);
      `) ||
    (type === "4" &&
      css`
        border: 1px solid rgb(255, 159, 64);
        background-color: rgba(255, 99, 132, 0.2);
        color: rgb(255, 159, 64);
      `)}
`;

export const TopLayout = styled(motion.div)`
  display: flex;
  flex-direction: row;
  width: 100%;
  border: ${({ theme }) => theme.border};
  border-radius: 4px;
  margin-bottom: 24px;
  padding: 8px;
  position: relative;
`;

export const TopProfileContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 50px;
  align-items: center;
  filter: ${({ isPro }) => (!isPro ? "blur(4px)" : "")};

  & > p {
    margin-top: 4px;
    color: ${({ theme }) => theme.colors.primary};
    .bi {
      font-size: 14px;
    }
  }
`;

export const TopProfileTable = styled(motion.table)`
  width: 100%;
`;

export const TopProfileTH = styled(motion.th)`
  text-align: left;
  padding: 4px;
`;

export const TopProfileTR = styled(motion.tr)`
  padding: 8px;
`;

export const TopProfileTD = styled(motion.td)`
  align-items: center;
  padding: 16px 8px;
  word-wrap: break-word;
  word-break: break-all;
`;

export const DevicesLayout = styled(motion.div)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 24px;

  /* @media screen and (min-width: 768px) {
    flex-direction: row;
  } */
`;

export const DevicesCard = styled(motion.div)`
  display: flex;
  width: 100%;
  margin-bottom: 16px;

  border: ${({ theme }) => theme.border};
  border-radius: 4px;
  padding: 16px;
  position: relative;
`;

export const DevicesContent = styled(motion.div)`
  display: flex;
  flex-direction: column;
  width: 100%;

  filter: ${({ isPro }) => (!isPro ? "blur(8px)" : "")};

  @media screen and (min-width: 768px) {
    flex-direction: row-reverse;
    justify-content: space-between;
  }
`;

export const DevicesLegendContainer = styled(motion.div)`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
  position: relative;
  margin-bottom: 16px;

  @media screen and (min-width: 768px) {
    flex-direction: column;
    margin-bottom: 0px;
  }
`;

export const DeviceLegend = styled(motion.div)`
  display: flex;
  flex-direction: column;
  padding: 16px;

  span {
    font-size: 12px;
    margin-right: 8px;
  }

  #value {
    margin-top: 4px;
    font-size: 18px;
  }

  #mobile {
    .bi {
      color: rgba(255, 206, 86, 1);
    }
  }

  #desktop {
    .bi {
      color: rgba(54, 162, 235, 1);
    }
  }
`;

export const DeviceLegendBreak = styled(motion.div)`
  position: absolute;
  left: 50%;
  top: 16%;
  bottom: 16%;
  border-left: 2px solid #dee3ed;

  @media screen and (min-width: 768px) {
    top: 50%;
    left: 16%;
    right: 16%;
    border-top: 2px solid #dee3ed;
    border-left: none;
  }
`;

export const DevicesBlurContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  position: absolute;
  top: 8px;
  left: 8px;
  right: 8px;
  bottom: 8px;

  & > span {
    font-size: 56px;
    margin-bottom: 8px;
  }

  & > p {
    text-align: center;
    letter-spacing: 1.2px;
    & > a {
      color: ${({ theme }) => theme.colors.pro};
      margin-left: 4px;
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

export const DevicesTable = styled(motion.table)`
  width: 100%;
`;

export const DevicesTH = styled(motion.th)`
  text-align: left;
  padding: 16px 8px;
`;

export const DevicesTR = styled(motion.tr)`
  padding: 16px 8px;
`;

export const DevicesTD = styled(motion.td)`
  padding: 16px 8px;
  align-items: center;

  & > span {
    font-size: 12px;
    margin-left: 2px;
  }
`;

export const EmptyLayout = styled(motion.div)`
  min-height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
