import React, { useContext, useState } from "react";
import { Container, Content, Input } from "./AddLinkItem.elements";
import Button from "../button/Button";
import { ErrorLayout } from "..";
import makeid from "../../utils/functions";
import { AuthContext } from "../../contexts/AuthContext";
import { db } from "../../firebase/firebase";
import firebase from "firebase";
import validator from "validator";
import { dropDownVariants } from "../../animations/animation";

function AddLinkItem({
  links,
  setShowAddLinkItem,
  scrollToLinkView,
  setShowUpgradeToProLayout,
  setUpgradeType,
}) {
  const { user } = useContext(AuthContext);

  const [title, setTitle] = useState("");
  const [linkValue, setLinkValue] = useState("");
  const [progress, setProgress] = useState(false);
  const [linkValueError, setLinkValueError] = useState("");
  const [error, setError] = useState("");

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleLinkValueChange = (e) => {
    setLinkValueError("");
    setError("");
    setLinkValue(e.target.value);
  };

  const handleAddLinkButtonClick = () => {
    if (linkValueError) {
      return;
    }

    if (linkValue === "") {
      setLinkValueError("Please enter valid link url");
      return;
    }

    if (!validator.isURL(linkValue)) {
      setLinkValueError("Please enter valid link url");
      return;
    }

    if (links.length >= 3 && !user.isPro) {
      setShowUpgradeToProLayout(true);
      setUpgradeType("links");
      return;
    }

    setProgress(true);
    const documentId = makeid();

    db.collection("users")
      .doc(user.userId)
      .collection("links")
      .doc(documentId)
      .set({
        link: linkValue,
        linkId: documentId,
        clicks: 0,
        image: "",
        title: title,
        order: links.length ? links[0].order - 0.095 : 0,
        isActive: true,
        userId: user.userId,
        animation: "",
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        type: "link",
      })
      .then(() => {
        setProgress(false);
        setShowAddLinkItem(false);
        scrollToLinkView();
      })
      .catch((error) => {
        setError(error.message);
        setProgress(false);
      });
  };

  return (
    <Container
      variants={dropDownVariants}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <Content>
        <label for="title">Title</label>
        <Input
          value={title}
          onChange={handleTitleChange}
          id="title"
          placeholder="Enter title here"
        />
        <label for="url">URL</label>
        <Input
          id=" url"
          value={linkValue}
          onChange={handleLinkValueChange}
          placeholder="Paste your link here"
        />
        {linkValueError && (
          <ErrorLayout
            margin="-8px 0px 0px 0px"
            errorMessage={linkValueError}
          />
        )}

        {error && <ErrorLayout margin="0px 0px" errorMessage={error} />}
        <Button
          variant={"dashboardAdd"}
          title="Add New Link"
          margin="8px 0px "
          handleClick={handleAddLinkButtonClick}
          progress={progress}
        />
      </Content>
    </Container>
  );
}

export default AddLinkItem;
