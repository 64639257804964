import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { animationvariants } from "../animations/animation";
import { openURL } from "../utils/functions";

function MobileScreenViewProfileItem({ profile }) {
  const handleProfileClick = () => {
    const URL = profile.URL + profile.value;
    openURL(URL);
  };

  return (
    <Container
      onClick={handleProfileClick}
      variants={animationvariants}
      animate={profile.animation}
    >
      <Content>
        <img src={profile.image} alt="" />
        <span>{profile.name}</span>
      </Content>
    </Container>
  );
}

const Container = styled(motion.div)`
  max-width: 50px;
  min-width: 50px;
  width: 50px;
  cursor: pointer;
`;

const Content = styled(motion.div)`
  padding: 12px;
  img {
    height: 35px;
    width: 35px;
    border-radius: ${({ theme }) => theme.profile.logo.borderRadius};
  }
  span {
    text-transform: capitalize;
    display: ${({ theme }) => (theme.profile.isLabel ? "block" : "none")};
    color: ${({ theme }) => theme.profile.labelColor};
    word-wrap: break-word;
    font-size: 10px;
    text-align: center;
  }
`;
export default MobileScreenViewProfileItem;
