import styled, { keyframes } from "styled-components";
import { motion } from "framer-motion";

export const rotate = keyframes`
from {
  transform: rotate(0deg);
  transform-origin: center;
}
to {
  transform: rotate(360deg);
  transform-origin: center;
}
`;

export const Container = styled(motion.div)`
  background-color: #f1f5f8;
`;

export const Content = styled(motion.div)`
  display: flex;
  flex-direction: column;
  min-height: 90vh;
  padding: 16px 16px 125px 16px;
  background-color: white;
  border-radius: 24px 24px 0px 0px;
`;

export const Section = styled(motion.section)`
  border: ${({ theme }) => theme.border};
  border-radius: 4px;
  margin: 16px 0px 24px 0px;
  padding: 16px;
`;

export const EmptyLayout = styled(motion.div)`
  display: flex;
  border: ${({ theme }) => theme.border};
  border-radius: 4px;
  min-height: 150px;
  margin: 16px 0px 24px 0px;
  padding: 16px;
  align-items: center;
  justify-content: center;

  p {
    text-align: center;
    letter-spacing: 1.2px;
  }

  a {
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.pro};
    font-weight: 500;
    cursor: pointer;
    margin-left: 4px;
  }
`;

export const EmailLayout = styled(motion.div)`
  display: flex;
  flex-direction: column;
`;

export const Title = styled(motion.p)`
  font-weight: 500;
`;

export const Value = styled(motion.div)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;

  a {
    font-size: 14px;
    font-weight: 500;
    color: #e91e63;
    cursor: pointer;
  }
`;

export const ChangeButton = styled(motion.button)`
  width: 100%;
  height: 40px;
  margin-top: 16px;
`;

export const ChangeEmailLayout = styled(motion.div)`
  display: flex;
  flex-direction: column;
`;

export const Label = styled(motion.label)`
  text-transform: ${({ theme }) => theme.label.textTransform};
  margin-top: 20px;
  font-weight: 500;
`;

export const Input = styled(motion.input)`
  margin-top: 8px;
  height: ${({ theme }) => theme.input.height};
  border-radius: ${({ theme }) => theme.borderRadius};
  outline-width: 0px;
  border-width: 0px;
  padding: 0px 8px 0px 8px;
  border: ${({ theme }) => theme.border};
  font-size: ${({ theme }) => theme.input.fontSize};
  color: ${({ theme }) => theme.input.color};
  background-color: ${({ theme }) => theme.input.background};
`;

export const Message = styled(motion.span)`
  margin-top: 16px;
  padding: 0px 4px;
  font-size: 13px;
`;

export const PasswordLayout = styled(motion.div)`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`;

export const ChangePasswordLayout = styled(motion.div)`
  display: flex;
  flex-direction: column;
`;

export const ForgotPasswordText = styled(motion.span)`
  text-align: right;
  margin-top: 8px;
  font-size: ${({ theme }) => theme.login.forgotPassword.fontSize};
  margin-right: 0;
  color: ${({ theme }) => theme.login.forgotPassword.color};
  text-decoration: ${({ theme }) => theme.login.forgotPassword.textDecoration};
`;

export const SuccessLayout = styled(motion.div)`
  padding: ${({ padding }) => (padding ? padding : "8px")};
  margin: ${({ margin }) => (margin ? margin : "0px")};
  border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : "4px")};
  min-height: 30px;
  background-color: lightseagreen;

  p {
    font-size: 14px;
    color: green;
    word-wrap: break-word;
  }
`;

export const AccountItemContainer = styled(motion.div)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 0px;
  margin: ${({ margin }) => margin};
  text-transform: capitalize;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 550;

  span {
    height: 26px;
    width: 24px;
    font-size: 24px;
    margin-right: 8px;
  }

  & #signout {
    color: ${({ theme }) => theme.colors.delete};
  }

  #loader {
    animation: ${rotate} 0.8s linear infinite;
  }

  #loaderSignout {
    animation: ${rotate} 0.8s linear infinite;
    color: ${({ theme }) => theme.colors.delete};
  }
`;
