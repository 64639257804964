export const theme = {
  background: "#fffefd",
  borderRadius: "4px",
  border: "1px solid #dee3ed",
  fontFamily: "'Varela Round', sans-serif",

  pricing: {
    free: {
      background: "rgba(26, 198, 255, 0.05)",
      border: "1px solid #1ac6ff",
      color: "#1ac6ff",
      boxShadow: "0 0 2px  #9ecaed",
    },
    pro: {
      background: "rgba(255, 153, 0 , 0.05)",
      border: "1px solid #ff9900",
      color: "#ff9900",
      boxShadow: " 0 0 2px #ff9900",
    },
  },

  cards: {
    backgroundColor: {
      one: "rgba(15, 4, 76 , 0.2)",
      two: "rgba(20, 30, 97, 0.2)",
      three: "rgba(120, 122, 45, 0.2)",
      four: "rgba(252, 84, 4, 0.2)",
      five: "rgba(249, 132, 4, 0.2)",
      six: "rgba(249, 178, 8, 0.2)",
      seven: "rgba(64, 34, 24, 0.2)",
      eight: "rgba(134, 84, 57, 0.2)",
      nine: "rgba(198, 139, 89, 0.2)",
      ten: " rgba(241, 137, 4, 0.2)",
      eleven: "rgba(241, 137, 4, 0.2)",
      twelve: "rgba(241, 137, 4, 0.2)",
    },

    color: {
      one: "rgb(15, 4, 76)",
      two: "rgb(20, 30, 97)",
      three: "rgb(120, 122, 45)",
      four: "rgb(252, 84, 4)",
      five: "rgb(249, 132, 4)",
      six: "rgb(249, 178, 8)",
      seven: "rgb(64, 34, 24)",
      eight: "rgb(134, 84, 57)",
      nine: "rgb(198, 139, 89)",
      ten: "rgb(241, 147, 4)",
      eleven: "rgb(241, 147, 4)",
      twelve: "rgb(241, 147, 4)",
    },
  },

  colors: {
    primaryDark: "#151153",
    primary: "#4D80E4",
    secondary: "",
    pro: "#ff8f50",
    free: "#1ac6ff",
    delete: "#D83A56",
    analytics: "#00b8e6",
    deepLink: "#2b2e4a",
    visit: "#121212",
    cancel: "#e9effc",
    buttonSecondary: "#e9effc",
    iconLight: "#8a94a8",
    animation: "#f98404",
    success: "#267326",
    failure: "#ff3300",
  },

  textColors: {
    primary: "#000000",
    secondary: "rgba(0,0,0,0.7)",
  },

  appearance: {
    active: {
      background: "rgba(26, 163, 255 , 0.1)",
      border: "1px solid transparent",
      itemBorder: " 1px solid #000000 ",
      iconColor: " #000000",
    },

    border: {
      itemBorder: " 1px solid #666666 ",
    },

    icons: {
      active: "#333333",
      inActive: "#b3b3b3",
      iconBorder: " 1px solid #666666 ",
      iconBorderInActive: " 1px solid #d9d9d9 ",
    },
  },

  nav: {
    color: "black",
    activeColor: "rgb(125, 44, 255)",
    hoverColor: "rgba(125, 44, 255 , 0.2)",
    fontSize: "18px",
    fontWeight: "400",
  },

  container: {
    background: "white",
  },

  login: {
    title: {
      fontSize: "24px",
      color: "black",
      lineHeight: "1.5",
    },

    forgotPassword: {
      fontSize: "14px",
      color: "black",
      textDecoration: "underline",
    },

    loginWithGoogle: {
      fontSize: "12px",
      color: "black",
      textDecoration: "none",
    },
  },

  button: {
    background: " #4D80E4",
    disabledBackground: "#ededed",
    fontSize: "16px",
    color: "#fff",
    disabledColor: "#bfbfbf",
    height: "50px",
    textTransform: "uppercase",
    letterSpacing: "1.2px",
  },
  googleButton: {
    background: "#4285F4",
  },

  progress: {
    background: "#4D80E4",
    baseBackground: "rgba(77, 128, 228 , 0.2)",
    color: "#fff",
    textTransform: "uppercase",
    letterSpacing: "1.2px",
  },

  googleProgress: {
    background: "rgb(31, 121, 239)",
    baseBackground: "rgba(31, 121, 239 , 0.2)",
  },

  input: {
    fontSize: "16px",
    background: "white",
    color: "black",
    height: "50px",
  },

  label: {
    fontSize: "14px",
    color: "black",
    textTransform: "capitalize",
  },

  boxShadows: {
    transition: "box-shadow 0.3s ease-in-out",
    success: "0px 0px 4px 1px #00b300",
    error: "0px 0px 4px 0.5px red;",
    regular: " 0px 0px 4px 2px rgba(0, 0, 0, 0.1)",
  },
};

export const viewProfileTheme = {
  theme: "Dark",
  background: {
    backgroundColor: "#121212",
    backgroundSecondColor: "#717171",
    image: "",
    source: "",
    type: "Flat",
  },

  font: { fontFamily: "'Rubik', sans-serif", fontName: "Rubik" },

  link: {
    image: { borderRadius: "4px", shape: "square4px" },
    isLinkImage: true,
    isLinkURL: true,
    linkTitleColor: "#000000",
    linkURLColor: "#000000",
    style: {
      backgroundColor: "#ffffff",
      borderColor: "#000000",
      borderRadius: "4px",
      style: "filledRectangle4px",
      type: "filled",
    },
  },

  profile: {
    isLabel: true,
    labelColor: "#ffffff",
    logo: { shape: "square4px", borderRadius: "4px" },
  },

  user: {
    bioColor: "#ffffff",
    isBio: true,
    isName: true,
    isProfilePhoto: true,
    isUsername: true,
    nameColor: "#ffffff",
    profilePhoto: { shape: "square4px", borderRadius: "4px" },
    usernameColor: "#ffffff",
  },

  image: {
    isImageDescription: true,
    isImageTitle: true,
    isImageURL: true,
    imageDescriptionColor: "#000000",
    imageTitleColor: "#000000",
    imageURLColor: "#000000",
    style: {
      backgroundColor: "#ffffff",
      borderColor: "#000000",
      borderRadius: "4px",
      style: "filledRectangle4px",
      type: "filled",
    },
  },

  video: {
    isVideoTitle: true,
    isVideoURL: true,
    videoTitleColor: "#000000",
    videoURLColor: "#000000",
    style: {
      backgroundColor: "#ffffff",
      borderColor: "#000000",
      borderRadius: "4px",
      style: "filledRectangle4px",
      type: "filled",
    },
  },
};

export const customTheme = {
  theme: "Custom",
  isPro: false,
  background: {
    backgroundColor: "#ededed",
    backgroundSecondColor: "#ededed",
    image: "",
    source: "",
    type: "Flat",
  },

  font: { fontFamily: "'Monda', sans-serif", fontName: "Monda" },

  link: {
    image: { borderRadius: "4px", shape: "square4px" },
    isLinkImage: true,
    isLinkURL: true,
    linkTitleColor: "#000000",
    linkURLColor: "#000000",
    style: {
      backgroundColor: "#ffffff",
      borderColor: "#000000",
      borderRadius: "4px",
      style: "filledRectangle4px",
      type: "filled",
    },
  },

  profile: {
    isLabel: true,
    labelColor: "#ffffff",
    logo: { shape: "square4px", borderRadius: "4px" },
  },

  user: {
    bioColor: "#ffffff",
    isBio: true,
    isName: true,
    isProfilePhoto: true,
    isUsername: true,
    nameColor: "#ffffff",
    profilePhoto: { shape: "square4px", borderRadius: "4px" },
    usernameColor: "#ffffff",
  },
};
