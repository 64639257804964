import React, { useState, useEffect, useContext } from "react";
import { moveUpContainerVariants } from "../../animations/animation";

import { Container, Content } from "./ChatWidget.elements";
import { AuthContext } from "../../contexts/AuthContext";

function ChatWidget({ variant, progress }) {
  const [isProgress, setIsProgress] = useState(false);
  const [isProgressCompleted, setIsProgressCompleted] = useState(false);

  const { user } = useContext(AuthContext);

  useEffect(() => {
    if (progress) {
      setIsProgress(true);
    } else {
      setIsProgressCompleted(true);
      setTimeout(() => {
        setIsProgressCompleted(false);
        setIsProgress(false);
      }, 1500);
    }
  }, [progress]);

  useEffect(() => {
    if (user) {
      window.fcWidget.setExternalId(user.userId);
      window.fcWidget.user.setFirstName(user.name);
      window.fcWidget.user.setEmail(user.email);
      window.fcWidget.user.setProperties({
        isPro: user.isPro,
        proExpire: user.dateOfProExpire.toDate(),
      });
    }
  }, [user]);

  useEffect(() => {
    if (window.fcWidget) {
      window.fcWidget.init({
        token: process.env.REACT_APP_FRESHCHAT_TOKEN,
        host: "https://wchat.in.freshchat.com",
        config: {
          headerProperty: {
            hideChatButton: true,
          },
        },
      });
    }
  }, [window.fcWidget]);

  const handleSupportIconClick = () => {
    if (isProgress) {
      return;
    }

    window.fcWidget.open();
  };

  return (
    <Container
      initial="initial"
      animate="animate"
      variants={moveUpContainerVariants}
      variant={variant}
      onClick={handleSupportIconClick}
    >
      <Content>
        {!isProgress && (
          <span>
            <i class="bi bi-chat-left-text-fill"></i>
          </span>
        )}

        {isProgress && !isProgressCompleted && (
          <span id="loader">
            <i class="fas fa-circle-notch"></i>
          </span>
        )}

        {isProgress && isProgressCompleted && (
          <span id="checkMark">
            <i class="fas fa-check"></i>
          </span>
        )}
      </Content>
    </Container>
  );
}

export default ChatWidget;
