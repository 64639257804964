import styled, { keyframes } from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import { motion } from "framer-motion";

export const rotate = keyframes`
from {
  transform: rotate(0deg);
  transform-origin: center;
}
to {
  transform: rotate(360deg);
  transform-origin: center;
}
`;

export const Container = styled(motion.div)`
  background-color: #f1f5f8;
`;

export const Content = styled(motion.div)`
  display: flex;
  flex-direction: column;
  padding: 16px 16px 125px 16px;
  background-color: white;
  border-radius: 24px 24px 0px 0px;
`;

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;

  & > .MuiSvgIcon-root {
    color: #000000;
  }
`;

export const Heading = styled.h3`
  text-transform: capitalize;
  letter-spacing: 1.1px;
  color: #172126;
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

export const Title = styled.p`
  margin-bottom: 8px;
  font-weight: 600;
`;

export const SubTitle = styled.p`
  margin-bottom: 8px;
  color: "#333333";
`;

export const ProfileNameLayout = styled.div`
  padding: 16px;
  margin: 16px 0 0 0;
  border-radius: 4px;
  border: ${({ theme }) => theme.border};

  & > label {
    font-size: 14px;
  }
`;

export const ProfilePhotoLayout = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  margin: 0px 0px 24px 0px;
`;

export const ProfilePhotoImageLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 50%;
  label {
    display: flex;
    border-radius: 50%;
    position: relative;
    img {
      height: 100px;
      width: 100px;
      object-fit: cover;
      border-radius: 50%;
    }
  }
  #loader {
    height: 42px;
    width: 40px;
    font-size: 40px;
    position: absolute;
    color: #ee5253;
    animation: ${rotate} 0.8s linear infinite;
  }

  #progressText {
    position: absolute;
    font-size: 14px;
    color: #ffffff;
  }
`;

export const ProfilePhotoUploadButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  width: 100%;
  height: 40px;
  background-color: #ee5253;
  color: #f1f5f8;
  margin: 0px 0px 0px 16px;
  border-radius: 4px;
`;

export const NameInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 4px 0px 16px 0px;

  & > input {
    background: transparent;
    border: none;
    flex: 1;
    outline: none;
    font-size: 16px;
    font-weight: 500;
    min-height: 0px;
    padding: 0px;
  }

  & > span {
    color: ${({ theme }) => theme.colors.iconLight};
    font-size: 16px;
    font-weight: 500;
    margin-left: 4px;
  }
`;

export const BioTextAreaWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 4px;
  textarea {
    flex: 1;
    width: 100%;
    height: 80px;
    padding-bottom: 0;
    padding-left: 0;
    line-height: 1.2;
    outline: none;
    border: none;
    font-family: ${({ theme }) => theme.fontFamily};
    font-size: 16px;
  }

  & > span {
    color: ${({ theme }) => theme.colors.iconLight};
    font-size: 14px;
    margin-left: 4px;
  }
`;

export const UsernameLayout = styled.div`
  padding: 16px;
  margin: 16px 0 0 0;
  border-radius: 4px;
   border: ${({ theme }) => theme.border};
  position: relative;
`;

export const UsernameWrapper = styled.div`
  display: block;
  position: relative;
  margin-top: 4px;
  border-radius: 4px;
  background-color: white;
  border-bottom: ${({ theme, success, error }) => theme.border};
  transition: ${({ theme }) => theme.boxShadows.transition};

  div {
    display: flex;
    height: 50px;
    align-items: center;
    cursor: pointer;
  }

  p {
    font-size: 16px;
  }

  input {
    display: inline-block;
    width: 100%;
    background: transparent;
    border: none;
    outline: none;
    font-size: 16px;
    margin-right: 8px;
    flex: 1;
    padding-left: 0;
    color: black;
    text-decoration: none;

    ::placeholder,
    ::-webkit-input-placeholder {
      color: #b3b3b3;
    }
    :-ms-input-placeholder {
      color: #b3b3b3;
    }
  }

  span {
    height: 26px;
    width: 24px;
    font-size: 24px;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.iconLight};
  }

  #edit {
    font-size: 14px;
    height: 16px;
    width: 14px;
    margin-right: 4px;
  }

  #loading {
    animation: ${rotate} 1s linear infinite;
    color: #e91e63;
  }

  #error {
    color: red;
    font-size: 20px;
  }
`;

export const UsernameOverflowContainer = styled(motion.div)`
  min-width: 200px;
  position: absolute;
  background-color: #ffffff;
  box-shadow: ${({ theme }) => theme.boxShadows.regular};
  transition: ${({ theme }) => theme.boxShadows.transition};
  top: 100px;
  right: 16px;
  padding: 8px;
  border-radius: 4px;
  z-index: 166;
`;

export const UsernameOverflowMenuItem = styled(motion.div)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 0px;
  margin: 4px 0px;
  cursor: pointer;

  & > span {
    margin-right: 16px;
    font-size: 22px;
    color: ${({ theme }) => theme.colors.iconLight};
  }
`;

export const ShapesLayout = styled(motion.div)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0px 0px 16px 0px;
`;

export const LinkStylesLayout = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0px 0px 16px 0px;
`;

export const FontColorsLayout = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0px 0px 16px 0px;
`;
export const BackgroundColorsLayout = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0px 0px 16px 0px;
`;

export const VisibilityLayout = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0px 0px 16px 0px;
`;

export const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 38,
    height: 22,
    padding: 0,
    // margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#52d869",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 20,
    height: 20,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

export const ThemeLayout = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16px;
  flex-wrap: wrap;
  margin: 16px 0 0 0;
  border-radius: 4px;
   border: ${({ theme }) => theme.border};
`;

export const ThemeLoadingContainer = styled.div`
  border: ${({ theme }) => theme.border};
  border-radius: 4px;
  margin: 16px 0px;
  padding: 16px;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > span {
    height: 36px;
    width: 34px;
    font-size: 34px;
    color: rgba(0, 0, 0, 0.8);
    animation: ${rotate} 0.8s linear infinite;
  }

  & > p {
    margin-top: 8px;
    text-align: center;
  }
`;

export const BackgroundLayout = styled.div`
  margin: 16px 0 0 0;
  border-radius: 4px;
  padding: 16px;
   border: ${({ theme }) => theme.border};
`;

export const PersonalInfoLayout = styled.div`
  margin: 16px 0 0 0;
  border-radius: 4px;
   border: ${({ theme }) => theme.border};
  padding: 16px 16px 0px 16px;
`;

export const ProfileLayout = styled.div`
  margin: 16px 0 0 0;
  border-radius: 4px;
   border: ${({ theme }) => theme.border};
  padding: 16px;
`;

export const LinkLayout = styled.div`
  margin: 16px 0 0 0;
  border-radius: 4px;
   border: ${({ theme }) => theme.border};
  padding: 16px;
`;

export const StylesLayout = styled.div`
  min-height: 500px;
  margin: 16px 0 0 0;
  border-radius: 4px;
   border: ${({ theme }) => theme.border};
  padding: 16px;
`;

export const FontColorLayout = styled.div`
  padding: 16px;
  margin: 16px 0 50 0;
  border-radius: 4px;
   border: ${({ theme }) => theme.border};
`;

export const FontLayout = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 16px 0 0 0;
  padding: 16px;
  border-radius: 4px;
   border: ${({ theme }) => theme.border};
`;

export const LoadingLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 16px 0 0 0;
  border-radius: 4px;
  height: 200px;
  border: ${({ theme }) => theme.border};

  & > span {
    height: 36px;
    width: 34px;
    font-size: 34px;
    color: #ee5253;
    animation: ${rotate} 0.8s linear infinite;
  }

  & > p {
    margin-top: 8px;
    text-align: center;
    color: #676b6f;
  }
`;
