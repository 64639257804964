import React, { useEffect } from "react";
import { Footer, Navbar } from "../../components";

import { useHistory } from "react-router-dom";

import {
  Container,
  Content,
  Heading,
  Paragraph,
  PrivacyList,
  PrivacyListItem,
} from "./Privacy.elements";

function Privacy() {
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, []);

  return (
    <Container>
      <Navbar navLocation="/privacy" />
      <Content>
        <h1>Privacy Policy</h1>
        <p>
          <span>Draft : </span> 5 Dec 2021
        </p>
        <p>
          <span>Effective From :</span> 5 Dec 2021
        </p>

        <Heading>Protection of Your data</Heading>
        <Paragraph>
          When we collect or use your data, we will utilize commercially
          reasonable safeguards to ensure its protection. It should be noted
          that no security procedure is currently 100% effective. Should any
          breach of your Personal data occur, we will inform you as soon as
          reasonably possible, as required by applicable law.
        </Paragraph>
        <Heading>Data Covered</Heading>
        <Paragraph>
          We collect data at various points in the Platform to facilitate its
          use by our users. The data covered by this policy includes, but is not
          limited to, data collected via any advertising unit, widget, pixel
          tag, cookie, script or other data collection process. Specifically,
          two types of data are collected:
        </Paragraph>
        <PrivacyList>
          <PrivacyListItem>
            Non-Personal data: Upon accessing the Platform, certain non-personal
            data will be automatically collected without your knowledge or
            consent, such as your IP address, location data and the referring
            website (“Non-Personal data”). We use Non-Personal data to examine
            our traffic and to view how our customers use the Platform. This
            type of data will not allow you to be personally identified although
            we might be able to associate it with your account.
          </PrivacyListItem>
          <PrivacyListItem>
            Identifying Personal data: If you initiate a transaction through the
            Platform, we may collect and store data about you, such as your
            name, phone number, address, email as well as any other data you
            provide to us ("Personal data"), in order to process your
            transaction, send communications about them to you, and populate
            forms for future transactions. This data may be shared with third
            parties for the same purposes.
          </PrivacyListItem>
        </PrivacyList>
        <Paragraph>
          Additionally, Personal data may be used and disclosed to the extent
          Getmyprofiles may deem reasonably necessary to enforce the terms of
          any agreement between you and Getmyprofiles, or to protect the rights,
          property or safety of any person or entity.
        </Paragraph>
        <Heading>Cookies and Similar Technologies</Heading>
        <Paragraph>
          When you interact with the Platform, we try to make that experience
          easy, simple and meaningful. When you use our Platform, our web server
          sends a cookie to your computer or mobile device or tablet device.
          Cookies are small pieces of data which are issued to your computer or
          mobile device or tablet device (as the case may be) when you visit a
          website or access or use a mobile application and which store and
          sometimes track data about your use of a website or application (as
          the case may be). A number of cookies we use last only for the
          duration of your web session or Platform session and expire when you
          close your browser or exit the Platform. Other cookies are used to
          remember you when you return to the Platform and will last for longer.
          Some of the cookies used by the Platform is set by us, and some are
          set by third parties who are delivering services on our behalf. Most
          web, mobile and tablet device browsers automatically accept cookies
          but, if you prefer, you can change your browser to prevent that or to
          notify you each time a cookie is set. You can also learn more about
          cookies by visiting https://www.allaboutcookies.org/. It includes
          additional useful information on cookies and how to block cookies
          using different types of browser or mobile device. Please note,
          however, that by blocking or deleting cookies used on the Platform,
          you may not be able to take full advantage of the Platform.
        </Paragraph>
        <Heading>Right to Examine data</Heading>
        <Paragraph>
          You have the right to examine any of your Personal data that we
          collect. Should you wish to examine such data, please send us a
          written request to support@getmyprofiles.in. We reserve the right to
          charge you a reasonable administrative fee to access your data, as
          permitted by applicable law. In certain cases, we may not be able to
          provide you with access to all of your Personal data (example: if the
          data also pertains to the Personal data of another user).
        </Paragraph>
        <Heading>Withdrawal of Consent</Heading>
        <Paragraph>
          You may withdraw your consent to the collection of Personal data at
          any time by sending an email to us. Upon receiving notice that you
          have revoked your consent, we will stop using your Personal data
          within a reasonable time, which will vary depending on what data we
          have collected and for what purpose. Please note that we will send you
          an email confirmation upon receipt of your request. Therefore, if you
          do not receive a confirmation email, please contact us again with your
          request. If you do choose to withdraw such consent, your access to the
          Platform may be diminished, or your ability to choose some of the
          options on the Platform may be limited. Contact email:
          support@getmyprofiles.in.
        </Paragraph>
        <Heading>Sharing data</Heading>
        <Paragraph>
          We may share the collected data with other parties as follows:
        </Paragraph>
        <PrivacyList>
          <PrivacyListItem>
            Businesses on Getmyprofiles.in: we may share data from or about you
            (such as your age and gender and how you use the Platform) with
            businesses listed on the Platform.
          </PrivacyListItem>
          <PrivacyListItem>
            Where required by law: we may share the collected data where
            required by law, specifically in response to a demand from
            government authorities where such demand meets the legal
            requirements.
          </PrivacyListItem>
          <PrivacyListItem>
            Statistical Analysis: we may share Non-Personal data and aggregated
            data with third parties, including but not limited to for
            advertising or marketing purposes. No Personal data will be shared
            in this manner.
          </PrivacyListItem>
          <PrivacyListItem>
            Affiliated Service Providers: we have agreements with various
            affiliated service providers to facilitate the functioning of the
            Platform, with whom we may share the data we have collected.
          </PrivacyListItem>
          <PrivacyListItem>
            Transactions: in connection with, or during negotiations of, any
            merger, sale of company assets, financing or acquisition, or in any
            other situation where Personal data may be disclosed or transferred
            as one of our business assets.
          </PrivacyListItem>
        </PrivacyList>
        <Heading>Location-Based Services Consent</Heading>
        <Paragraph>
          You hereby expressly consent to our use of location-based services and
          expressly waive and release Getmyprofiles.in from any and all
          liability, claims, causes of action or damages arising from your use
          of Getmyprofiles.in services, including but not limited to use of the
          Platform, or in any way relating to the use of the precise location
          and other location-based services.
        </Paragraph>
        <Heading>External Links</Heading>
        <Paragraph>
          The Platform contains links and references to other websites. We are
          not responsible for the collection, use and disclosure of data, or the
          privacy practices of such websites, and we expressly disclaim any
          liability relating thereto.
        </Paragraph>
        <Heading>Terms of Service</Heading>
        <Paragraph>
          This Privacy Policy is incorporated into and forms part of the Terms
          of Service, which outlines the terms and conditions you agree to when
          accessing and using the Platform, and which can be found here:
          https://getmyprofiles.in/terms.
        </Paragraph>
        <Heading>Other Rights</Heading>
        <Paragraph>
          Whenever made possible, you can update or request deletion of your
          personal information. If you are unable to perform these actions
          yourself, please contact us for assistance using the contact details
          at the bottom of this Privacy Policy
        </Paragraph>
        <Heading>Our Contact information</Heading>
        <Paragraph>
          Please contact us with any questions or comments about this Policy,
          your personal data, our use and disclosure practices by email at
          support@getmyprofiles.in. Alternatively, please use this page for more
          information: https://getmyprofiles.in/contact.
        </Paragraph>
      </Content>
      <Footer />
    </Container>
  );
}

export default Privacy;
