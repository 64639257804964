import React from "react";
import styled, { css } from "styled-components";

function ThemeItem({ active, themeValue, uploadUserTheme }) {
  const handleThemeUpload = () => {
    uploadUserTheme(themeValue);
  };

  return (
    <Container active={active} onClick={handleThemeUpload}>
      <Content>
        <ThemeStyle themeValue={themeValue}>
          {themeValue.isPro && <span>Pro</span>}
        </ThemeStyle>
        <p>{themeValue.theme}</p>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  width: 124px;
  border: ${({ active, theme }) =>
    active ? theme.appearance.active.border : "1px solid transparent"};
  background: ${({ active, theme }) =>
    active ? theme.appearance.active.background : ""};
  justify-content: center;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  margin: 8px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    margin-top: 8px;
    text-align: center;
    font-size: 14px;
    word-wrap: break-word;
    word-break: break-all;
    line-height: 1.2;
  }
`;

const ThemeStyle = styled.div`
  height: 125px;
  width: 100px;
  position: relative;
  display: flex;
  justify-content: center;
  border-radius: 4px;
  border: ${({ themeValue }) =>
    (themeValue.background.backgroundColor === "#ffffff" &&
      "1px solid #121212") ||
    (themeValue.theme === "Custom" && "1px solid #666666")};

  span {
    font-size: 10px;
    display: flex;
    position: absolute;
    bottom: 4px;
    background-color: ${({ theme }) => theme.colors.pro};
    color: white;
    padding: 4px 12px;
    border-radius: 4px;
    letter-spacing: 1.2px;
    font-weight: 600;
    text-transform: uppercase;
  }

  ${({ themeValue }) =>
    (themeValue.background.type === "Up" &&
      css`
        background: linear-gradient(
          to top,
          ${themeValue.background.backgroundColor},
          ${themeValue.background.backgroundSecondColor}
        );
      `) ||
    (themeValue.background.type === "Down" &&
      css`
        background: linear-gradient(
          to bottom,
          ${themeValue.background.backgroundColor},
          ${themeValue.background.backgroundSecondColor}
        );
      `) ||
    (themeValue.background.type === "Right" &&
      css`
        background: linear-gradient(
          to right,
          ${themeValue.background.backgroundColor},
          ${themeValue.background.backgroundSecondColor}
        );
      `) ||
    (themeValue.background.type === "Left" &&
      css`
        background: linear-gradient(
          to left,
          ${themeValue.background.backgroundColor},
          ${themeValue.background.backgroundSecondColor}
        );
      `) ||
    (themeValue.background.type === "Flat" &&
      css`
        background: ${themeValue.background.backgroundColor};
      `) ||
    (themeValue.background.type === "Image" &&
      css`
        background-image: url(${themeValue.background.image});
        height: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      `)}
`;

export default ThemeItem;
