import React, { useState, createContext, useEffect } from "react";
import { auth, db } from ".././firebase/firebase";

export const AuthContext = createContext();

const AuthContextProvider = (props) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [user, setUser] = useState(null);

  const authListener = () => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        setIsAuthenticated(true);
        getUser(auth.currentUser.uid);
      } else {
        setIsAuthenticated(false);
      }
    });
  };

  const getUser = (UserId) => {
    var docRef = db.collection("users").doc(UserId);
    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          setUser(doc.data());
        }
      })
      .catch((error) => {});
  };

  const updateProDetails = () => {
    const todaysDate = new Date();
    if (user.dateOfProExpire !== null && user.isPro) {
      const dateOfProExpire = user.dateOfProExpire.toDate();
      if (todaysDate > dateOfProExpire) {
        db.collection("users")
          .doc(user.userId)
          .update({
            isPro: false,
          })
          .catch((error) => {});
      } else {
      }
    }
  };

  const updateTheme = () => {
    const lastUpdateDate = new Date("2022-01-15");

    if (
      user.accountCreatedOn !== null &&
      user.accountCreatedOn.toDate() < lastUpdateDate &&
      user.version !== 2
    ) {
      db.collection("users")
        .doc(user.userId)
        .collection("themes")
        .doc("theme")
        .update({
          "image.isImageDescription": true,
          "image.isImageTitle": true,
          "image.isImageURL": true,
          "image.imageDescriptionColor": "#000000",
          "image.imageTitleColor": "#000000",
          "image.imageURLColor": "#000000",
          "image.style.backgroundColor": "#ffffff",
          "image.style.borderColor": "#000000",
          "image.style.borderRadius": "4px",
          "image.style.style": "filledRectangle4px",
          "image.style.type": "filled",
          "video.isVideoTitle": true,
          "video.isVideoURL": true,
          "video.videoTitleColor": "#000000",
          "video.videoURLColor": "#000000",
          "video.style.backgroundColor": "#ffffff",
          "video.style.borderColor": "#000000",
          "video.style.borderRadius": "4px",
          "video.style.style": "filledRectangle4px",
          "video.style.type": "filled",
        })
        .then(() => {
          db.collection("users").doc(user.userId).update({
            version: 2,
          });
        })
        .catch((error) => {});
    } else {
    }
  };

  useEffect(() => {
    if (user) {
      updateProDetails();
      updateTheme();
    }
  }, [user]);

  useEffect(() => {
    authListener();
  }, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated, user }}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
