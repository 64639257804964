import styled from "styled-components";
import { motion } from "framer-motion";

export const Container = styled(motion.div)`
  width: 100%;
  margin-top: 8px;
`;

export const Divider = styled(motion.div)`
  border-top: 1px solid #dee3ed;
  border-radius: 4px;
`;

export const FooterMainContainer = styled(motion.div)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-direction: row;
  margin: 16px 0px 8px 0px;

  p {
    margin-right: 4px;
    font-size: 14px;

    span {
      margin-left: 4px;
      color: #1a1a1a;
    }
  }
`;

export const CheckBoxContainer = styled(motion.div)`
  display: flex;
  align-items: center;
  position: relative;

  input {
    cursor: pointer;
    height: 18px;
    width: 18px;
  }

  p {
    margin-left: 8px;
    font-size: 14px;
  }
`;
