import styled, { keyframes } from "styled-components";
import { motion } from "framer-motion";
//Animations

const rotate = keyframes`
  from {
    transform: rotate(0deg);
    transform-origin: center;
  }
  to {
    transform: rotate(360deg);
    transform-origin: center;
  }
`;

export const Container = styled(motion.div)`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 8px 16px 16px 16px;
  background: #f1f5f8;
  color: #172126;
`;

export const Content = styled(motion.div)``;

export const SelectedProfileWrapper = styled(motion.div)`
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  cursor: pointer;
  color: #172126;

  #icon {
    font-size: 18px;
  }
`;

export const InputWrapper = styled.input`
  margin-top: 8px;
  height: 50px;
  width: 100%;
  padding-left: 4px;
  outline: none;
  border: none;
  font-size: 16px;
  border-radius: 4px;
  background: #ffffff;
  border: ${({ theme }) => theme.border};
`;

export const SelectedProfileContainer = styled(motion.div)`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #172126;
  img {
    width: 32px;
    height: 32px;
    margin: 0px 8px;
    border-radius: 4px;
  }
`;

export const AddProfileDialog = styled(motion.div)`
  display: block;
  background-color: white;
  position: absolute;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  top: 50px;
  width: 100%;
  z-index: 2;
  border-radius: 4px;
`;

export const ProfileSearchBarWrapper = styled(motion.div)`
  display: flex;
  flex-direction: row;
  align-items: center;

  #close {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px 8px 0px 0px;
    height: 40px;
    width: 40px;
    font-size: 18px;
    border-radius: 4px;
    border: ${({ theme }) => theme.border};
  }
`;

export const ProfileSearchBar = styled(motion.div)`
  margin: 8px;
  padding: 0px 8px;
  height: 40px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  flex: 1;
  border: ${({ theme }) => theme.border};

  #searchIcon {
    font-size: 18px;
    margin-right: 8px;
    color: ${({ theme }) => theme.colors.iconLight};
  }
  input {
    flex: 1;
    outline: none;
    border: none;
    font-size: 16px;
    background-color: white;
  }
`;

export const DisplayAddProfileItemContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  background-color: white;
  max-height: 550px;
  overflow-y: scroll;
  overflow-x: none;
`;

export const AddProfileProfilesLoadingLayout = styled(motion.div)`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  min-height: 80px;

  & > span {
    height: 30px;
    width: 28px;
    font-size: 28px;
    color: #ee5253;
    animation: ${rotate} 0.8s linear infinite;
  }
`;

export const MissedProfiles = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 8px;
  padding: 8px;
  border-top: 1px solid #ededed;
  p {
    text-align: center;
  }

  & > span {
    font-size: 14px;

    & > a {
      color: #ee5253;
      font-weight: 550;
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;
