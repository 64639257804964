import { Avatar } from "@material-ui/core";
import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import { auth } from "../../firebase/firebase";

import DashboardIcon from "@material-ui/icons/Dashboard";
import LogOutIcon from "@material-ui/icons/ExitToApp";
import SettingsIcon from "@material-ui/icons/Settings";
import PaymentIcon from "@material-ui/icons/Payment";

import {
  NavbarContainer,
  Nav,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks,
  AvatarLayout,
  AvatarOverFlowMenu,
  UserInfoWrapper,
  UserInfo,
  horizontalBreak,
  OverFlowMenuItem,
  NavLinksDummy,
} from "./Navbar.elements";

import { NavbarButton, NavbarLogo } from "../index";
import useComponentVisible from "../../hooks/useComponentVisible";
import { Button } from "../navbar-button/NavbarButton.elements";

function Navbar({ navLocation, background, navTheme }) {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const [currentBackground, setCurrentBackground] = useState(background);
  const [currentNavTheme, setCurrentNavTheme] = useState(navTheme);

  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);
  const { isAuthenticated, user } = useContext(AuthContext);

  const history = useHistory();

  const handleClick = () => {
    setCurrentBackground("");
    setCurrentNavTheme("light");
    setClick(!click);
  };

  const closeMobileMenu = () => {
    setCurrentBackground(background);
    setCurrentNavTheme(navTheme);
    setClick(false);
  };

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  const handleLogout = () => {
    auth.signOut();
    history.push("/login");
  };

  const handleSignin = () => {
    history.push("/signup");
  };

  useEffect(() => {
    setCurrentBackground(background);
    setCurrentNavTheme(navTheme);
  }, [background]);

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener("resize", showButton);

  return (
    <Nav background={currentBackground}>
      <NavbarContainer>
        <NavbarLogo navTheme={currentNavTheme} />
        {isAuthenticated && !button && navLocation === "/dashboard" ? (
          <Avatar
            onClick={click ? closeMobileMenu : handleClick}
            style={{
              height: "35px",
              width: "35px",
              margin: "0px 8px",
              objectFit: "contain",
            }}
            className="avatar"
            src={user ? user.profilePhoto : ""}
          />
        ) : (
          <MobileIcon
            onClick={click ? closeMobileMenu : handleClick}
            navTheme={currentNavTheme}
          >
            {click ? <i class="bi bi-x"></i> : <i class="bi bi-list"></i>}
          </MobileIcon>
        )}

        <NavMenu
          onClick={handleClick}
          click={click}
          navTheme={currentNavTheme}
          background={currentBackground}
        >
          <NavItem>
            <NavLinks active={navLocation === "/"} to="/">
              Home
            </NavLinks>
          </NavItem>

          {isAuthenticated && (
            <NavItem>
              <NavLinks active={navLocation === "/dashboard"} to="/dashboard">
                Dashboard
              </NavLinks>
            </NavItem>
          )}

          <NavItem>
            <NavLinks active={navLocation === "/pricing"} to="/pricing">
              Pricing
            </NavLinks>
          </NavItem>
          <NavItem>
            <NavLinks active={navLocation === "/contact"} to="/contact">
              Contact Us
            </NavLinks>
          </NavItem>

          {!isAuthenticated && (
            <NavItem>
              <NavLinks active={navLocation === "/login"} to="/login">
                Login
              </NavLinks>
            </NavItem>
          )}

          {isAuthenticated && !button && (
            <NavItem>
              <NavLinks
                active={navLocation === "/dashboard/settings"}
                to="/dashboard/settings"
              >
                Settings
              </NavLinks>
            </NavItem>
          )}

          {isAuthenticated && !button && (
            <NavItem>
              <NavLinksDummy onClick={handleLogout}>Sign Out</NavLinksDummy>
            </NavItem>
          )}

          {!isAuthenticated && button && (
            <NavbarButton
              margin="0px 8px"
              title=" Get started—it’s free"
              handleButtonClick={handleSignin}
              navTheme={currentNavTheme}
            />
          )}

          {isAuthenticated && button && (
            <AvatarLayout
              ref={ref}
              onClick={() => setIsComponentVisible(!isComponentVisible)}
            >
              <Avatar
                style={{
                  height: "30px",
                  width: "30px",
                  margin: "0px 8px",
                  objectFit: "contain",
                }}
                className="avatar"
                src={user ? user.profilePhoto : ""}
              />
              {isComponentVisible && (
                <AvatarOverFlowMenu>
                  <UserInfoWrapper>
                    <img
                      src={user.profilePhoto ? user.profilePhoto : ""}
                      alt=""
                    />

                    <UserInfo>
                      <p>{user.name}</p>
                      <span>{user.email}</span>
                    </UserInfo>
                  </UserInfoWrapper>
                  <horizontalBreak />
                  <OverFlowMenuItem onClick={() => history.push("/dashboard")}>
                    <DashboardIcon
                      style={{ fontSize: "20px", color: "gray" }}
                    />
                    <p>Dashboard</p>
                  </OverFlowMenuItem>
                  <OverFlowMenuItem
                    onClick={() => history.push("/dashboard/settings")}
                  >
                    <SettingsIcon style={{ fontSize: "20px", color: "gray" }} />
                    <p>Settings</p>
                  </OverFlowMenuItem>
                  <OverFlowMenuItem
                    onClick={() => history.push("/dashboard/billing")}
                  >
                    <PaymentIcon style={{ fontSize: "20px", color: "gray" }} />
                    <p>Billing and Subscription</p>
                  </OverFlowMenuItem>

                  <OverFlowMenuItem onClick={handleLogout}>
                    <LogOutIcon style={{ fontSize: "24px", color: "gray" }} />
                    <p>Log Out</p>
                  </OverFlowMenuItem>
                </AvatarOverFlowMenu>
              )}
            </AvatarLayout>
          )}
        </NavMenu>
      </NavbarContainer>
    </Nav>
  );
}

export default Navbar;
