import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { BillingItem, Button, ErrorLayout } from "../../../components";
import { AuthContext } from "../../../contexts/AuthContext";
import { db } from "../../../firebase/firebase";
import makeid from "../../../utils/functions";
import { LoadingLayout, Title } from "../profile/Profile.elements";
import firebase from "firebase";
import {
  BillingContainer,
  Container,
  Content,
  EmptyLayout,
  ExpiryHeading,
  ExpiryMessage,
  RenewContainer,
  RenewProSvg,
  SvgWrapper,
} from "./Billing.elements";
import { moveUpVariants } from "../../../animations/animation";

function Billing({ setLocation }) {
  const location = useLocation();
  const history = useHistory();

  const { user } = useContext(AuthContext);

  const [annuallyPrice, setAnnuallyPrice] = useState("");
  const [proExpireText, setProExpireText] = useState("");
  const [remainingDays, setRemainingDays] = useState(0);
  const [upgradeProProgress, setUpgradeProProgress] = useState(true);
  const [error, setError] = useState("");

  const [isBillFetched, setIsBillFetched] = useState(false);
  const [billings, setBillings] = useState([]);

  const getProDetails = () => {
    db.collection("app")
      .doc("data")
      .collection("pro")
      .doc("planDetails")
      .get()
      .then((doc) => {
        const planDetails = doc.data();
        setAnnuallyPrice(planDetails.annualPrice);
        setUpgradeProProgress(false);
      });
  };

  const getBillings = () => {
    let billingsList;
    db.collection("users")
      .doc(user.userId)
      .collection("billings")
      .orderBy("dateOfProPurchase", "desc")
      .get()
      .then((snapshot) => {
        billingsList = snapshot.docs.map((doc) => doc.data());
        setBillings(billingsList);
        setIsBillFetched(true);
      })
      .catch((error) => {
        setIsBillFetched(true);
      });
  };

  const getProExpiryDate = () => {
    const todaysDate = new Date();

    if (user.dateOfProExpire !== null && user.isPro) {
      const dateOfProExpire = user.dateOfProExpire.toDate();
      const diffTime = Math.abs(todaysDate - dateOfProExpire);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      if (todaysDate <= dateOfProExpire) {
        setProExpireText(`Expires in ${diffDays} days`);
        setRemainingDays(diffDays);
      } else {
        setProExpireText("Expired");
      }
    } else {
      setProExpireText("Expired");
    }
  };

  const handleRenewButtonClick = () => {
    setError("");

    if (upgradeProProgress) {
      return;
    }

    setUpgradeProProgress(true);

    const options = {
      key: process.env.REACT_APP_RAZORPAY_API_KEY,
      currency: "INR",
      amount: annuallyPrice * 100,
      order_id: "",
      name: "GetMyProfiles.in",
      description: "Annual Pro Plan.",
      image:
        "https://firebasestorage.googleapis.com/v0/b/getmyprofiles-test.appspot.com/o/data%2FprofileImages%2Fgetmyprofileslogo.png?alt=media&token=4495298f-10c6-4f3b-a547-16baa9503dc0",
      handler: function (response) {
        savePaymentSuccessToDataBase(response);
      },
      prefill: {
        name: user.name,
        email: user.email,
        phone_number: "",
      },
      theme: {
        color: "#ff9900",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.on("payment.failed", function (response) {
      savePaymentFailureToDataBase(response);
      setUpgradeProProgress(false);
    });
    paymentObject.open();
  };

  const savePaymentSuccessToDataBase = (response) => {
    const BillingId = makeid();

    var YearlyExpiryDateValue = new Date();
    YearlyExpiryDateValue.setDate(
      YearlyExpiryDateValue.getDate() + 365 + remainingDays
    );

    db.collection("users")
      .doc(user.userId)
      .update({
        isPro: true,
        dateOfProPurchase: firebase.firestore.FieldValue.serverTimestamp(),
        dateOfProExpire: YearlyExpiryDateValue,
      })
      .then(() => {
        db.collection("users")
          .doc(user.userId)
          .collection("billings")
          .doc(BillingId)
          .set({
            billingId: BillingId,
            amount: annuallyPrice,
            plan: "Annual Plan",
            status: "Success",
            duration: 365 + remainingDays,
            userId: user.userId,
            paymentId: response.razorpay_payment_id,
            orderId: "",
            dateOfProPurchase: firebase.firestore.FieldValue.serverTimestamp(),
            dateOfProExpire: YearlyExpiryDateValue,
          })
          .then(() => {
            setUpgradeProProgress(false);
            history.go(0);
          })
          .catch((error) => {
            setError(error.message);
            setUpgradeProProgress(false);
          });
      })
      .catch((error) => {
        setError(error.message);
        setUpgradeProProgress(false);
      });
  };

  const savePaymentFailureToDataBase = (response) => {
    setUpgradeProProgress(false);
    setError("Oops! Something went wrong try again later.");
    const BillingId = makeid();

    var YearlyExpiryDateValue = new Date();
    YearlyExpiryDateValue.setDate(
      YearlyExpiryDateValue.getDate() + 365 + remainingDays
    );

    db.collection("users")
      .doc(user.userId)
      .collection("billings")
      .doc(BillingId)
      .set({
        billingId: BillingId,
        amount: annuallyPrice,
        plan: "Annual Plan",
        status: "Failure",
        duration: 365 + remainingDays,
        dateOfProPurchase: firebase.firestore.FieldValue.serverTimestamp(),
        dateOfProExpire: "-",
        userId: user.userId,
        paymentId: response.error.metadata.payment_id,
        orderId: "",
        errorCode: response.error.code,
        errorDescription: response.error.description,
        errorReason: response.error.reason,
      })
      .then(() => {
        setUpgradeProProgress(false);
      })
      .catch((error) => {
        setUpgradeProProgress(false);
        setError(error.message);
      });
  };

  useEffect(() => {
    getBillings();
    getProExpiryDate();
    getProDetails();
    setLocation(location.pathname);
  }, []);

  return (
    <Container
      variants={moveUpVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <Content>
        <Title>Renew Pro Membership</Title>
        <RenewContainer>
          <SvgWrapper>
            <RenewProSvg />
          </SvgWrapper>
          <ExpiryHeading>
            Your pro membership <span id="expiryDays">{proExpireText}</span>
          </ExpiryHeading>
          <ExpiryMessage>
            Hi {user.name}! Your pro membership{" "}
            <span id="expiryDays">{proExpireText}</span>. Renew your membership
            for un-interrupted experience.
          </ExpiryMessage>
          {error && (
            <ErrorLayout margin="16px 0px 0px 0px" errorMessage={error} />
          )}
          <Button
            variant="pro"
            margin="16px 0px 24px 0px"
            title={`Renew Pro @ ${annuallyPrice}`}
            progress={upgradeProProgress}
            handleClick={handleRenewButtonClick}
          />
        </RenewContainer>

        {false && (
          <LoadingLayout>
            {" "}
            <span id="loader">
              <i class="fas fa-circle-notch"></i>
            </span>
            <p>Loading</p>
          </LoadingLayout>
        )}

        <Title>Billing History</Title>

        {!isBillFetched && (
          <LoadingLayout>
            <span id="loader">
              <i class="fas fa-circle-notch"></i>
            </span>
            <p>Loading</p>
          </LoadingLayout>
        )}

        {isBillFetched && billings.length != 0 && (
          <BillingContainer>
            {billings.map((billing) => (
              <BillingItem key={billing.billingId} billing={billing} />
            ))}
          </BillingContainer>
        )}

        {isBillFetched && billings.length === 0 && (
          <EmptyLayout>
            <p>
              You have not bought any Pro membership earlier.
              <a>Buy Pro</a>
            </p>
          </EmptyLayout>
        )}
      </Content>
    </Container>
  );
}

export default Billing;
