import styled, { keyframes } from "styled-components";
import { motion } from "framer-motion";
//Animations

const rotate = keyframes`
  from {
    transform: rotate(0deg);
    transform-origin: center;
  }
  to {
    transform: rotate(360deg);
    transform-origin: center;
  }
`;

export const Container = styled(motion.div)`
  width: 100%;
  background-color: #f1f5f8;
`;

export const Content = styled(motion.div)`
  position: relative;
  width: 100%;
`;

export const ProfileContentWrapper = styled.div`
  position: relative;
  padding: 16px 0px 125px 0px;
  background-color: white;
  border-radius: 24px 24px 0px 0px;
`;

export const TopActionBarContainer = styled(motion.div)`
  display: flex;
  width: 100%;
  overflow-x: scroll;
  background: #f1f5f8;

  ::-webkit-scrollbar {
    display: none;
  }
`;



export const TopActionBarItem = styled(motion.div)`
  margin: 8px;
  padding: 8px;
  text-align: center;
  width: 80px;
  color: ${({ active }) => (active ? "#ee5253" : "#172126")};

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: ${({ active }) =>
      active ? "1px solid #ee5253" : "1px solid #172126"};
    height: 50px;
    width: 48px;
    font-size: 22px;
    text-align: center;
    margin: 0 auto;
    color: ${({ active }) => (active ? "#ee5253" : "#172126")};
  }

  p {
    margin-top: 8px;
    font-size: 14px;
  }
`;

export const Title = styled.h3`
  margin-left: 16px;
  margin-bottom: 16px;
  text-transform: capitalize;
  letter-spacing: 1.1px;
  color: #172126;
`;


export const ProfilesWrapper = styled(motion.div)`
  margin: 0px 8px 16px 8px;
  display: flex;
  flex-direction: column;
`;

export const CustomLinksWrapper = styled(motion.div)`
  margin: 0px 8px 16px 8px;
  display: flex;
  flex-direction: column;
`;

export const VideosWrapper = styled(motion.div)`
  margin: 0px 8px 16px 8px;
  display: flex;
  flex-direction: column;
`;

export const ImagesWrapper = styled(motion.div)`
  margin: 0px 8px 16px 8px;
  display: flex;
  flex-direction: column;
`;

export const CustomLinksButtonContainer = styled(motion.div)`
  margin: 0px 16px 16px 16px;
`;

export const EmptyLayout = styled(motion.div)`
  display: flex;
  border: ${({ theme }) => theme.border};
  border-radius: 4px;
  min-height: 200px;
  margin: 0px 16px 16px 16px;
  padding: 16px;
  align-items: center;
  justify-content: center;

  p {
    text-align: center;
    letter-spacing: 1.2px;
  }

  a {
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.primary};
    font-weight: 500;
    cursor: pointer;
    margin-left: 4px;
  }
`;

export const LoadingLayout = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0px 16px 16px 16px;
  border-radius: 4px;
  height: 200px;
  border: ${({ theme }) => theme.border};

  & > span {
    height: 36px;
    width: 34px;
    font-size: 34px;
    color: #ee5253;
    animation: ${rotate} 0.8s linear infinite;
  }

  & > p {
    margin-top: 8px;
    text-align: center;
    color: #676b6f;
  }
`;
