import styled, { keyframes } from "styled-components";
import { motion } from "framer-motion";
const rotate = keyframes`
  from {
    transform: rotate(0deg);
    transform-origin: center;
  }
  to {
    transform: rotate(360deg);
    transform-origin: center;
  }
`;

export const ImageLayout = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40px;
  min-width: 40px;
  margin-right: 8px;
  background-color: lightgray;
  border-radius: 4px;
  position: relative;

  img {
    height: 40px;
    width: 40px;
    border-radius: 4px;
    object-fit: cover;
  }

  label {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    border-radius: 4px;
    background-color: lightgray;
    cursor: pointer;
    img {
      height: 40px;
      width: 40px;
      border-radius: 4px;
    }
  }

  #loader {
    height: 24px;
    width: 24px;
    font-size: 24px;
    position: absolute;
    color: white;
    animation: ${rotate} 0.8s linear infinite;
  }

  #progressText {
    position: absolute;
    font-size: 10px;
    color: white;
  }
`;

export const LinkInformation = styled(motion.div)`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  justify-content: center;
`;

export const TitleInputWrapper = styled(motion.div)`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > input {
    min-width: 0;
    min-height: 0;
    width: 100%;
    outline: none;
    border: none;
    font-family: ${({ theme }) => theme.fontFamily};
    font-weight: 500;
    padding: 0px;
    font-size: 16px;
    ::placeholder,
    ::-webkit-input-placeholder {
      color: #b3b3b3;
    }
    :-ms-input-placeholder {
      color: red;
    }
  }

  & > span {
    color: ${({ theme }) => theme.colors.iconLight};
    font-size: 14px;
  }
`;
