import React, { useState, useContext, useEffect } from "react";
import {
  Container,
  Content,
  Heading,
  Section,
  SubHeading,
  PlanSwicth,
  ToggleSwitch,
  PriceCard,
  PlanName,
  PlanPrice,
  Message,
  Features,
  FeaturesItem,
  PlanButton,
  Monthly,
  Annually,
  PlanFullPrice,
  Discount,
  ActivePlanLayout,
  SuccessCard,
  SvgWrapper,
  SuccessSvg,
  SuccessHeading,
  SuccessMessage,
  DashboardButton,
} from "./Pricing.elements";

import { ErrorLayout, Footer, Navbar } from "../../components/index";
import { AuthContext } from "../../contexts/AuthContext";

import { whileHover, whileTap } from "../../animations/animation";
import { db } from "../../firebase/firebase";
import makeid from "../../utils/functions";
import firebase from "firebase";
import { ErrorBoundary } from "react-error-boundary";
import ErrorPage from "../../components/error-page/ErrorPage";
import { useHistory } from "react-router-dom";

function Pricing() {
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, []);

  const [userId, setUserId] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [isPro, setIsPro] = useState("");

  const [isAnnually, setIsAnnually] = useState(true);
  const [monthlyPrice, setMonthlyPrice] = useState("");
  const [annuallyPrice, setAnnuallyPrice] = useState("");
  const [fullPrice, setFullPrice] = useState("");
  const [discount, setDiscount] = useState("");

  const [proExpireText, setProExpireText] = useState("");
  const [remainingDays, setRemainingDays] = useState(0);

  const { isAuthenticated, user } = useContext(AuthContext);

  const [isDataFetched, setIsDataFetched] = useState(false);

  const [showPaymentSuccessLayout, setShowPaymentSuccessLayout] =
    useState(false);
  const [isPaymentProcessing, setIsPaymentProcessing] = useState(false);
  const [paymentError, setPaymentError] = useState("");

  const onTryForFreeButtonClick = (e) => {
    if (isAuthenticated) {
      history.push("/dashboard");
    } else {
      history.push("/login");
    }
  };

  const getProDetails = () => {
    db.collection("app")
      .doc("data")
      .collection("pro")
      .doc("planDetails")
      .get()
      .then((doc) => {
        const planDetails = doc.data();
        setAnnuallyPrice(planDetails.annualPrice);
        setMonthlyPrice(planDetails.monthlyPrice);
        setDiscount(planDetails.annualDiscount);
        setFullPrice(planDetails.fullPrice);
        setIsDataFetched(true);
      });
  };

  const getProExpiryDate = () => {
    const todaysDate = new Date();

    if (user.dateOfProExpire !== null && user.isPro) {
      const dateOfProExpire = user.dateOfProExpire.toDate();
      const diffTime = Math.abs(todaysDate - dateOfProExpire);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      if (todaysDate <= dateOfProExpire) {
        setProExpireText(`Expires in ${diffDays} days`);
        setRemainingDays(diffDays);
      } else {
        setProExpireText("Expired");
      }
    } else {
      setProExpireText("Expired");
    }
  };

  const handleBuyProPlan = async () => {
    if (isPaymentProcessing) {
      return;
    }

    if (!isAuthenticated) {
      history.push("/login");
      return;
    }

    if (!userId || !isDataFetched) {
      setPaymentError("Oops! Something went wrong.Try again later.");
      return;
    }

    setIsPaymentProcessing(true);
    setPaymentError("");

    const options = {
      key: process.env.REACT_APP_RAZORPAY_API_KEY,
      currency: "INR",
      amount: isAnnually ? annuallyPrice * 100 : monthlyPrice * 100,
      order_id: "",
      name: "GetMyProfiles.in",
      description: isAnnually ? "Annual Pro Plan." : "Monthly Pro Plan.",
      image:
        "https://firebasestorage.googleapis.com/v0/b/getmyprofiles-test.appspot.com/o/data%2FprofileImages%2Fgetmyprofileslogo.png?alt=media&token=4495298f-10c6-4f3b-a547-16baa9503dc0",
      handler: function (response) {
        savePaymentSuccessToDataBase(response);
      },
      prefill: {
        name,
        email,
        phone_number: "",
      },
      theme: {
        color: "#ff9900",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.on("payment.failed", function (response) {
      savePaymentFailureToDataBase(response);
      setIsPaymentProcessing(false);
    });
    paymentObject.open();
  };

  const savePaymentSuccessToDataBase = (response) => {
    const BillingId = makeid();

    var MonthlyExpiryDateValue = new Date();
    MonthlyExpiryDateValue.setDate(
      MonthlyExpiryDateValue.getDate() + 30 + remainingDays
    );

    var YearlyExpiryDateValue = new Date();
    YearlyExpiryDateValue.setDate(
      YearlyExpiryDateValue.getDate() + 365 + remainingDays
    );

    db.collection("users")
      .doc(userId)
      .update({
        isPro: true,
        dateOfProPurchase: firebase.firestore.FieldValue.serverTimestamp(),
        dateOfProExpire: isAnnually
          ? YearlyExpiryDateValue
          : MonthlyExpiryDateValue,
        daysOfProLeft: isAnnually
          ? firebase.firestore.FieldValue.increment(365)
          : firebase.firestore.FieldValue.increment(30),
      })
      .then(() => {
        db.collection("users")
          .doc(userId)
          .collection("billings")
          .doc(BillingId)
          .set({
            billingId: BillingId,
            amount: isAnnually ? annuallyPrice : monthlyPrice,
            plan: isAnnually ? "Annual Plan" : "Monthly Plan",
            status: "Success",
            userId: userId,
            paymentId: response.razorpay_payment_id,
            orderId: "",
            dateOfProPurchase: firebase.firestore.FieldValue.serverTimestamp(),
            dateOfProExpire: isAnnually
              ? YearlyExpiryDateValue
              : MonthlyExpiryDateValue,
          })
          .then(() => {
            setIsPaymentProcessing(false);
            setShowPaymentSuccessLayout(true);
          })
          .catch((error) => {
            setPaymentError(error.message);
            setIsPaymentProcessing(false);
          });
      })
      .catch((error) => {
        setPaymentError(error.message);
        setIsPaymentProcessing(false);
      });
  };

  const savePaymentFailureToDataBase = (response) => {
    setIsPaymentProcessing(false);
    setPaymentError("Oops! Something went wrong try again later.");

    const BillingId = makeid();

    var MonthlyExpiryDateValue = new Date();
    MonthlyExpiryDateValue.setDate(
      MonthlyExpiryDateValue.getDate() + 30 + remainingDays
    );

    var YearlyExpiryDateValue = new Date();
    YearlyExpiryDateValue.setDate(
      YearlyExpiryDateValue.getDate() + 365 + remainingDays
    );

    db.collection("users")
      .doc(userId)
      .collection("billings")
      .doc(BillingId)
      .set({
        billingId: BillingId,
        amount: isAnnually ? annuallyPrice : monthlyPrice,
        plan: isAnnually ? "Annual Plan" : "Monthly Plan",
        status: "Failure",
        dateOfProPurchase: firebase.firestore.FieldValue.serverTimestamp(),
        dateOfProExpire: "-",
        userId: userId,
        paymentId: response.error.metadata.payment_id,
        orderId: "",
        errorCode: response.error.code,
        errorDescription: response.error.description,
        errorReason: response.error.reason,
      })
      .then(() => {
        setIsPaymentProcessing(false);
        setPaymentError("Oops Something went wrong! Try again later.");
      })
      .catch((error) => {
        setPaymentError("Oops Something went wrong! Try again later.");
        setIsPaymentProcessing(false);
      });
  };

  const handleRenewButtonClick = () => {
    if (isPaymentProcessing) {
      return;
    }

    if (!userId || !isDataFetched) {
      setPaymentError("Oops! Something went wrong.Try again later.");
      return;
    }

    setIsPaymentProcessing(true);
    setPaymentError("");

    const options = {
      key: process.env.REACT_APP_RAZORPAY_API_KEY,
      currency: "INR",
      amount: isAnnually ? annuallyPrice * 100 : monthlyPrice * 100,
      order_id: "",
      name: "GetMyProfiles.in",
      description: isAnnually ? "Annual Pro Plan." : "Monthly Pro Plan.",
      image:
        "https://firebasestorage.googleapis.com/v0/b/getmyprofiles-test.appspot.com/o/data%2FprofileImages%2Fgetmyprofileslogo.png?alt=media&token=4495298f-10c6-4f3b-a547-16baa9503dc0",
      handler: function (response) {
        savePaymentSuccessToDataBase(response);
      },
      prefill: {
        name,
        email,
        phone_number: "",
      },
      theme: {
        color: "#ff9900",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.on("payment.failed", function (response) {
      savePaymentFailureToDataBase(response);
      setIsPaymentProcessing(false);
    });
    paymentObject.open();
  };

  const handleDashboardClick = () => {
    history.push("/dashboard");
    history.go(0);
  };

  useEffect(() => {
    getProDetails();
  }, []);

  useEffect(() => {
    if (user) {
      setName(user.name);
      setEmail(user.email);
      setIsPro(user.isPro);
      setUserId(user.userId);
      getProExpiryDate();
    }
  }, [user]);

  useEffect(() => {
    document.title = "Pricing" + " | " + process.env.REACT_APP_TITLE;
  }, []);

  return (
    <ErrorBoundary FallbackComponent={ErrorPage}>
      <Container>
        <Navbar navLocation="/pricing" />
        <Content>
          <Heading>Join Our Pro Community</Heading>
          <SubHeading>Pick your plan</SubHeading>
          <PlanSwicth>
            <Monthly active={!isAnnually}>Monthly</Monthly>
            <ToggleSwitch
              checked={isAnnually}
              onChange={() => setIsAnnually(!isAnnually)}
            />
            <Annually active={isAnnually}>
              Annually <span>{discount} off</span>
            </Annually>
          </PlanSwicth>
          <Section>
            <PriceCard whileHover={whileHover} plan="free">
              <PlanName>Standard</PlanName>
              <PlanPrice>
                Free <span>/Forever</span>
              </PlanPrice>
              <Message>
                Standard Free Plan For All <br />
              </Message>

              <Features>
                <FeaturesItem>
                  <span>
                    {" "}
                    <i class="fas fa-check" />
                  </span>
                  Upto 5 Profiles
                </FeaturesItem>
                <FeaturesItem>
                  {" "}
                  <span>
                    {" "}
                    <i class="fas fa-check" />
                  </span>
                  Upto 3 Links
                </FeaturesItem>
                <FeaturesItem>
                  <span>
                    {" "}
                    <i class="fas fa-check" />
                  </span>
                  Upto One Image
                </FeaturesItem>
                <FeaturesItem>
                  <span>
                    {" "}
                    <i class="fas fa-check" />
                  </span>
                  Upto One Video
                </FeaturesItem>
                <FeaturesItem>
                  {" "}
                  <span>
                    {" "}
                    <i class="fas fa-check" />
                  </span>
                  Limited Analytics
                </FeaturesItem>
                <FeaturesItem>
                  {" "}
                  <span>
                    {" "}
                    <i class="fas fa-check" />
                  </span>
                  Limited Themes
                </FeaturesItem>

                <FeaturesItem>
                  {" "}
                  <span> </span>
                </FeaturesItem>
              </Features>
              {!isAuthenticated && (
                <PlanButton
                  whileTap={whileTap}
                  onClick={onTryForFreeButtonClick}
                >
                  Try For Free
                </PlanButton>
              )}
              {isAuthenticated && !isPro && (
                <ActivePlanLayout whileTap={whileTap}>
                  Current Active Plan
                </ActivePlanLayout>
              )}
            </PriceCard>

            {!isDataFetched && !showPaymentSuccessLayout && (
              <PriceCard pro>
                <span id="loader">
                  <i class="fas fa-circle-notch"></i>
                </span>
              </PriceCard>
            )}
            {isDataFetched && !showPaymentSuccessLayout && (
              <PriceCard whileHover={whileHover} plan="pro" pro>
                <PlanName pro>Pro</PlanName>

                <PlanPrice pro>
                  <i id="rupeeSymbol" class="fas fa-rupee-sign"></i>{" "}
                  {isAnnually ? `${annuallyPrice}` : `${monthlyPrice}`}
                  {isAnnually && <PlanFullPrice>{fullPrice}</PlanFullPrice>}
                  <span>{isAnnually ? "/year" : "/month"}</span>
                  {isAnnually && <Discount>{discount} off</Discount>}
                </PlanPrice>

                <Message>
                  Pro plan for Influencers, Creators, Business and{" "}
                  <span id="special">YOU 💛</span>
                </Message>
                <Features pro>
                  <FeaturesItem>
                    {" "}
                    <span>
                      {" "}
                      <i class="fas fa-check" />
                    </span>
                    Unlimited Profiles
                  </FeaturesItem>
                  <FeaturesItem>
                    {" "}
                    <span>
                      {" "}
                      <i class="fas fa-check" />
                    </span>
                    Unlimited Links
                  </FeaturesItem>
                  <FeaturesItem>
                    <span>
                      {" "}
                      <i class="fas fa-check" />
                    </span>
                    Unlimited Images
                  </FeaturesItem>
                  <FeaturesItem>
                    <span>
                      {" "}
                      <i class="fas fa-check" />
                    </span>
                    Unlimited Videos
                  </FeaturesItem>
                  <FeaturesItem>
                    {" "}
                    <span>
                      {" "}
                      <i class="fas fa-check" />
                    </span>
                    Pro Analytics
                  </FeaturesItem>
                  <FeaturesItem>
                    {" "}
                    <span>
                      {" "}
                      <i class="fas fa-check" />
                    </span>
                    Pro Themes
                  </FeaturesItem>
                  <FeaturesItem>
                    {" "}
                    <span>
                      {" "}
                      <i class="fas fa-check" />
                    </span>
                    And Everything
                  </FeaturesItem>
                </Features>
                {paymentError && (
                  <ErrorLayout
                    margin="16px 0px -8px 0px"
                    errorMessage={paymentError}
                  />
                )}

                {!isPro && isDataFetched && (
                  <PlanButton
                    onClick={handleBuyProPlan}
                    whileTap={whileTap}
                    pro
                  >
                    {!isPaymentProcessing ? (
                      <p>
                        Join Pro Now @
                        {isAnnually ? `${annuallyPrice}` : `${monthlyPrice}`}/
                        {isAnnually ? "year" : "month"}
                      </p>
                    ) : (
                      <span id="loader">
                        <i class="fas fa-circle-notch"></i>
                      </span>
                    )}
                  </PlanButton>
                )}

                {isPro && (
                  <ActivePlanLayout pro>
                    Wohoooo! your Pro membership is currently active and will
                    expire in <span id="expire">{proExpireText}</span>
                    <PlanButton
                      onClick={handleRenewButtonClick}
                      whileTap={whileTap}
                      pro
                    >
                      Renew Pro @{" "}
                      {isAnnually ? `${annuallyPrice}` : `${monthlyPrice}`}/
                      {isAnnually ? "year" : "month"}
                    </PlanButton>
                  </ActivePlanLayout>
                )}
              </PriceCard>
            )}

            {showPaymentSuccessLayout && (
              <SuccessCard>
                <SvgWrapper>
                  <SuccessSvg />
                </SvgWrapper>

                <SuccessHeading>Payment Successfull ✅</SuccessHeading>

                <SuccessMessage>
                  {" "}
                  Hey {name} welcome to our <span id="pro"> PRO</span> family.
                  Now you can enjoy all our <span id="pro"> PRO</span> benefits.
                </SuccessMessage>

                <DashboardButton
                  whileTap={whileTap}
                  onClick={handleDashboardClick}
                >
                  Go to Dashboard
                </DashboardButton>
              </SuccessCard>
            )}
          </Section>
        </Content>
        <Footer />
      </Container>
    </ErrorBoundary>
  );
}

export default Pricing;
