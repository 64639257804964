import React from "react";

import {
  ActionLayoutContainer,
  ActionLayoutHeader,
  HorizontalBreak,
} from "../../themes/globalStyledComponents";

import { AnimationWrapper } from "./AnimationItem.elements";

function AnimationItem({
  handleCancelClick,
  linkAnimation,
  handleAddAnimation,
}) {
  const animationVariants = ["buzz", "flash", "pulse", "wobble"];

  return (
    <ActionLayoutContainer>
      <ActionLayoutHeader>
        <h6>Animations</h6>
        <span onClick={handleCancelClick}>
          <i class="bi bi-x-lg"></i>
        </span>
      </ActionLayoutHeader>

      {animationVariants.map((animation, index) => (
        <>
          <AnimationWrapper
            key={animation}
            active={animation === linkAnimation}
            onClick={() => handleAddAnimation(animation)}
          >
            <p>{animation}</p>
          </AnimationWrapper>
          {index !== animationVariants.length - 1 && <HorizontalBreak />}
        </>
      ))}
    </ActionLayoutContainer>
  );
}

export default AnimationItem;
