import React from "react";
import { GoogleButtonLayout, Container } from "./GoogleButton.elements";
import GoogleLogo from "../../images/google-logo.png";
import { whileTap } from "../../animations/animation";

function GoogleButton({ marginTop, progress, handleClick }) {
  return (
    <Container whileTap={whileTap} marginTop={marginTop} onClick={handleClick}>
      {!progress ? (
        <GoogleButtonLayout>
          <img src={GoogleLogo} alt="logo" />
          Sign in with Google
        </GoogleButtonLayout>
      ) : (
        <span id="loader">
          <i class="fas fa-circle-notch"></i>
        </span>
      )}
    </Container>
  );
}

export default GoogleButton;
