import React, { useState, useEffect } from "react";
import validator from "validator";
import {
  AnimationItem,
  DeepLinkItem,
  DeleteItem,
  ResponsivePlayer,
  VisitItem,
} from "..";
import { moveRightVariants } from "../../animations/animation";
import { db } from "../../firebase/firebase";
import { openURL } from "../../utils/functions";

import {
  MainContainer,
  Container,
  DragIconWrapper,
  ContentWrapper,
  SwitchWrapper,
  ActiveSwitch,
  ActionLayoutWrapper,
  ActionIconWrapper,
  InputWrapper,
  ActionProceedLayout,
} from "../profile-item/ProfileItem.elements";
import {
  Content,
  TitleInputWrapper,
  VideoInformation,
  VideoInformationWrapper,
} from "./VideoItem.elements";

function VideoItem({
  video,
  user,
  setShowProgressLayout,
  progressTimeout,
  setDeepLink,
  deepLinkId,
  setDeepLinkId,
}) {
  const [title, setTitle] = useState(video.title);
  const [titleError, setTitleError] = useState("");

  const [videoId, setVideoId] = useState(video.videoId);
  const [videoURL, setVideoURL] = useState(video.videoURL);
  const [finalVideoURL, setFinalVideoURL] = useState(video.videoURL);
  const [videoURLError, setVideoURLError] = useState("");

  const [isActive, setIsActive] = useState(video.isActive);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");
  const [activeError, setActiveError] = useState("");
  const [isTitleChanged, setIsTitleChanged] = useState(false);
  const [isVideoURLChanged, setIsVideoURLChanged] = useState(false);

  const [deepLinkProgress, setDeeplinkProgress] = useState(false);
  const [deleteVideoProgress, setDeleteVideoProgress] = useState(false);

  const [showTitleEditIcon, setShowTitleEditIcon] = useState(true);
  const [showURLEditIcon, setShowURLEditIcon] = useState(true);

  const [showDeleteLayout, setShowDeleteLayout] = useState(false);

  const [showAnimationLayout, setShowAnimationLayout] = useState(false);

  const [videoAnimation, setVideoAnimation] = useState(video.animation);

  const [showDeepLinkLayout, setShowDeepLinkLayout] = useState(false);
  const [showVisitLayout, setShowVisitLayout] = useState(false);

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
    setIsTitleChanged(true);
    setTitleError("");
  };

  const updateNewTitle = () => {
    setShowTitleEditIcon(true);
    if (titleError || !isTitleChanged) {
      return;
    }

    setShowProgressLayout(true);

    db.collection("users")
      .doc(user.userId)
      .collection("videos")
      .doc(video.videoId)
      .update({
        title: title,
      })
      .then(() => {
        setSuccess(true);
        setShowProgressLayout(false);
        setIsTitleChanged(false);
        handleCancelClick();
        setTimeout(() => {
          setSuccess(false);
        }, progressTimeout);
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  const handleVideoURLChange = (e) => {
    setVideoURL(e.target.value);
    setIsVideoURLChanged(true);

    if (e.target.value === "") {
      setVideoURLError("Video url cannot be empty");
      return;
    }
    setVideoURLError("");
  };

  const updateNewVideoURL = () => {
    setShowURLEditIcon(true);
    if (videoURLError || !isVideoURLChanged) {
      return;
    }

    if (!validator.isURL(videoURL)) {
      setVideoURLError("Invalid Video url");
      return;
    }

    db.collection("users")
      .doc(user.userId)
      .collection("videos")
      .doc(video.videoId)
      .update({
        videoURL: videoURL,
      })
      .then(() => {
        setIsVideoURLChanged(false);
        if (deepLinkId === videoId) {
          handleAddDeepLink();
        } else {
          setSuccess(true);
          setShowProgressLayout(true);
          handleCancelClick();
          setTimeout(() => {
            setSuccess(false);
            setShowProgressLayout(false);
          }, progressTimeout);
        }
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  useEffect(() => {
    const delayRequestFunction = setTimeout(() => {
      if (!/^https?:\/\//i.test(videoURL) && !/^http?:\/\//i.test(videoURL)) {
        setFinalVideoURL("https://" + videoURL);
      } else {
        setFinalVideoURL(videoURL);
      }
    }, 1000);
    return () => {
      clearTimeout(delayRequestFunction);
    };
  }, [videoURL]);

  const handleActiveStatusChange = (e) => {
    e.preventDefault();
    setIsActive(e.target.checked);
    setActiveError("");
    setShowProgressLayout(true);

    db.collection("users")
      .doc(user.userId)
      .collection("videos")
      .doc(video.videoId)
      .update({
        isActive: e.target.checked,
      })
      .then(() => {
        setSuccess(true);
        setShowProgressLayout(false);
        setTimeout(() => {
          setSuccess(false);
        }, progressTimeout);
      })
      .catch((error) => {
        setActiveError(error.message);
      });
  };

  const onDeleteIconClick = () => {
    setShowDeleteLayout(!showDeleteLayout);
    setShowVisitLayout(false);
    setShowAnimationLayout(false);
    setShowDeepLinkLayout(false);
    blurInputs();
  };

  const handleDeleteVideo = () => {
    setError(false);
    setDeleteVideoProgress(true);
    setShowProgressLayout(true);
    db.collection("users")
      .doc(user.userId)
      .collection("videos")
      .doc(video.videoId)
      .delete()
      .then(() => {
        if (deepLinkId === videoId) {
          handleRemoveDeepLink();
        }
        setShowProgressLayout(false);
        handleCancelClick();
        setDeleteVideoProgress(false);
      })
      .catch((error) => {
        setDeleteVideoProgress(false);
        setError(true);
        handleCancelClick();
      });
  };

  const onAnimationIconClick = () => {
    setShowAnimationLayout(!showAnimationLayout);
    setShowDeleteLayout(false);
    setShowVisitLayout(false);
    setShowDeepLinkLayout(false);
    blurInputs();
  };

  const handleAddAnimation = (animation) => {
    setShowProgressLayout(true);
    if (animation !== videoAnimation) {
      setVideoAnimation(animation);
      handleCancelClick();
      db.collection("users")
        .doc(user.userId)
        .collection("videos")
        .doc(video.videoId)
        .update({
          animation: animation,
        })
        .then(() => {
          setSuccess(true);
          setShowProgressLayout(false);

          setTimeout(() => {
            setSuccess(false);
          }, progressTimeout);
        })
        .catch((error) => {
          setError(error.message);
        });
    } else {
      setVideoAnimation("");
      handleCancelClick();
      db.collection("users")
        .doc(user.userId)
        .collection("videos")
        .doc(video.videoId)
        .update({
          animation: "",
        })
        .then(() => {
          setSuccess(true);
          setShowProgressLayout(false);
          setTimeout(() => {
            setSuccess(false);
          }, progressTimeout);
        })
        .catch((error) => {
          setError(error.message);
        });
    }
  };

  const onDeepLinkIconClick = () => {
    setShowDeepLinkLayout(!showDeepLinkLayout);
    setShowVisitLayout(false);
    setShowDeleteLayout(false);
    setShowAnimationLayout(false);
    blurInputs();
  };

  const handleAddDeepLink = () => {
    setDeeplinkProgress(true);
    setShowProgressLayout(true);
    db.collection("users")
      .doc(user.userId)
      .update({
        "deepLink.link": videoURL,
        "deepLink.type": "video",
        "deepLink.id": video.videoId,
      })
      .then(() => {
        setSuccess(true);
        handleCancelClick();
        setShowProgressLayout(false);
        setDeepLink(videoURL);
        setDeepLinkId(videoId);
        setTimeout(() => {
          setSuccess(false);
          setDeeplinkProgress(false);
          handleCancelClick();
        }, progressTimeout);
      })
      .catch((error) => {
        setError(error.message);
        setDeeplinkProgress(false);
      });
  };

  const handleRemoveDeepLink = () => {
    setDeeplinkProgress(true);
    setShowProgressLayout(true);
    db.collection("users")
      .doc(user.userId)
      .update({
        "deepLink.link": "",
        "deepLink.type": "",
        "deepLink.id": "",
      })
      .then(() => {
        setSuccess(true);
        setShowProgressLayout(false); 
        setIsVideoURLChanged(false);
        setDeepLink("");
        setDeepLinkId("");
        handleCancelClick();
        setTimeout(() => {
          setSuccess(false);

          setDeeplinkProgress(false);
        }, progressTimeout);
      })
      .catch((error) => {
        setError(error.message);
        setDeeplinkProgress(false);
      });
  };

  const onVisitIconClick = () => {
    setShowVisitLayout(!showVisitLayout);
    setShowDeepLinkLayout(false);
    setShowDeleteLayout(false);
    setShowAnimationLayout(false);

    blurInputs();
  };

  const handleVisitLink = () => {
    if (videoURL === "") {
      setVideoURLError("Invalid Video URL");
      document.getElementById(`${video.videoId}URL`).focus();
      return;
    }
    openURL(videoURL);
  };

  const handleCancelClick = () => {
    setShowDeleteLayout(false);
    setShowAnimationLayout(false);
    setShowDeepLinkLayout(false);
    setShowVisitLayout(false);
  };

  const blurInputs = () => {
    document.getElementById(`${video.videoId}title`).blur();
    document.getElementById(`${video.videoId}URL`).blur();
  };

  return (
    <MainContainer
      success={success}
      error={titleError || videoURLError || error || activeError}
      variants={moveRightVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <Container
        success={success}
        error={titleError || videoURLError || error || activeError}
      >
        <DragIconWrapper>
          <i class="bi bi-grip-vertical"></i>
        </DragIconWrapper>
        <ContentWrapper>
          <Content>
            <ResponsivePlayer
              margin="0px 0px 16px 0px"
              videoURL={finalVideoURL}
            />

            <VideoInformationWrapper>
              <VideoInformation>
                <TitleInputWrapper>
                  <input
                    id={`${video.videoId}title`}
                    value={title}
                    onChange={handleTitleChange}
                    type="text"
                    placeholder="Enter title here"
                    onBlur={updateNewTitle}
                    spellCheck="false"
                    onFocus={() => setShowTitleEditIcon(false)}
                  />
                  {showTitleEditIcon && (
                    <span>
                      <i class="bi bi-pencil"></i>
                    </span>
                  )}
                </TitleInputWrapper>

                <InputWrapper>
                  <input
                    id={`${video.videoId}URL`}
                    value={videoURL}
                    onChange={handleVideoURLChange}
                    type="text"
                    placeholder="Paste your video url here"
                    onBlur={updateNewVideoURL}
                    spellCheck="false"
                    onFocus={() => setShowURLEditIcon(false)}
                  />
                  {showURLEditIcon && (
                    <span>
                      <i class="bi bi-pencil"></i>
                    </span>
                  )}
                </InputWrapper>
              </VideoInformation>
              <SwitchWrapper>
                <ActiveSwitch
                  checked={isActive}
                  onChange={handleActiveStatusChange}
                />
              </SwitchWrapper>
            </VideoInformationWrapper>
          </Content>
          <ActionLayoutWrapper onClick={blurInputs}>
            <ActionIconWrapper
              active={showVisitLayout}
              onClick={onVisitIconClick}
              type="visit"
            >
              <i class="bi bi-box-arrow-up-right"></i>
            </ActionIconWrapper>

            <ActionIconWrapper
              active={showDeepLinkLayout || videoId === deepLinkId}
              onClick={onDeepLinkIconClick}
              type="deepLink"
            >
              <i class="bi bi-lightning"></i>
            </ActionIconWrapper>

            <ActionIconWrapper
              onClick={onAnimationIconClick}
              active={showAnimationLayout || videoAnimation !== ""}
              type="animation"
            >
              <i class="bi bi-bounding-box-circles"></i>
            </ActionIconWrapper>

            <ActionIconWrapper
              type="delete"
              active={showDeleteLayout}
              onClick={onDeleteIconClick}
            >
              <i class="bi bi-trash2"></i>
            </ActionIconWrapper>
          </ActionLayoutWrapper>
        </ContentWrapper>
      </Container>
      {showDeleteLayout && (
        <ActionProceedLayout
          success={success}
          error={titleError || videoURLError || error || activeError}
        >
          <DeleteItem
            type={"Video"}
            progress={deleteVideoProgress}
            handleCancelClick={handleCancelClick}
            handleDeleteClick={handleDeleteVideo}
          />
        </ActionProceedLayout>
      )}

      {showAnimationLayout && (
        <ActionProceedLayout
          success={success}
          error={titleError || videoURLError || error || activeError}
        >
          <AnimationItem
            type={"video"}
            handleCancelClick={handleCancelClick}
            linkAnimation={videoAnimation}
            setLinkAnimation={setVideoAnimation}
            handleAddAnimation={handleAddAnimation}
          />
        </ActionProceedLayout>
      )}

      {showDeepLinkLayout && (
        <ActionProceedLayout
          success={success}
          error={titleError || videoURLError || error || activeError}
        >
          <DeepLinkItem
            handleCancelClick={handleCancelClick}
            active={videoId === deepLinkId}
            handleAddDeepLink={handleAddDeepLink}
            handleRemoveDeepLink={handleRemoveDeepLink}
            progress={deepLinkProgress}
            type={"Video"}
          />
        </ActionProceedLayout>
      )}

      {showVisitLayout && (
        <ActionProceedLayout
          success={success}
          error={titleError || videoURLError || error || activeError}
        >
          <VisitItem
            type="Video"
            handleCancelClick={handleCancelClick}
            handleVisitLink={handleVisitLink}
          />
        </ActionProceedLayout>
      )}
    </MainContainer>
  );
}

export default VideoItem;
