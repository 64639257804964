import styled from "styled-components";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

export const Nav = styled(motion.nav)`
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 9999;
  background: ${({ background }) => (background ? background : "#ffffff")};
  width: 100%;

  /* border-bottom: 0.5px solid #ededed; */
`;

export const NavbarContainer = styled(motion.div)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  width: 100%;
  padding: 0px 8px;

  @media (min-width: 1024px) {
    padding: 0px 24px;
  }
`;

export const MobileIcon = styled(motion.div)`
  display: none;
  color: ${({ navTheme }) => (navTheme === "dark" && "#FFFFFF") || "#000000"};
  border-radius: 50%;
  transition: display 0.2s ease;
  font-size: 32px;
  cursor: pointer;

  @media screen and (max-width: 960px) {
    display: block;
  }
`;

export const NavMenu = styled(motion.ul)`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  height: 60px;
  color: ${({ navTheme }) => (navTheme === "dark" && "#FFFFFF") || "#000000"};

  @media screen and (max-width: 960px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    padding-bottom: 50px;
    overflow: scroll;
    overflow-x: hidden;
    position: absolute;
    top: 60px;
    left: ${({ click }) => (click ? 0 : "-100%")};
    opacity: 1;
    transition: all 0.5s ease;
    background: white;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const NavItem = styled(motion.li)`
  margin: 0px 8px;
  color: inherit;

  @media screen and (max-width: 960px) {
    width: 100%;

    &:hover {
      border: none;
    }
  }
`;

export const NavLinks = styled(Link)`
  color: inherit;
  display: flex;
  opacity: ${({ active }) => (active ? "1" : "0.8")};
  align-items: center;
  text-decoration: none;
  padding: 8px 16px;

  &:hover {
    opacity: 1;
    transition: opacity 0.3s ease;
  }

  @media screen and (max-width: 960px) {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  &:hover {
  }
`;
export const NavLinksDummy = styled(motion.span)`
  color: #000000;
  display: flex;
  opacity: ${({ active }) => (active ? "1" : "0.6")};
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  text-decoration: none;
  padding: 8px 16px;
  cursor: pointer;

  &:hover {
    opacity: 1;
    transition: opacity 0.3s ease;
  }

  @media screen and (max-width: 960px) {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  &:hover {
  }
`;

export const AvatarLayout = styled(motion.div)`
  display: block;
  position: relative;
  margin: 0px 8px;
`;

export const AvatarOverFlowMenu = styled(motion.div)`
  display: flex;
  flex-direction: column;
  width: 250px;
  position: absolute;
  background-color: white;
  top: 48px;
  right: 0px;
  box-shadow: ${({ theme }) => theme.boxShadows.regular};
  transition: ${({ theme }) => theme.boxShadows.transition};
  border-radius: 4px;
`;

export const UserInfoWrapper = styled(motion.div)`
  display: flex;
  padding: 16px;
  flex-direction: row;
  border-bottom: 1px solid #ededed;

  img {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    object-fit: cover;
  }
`;

export const UserInfo = styled(motion.div)`
  display: flex;
  flex-direction: column;
  background: white;
  margin-left: 8px;
  min-height: 0px;
  height: fit-content;

  & > p {
    text-align: start;
    font-size: 16px;
    word-wrap: normal;

  }

  & > span {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.8);

    margin-left: 0px;
    padding-left: 0px;
  }
`;

export const OverFlowMenuItem = styled(motion.div)`
  display: flex;
  flex-direction: row;
  padding: 16px;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: #ededed;
  }

  & > p {
    font-size: 14px;
    margin-left: 8px;
  }
`;
