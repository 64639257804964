import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Line, defaults, Doughnut } from "react-chartjs-2";

import {
  moveUpVariants,
  popOutVariants,
} from "../../../animations/animation";

import {
  Container,
  Content,
  AnalyticsFilterContainer,
  Filter,
  FilterItem,
  Title,
  OverViewLayout,
  OverViewSection,
  OverViewCardLayout,
  OverViewCard,
  FilterText,
  CardCount,
  CardTitle,
  FilterValue,
  GraphViewLayout,
  GraphViewCardLayout,
  GraphViewCard,
  Graph,
  LegendContainer,
  FilterContainer,
  TopLayout,
  DevicesLayout,
  DevicesCard,
  DevicesLegendContainer,
  DevicesContent,
  DevicesBlurContainer,
  DeviceLegend,
  DeviceLegendBreak,
  DevicesTable,
  DevicesTR,
  DevicesTD,
  DevicesTH,
  TopProfileContainer,
  TopProfileTable,
  TopProfileTR,
  TopProfileTH,
  TopProfileTD,
  GraphViewHeader,
  EmptyLayout,
  AnalyticsInformationContainer,
  AnalyticsErrorContainer,
  GraphViewCardLoadingLayout,
} from "./Analytics.elements";
import { TopLayoutFooter } from "../../../components";
import { db } from "../../../firebase/firebase";
import { AnimatePresence } from "framer-motion";

function Analytics({ user, setLocation }) {
  const location = useLocation();
  useEffect(() => {
    setLocation(location.pathname);
  }, []);

  const { JWT } = require("google-auth-library");

  const history = useHistory();
  defaults.font.family = "'Varela Round', sans-serif";

  const [isPro, setIsPro] = useState(user.isPro);
  const [userId, setUserId] = useState(user.userId);
  const [showFilter, setShowFilter] = useState(false);
  const [filterValue, setFilterValue] = useState("Week");
  const filterDataValues = [
    "Week",
    "2 Weeks",
    "Month",
    "3 Months",
    "6 Months",
    "Year",
  ];

  //report values

  const [dateValues, setDateValues] = useState([]);
  const [pageViews, setPageViews] = useState([]);
  const [uniqueVisitors, setUniqueVisitors] = useState([]);
  const [clicks, setClicks] = useState([]);
  const [CTRs, setCTRs] = useState([]);
  const [clicksDateValues, setClicksDateValues] = useState([]);
  const [totalPageviews, setTotalPageviews] = useState(0);
  const [totalUniquePageviews, setTotalUniquePageviews] = useState(0);
  const [totalClicks, setTotalClicks] = useState(0);

  const [topProfiles, setTopProfiles] = useState([]);
  const [top5Profiles, setTop5Profiles] = useState([]);
  const [topProfilesFilteredList, setTopProfilesFilteredList] = useState([]);
  const [top5ProfilesFilteredList, setTop5ProfilesFilteredList] = useState([]);
  const [topProfilesFinalList, setTopProfilesFinalList] = useState([]);
  const [showAllProfiles, setShowAllProfiles] = useState(false);
  const [includeDeletedProfiles, setIncludeDeletedProfiles] = useState(false);

  const [topLinks, setTopLinks] = useState([]);
  const [top5Links, setTop5Links] = useState([]);
  const [topLinksFilteredList, setTopLinksFilteredList] = useState([]);
  const [top5LinksFilteredList, setTop5LinksFilteredList] = useState([]);
  const [topLinksFinalList, setTopLinksFinalList] = useState([]);
  const [showAllLinks, setShowAllLinks] = useState(false);
  const [includeDeletedLinks, setIncludeDeletedLinks] = useState(false);

  const [topImages, setTopImages] = useState([]);
  const [top5Images, setTop5Images] = useState([]);
  const [topImagesFilteredList, setTopImagesFilteredList] = useState([]);
  const [top5ImagesFilteredList, setTop5ImagesFilteredList] = useState([]);
  const [topImagesFinalList, setTopImagesFinalList] = useState([]);
  const [showAllImages, setShowAllImages] = useState(false);
  const [includeDeletedImages, setIncludeDeletedImages] = useState(false);

  const [topVideos, setTopVideos] = useState([]);
  const [top5Videos, setTop5Videos] = useState([]);
  const [topVideosFilteredList, setTopVideosFilteredList] = useState([]);
  const [top5VideosFilteredList, setTop5VideosFilteredList] = useState([]);
  const [topVideosFinalList, setTopVideosFinalList] = useState([]);
  const [showAllVideos, setShowAllVideos] = useState(false);
  const [includeDeletedVideos, setIncludeDeletedVideos] = useState(false);

  const [deviceCategory, setDeviceCategory] = useState([]);
  const [mobileDeviceModels, setMobileDeviceModels] = useState([]);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);

  const [isViewsFetched, setIsViewsFetched] = useState(false);
  const [isClicksFetched, setIsClicksFetched] = useState(false);
  const [isCTRFetched, setIsCTRFetched] = useState(false);
  const [isDevicesFetched, setIsDevicesFetched] = useState(false);
  const [isDeviceModelsFetched, setIsDeviceModelsFetched] = useState(false);
  const [isCountriesFetched, setIsCountriesFetched] = useState(false);
  const [isCitiesFetched, setIsCitiesFetched] = useState(false);
  const [isTopProfilesFetched, setIsTopProfilesFetched] = useState(false);
  const [isTopLinksFetched, setIsTopLinksFetched] = useState(false);
  const [isTopImagesFetched, setIsTopImagesFetched] = useState(false);
  const [isTopVideosFetched, setIsTopVideosFetched] = useState(false);

  const [profileURLs, setProfileURLs] = useState([]);
  const [linkURLs, setLinkURLs] = useState([]);
  const [imageVisitURLs, setImageVisitURLs] = useState([]);
  const [videoURLs, setVideoURLs] = useState([]);

  const [analyticsError, setAnalyticsError] = useState(false);

  /// reports test

  useEffect(() => {
    getAccessToken();
  }, []);

  window.gapi.load("client");

  const getAccessToken = async () => {
    const JWTClient = new JWT({
      email: "google-analytics@getmyprofiles-fb7d6.iam.gserviceaccount.com",
      key: "-----BEGIN PRIVATE KEY-----\nMIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQCfzXB6IHrGl4TJ\nvmc+8pRP/NUyXd6VKTOQfIB1VfG0ZJ4ngxIFGysYbc2daA49v65qdMzzIb/XRiZ2\nYOnNz7Ms5BcJAySzK05/kxHXM2kwB9xMdF1VgGsX8fWp7XZs3lLUDSnq/OCvyZZD\nMS/T1GA8CLaPXf0TMDcqCU5uIzZ5nH8gCaKUaebHd/8DrTKmU/WELRO6OtOAezMA\nzVkbpVbhS8XBVX7y55YuV+/LumIJch5YXfpm3m585utTnWRKVwpSpFe+hlHGrtp5\n2EDzQChfUeOSqvzon2NHIzxM8SJEHf6834afX4SzIk8luIZp7KskAjNSztsSu83g\n1Nh6LGgLAgMBAAECggEAD3uvvggp7SuexY9JtfXPUfAHgMb15dI6o/Fe3uoyA45L\ngTziq5T7eB/3b/ILjdxlxqcaI8/YJGsFwgAs1xtHMH50ZD6mOSflMK4sEy5GLvhW\nq3ULhVkZHJZTJ6Dm5wXO1QCQ2FWiYtXaKxjNGgNLRGB8aRKsY8YO/5HW7raXChxC\nDIPj2kPbCU4vwi1PKciJyYJBFb7vlHi6kQbf5V2/s6gGv3voHsyXUCsacthzFUoL\nYd4E62otv9ZhDM52fR1Av+CLCpThX0Iqx1ADicFRRNO6Dy13qyWu+1CofhKRGc5B\nmzc3M68Wq3c0jzdCnvvTb7FwOQkhUDLEshc3uH2pGQKBgQDhNdzO1jJ6yM0LWasy\npcxx69KFW5NXoGzBB4O0JqrqylxRxSfV/j4NsCFbvfe2QFONGsgq1AZGUpz68NXn\nz5eZbHjklRSiG4x7141Ldvqa/vYrWWG6j+uEDmBjhVQIg2DFV1OtSh46nBl2e9WG\nCAk2FjRDjIEHGZhN/9E8r/1PQwKBgQC1pl2zwmvOnV6Y8DqXCRvJa6shcUyvkGgK\nD/fCSKseLQ2NhlyrxQAL/JgGLwnn1VtdG9n0GIqwlJxE07XqriJgE0qtioqp8dqL\nkSWO83Hn1vfazipZhgmIFd5iOJ+xqX1CecItmPlntp37IxIM1j/YOU3DDvkMrYk/\nfIzRe3jDmQKBgEB72K6MnEWV60f9s8w7tu9Dk7LFhsnHUHxwt2BE1hmGOarcJczb\nEE+BB/wpx5yyjHnRmlDRBr7p9rLPdJ/8S68NZQwmN5M3CiGHlZhvB9CLglblL9to\nTAoyx5yXV3lcjL/UgkwS9dbYTV4klP3cnS6UawCQYX1z8a0bu4CB76jbAoGAMMx+\nw7npSFpSfXeb5dNWmafJE3Tiiw+aQ4nCn3jyL2sfBvQflS2ZM58qIA8m9BcHMl43\nETI+n+1shbjyWBdruK+2EiTz12/XrELT+jFAWm4Pq8JS8jXl/Z8SXQSlMg88TU10\nctdzuP8ANeVYBgbQo//Q+iPdmCBOjLWjBuvKp5ECgYEAstTbqnm8l+E+Ftj6TIPw\nwsxhXt+v6ECSFZO+oSGZEffhZ9bT/7eQ0mLi09IOKiGnC7xTQrPOjD8OiLO1GYeq\ntbrhd2EzCUCTN6EJhVUDLmBtBPb8nnwnySsbkomCJbv8QqzZ29VlNFxu6cha1fIG\nNBoVDiblKuO/FSsElmCA/3U=\n-----END PRIVATE KEY-----\n",
      scopes: ["https://www.googleapis.com/auth/analytics.readonly"],
    });

    const token = await (await JWTClient.getAccessToken()).token;
    setAccessTokenForAuth(token);
  };

  const setAccessTokenForAuth = (access_token) => {
    window.gapi.client.setToken({
      access_token: access_token,
    });

    setTimeout(() => {
      getInitialqueryReports();
    }, 1000);
  };

  //filter
  const onFilterValueClick = (filterValue) => {
    setAnalyticsError(false);

    setShowFilter(false);
    setFilterValue(filterValue);

    setIsViewsFetched(false);
    setIsClicksFetched(false);
    setIsCTRFetched(false);
    setIsTopProfilesFetched(false);
    setIsTopLinksFetched(false);
    setIsTopImagesFetched(false);
    setIsTopVideosFetched(false);
    setIsDevicesFetched(false);
    setIsDeviceModelsFetched(false);
    setIsCountriesFetched(false);
    setIsCitiesFetched(false);

    if (filterValue === "Week") {
      pageviewsReport("7daysAgo");
      clicksReport("7daysAgo");
      if (!isPro) {
        setIsTopProfilesFetched(true);
        setIsTopLinksFetched(true);
        setIsDevicesFetched(true);
        setIsDeviceModelsFetched(true);
        setIsCountriesFetched(true);
        setIsCitiesFetched(true);
        setIsTopImagesFetched(true);
        setIsTopVideosFetched(true);
        return;
      }

      topProfilesReport("7daysAgo");
      topLinksReport("7daysAgo");
      topImagesReport("7daysAgo");
      topVideosReport("7daysAgo");
      devicesReport("7daysAgo");
      deviceModelsReport("7daysAgo");
      countriesReport("7daysAgo");
      citiesReport("7daysAgo");
    } else if (filterValue === "2 Weeks") {
      pageviewsReport("14daysAgo");
      clicksReport("14daysAgo");
      if (!isPro) {
        setIsTopProfilesFetched(true);
        setIsTopLinksFetched(true);
        setIsDevicesFetched(true);
        setIsDeviceModelsFetched(true);
        setIsCountriesFetched(true);
        setIsCitiesFetched(true);
        setIsTopImagesFetched(true);
        setIsTopVideosFetched(true);
        return;
      }

      topProfilesReport("14daysAgo");
      topLinksReport("14daysAgo");
      topImagesReport("14daysAgo");
      topVideosReport("14daysAgo");
      devicesReport("14daysAgo");
      deviceModelsReport("14daysAgo");
      countriesReport("14daysAgo");
      citiesReport("14daysAgo");
    } else if (filterValue === "Month") {
      pageviewsReport("30daysAgo");
      clicksReport("30daysAgo");

      if (!isPro) {
        setIsTopProfilesFetched(true);
        setIsTopLinksFetched(true);
        setIsTopImagesFetched(true);
        setIsTopVideosFetched(true);
        setIsDevicesFetched(true);
        setIsDeviceModelsFetched(true);
        setIsCountriesFetched(true);
        setIsCitiesFetched(true);
        return;
      }
      topProfilesReport("30daysAgo");
      topLinksReport("30daysAgo");
      topImagesReport("30daysAgo");
      topVideosReport("30daysAgo");
      devicesReport("30daysAgo");
      deviceModelsReport("30daysAgo");
      countriesReport("30daysAgo");
      citiesReport("30daysAgo");
    } else if (filterValue === "3 Months") {
      pageviewsReport("90daysAgo");
      clicksReport("90daysAgo");
      if (!isPro) {
        setIsTopProfilesFetched(true);
        setIsTopLinksFetched(true);
        setIsTopImagesFetched(true);
        setIsTopVideosFetched(true);
        setIsDevicesFetched(true);
        setIsDeviceModelsFetched(true);
        setIsCountriesFetched(true);
        setIsCitiesFetched(true);
        return;
      }

      topProfilesReport("90daysAgo");
      topLinksReport("90daysAgo");
      topImagesReport("90daysAgo");
      topVideosReport("90daysAgo");
      devicesReport("90daysAgo");
      deviceModelsReport("90daysAgo");
      countriesReport("90daysAgo");
      citiesReport("90daysAgo");
    } else if (filterValue === "6 Months") {
      pageviewsReport("180daysAgo");
      clicksReport("180daysAgo");

      if (!isPro) {
        setIsTopProfilesFetched(true);
        setIsTopLinksFetched(true);
        setIsTopImagesFetched(true);
        setIsTopVideosFetched(true);
        setIsDevicesFetched(true);
        setIsDeviceModelsFetched(true);
        setIsCountriesFetched(true);
        setIsCitiesFetched(true);
        return;
      }
      topProfilesReport("180daysAgo");
      topLinksReport("180daysAgo");
      topImagesReport("180daysAgo");
      topVideosReport("180daysAgo");
      devicesReport("180daysAgo");
      deviceModelsReport("180daysAgo");
      countriesReport("180daysAgo");
      citiesReport("180daysAgo");
    } else if (filterValue === "Year") {
      pageviewsReport("365daysAgo");
      clicksReport("365daysAgo");
      if (!isPro) {
        setIsTopProfilesFetched(true);
        setIsTopLinksFetched(true);
        setIsTopImagesFetched(true);
        setIsTopVideosFetched(true);
        setIsDevicesFetched(true);
        setIsDeviceModelsFetched(true);
        setIsCountriesFetched(true);
        setIsCitiesFetched(true);
        return;
      }

      topProfilesReport("365daysAgo");
      topLinksReport("365daysAgo");
      topImagesReport("365daysAgo");
      topVideosReport("365daysAgo");
      devicesReport("365daysAgo");
      deviceModelsReport("365daysAgo");
      countriesReport("365daysAgo");
      citiesReport("365daysAgo");
    }
  };

  ///Graphical Overview

  const viewsData = {
    labels: dateValues,
    datasets: [
      {
        data: pageViews,
        fill: true,
        borderColor: "rgb(153, 102, 255)",
        backgroundColor: "rgba(153, 102, 255, 0.2)",
        pointBackgroundColor: "rgb(153, 102, 255)",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "rgb(153, 102, 255)",
      },
    ],
  };
  const uniqueVisitorsData = {
    labels: dateValues,
    datasets: [
      {
        data: uniqueVisitors,
        fill: true,
        borderColor: "rgb(54, 162, 235)",
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        pointBackgroundColor: "rgb(54, 162, 235)",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "rgb(54, 162, 235)",
      },
    ],
  };

  const clicksData = {
    labels: clicksDateValues,
    datasets: [
      {
        data: clicks,
        fill: true,
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: " rgba(255, 99, 132, 0.2)",
        pointBackgroundColor: "rgb(255, 99, 132)",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "rgb(255, 99, 132)",
      },
    ],
  };

  const ctrData = {
    labels: clicksDateValues,
    datasets: [
      {
        data: CTRs,
        fill: true,
        borderColor: "rgb(255, 159, 64)",
        backgroundColor: " rgba(255, 159, 64, 0.2)",
        pointBackgroundColor: "rgb(255, 159, 64)",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "rgb(255, 159, 64)",
      },
    ],
  };

  const donutData = {
    datasets: [
      {
        label: "# of Votes",
        data: deviceCategory,
        backgroundColor: ["rgba(255, 206, 86, 1)", "rgba(54, 162, 235, 1)"],
        borderWidth: 0,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    layout: {
      padding: {
        left: -20,
      },
    },

    scales: {
      y: {
        display: true,
        grid: {
          drawTicks: false,
        },

        ticks: {
          color: "#000000",
          padding: 20,
          backdropPadding: -200,
        },
      },
      x: {
        display: true,

        ticks: {
          color: "#000000",
          minRotation: 65,
        },
      },
    },
  };

  const doughnutOptions = {
    maintainAspectRatio: false,
  };

  const handleBuyProClick = () => {
    history.push("/pricing");
    history.go(0);
  };

  useEffect(() => {
    document.title = "Analytics" + " | " + process.env.REACT_APP_TITLE;
  }, []);

  const getInitialqueryReports = () => {
    pageviewsReport("7daysAgo");
    clicksReport("7daysAgo");

    if (!isPro) {
      setIsTopProfilesFetched(true);
      setIsTopLinksFetched(true);
      setIsTopImagesFetched(true);
      setIsTopVideosFetched(true);
      setIsDevicesFetched(true);
      setIsDeviceModelsFetched(true);
      setIsCountriesFetched(true);
      setIsCitiesFetched(true);
      return;
    }
    topProfilesReport("7daysAgo");
    topLinksReport("7daysAgo");
    topImagesReport("7daysAgo");
    topVideosReport("7daysAgo");
    devicesReport("7daysAgo");
    deviceModelsReport("7daysAgo");
    countriesReport("7daysAgo");
    citiesReport("7daysAgo");
  };

  useEffect(() => {
    if (isPro) {
      getProfiles();
      getLinks();
      getImages();
      getVideos();
    }
  }, []);

  const getProfiles = () => {
    let profileLists;

    db.collection("users")
      .doc(userId)
      .collection("profiles")
      .orderBy("order", "asc")
      .get()
      .then((snapshot) => {
        profileLists = snapshot.docs.map(
          (doc) => doc.data().URL + doc.data().value
        );
        setProfileURLs(profileLists);
      });
  };

  const getLinks = () => {
    let linkValues;

    db.collection("users")
      .doc(userId)
      .collection("links")
      .orderBy("order")
      .get()
      .then((snapshot) => {
        linkValues = snapshot.docs.map((doc) => doc.data().link);
        setLinkURLs(linkValues);
      });
  };

  const getImages = () => {
    let imageValues;

    db.collection("users")
      .doc(userId)
      .collection("images")
      .orderBy("order")
      .get()
      .then((snapshot) => {
        imageValues = snapshot.docs.map((doc) => doc.data().visitURL);
        setImageVisitURLs(imageValues);
      });
  };

  const getVideos = () => {
    let videoValues;

    db.collection("users")
      .doc(userId)
      .collection("videos")
      .orderBy("order")
      .get()
      .then((snapshot) => {
        videoValues = snapshot.docs.map((doc) => doc.data().videoURL);
        setVideoURLs(videoValues);
      });
  };

  const handleAnalyticsError = (error) => {
    setAnalyticsError(true);
  };

  const pageviewsReport = (startDate) => {
    window.gapi.client
      .request({
        path: "/v4/reports:batchGet",
        root: "https://analyticsreporting.googleapis.com/",
        method: "POST",
        body: {
          reportRequests: [
            {
              viewId: process.env.REACT_APP_GOOGLE_ANALYTICS_VIEW_ID,
              dateRanges: [
                {
                  startDate: startDate,
                  endDate: "yesterday",
                },
              ],

              metrics: [
                {
                  expression: "ga:pageviews",
                },

                {
                  expression: "ga:uniquePageviews",
                },
              ],
              dimensions: [
                {
                  name: "ga:date",
                },
              ],

              dimensionFilterClauses: [
                {
                  filters: [
                    {
                      operator: "EXACT",
                      dimensionName: "ga:pagePath",
                      expressions: ["/" + userId],
                    },
                  ],
                },
              ],

              includeEmptyRows: true,
            },
          ],
        },
      })
      .then(pageviewsResults, handleAnalyticsError);
  };

  const pageviewsResults = (response) => {
    const queryResult = response.result.reports[0].data.rows;
    setTotalPageviews(response.result.reports[0].data.totals[0].values[0]);
    setTotalUniquePageviews(
      response.result.reports[0].data.totals[0].values[1]
    );
    let dateData = [];
    let pageViewsData = [];
    let uniqueVisitorsData = [];

    queryResult.forEach((row, index) => {
      const dateSting = row.dimensions[0];
      const formattedDate = `${dateSting.substring(6, 8)}-${dateSting.substring(
        4,
        6
      )}-${dateSting.substring(0, 4)}`;
      dateData.push(formattedDate);
      pageViewsData.push(row.metrics[0].values[0]);
      uniqueVisitorsData.push(row.metrics[0].values[1]);
    });
    setPageViews(pageViewsData);
    setUniqueVisitors(uniqueVisitorsData);
    setDateValues(dateData);
    setIsViewsFetched(true);
  };

  const clicksReport = (startDate) => {
    window.gapi.client
      .request({
        path: "/v4/reports:batchGet",
        root: "https://analyticsreporting.googleapis.com/",
        method: "POST",
        body: {
          reportRequests: [
            {
              viewId: process.env.REACT_APP_GOOGLE_ANALYTICS_VIEW_ID,
              dateRanges: [
                {
                  startDate: startDate,
                  endDate: "yesterday",
                },
              ],
              metrics: [
                {
                  expression: "ga:totalEvents",
                },
              ],

              dimensions: [
                {
                  name: "ga:date",
                },
              ],
              dimensionFilterClauses: [
                {
                  filters: [
                    {
                      operator: "EXACT",
                      dimensionName: "ga:eventCategory",
                      expressions: [userId],
                    },
                  ],
                },
              ],
              includeEmptyRows: true,
            },
          ],
        },
      })
      .then(clicksResults, handleAnalyticsError);
  };

  const clicksResults = (response) => {
    const queryResult = response.result.reports[0].data.rows;
    let clicksData = [];
    let clicksDateData = [];

    queryResult.forEach((row, index) => {
      const dateSting = row.dimensions[0];
      const formattedDate = `${dateSting.substring(6, 8)}-${dateSting.substring(
        4,
        6
      )}-${dateSting.substring(0, 4)}`;
      clicksDateData.push(formattedDate);
      clicksData.push(row.metrics[0].values[0]);
    });

    setClicks(clicksData);
    setClicksDateValues(clicksDateData);
    setTotalClicks(response.result.reports[0].data.totals[0].values[0]);
    setIsClicksFetched(true);
  };

  const CTRReport = (clicksDatas) => {
    let CTRData = [];

    clicksDatas.forEach((click, index) => {
      let CTRValue = (click * 100) / pageViews[index];
      CTRData.push(CTRValue || 0);
    });

    setCTRs(CTRData);
    setIsCTRFetched(true);
  };

  useEffect(() => {
    if (clicks.length !== 0 && pageViews.length !== 0) {
      CTRReport(clicks);
    }
  }, [clicks, pageViews]);

  const topProfilesReport = (startDate) => {
    window.gapi.client
      .request({
        path: "/v4/reports:batchGet",
        root: "https://analyticsreporting.googleapis.com/",
        method: "POST",
        body: {
          reportRequests: [
            {
              viewId: process.env.REACT_APP_GOOGLE_ANALYTICS_VIEW_ID,
              dateRanges: [
                {
                  startDate: startDate,
                  endDate: "yesterday",
                },
              ],
              metrics: [
                {
                  expression: "ga:totalEvents",
                },
              ],
              orderBys: [
                { fieldName: "ga:totalEvents", sortOrder: "DESCENDING" },
              ],
              dimensions: [
                {
                  name: "ga:eventLabel",
                },
              ],
              dimensionFilterClauses: [
                {
                  filters: [
                    {
                      operator: "EXACT",
                      dimensionName: "ga:eventCategory",
                      expressions: [userId + "_PROFILES"],
                    },
                  ],
                },
              ],
            },
          ],
        },
      })
      .then(topProfilesResults, handleAnalyticsError);
  };

  const topProfilesResults = (response) => {
    const queryResult = response.result.reports[0].data.rows;

    let topProfilesData = [];
    let top5ProfilesData = [];
    queryResult.forEach((row, index) => {
      let tempObject = {
        link: row.dimensions[0],
        clicks: row.metrics[0].values[0],
      };

      if (index < 5) {
        top5ProfilesData.push(tempObject);
      }
      topProfilesData.push(tempObject);
    });
    setTopProfiles(topProfilesData);
    setTop5Profiles(top5ProfilesData);
  };

  const topLinksReport = (startDate) => {
    window.gapi.client
      .request({
        path: "/v4/reports:batchGet",
        root: "https://analyticsreporting.googleapis.com/",
        method: "POST",
        body: {
          reportRequests: [
            {
              viewId: process.env.REACT_APP_GOOGLE_ANALYTICS_VIEW_ID,
              dateRanges: [
                {
                  startDate: startDate,
                  endDate: "yesterday",
                },
              ],
              metrics: [
                {
                  expression: "ga:totalEvents",
                },
              ],
              orderBys: [
                { fieldName: "ga:totalEvents", sortOrder: "DESCENDING" },
              ],
              dimensions: [
                {
                  name: "ga:eventLabel",
                },
              ],
              dimensionFilterClauses: [
                {
                  filters: [
                    {
                      operator: "EXACT",
                      dimensionName: "ga:eventCategory",
                      expressions: [userId + "_LINKS"],
                    },
                  ],
                },
              ],
            },
          ],
        },
      })
      .then(topLinksResults, handleAnalyticsError);
  };

  const topLinksResults = (response) => {
    const queryResult = response.result.reports[0].data.rows;

    let topLinksData = [];
    let top5LinksData = [];

    queryResult.forEach((row, index) => {
      let tempObject = {
        link: row.dimensions[0],
        clicks: row.metrics[0].values[0],
      };

      if (index < 5) {
        top5LinksData.push(tempObject);
      }
      topLinksData.push(tempObject);
    });

    setTopLinks(topLinksData);
    setTop5Links(top5LinksData);
  };

  const topImagesReport = (startDate) => {
    window.gapi.client
      .request({
        path: "/v4/reports:batchGet",
        root: "https://analyticsreporting.googleapis.com/",
        method: "POST",
        body: {
          reportRequests: [
            {
              viewId: process.env.REACT_APP_GOOGLE_ANALYTICS_VIEW_ID,
              dateRanges: [
                {
                  startDate: startDate,
                  endDate: "yesterday",
                },
              ],
              metrics: [
                {
                  expression: "ga:totalEvents",
                },
              ],
              orderBys: [
                { fieldName: "ga:totalEvents", sortOrder: "DESCENDING" },
              ],
              dimensions: [
                {
                  name: "ga:eventLabel",
                },
              ],
              dimensionFilterClauses: [
                {
                  filters: [
                    {
                      operator: "EXACT",
                      dimensionName: "ga:eventCategory",
                      expressions: [userId + "_IMAGES"],
                    },
                  ],
                },
              ],
            },
          ],
        },
      })
      .then(topImagesResults, handleAnalyticsError);
  };

  const topImagesResults = (response) => {
    const queryResult = response.result.reports[0].data.rows;

    let topImagesData = [];
    let top5ImagesData = [];

    queryResult.forEach((row, index) => {
      let tempObject = {
        visitURL: row.dimensions[0],
        clicks: row.metrics[0].values[0],
      };

      if (index < 5) {
        top5ImagesData.push(tempObject);
      }
      topImagesData.push(tempObject);
    });

    setTopImages(topImagesData);
    setTop5Images(top5ImagesData);
  };

  //Videos

  const topVideosReport = (startDate) => {
    window.gapi.client
      .request({
        path: "/v4/reports:batchGet",
        root: "https://analyticsreporting.googleapis.com/",
        method: "POST",
        body: {
          reportRequests: [
            {
              viewId: process.env.REACT_APP_GOOGLE_ANALYTICS_VIEW_ID,
              dateRanges: [
                {
                  startDate: startDate,
                  endDate: "yesterday",
                },
              ],
              metrics: [
                {
                  expression: "ga:totalEvents",
                },
              ],
              orderBys: [
                { fieldName: "ga:totalEvents", sortOrder: "DESCENDING" },
              ],
              dimensions: [
                {
                  name: "ga:eventLabel",
                },
              ],
              dimensionFilterClauses: [
                {
                  filters: [
                    {
                      operator: "EXACT",
                      dimensionName: "ga:eventCategory",
                      expressions: [userId + "_VIDEOS"],
                    },
                  ],
                },
              ],
            },
          ],
        },
      })
      .then(topVideosResults, handleAnalyticsError);
  };

  const topVideosResults = (response) => {
    const queryResult = response.result.reports[0].data.rows;

    let topVideosData = [];
    let top5VideosData = [];

    queryResult.forEach((row, index) => {
      let tempObject = {
        videoURL: row.dimensions[0],
        clicks: row.metrics[0].values[0],
      };

      if (index < 5) {
        top5VideosData.push(tempObject);
      }
      topVideosData.push(tempObject);
    });

    setTopVideos(topVideosData);
    setTop5Videos(top5VideosData);
  };

  ////

  const devicesReport = (startDate) => {
    window.gapi.client
      .request({
        path: "/v4/reports:batchGet",
        root: "https://analyticsreporting.googleapis.com/",
        method: "POST",
        body: {
          reportRequests: [
            {
              viewId: process.env.REACT_APP_GOOGLE_ANALYTICS_VIEW_ID,
              dateRanges: [
                {
                  startDate: startDate,
                  endDate: "yesterday",
                },
              ],
              metrics: [
                {
                  expression: "ga:pageviews",
                },
              ],

              dimensions: [
                {
                  name: "ga:deviceCategory",
                },
              ],

              dimensionFilterClauses: [
                {
                  filters: [
                    {
                      operator: "EXACT",
                      dimensionName: "ga:pagePath",
                      expressions: ["/" + userId],
                    },
                  ],
                },
              ],
            },
          ],
        },
      })
      .then(devicesResults, handleAnalyticsError);
  };

  const devicesResults = (response) => {
    const queryResult = response.result.reports[0].data.rows;
    setIsDevicesFetched(true);
    let mobileData = 0;
    let desktopData = 0;

    queryResult.forEach((row, index) => {
      if (row.dimensions[0] === "mobile") {
        mobileData = row.metrics[0].values[0];
      } else {
        desktopData = row.metrics[0].values[0];
      }
    });
    setDeviceCategory([mobileData, desktopData]);
  };

  const deviceModelsReport = (startDate) => {
    window.gapi.client
      .request({
        path: "/v4/reports:batchGet",
        root: "https://analyticsreporting.googleapis.com/",
        method: "POST",
        body: {
          reportRequests: [
            {
              viewId: process.env.REACT_APP_GOOGLE_ANALYTICS_VIEW_ID,
              dateRanges: [
                {
                  startDate: startDate,
                  endDate: "yesterday",
                },
              ],
              metrics: [
                {
                  expression: "ga:pageviews",
                },
              ],
              orderBys: [
                { fieldName: "ga:pageviews", sortOrder: "DESCENDING" },
              ],

              dimensions: [
                {
                  name: "ga:mobileDeviceModel",
                },
              ],

              dimensionFilterClauses: [
                {
                  filters: [
                    {
                      operator: "EXACT",
                      dimensionName: "ga:pagePath",
                      expressions: ["/" + userId],
                    },
                  ],
                },
              ],
            },
          ],
        },
      })
      .then(deviceModelsResults, handleAnalyticsError);
  };

  const deviceModelsResults = (response) => {
    const queryResult = response.result.reports[0].data.rows;

    let mobileDeviceModelsData = [];
    setIsDeviceModelsFetched(true);

    queryResult.forEach((row, index) => {
      let tempObject = {
        deviceModelName: row.dimensions[0],
        views: row.metrics[0].values[0],
      };

      mobileDeviceModelsData.push(tempObject);
    });

    setMobileDeviceModels(mobileDeviceModelsData);
  };

  const countriesReport = (startDate) => {
    window.gapi.client
      .request({
        path: "/v4/reports:batchGet",
        root: "https://analyticsreporting.googleapis.com/",
        method: "POST",
        body: {
          reportRequests: [
            {
              viewId: process.env.REACT_APP_GOOGLE_ANALYTICS_VIEW_ID,
              dateRanges: [
                {
                  startDate: startDate,
                  endDate: "yesterday",
                },
              ],
              metrics: [
                {
                  expression: "ga:pageviews",
                },
              ],
              orderBys: [
                { fieldName: "ga:pageviews", sortOrder: "DESCENDING" },
              ],

              dimensions: [
                {
                  name: "ga:country",
                },
              ],

              dimensionFilterClauses: [
                {
                  filters: [
                    {
                      operator: "EXACT",
                      dimensionName: "ga:pagePath",
                      expressions: ["/" + userId],
                    },
                  ],
                },
              ],
            },
          ],
        },
      })
      .then(countriesResult, handleAnalyticsError);
  };

  const countriesResult = (response) => {
    const queryResult = response.result.reports[0].data.rows;
    let countriesData = [];

    setIsCountriesFetched(true);

    queryResult.forEach((row, index) => {
      let tempObject = {
        country: row.dimensions[0],
        views: row.metrics[0].values[0],
      };
      countriesData.push(tempObject);
    });

    setCountries(countriesData);
  };

  const citiesReport = (startDate) => {
    window.gapi.client
      .request({
        path: "/v4/reports:batchGet",
        root: "https://analyticsreporting.googleapis.com/",
        method: "POST",
        body: {
          reportRequests: [
            {
              viewId: process.env.REACT_APP_GOOGLE_ANALYTICS_VIEW_ID,
              dateRanges: [
                {
                  startDate: startDate,
                  endDate: "yesterday",
                },
              ],
              metrics: [
                {
                  expression: "ga:pageviews",
                },
              ],
              orderBys: [
                { fieldName: "ga:pageviews", sortOrder: "DESCENDING" },
              ],

              dimensions: [
                {
                  name: "ga:city",
                },
              ],

              dimensionFilterClauses: [
                {
                  filters: [
                    {
                      operator: "EXACT",
                      dimensionName: "ga:pagePath",
                      expressions: ["/" + userId],
                    },
                  ],
                },
              ],
            },
          ],
        },
      })
      .then(citiesResults, handleAnalyticsError);
  };

  const citiesResults = (response) => {
    const queryResult = response.result.reports[0].data.rows;

    let citiesData = [];
    setIsCitiesFetched(true);

    queryResult.forEach((row, index) => {
      let tempObject = {
        city: row.dimensions[0],
        views: row.metrics[0].values[0],
      };

      citiesData.push(tempObject);
    });

    setCities(citiesData);
  };

  const handleIncludeDeletedProfilesClick = () => {
    if (showAllProfiles) {
      setTopProfilesFinalList(Array.from(topProfiles));
    } else {
      setTopProfilesFinalList(Array.from(top5Profiles));
    }
    setIncludeDeletedProfiles(true);
  };

  const handleExcludeDeletedProfilesClick = () => {
    let excludedLists = [];
    let excluded5Lists = [];

    topProfiles.map((topProfile) => {
      let tempObject = {
        link: topProfile.link,
        clicks: topProfile.clicks,
      };
      if (profileURLs.indexOf(topProfile.link) !== -1) {
        excludedLists.push(tempObject);
      }

      if (
        profileURLs.indexOf(topProfile.link) !== -1 &&
        excluded5Lists.length < 5
      ) {
        excluded5Lists.push(tempObject);
      }
    });
    setTopProfilesFilteredList(excludedLists);
    setTop5ProfilesFilteredList(excluded5Lists);

    if (showAllProfiles) {
      setTopProfilesFinalList(Array.from(excludedLists));
    } else {
      setTopProfilesFinalList(Array.from(excluded5Lists));
    }

    setIsTopProfilesFetched(true);
    setIncludeDeletedProfiles(false);
  };

  const handleShowMoreProfilesClick = () => {
    setShowAllProfiles(true);

    if (includeDeletedProfiles) {
      setTopProfilesFinalList(Array.from(topProfiles));
    } else {
      setTopProfilesFinalList(Array.from(topProfilesFilteredList));
    }
  };

  const handleShowLessProfilesClick = () => {
    setShowAllProfiles(false);
    if (includeDeletedProfiles) {
      setTopProfilesFinalList(Array.from(top5Profiles));
    } else {
      setTopProfilesFinalList(Array.from(top5ProfilesFilteredList));
    }
  };

  const handleIncludeDeletedLinksClick = () => {
    if (showAllLinks) {
      setTopLinksFinalList(Array.from(topLinks));
    } else {
      setTopLinksFinalList(Array.from(top5Links));
    }

    setIncludeDeletedLinks(true);
  };

  const handleExcludeDeletedLinksClick = () => {
    let excludedLists = [];
    let excluded5Lists = [];
    topLinks.map((topLink, index) => {
      let tempObject = {
        link: topLink.link,
        clicks: topLink.clicks,
      };
      if (linkURLs.indexOf(topLink.link) !== -1) {
        excludedLists.push(tempObject);
      }
      if (linkURLs.indexOf(topLink.link) !== -1 && excluded5Lists.length < 5) {
        excluded5Lists.push(tempObject);
      }
    });
    setTopLinksFilteredList(excludedLists);
    setTop5LinksFilteredList(excluded5Lists);

    if (showAllLinks) {
      setTopLinksFinalList(Array.from(excludedLists));
    } else {
      setTopLinksFinalList(Array.from(excluded5Lists));
    }
    setIsTopLinksFetched(true);
    setIncludeDeletedLinks(false);
  };

  const handleShowMoreLinksClick = () => {
    setShowAllLinks(true);

    if (includeDeletedLinks) {
      setTopLinksFinalList(Array.from(topLinks));
    } else {
      setTopLinksFinalList(Array.from(topLinksFilteredList));
    }
  };

  const handleShowLessLinksClick = () => {
    setShowAllLinks(false);
    if (includeDeletedLinks) {
      setTopLinksFinalList(Array.from(top5Links));
    } else {
      setTopLinksFinalList(Array.from(top5LinksFilteredList));
    }
  };

  const handleIncludeDeletedImagesClick = () => {
    if (showAllImages) {
      setTopImagesFinalList(Array.from(topImages));
    } else {
      setTopImagesFinalList(Array.from(top5Images));
    }

    setIncludeDeletedImages(true);
  };

  const handleExcludeDeletedImagesClick = () => {
    let excludedLists = [];
    let excluded5Lists = [];
    topImages.map((topImage, index) => {
      let tempObject = {
        visitURL: topImage.visitURL,
        clicks: topImage.clicks,
      };
      if (imageVisitURLs.indexOf(topImage.visitURL) !== -1) {
        excludedLists.push(tempObject);
      }
      if (
        imageVisitURLs.indexOf(topImage.visitURL) !== -1 &&
        excluded5Lists.length < 5
      ) {
        excluded5Lists.push(tempObject);
      }
    });
    setTopImagesFilteredList(excludedLists);
    setTop5ImagesFilteredList(excluded5Lists);

    if (showAllLinks) {
      setTopImagesFinalList(Array.from(excludedLists));
    } else {
      setTopImagesFinalList(Array.from(excluded5Lists));
    }
    setIsTopImagesFetched(true);
    setIncludeDeletedImages(false);
  };

  const handleShowMoreImagesClick = () => {
    setShowAllImages(true);

    if (includeDeletedImages) {
      setTopImagesFinalList(Array.from(topImages));
    } else {
      setTopImagesFinalList(Array.from(topImagesFilteredList));
    }
  };

  const handleShowLessImagesClick = () => {
    setShowAllImages(false);
    if (includeDeletedImages) {
      setTopImagesFinalList(Array.from(top5Images));
    } else {
      setTopImagesFinalList(Array.from(top5ImagesFilteredList));
    }
  };

  const handleIncludeDeletedVideosClick = () => {
    if (showAllVideos) {
      setTopVideosFinalList(Array.from(topVideos));
    } else {
      setTopVideosFinalList(Array.from(top5Videos));
    }

    setIncludeDeletedVideos(true);
  };

  const handleExcludeDeletedVideosClick = () => {
    let excludedLists = [];
    let excluded5Lists = [];
    topVideos.map((topVideo, index) => {
      let tempObject = {
        videoURL: topVideo.videoURL,
        clicks: topVideo.clicks,
      };
      if (videoURLs.indexOf(topVideo.videoURL) !== -1) {
        excludedLists.push(tempObject);
      }
      if (
        videoURLs.indexOf(topVideo.videoURL) !== -1 &&
        excluded5Lists.length < 5
      ) {
        excluded5Lists.push(tempObject);
      }
    });
    setTopVideosFilteredList(excludedLists);
    setTop5VideosFilteredList(excluded5Lists);

    if (showAllLinks) {
      setTopVideosFinalList(Array.from(excludedLists));
    } else {
      setTopVideosFinalList(Array.from(excluded5Lists));
    }
    setIsTopVideosFetched(true);
    setIncludeDeletedVideos(false);
  };

  const handleShowMoreVideosClick = () => {
    setShowAllVideos(true);

    if (includeDeletedVideos) {
      setTopVideosFinalList(Array.from(topVideos));
    } else {
      setTopVideosFinalList(Array.from(topVideosFilteredList));
    }
  };

  const handleShowLessVideosClick = () => {
    setShowAllVideos(false);
    if (includeDeletedVideos) {
      setTopVideosFinalList(Array.from(top5Videos));
    } else {
      setTopVideosFinalList(Array.from(top5VideosFilteredList));
    }
  };

  useEffect(() => {
    handleExcludeDeletedProfilesClick();
  }, [topProfiles, profileURLs]);

  useEffect(() => {
    handleExcludeDeletedLinksClick();
  }, [topLinks, linkURLs]);

  useEffect(() => {
    handleExcludeDeletedImagesClick();
  }, [topImages, imageVisitURLs]);

  useEffect(() => {
    handleExcludeDeletedVideosClick();
  }, [topVideos, videoURLs]);

  return (
    <Container
      variants={moveUpVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <Content>
        {analyticsError && (
          <AnalyticsErrorContainer>
            <p>
              Oops! Something went wrong while fetching your analytics data. Try
              again later.
            </p>
          </AnalyticsErrorContainer>
        )}

        <AnalyticsInformationContainer>
          <p>
            Analytics reports are not real time. It takes one to two days to
            show changes in data.
          </p>
        </AnalyticsInformationContainer>
        <AnalyticsFilterContainer>
          <Title>Overview</Title>
          <FilterContainer>
            <FilterText onClick={() => setShowFilter(!showFilter)}>
              Last {filterValue}
              {showFilter ? (
                <span>
                  <i class="bi bi-caret-up"></i>
                </span>
              ) : (
                <span>
                  <i class="bi bi-caret-down"></i>
                </span>
              )}
            </FilterText>
            <AnimatePresence>
              {showFilter && (
                <Filter
                  variants={popOutVariants}
                  initial="hidden"
                  animate="visible"
                  exit="exit"
                >
                  {filterDataValues.map((filterDataValue) => (
                    <FilterItem
                      key={filterDataValue}
                      onClick={() => onFilterValueClick(filterDataValue)}
                    >
                      Last {filterDataValue}
                    </FilterItem>
                  ))}
                </Filter>
              )}
            </AnimatePresence>
          </FilterContainer>
        </AnalyticsFilterContainer>
        <OverViewLayout>
          <OverViewSection>
            <OverViewCardLayout>
              <OverViewCard>
                {!isViewsFetched ? (
                  <span id="loader">
                    <i class="fas fa-circle-notch"></i>
                  </span>
                ) : (
                  <CardCount>{totalPageviews}</CardCount>
                )}

                <CardTitle>Views</CardTitle>
                <FilterValue> Last {filterValue}</FilterValue>
              </OverViewCard>
              <OverViewCard>
                {!isViewsFetched ? (
                  <span id="loader">
                    <i class="fas fa-circle-notch"></i>
                  </span>
                ) : (
                  <CardCount>{totalUniquePageviews}</CardCount>
                )}
                <CardTitle>Unique Views</CardTitle>
                <FilterValue> Last {filterValue}</FilterValue>
              </OverViewCard>
            </OverViewCardLayout>

            <OverViewCardLayout>
              <OverViewCard>
                {!isClicksFetched ? (
                  <span id="loader">
                    <i class="fas fa-circle-notch"></i>
                  </span>
                ) : (
                  <CardCount>{totalClicks}</CardCount>
                )}
                <CardTitle>Clicks</CardTitle>
                <FilterValue> Last {filterValue}</FilterValue>
              </OverViewCard>
              <OverViewCard>
                {!isCTRFetched ? (
                  <span id="loader">
                    <i class="fas fa-circle-notch"></i>
                  </span>
                ) : (
                  <CardCount>
                    {(totalClicks / totalPageviews).toFixed(2)}%
                  </CardCount>
                )}
                <CardTitle>CTR</CardTitle>
                <FilterValue> Last {filterValue}</FilterValue>
              </OverViewCard>
            </OverViewCardLayout>
          </OverViewSection>
        </OverViewLayout>

        <Title>Graphical View</Title>
        <GraphViewLayout>
          <GraphViewCardLayout>
            {isViewsFetched ? (
              <GraphViewCard>
                <GraphViewHeader>
                  <p>Views</p>
                  <LegendContainer type="1">
                    <span>
                      <i class="bi bi-eye"></i>
                    </span>
                    <p>{totalPageviews}</p>
                  </LegendContainer>
                </GraphViewHeader>
                <Graph>
                  <Line height={250} data={viewsData} options={options} />
                </Graph>
              </GraphViewCard>
            ) : (
              <GraphViewCardLoadingLayout>
                <span id="loader">
                  <i class="fas fa-circle-notch"></i>
                </span>
                <p>Loading</p>
              </GraphViewCardLoadingLayout>
            )}

            {isViewsFetched ? (
              <GraphViewCard>
                <GraphViewHeader>
                  <p>Unique Visitors</p>
                  <LegendContainer type="2">
                    <span>
                      <i class="bi bi-eye"></i>
                    </span>
                    <p>{totalUniquePageviews}</p>
                  </LegendContainer>
                </GraphViewHeader>
                <Graph>
                  <Line
                    height={250}
                    data={uniqueVisitorsData}
                    options={options}
                  />
                </Graph>
              </GraphViewCard>
            ) : (
              <GraphViewCardLoadingLayout>
                <span id="loader">
                  <i class="fas fa-circle-notch"></i>
                </span>
                <p>Loading</p>
              </GraphViewCardLoadingLayout>
            )}
          </GraphViewCardLayout>
          <GraphViewCardLayout>
            {isClicksFetched ? (
              <GraphViewCard>
                <GraphViewHeader>
                  <p>Clicks</p>
                  <LegendContainer type="3">
                    <span>
                      <i class="bi bi-eye"></i>
                    </span>
                    <p>{totalClicks}</p>
                  </LegendContainer>
                </GraphViewHeader>
                <Graph>
                  <Line height={250} data={clicksData} options={options} />
                </Graph>
              </GraphViewCard>
            ) : (
              <GraphViewCardLoadingLayout>
                <span id="loader">
                  <i class="fas fa-circle-notch"></i>
                </span>
                <p>Loading</p>
              </GraphViewCardLoadingLayout>
            )}
            {isCTRFetched ? (
              <GraphViewCard>
                <GraphViewHeader>
                  <p>CTR</p>
                  <LegendContainer type="4">
                    <span>
                      <i class="bi bi-eye"></i>
                    </span>
                    <p> {(totalClicks / totalPageviews).toFixed(2)}%</p>
                  </LegendContainer>
                </GraphViewHeader>
                <Graph>
                  <Line height={250} data={ctrData} options={options} />
                </Graph>
              </GraphViewCard>
            ) : (
              <GraphViewCardLoadingLayout>
                <span id="loader">
                  <i class="fas fa-circle-notch"></i>
                </span>
                <p>Loading</p>
              </GraphViewCardLoadingLayout>
            )}
          </GraphViewCardLayout>
        </GraphViewLayout>

        <Title>Top Profiles</Title>
        {isTopProfilesFetched ? (
          <TopLayout>
            <TopProfileContainer isPro={isPro}>
              <>
                {topProfilesFinalList.length !== 0 ? (
                  <TopProfileTable>
                    <TopProfileTR>
                      <TopProfileTH></TopProfileTH>
                      <TopProfileTH style={{ width: "100%" }}></TopProfileTH>
                      <TopProfileTH>Clicks</TopProfileTH>
                    </TopProfileTR>

                    {topProfilesFinalList.map((topProfile, index) => (
                      <TopProfileTR key={topProfile.link}>
                        <TopProfileTD>{index + 1}.</TopProfileTD>
                        <TopProfileTD>{topProfile.link}</TopProfileTD>
                        <TopProfileTD>{topProfile.clicks}</TopProfileTD>
                      </TopProfileTR>
                    ))}
                  </TopProfileTable>
                ) : (
                  <EmptyLayout>
                    <p>No data to show here.</p>
                  </EmptyLayout>
                )}
                <TopLayoutFooter
                  type={"Profiles"}
                  length={topProfilesFinalList.length}
                  handleInclude={handleIncludeDeletedProfilesClick}
                  handleExclude={handleExcludeDeletedProfilesClick}
                  showAll={showAllProfiles}
                  handleShowMoreClick={handleShowMoreProfilesClick}
                  handleShowLessClick={handleShowLessProfilesClick}
                />
              </>
            </TopProfileContainer>

            {!isPro && (
              <DevicesBlurContainer>
                <span>
                  <i class="bi bi-lock"></i>
                </span>
                <p>
                  Advanced analytics are only available on pro plan.
                  <a onClick={handleBuyProClick}>Buy Pro.</a>
                </p>
              </DevicesBlurContainer>
            )}
          </TopLayout>
        ) : (
          <GraphViewCardLoadingLayout>
            <span id="loader">
              <i class="fas fa-circle-notch"></i>
            </span>
            <p>Loading</p>
          </GraphViewCardLoadingLayout>
        )}

        <Title>Top Links</Title>
        {isTopLinksFetched ? (
          <TopLayout>
            <TopProfileContainer isPro={isPro}>
              <>
                {topLinksFinalList.length !== 0 ? (
                  <TopProfileTable>
                    <TopProfileTR>
                      <TopProfileTH></TopProfileTH>
                      <TopProfileTH style={{ width: "100%" }}></TopProfileTH>
                      <TopProfileTH>Clicks</TopProfileTH>
                    </TopProfileTR>

                    {topLinksFinalList.map((topLink, index) => (
                      <TopProfileTR key={index + "a"}>
                        <TopProfileTD>{index + 1}.</TopProfileTD>
                        <TopProfileTD>{topLink.link}</TopProfileTD>
                        <TopProfileTD>{topLink.clicks}</TopProfileTD>
                      </TopProfileTR>
                    ))}
                  </TopProfileTable>
                ) : (
                  <EmptyLayout>
                    <p>No data to show here.</p>
                  </EmptyLayout>
                )}

                <TopLayoutFooter
                  type={"Links"}
                  length={topLinksFinalList.length}
                  handleInclude={handleIncludeDeletedLinksClick}
                  handleExclude={handleExcludeDeletedLinksClick}
                  showAll={showAllLinks}
                  handleShowMoreClick={handleShowMoreLinksClick}
                  handleShowLessClick={handleShowLessLinksClick}
                />
              </>
            </TopProfileContainer>
            {!isPro && (
              <DevicesBlurContainer>
                <span>
                  <i class="bi bi-lock"></i>
                </span>
                <p>
                  Advanced analytics are only available on pro plan.
                  <a onClick={handleBuyProClick}>Buy Pro.</a>
                </p>
              </DevicesBlurContainer>
            )}
          </TopLayout>
        ) : (
          <GraphViewCardLoadingLayout>
            <span id="loader">
              <i class="fas fa-circle-notch"></i>
            </span>
            <p>Loading</p>
          </GraphViewCardLoadingLayout>
        )}

        <Title>Top Images</Title>
        {isTopImagesFetched ? (
          <TopLayout>
            <TopProfileContainer isPro={isPro}>
              <>
                {topImagesFinalList.length !== 0 ? (
                  <TopProfileTable>
                    <TopProfileTR>
                      <TopProfileTH></TopProfileTH>
                      <TopProfileTH style={{ width: "100%" }}></TopProfileTH>
                      <TopProfileTH>Clicks</TopProfileTH>
                    </TopProfileTR>

                    {topImagesFinalList.map((topImage, index) => (
                      <TopProfileTR key={index + "a"}>
                        <TopProfileTD>{index + 1}.</TopProfileTD>
                        <TopProfileTD>{topImage.visitURL}</TopProfileTD>
                        <TopProfileTD>{topImage.clicks}</TopProfileTD>
                      </TopProfileTR>
                    ))}
                  </TopProfileTable>
                ) : (
                  <EmptyLayout>
                    <p>No data to show here.</p>
                  </EmptyLayout>
                )}

                <TopLayoutFooter
                  type={"Images"}
                  length={topImagesFinalList.length}
                  handleInclude={handleIncludeDeletedImagesClick}
                  handleExclude={handleExcludeDeletedImagesClick}
                  showAll={showAllImages}
                  handleShowMoreClick={handleShowMoreImagesClick}
                  handleShowLessClick={handleShowLessImagesClick}
                />
              </>
            </TopProfileContainer>
            {!isPro && (
              <DevicesBlurContainer>
                <span>
                  <i class="bi bi-lock"></i>
                </span>
                <p>
                  Advanced analytics are only available on pro plan.
                  <a onClick={handleBuyProClick}>Buy Pro.</a>
                </p>
              </DevicesBlurContainer>
            )}
          </TopLayout>
        ) : (
          <GraphViewCardLoadingLayout>
            <span id="loader">
              <i class="fas fa-circle-notch"></i>
            </span>
            <p>Loading</p>
          </GraphViewCardLoadingLayout>
        )}

        <Title>Top Videos</Title>
        {isTopVideosFetched ? (
          <TopLayout>
            <TopProfileContainer isPro={isPro}>
              <>
                {topVideosFinalList.length !== 0 ? (
                  <TopProfileTable>
                    <TopProfileTR>
                      <TopProfileTH></TopProfileTH>
                      <TopProfileTH style={{ width: "100%" }}></TopProfileTH>
                      <TopProfileTH>Views</TopProfileTH>
                    </TopProfileTR>

                    {topVideosFinalList.map((topVideo, index) => (
                      <TopProfileTR key={index + "a"}>
                        <TopProfileTD>{index + 1}.</TopProfileTD>
                        <TopProfileTD>{topVideo.videoURL}</TopProfileTD>
                        <TopProfileTD>{topVideo.clicks}</TopProfileTD>
                      </TopProfileTR>
                    ))}
                  </TopProfileTable>
                ) : (
                  <EmptyLayout>
                    <p>No data to show here.</p>
                  </EmptyLayout>
                )}

                <TopLayoutFooter
                  type={"Videos"}
                  length={topVideosFinalList.length}
                  handleInclude={handleIncludeDeletedVideosClick}
                  handleExclude={handleExcludeDeletedVideosClick}
                  showAll={showAllVideos}
                  handleShowMoreClick={handleShowMoreVideosClick}
                  handleShowLessClick={handleShowLessVideosClick}
                />
              </>
            </TopProfileContainer>
            {!isPro && (
              <DevicesBlurContainer>
                <span>
                  <i class="bi bi-lock"></i>
                </span>
                <p>
                  Advanced analytics are only available on pro plan.
                  <a onClick={handleBuyProClick}>Buy Pro.</a>
                </p>
              </DevicesBlurContainer>
            )}
          </TopLayout>
        ) : (
          <GraphViewCardLoadingLayout>
            <span id="loader">
              <i class="fas fa-circle-notch"></i>
            </span>
            <p>Loading</p>
          </GraphViewCardLoadingLayout>
        )}

        <Title>Devices</Title>
        <DevicesLayout>
          {isDevicesFetched ? (
            <DevicesCard>
              <DevicesContent isPro={isPro}>
                {deviceCategory.length !== 0 && (
                  <DevicesLegendContainer>
                    <DeviceLegend>
                      <p>
                        <span id="mobile">
                          <i class="bi bi-circle-fill" />
                        </span>
                        Mobile
                      </p>

                      <p id="value">
                        {deviceCategory[0]}{" "}
                        <span>
                          (
                          {((deviceCategory[0] * 100) / totalPageviews).toFixed(
                            2
                          )}
                          %)
                        </span>
                      </p>
                    </DeviceLegend>
                    <DeviceLegendBreak></DeviceLegendBreak>

                    <DeviceLegend>
                      <p>
                        <span id="desktop">
                          <i class="bi bi-circle-fill" />
                        </span>
                        Desktop
                      </p>

                      <p id="value">
                        {deviceCategory[1]}{" "}
                        <span>
                          (
                          {((deviceCategory[1] * 100) / totalPageviews).toFixed(
                            2
                          )}
                          %)
                        </span>
                      </p>
                    </DeviceLegend>
                  </DevicesLegendContainer>
                )}
                {deviceCategory.length !== 0 && (
                  <Graph>
                    <Doughnut
                      height={"200px"}
                      data={donutData}
                      options={doughnutOptions}
                    />
                  </Graph>
                )}
                {deviceCategory.length === 0 && (
                  <EmptyLayout>
                    <p>No data to show here.</p>
                  </EmptyLayout>
                )}
              </DevicesContent>

              {!isPro && (
                <DevicesBlurContainer>
                  <span>
                    <i class="bi bi-lock"></i>
                  </span>
                  <p>
                    Advanced analytics are only available on pro plan.
                    <a onClick={handleBuyProClick}>Buy Pro.</a>
                  </p>
                </DevicesBlurContainer>
              )}
            </DevicesCard>
          ) : (
            <GraphViewCardLoadingLayout>
              <span id="loader">
                <i class="fas fa-circle-notch"></i>
              </span>
              <p>Loading</p>
            </GraphViewCardLoadingLayout>
          )}

          {isDeviceModelsFetched ? (
            <DevicesCard>
              <DevicesContent isPro={isPro}>
                {mobileDeviceModels.length !== 0 ? (
                  <DevicesTable>
                    <DevicesTR>
                      <DevicesTH></DevicesTH>
                      <DevicesTH style={{ width: "100%" }}>Device</DevicesTH>
                      <DevicesTH>Views</DevicesTH>
                    </DevicesTR>

                    {mobileDeviceModels.map((mobileDeviceModel, index) => (
                      <DevicesTR key={mobileDeviceModel.deviceModelName}>
                        <DevicesTD>{index + 1}.</DevicesTD>
                        <DevicesTD>
                          {mobileDeviceModel.deviceModelName}
                        </DevicesTD>
                        <DevicesTD>
                          {mobileDeviceModel.views}
                          <span>
                            (
                            {(
                              (mobileDeviceModel.views * 100) /
                              deviceCategory[0]
                            ).toFixed(2)}
                            %)
                          </span>
                        </DevicesTD>
                      </DevicesTR>
                    ))}
                  </DevicesTable>
                ) : (
                  <EmptyLayout isPro={isPro}>
                    <p>No data to show here.</p>
                  </EmptyLayout>
                )}
              </DevicesContent>

              {!isPro && (
                <DevicesBlurContainer>
                  <span>
                    <i class="bi bi-lock"></i>
                  </span>
                  <p>
                    Advanced analytics are only available on pro plan.
                    <a onClick={handleBuyProClick}>Buy Pro.</a>
                  </p>
                </DevicesBlurContainer>
              )}
            </DevicesCard>
          ) : (
            <GraphViewCardLoadingLayout>
              <span id="loader">
                <i class="fas fa-circle-notch"></i>
              </span>
              <p>Loading</p>
            </GraphViewCardLoadingLayout>
          )}
        </DevicesLayout>

        <Title>Country</Title>
        <DevicesLayout>
          {isCountriesFetched ? (
            <DevicesCard>
              <DevicesContent isPro={isPro}>
                {countries.length !== 0 ? (
                  <DevicesTable>
                    <DevicesTR>
                      <DevicesTH></DevicesTH>
                      <DevicesTH style={{ width: "100%" }}>Country</DevicesTH>
                      <DevicesTH>Views</DevicesTH>
                    </DevicesTR>

                    {countries.map((country, index) => (
                      <DevicesTR key={country.country}>
                        <DevicesTD>{index + 1}.</DevicesTD>
                        <DevicesTD style={{ width: "100%" }}>
                          {country.country}
                        </DevicesTD>
                        <DevicesTD>
                          {country.views}
                          <span>
                            (
                            {((country.views * 100) / totalPageviews).toFixed(
                              2
                            )}
                            %)
                          </span>
                        </DevicesTD>
                      </DevicesTR>
                    ))}
                  </DevicesTable>
                ) : (
                  <EmptyLayout>
                    <p>No data to show here.</p>
                  </EmptyLayout>
                )}
              </DevicesContent>

              {!isPro && (
                <DevicesBlurContainer>
                  <span>
                    <i class="bi bi-lock"></i>
                  </span>
                  <p>
                    Advanced analytics are only available on pro plan.
                    <a onClick={handleBuyProClick}>Buy Pro.</a>
                  </p>
                </DevicesBlurContainer>
              )}
            </DevicesCard>
          ) : (
            <GraphViewCardLoadingLayout>
              <span id="loader">
                <i class="fas fa-circle-notch"></i>
              </span>
              <p>Loading</p>
            </GraphViewCardLoadingLayout>
          )}
        </DevicesLayout>

        <Title>Cities</Title>
        <DevicesLayout>
          {isCitiesFetched ? (
            <DevicesCard>
              <DevicesContent isPro={isPro}>
                {cities.length !== 0 ? (
                  <DevicesTable>
                    <DevicesTR>
                      <DevicesTH></DevicesTH>
                      <DevicesTH style={{ width: "100%" }}>City</DevicesTH>
                      <DevicesTH>Views</DevicesTH>
                    </DevicesTR>
                    {cities.map((city, index) => (
                      <DevicesTR key={city.city}>
                        <DevicesTD>{index + 1}.</DevicesTD>
                        <DevicesTD style={{ width: "100%" }}>
                          {city.city}
                        </DevicesTD>
                        <DevicesTD>
                          {city.views}
                          <span>
                            ({((city.views * 100) / totalPageviews).toFixed(2)}
                            %)
                          </span>
                        </DevicesTD>
                      </DevicesTR>
                    ))}
                  </DevicesTable>
                ) : (
                  <EmptyLayout>
                    <p>No data to show here.</p>
                  </EmptyLayout>
                )}
              </DevicesContent>

              {!isPro && (
                <DevicesBlurContainer>
                  <span>
                    <i class="bi bi-lock"></i>
                  </span>
                  <p>
                    Advanced analytics are only available on pro plan.
                    <a onClick={handleBuyProClick}>Buy Pro.</a>
                  </p>
                </DevicesBlurContainer>
              )}
            </DevicesCard>
          ) : (
            <GraphViewCardLoadingLayout>
              <span id="loader">
                <i class="fas fa-circle-notch"></i>
              </span>
              <p>Loading</p>
            </GraphViewCardLoadingLayout>
          )}
        </DevicesLayout>
      </Content>
    </Container>
  );
}

export default Analytics;
