import styled from "styled-components";
import { motion } from "framer-motion";

export const Container = styled(motion.div)`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: white;
  background-color: white;
  box-sizing: border-box;
`;

export const Content = styled(motion.div)`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  align-items: center;
  margin: 16px 0px;
  min-height: 70vh;
`;

export const LeftSide = styled(motion.div)`
  border: ${({ theme }) => theme.border};
  display: flex;
  margin: 16px;
  padding: 16px;
  flex-direction: column;
  border-radius: 4px;
  max-width: 750px;
  width: 100%;

  form {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;

    & > span {
      text-align: right;
      margin-top: 8px;
      font-size: 14px;
      margin-right: 0;
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;
