import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player";

import {
  Container,
  ReactPlayerEmpty,
  ReactPlayerError,
  ReactPlayerLoading,
} from "./ResponsivePlayer.elements";

function ResponsivePlayer({ videoURL, margin, theme }) {
  const [isPlayerEmpty, setIsPlayerEmpty] = useState(true);
  const [isPlayerLoading, setIsPlayerLoading] = useState(false);
  const [isPlayerError, setIsPlayerError] = useState(false);

  useEffect(() => {
    if (videoURL === "" || videoURL === "https://" || videoURL === "http://") {
      setIsPlayerEmpty(true);
      setIsPlayerLoading(false);
      setIsPlayerError(false);
    } else {
      setIsPlayerLoading(true);
      setIsPlayerEmpty(false);
      setIsPlayerError(false);
    }
  }, [videoURL]);

  const handleOnPlayerReady = () => {
    setIsPlayerLoading(false);
    setIsPlayerError(false);
    setIsPlayerEmpty(false);
  };

  const handleOnPlayerError = () => {
    setIsPlayerError(true);
    setIsPlayerLoading(false);
    setIsPlayerEmpty(false);
  };

 

  return (
    <Container margin={margin}>
      {!isPlayerEmpty && !isPlayerError && (
        <ReactPlayer
          className="react-player"
          url={videoURL}
          width="100%"
          height="100%"
          controls
          onReady={handleOnPlayerReady}
          onError={handleOnPlayerError}
        
        />
      )}
      {isPlayerLoading && (
        <ReactPlayerLoading>
          {" "}
          <span id="loader">
            <i class="fas fa-circle-notch"></i>
          </span>
          <p>Loading</p>
        </ReactPlayerLoading>
      )}
      {isPlayerEmpty && (
        <ReactPlayerEmpty>
          <span>
            <i class="bi bi-play-btn"></i>
          </span>
          <p>Paste your link below to see preview of the video</p>
        </ReactPlayerEmpty>
      )}
      {isPlayerError && (
        <ReactPlayerError>
          <span>
            <i class="bi bi-bug"></i>
          </span>

          <p>
            Oops something went wrong while previewing your video. Please check
            the link and try again later.
          </p>
        </ReactPlayerError>
      )}
    </Container>
  );
}

export default ResponsivePlayer;
