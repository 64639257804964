import React from "react";

import {
  ActionLayoutContainer,
  ActionLayoutHeader,
  ActionLayoutButtonContainer,
  ActionLayoutButton,
} from "../../themes/globalStyledComponents";

function DeepLinkItem({
  handleCancelClick,
  active,
  handleAddDeepLink,
  handleRemoveDeepLink,
  progress,
  type,
}) {
  return (
    <ActionLayoutContainer>
      <ActionLayoutHeader>
        <h6>Deep Link</h6>

        <span onClick={handleCancelClick}>
          <i class="bi bi-x-lg"></i>
        </span>
      </ActionLayoutHeader>

      <p>When enabled, quickly redirects user to the specified {type}</p>
      <ActionLayoutButtonContainer>
        <ActionLayoutButton
          active={active}
          onClick={active ? handleRemoveDeepLink : handleAddDeepLink}
          variant={active ? "delete" : "deepLink"}
          margin="0px 0px 0px 0px"
        >
          {active && !progress && "Disable deep link"}
          {!active && !progress && " Enable deep link"}

          {progress && (
            <span id="loader">
              <i class="fas fa-circle-notch"></i>
            </span>
          )}
        </ActionLayoutButton>
      </ActionLayoutButtonContainer>
    </ActionLayoutContainer>
  );
}

export default DeepLinkItem;
