import styled from "styled-components";
import { ReactComponent as EmailSentIcon } from "../../images/email_sent.svg";
import { motion } from "framer-motion";

export const Container = styled(motion.div)`
  border: ${({ theme }) => theme.border};
  display: flex;
  margin: 16px;
  padding: 16px;
  flex-direction: column;
  border-radius: 4px;
  max-width: 750px;
`;

export const Heading = styled.h1`
  font-size: 28px;
  font-weight: 600;
`;

export const Title = styled.h3`
  font-size: 22px;
`;

export const SvgWrapper = styled.div`
  /* background-color: blue; */
  width: 100%;
  margin: 24px 0px 24px 0px;
  border-radius: 4px;
  svg {
    width: 100%;
    height: 150px;
  }
`;

// Style the component (treated like styled.svg in this case)
export const EmailSentSvg = styled(EmailSentIcon)`
  height: 200px;
`;

export const Message = styled.p`
  margin: 16px 0px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.8);
  #email {
    color: ${({ theme }) => theme.colors.primary};
    font-weight: 550;
  }
`;

export const ResendCounter = styled.span`
  font-size: 12px;
  text-align: center;
  margin-top: 8px;
  color: rgba(0, 0, 0, 0.8);
`;
