import React from "react";
import {
  ActionLayoutContainer,
  ActionLayoutHeader,
  ActionLayoutButton,
  ActionLayoutButtonContainer,
} from "../../themes/globalStyledComponents";

function DeleteItem({ handleCancelClick, handleDeleteClick, type, progress }) {
  return (
    <ActionLayoutContainer>
      <ActionLayoutHeader>
        <h6>Delete {type}</h6>
        <span onClick={handleCancelClick}>
          <i class="bi bi-x-lg"></i>
        </span>
      </ActionLayoutHeader>

      <p>Are you sure you want to delete this {type} permanently</p>

      <ActionLayoutButtonContainer>
        <ActionLayoutButton
          margin="0px"
          onClick={handleDeleteClick}
          variant="delete"
        >
          {!progress && `Delete ${type}`}
          {progress && (
            <span id="loader">
              <i class="fas fa-circle-notch"></i>
            </span>
          )}
        </ActionLayoutButton>
      </ActionLayoutButtonContainer>
    </ActionLayoutContainer>
  );
}

export default DeleteItem;
