import styled from "styled-components";

export const Button = styled.button`
  background-color: ${({ navTheme }) =>
    (navTheme === "dark" && "#FFFFFF") || "#4D80E4"};
  border: none;
  outline: none;
  color: ${({ navTheme }) => (navTheme === "dark" && "#000000") || "#FFFFFF"};
  border-radius: 4px;
  padding: 8px 16px;
  min-width: 80px;
  cursor: pointer;
`;
