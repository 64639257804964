import React, { useState } from "react";
import {
  Button,
  ErrorLayout,
  Footer,
  Navbar,
  SuccessLayout,
} from "../../components";
import { auth, db } from "../../firebase/firebase";
import firebase from "firebase";

import {
  Container,
  Heading,
  HeadingMessage,
  Input,
  InputContainer,
  Label,
  Leftside,
  Rightside,
  ReportViolationSvg,
  SvgWrapper,
  TextArea,
  Information,
  Content,
  MobileSvgWrapper,
} from "./Report.elements";

function Report() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [violatorsName, setViolatorsName] = useState("");
  const [violatorsURL, setViolatorsURL] = useState("");
  const [reason, setReason] = useState("");

  const [emailError, setEmailError] = useState("");
  const [violatorsURLError, setViolatorsURLError] = useState("");
  const [reasonError, setReasonError] = useState("");

  const [reportProgress, setReportProgress] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    e.currentTarget.value = e.currentTarget.value.replace(/\s/g, "");
    setEmail(e.target.value);
    setEmailError("");
  };

  const handleViolatorsNameChange = (e) => {
    setViolatorsName(e.target.value);
  };

  const handleViolatorsURLChange = (e) => {
    setViolatorsURL(e.currentTarget.value.replace(/\s/g, ""));
    setViolatorsURLError("");
  };

  const handleReasonChange = (e) => {
    setReason(e.target.value);
    setReasonError("");
  };

  const handleOnSubmitButtonClick = () => {
    setError("");
    setEmailError("");
    setViolatorsURLError("");
    setReasonError("");
    setSuccess("");

    if (
      email === "" ||
      !email
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      setEmailError("Enter valid email");
      document.getElementById("email").focus();
      return;
    }

    if (violatorsURL === "") {
      setViolatorsURLError("Enter violating getmyprofiles URL");
      document.getElementById("violatorsURL").focus();
      return;
    }

    if (reason === "") {
      setReasonError("Enter your reason");
      document.getElementById("reason").focus();
      return;
    }

    setReportProgress(true);

    db.collection("reports")
      .add({
        name: name,
        email: email,
        violatorsName,
        violatorsURL,
        reason,
        userId:
          auth.currentUser !== null ? auth.currentUser.uid : "Not Signed In",
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        isResolved: false,
      })
      .then(() => {
        setError("");
        setSuccess(
          "Thanks for reporting and Thanks for showing concern about our community. We will review the violator and take necessary action if it goes against our policies"
        );
        setReportProgress(false);
        setName("");
        setEmail("");
        setViolatorsName("");
        setViolatorsURL("");
        setReason("");
      })
      .catch((error) => {
        setError(error.message);
        setReportProgress(false);
      });
  };

  return (
    <Container>
      <Navbar navLocation="/report" />
      <Content>
        <Leftside>
          <Heading>Report a Violation - Trust Centre</Heading>
          <HeadingMessage>
            Please use the form below to report a violation or an account you
            think we should review.
          </HeadingMessage>
          <MobileSvgWrapper>
            <SvgWrapper>
              <ReportViolationSvg />
            </SvgWrapper>
          </MobileSvgWrapper>
          <InputContainer>
            <Label for="name">Name</Label>
            <Input
              type="text"
              id="name"
              value={name}
              onChange={handleNameChange}
              placeholder="Enter your name"
            />
          </InputContainer>
          <InputContainer>
            <Label for="email">Email</Label>
            <Input
              type="email"
              id="email"
              value={email}
              onChange={handleEmailChange}
              placeholder="Enter your email"
            />

            {emailError && (
              <ErrorLayout margin="4px 0px 0px 0px" errorMessage={emailError} />
            )}
          </InputContainer>
          <InputContainer>
            <Label for="violatorsName">Violators Name</Label>
            <Input
              type="text"
              id="violatorsName"
              value={violatorsName}
              onChange={handleViolatorsNameChange}
              placeholder="Enter violators name"
            />
          </InputContainer>
          <InputContainer>
            <Label for="violatorsURL">Violating getmyprofiles URL</Label>
            <Input
              type="text"
              id="violatorsURL"
              value={violatorsURL}
              onChange={handleViolatorsURLChange}
              placeholder="Enter violating getmyprofiles URL"
            />
            {violatorsURLError && (
              <ErrorLayout
                margin="4px 0px 0px 0px"
                errorMessage={violatorsURLError}
              />
            )}
          </InputContainer>
          <InputContainer>
            <Label for="reason">
              What is the reason for reporting this getmyprofiles URL/USER ?
            </Label>
            <TextArea
              id="reason"
              type="text"
              value={reason}
              onChange={handleReasonChange}
              placeholder="Enter your reasons here"
              required="true"
            />
            {reasonError && (
              <ErrorLayout
                margin="4px 0px 0px 0px"
                errorMessage={reasonError}
              />
            )}
          </InputContainer>

          {success && (
            <SuccessLayout margin="16px 0px" successMessage={success} />
          )}
          {error && <ErrorLayout margin="16px 0px" errorMessage={error} />}
          <Button
            title="Submit Report"
            handleClick={handleOnSubmitButtonClick}
            progress={reportProgress}
          />

          <Information>
            Please note this form is not for general support requests. If you
            need help with your own Getmyprofiles account, please contact
            support on{" "}
            <a href="mailto:support@getmyprofiles.in">
              support@getmyprofiles.in
            </a>{" "}
            or visit{" "}
            <a href="/help-center" target="_blank">
              Help center
            </a>
          </Information>
        </Leftside>
        <Rightside>
          <h1>
            Lets <br />
            Keep <br />
            Our Community <br />
            Safe and Healthy
          </h1>
          <SvgWrapper>
            <ReportViolationSvg />
          </SvgWrapper>
        </Rightside>
      </Content>
      <Footer />
    </Container>
  );
}

export default Report;
