import styled, { keyframes } from "styled-components";
//Animations

const rotate = keyframes`
  from {
    transform: rotate(0deg);
    transform-origin: center;
  }
  to {
    transform: rotate(360deg);
    transform-origin: center;
  }
`;

export const Container = styled.div`
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  margin: ${({ margin }) => (margin ? margin : "")};

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 4px;
    overflow: hidden;
  }
`;

export const ReactPlayerEmpty = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 4px;
  overflow: hidden;
  border: ${({ theme }) => theme.border};
  background-color: #ffffff;
  color: #ee5253;
  & > span {
    height: 46px;
    width: 44px;
    font-size: 44px;
  }

  & > p {
    margin: 8px 16px 0px 16px;
    text-align: center;
    font-size: 14px;
    color: #676b6f;
  }
`;

export const ReactPlayerLoading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 4px;
  overflow: hidden;
  color: #ee5253;
  border: ${({ theme }) => theme.border};
  background-color: #ffffff;

  & > span {
    height: 36px;
    width: 34px;
    font-size: 34px;
    animation: ${rotate} 0.8s linear infinite;
  }

  & > p {
    margin-top: 8px;
    text-align: center;
    color: #676b6f;
  }
`;

export const ReactPlayerError = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: #ffffff;
  color: #ee5253;

  position: absolute;
  top: 0;
  left: 0;
  border-radius: 8px;
  overflow: hidden;
  border: ${({ theme }) => theme.border};

  & > span {
    height: 46px;
    width: 44px;
    font-size: 44px;
  }

  & > p {
    margin: 8px 16px 0px 16px;
    text-align: center;
    color: #676b6f;
    font-size: 14px;
  }
`;
