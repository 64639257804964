import styled from "styled-components";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

export const Container = styled(motion.div)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #ffffff;
  position: fixed;
  bottom: 0;
  z-index: 11111;
  border-top: 0.5px solid #ededed;

  @media (min-width: 960px) {
    display: none;
  }
`;

export const BottomNavigationLink = styled(Link)`
  text-decoration: none;
  color: inherit;

  .bi {
    font-size: 24px;
  }
`;

export const BottomNavigationItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 8px;
  padding: 8px;
  color: #2b2e4a;
  opacity: ${({ active }) => (active ? "1" : " 0.2")};
  span {
    display: ${({ active }) => (active ? "block" : "none")};
    width: 16px;
    height: 4px;
    margin-top: 2px;
    background-color: #2b2e4a;
    border-radius: 0px 0px 8px 8px;
  }
`;

export const BottomNavigationOverFlowMenuContainer = styled.div`
  display: block;
  padding: 8px;
  background-color: white;
  box-shadow: ${({ theme }) => theme.boxShadows.regular};
  transition: ${({ theme }) => theme.boxShadows.transition};
  position: absolute;
  right: 4px;
  bottom: 70px;
  border-radius: 4px;
`;

export const BottomNavigationOverflowMenuItem = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 0px;
  margin: 4px 0px;
  cursor: pointer;
  text-decoration: none;
  color: #2b2e4a;
  opacity: ${({ active }) => (active ? "1" : " 0.4")};

  & > span {
    margin-right: 16px;
    font-size: 22px;
  }
`;
