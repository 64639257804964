import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { moveUpVariants } from "../../../animations/animation";
import { Button } from "../../../components";
import { openProfile } from "../../../utils/functions";
import { CominSoonSvg } from "../analytics/Analytics.elements";
import { Container, Content } from "../billing/Billing.elements";
import { LoadingLayout, Title } from "../profile/Profile.elements";
import {
  BoostContainer,
  CollaborateContainer,
  CollaborateMessage,
  CollaborateWrapper,
  HappyNewsSvg,
  SvgWrapper,
} from "./Promote.elements";

function Promote({ setLocation }) {
  const location = useLocation();
  useEffect(() => {
    setLocation(location.pathname);
  }, []);

  const handleEnquiryButtonClick = () => {
    openProfile("mailto:ads@getmyprofiles.in");
  };

  return (
    <Container variants={moveUpVariants} initial="hidden" animate="visible">
      <Content>
        <Title>Boost Your Profile</Title>
        <BoostContainer>
          <SvgWrapper>
            <HappyNewsSvg />
          </SvgWrapper>

          <h3>Coming Soon</h3>
        </BoostContainer>
        <Title>Collaborate with Us</Title>
        <CollaborateContainer>
          <CollaborateWrapper>
            <span id="company">
              Getmyprofiles <br />
            </span>
            <span id="cross">
              X <br />
            </span>
            <span id="brand">Your Brand</span>
          </CollaborateWrapper>
          <CollaborateMessage>
            Hi there! Looking to collaborate with us? Leave us an enquiry email
            at <a href="mailto:ads@getmyprofiles.in"> ads@getmyprofiles.in </a>.
            we will get back to you ASAP.
          </CollaborateMessage>
          <Button
            variant={"dashboardPromote"}
            margin="16px 0px"
            title="Enquire Now"
            handleClick={handleEnquiryButtonClick}
          />
        </CollaborateContainer>
      </Content>
    </Container>
  );
}

export default Promote;
