import React from "react";
import { openURL } from "../../utils/functions";

import {
  Container,
  Content,
  FooterList,
  FooterListContaier,
  FooterListHeading,
  FooterListItem,
  FooterListItemLink,
  Section,
} from "./Footer.elements";

function Footer() {


  return (
    <Container>
      <Content>
        <FooterListContaier>
          <FooterListHeading>Company</FooterListHeading>
          <FooterList>
            <FooterListItem>
              <FooterListItemLink to="/contact">About</FooterListItemLink>
            </FooterListItem>
            <FooterListItem>
              <FooterListItemLink>Blog</FooterListItemLink>
            </FooterListItem>
            <FooterListItem>
              <FooterListItemLink to="/pricing">Pricing</FooterListItemLink>
            </FooterListItem>
            <FooterListItem>
              <FooterListItemLink>Careers</FooterListItemLink>
            </FooterListItem>
            <FooterListItem>
              <FooterListItemLink to="/contact">Contact</FooterListItemLink>
            </FooterListItem>
          </FooterList>
        </FooterListContaier>
        <FooterListContaier>
          <FooterListHeading>Support</FooterListHeading>
          <FooterList>
            <FooterListItem>
              <FooterListItemLink>Getting Started</FooterListItemLink>
            </FooterListItem>
            <FooterListItem>
              <FooterListItemLink>Help Topics</FooterListItemLink>
            </FooterListItem>
            <FooterListItem>
              <FooterListItemLink>FAQs</FooterListItemLink>
            </FooterListItem>
            <FooterListItem>
              <FooterListItemLink to="/report">
                Report a Violation
              </FooterListItemLink>
            </FooterListItem>
          </FooterList>
        </FooterListContaier>

        <FooterListContaier>
          <FooterListHeading>Social</FooterListHeading>
          <FooterList>
            <FooterListItem>
              <FooterListItemLink>Facebook</FooterListItemLink>
            </FooterListItem>
            <FooterListItem>
              <FooterListItemLink
                onClick={() =>
                  openURL("https://instagram.com/getmyprofiles.in")
                }
              >
                Instagram
              </FooterListItemLink>
            </FooterListItem>
            <FooterListItem>
              <FooterListItemLink
                onClick={() => openURL("https://twitter.com/getmyprofiles")}
              >
                Twitter
              </FooterListItemLink>
            </FooterListItem>
            <FooterListItem>
              <FooterListItemLink>Linked In</FooterListItemLink>
            </FooterListItem>
          </FooterList>
        </FooterListContaier>

        <FooterListContaier>
          <FooterListHeading>Legal</FooterListHeading>
          <FooterList>
            <FooterListItem>
              <FooterListItemLink to="/privacy">
                Privacy Policy
              </FooterListItemLink>
            </FooterListItem>
            <FooterListItem>
              <FooterListItemLink to="/terms">
                Terms and Condition
              </FooterListItemLink>
            </FooterListItem>
            <FooterListItem>
              <FooterListItemLink to="/trust-centre">
                Trust Center
              </FooterListItemLink>
            </FooterListItem>
          </FooterList>
        </FooterListContaier>
      </Content>
    </Container>
  );
}

export default Footer;
