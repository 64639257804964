import styled from "styled-components";
import { motion } from "framer-motion";

import { withStyles } from "@material-ui/core/styles";

import Switch from "@material-ui/core/Switch";

export const MainContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  margin: 8px;
  box-sizing: border-box;
  border-radius: 4px;
  transition: ${({ theme }) => theme.boxShadows.transition};
  box-shadow: ${({ theme, success, error }) =>
    (success && theme.boxShadows.success) || (error && theme.boxShadows.error)};
  cursor: default;
  background-color: #ffffff;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  border: ${({ theme, success, error }) => !success && !error && theme.border};
  padding: 8px;
  border-radius: 5px;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 8px;
`;

export const DragIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: grab;
  font-size: 32px;
  width: 18px;
  color: ${({ theme }) => theme.colors.iconLight};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  /* background-color: black; */
  width: 100%;
  align-items: center;
`;

export const ImageWrapper = styled.div`
  display: flex;
  height: 45px;
  width: 45px;
  margin: 0px 4px 0px 0px;
  /* background-color: blue; */
  & > img {
    height: 100%;
    width: 100%;
    border-radius: 4px;
  }
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  & > p {
    text-transform: capitalize;
    color: #000000;
    font-weight: 450;
  }

  input {
    border: none;
    outline: none;
    background-color: red;
    text-decoration: none;
    background: transparent;
    font-family: ${({ theme }) => theme.fontFamily};
    ::placeholder,
    ::-webkit-input-placeholder {
      color: #b3b3b3;
    }
    :-ms-input-placeholder {
      color: red;
    }
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  input {
    flex: 1;
    border: none;
    outline: none;
    background-color: red;
    text-decoration: none;
    padding-left: 0px;
    background: transparent;
    ::placeholder,
    ::-webkit-input-placeholder {
      color: #b3b3b3;
    }
    :-ms-input-placeholder {
      color: red;
    }
  }

  & > span {
    color: ${({ theme }) => theme.colors.iconLight};
    font-size: 14px;
  }
`;

export const SwitchWrapper = styled.div`
  display: flex;
  margin-left: 4px;

  /* background-color: red; */
`;

export const ActionLayoutWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
  width: 100%;

  & > span {
    font-size: 22px;
    min-height: 0px;
  }
`;

export const ActionIconWrapper = styled.div`
  font-size: 19px;
  height: 18px;
  width: 18px;
  color: ${({ active, type, theme }) =>
    (type === "delete" && active && theme.colors.delete) ||
    (type === "analytics" && active && theme.colors.analytics) ||
    (type === "deepLink" && active && theme.colors.deepLink) ||
    (type === "visit" && active && theme.colors.visit) ||
    (type === "animation" && active && theme.colors.animation) ||
    theme.colors.iconLight};
  cursor: pointer;
`;

export const ActionProceedLayout = styled.div`
  display: block;
  width: 100%;
  margin-top: 8px;
  min-height: 100px;
  border: ${({ theme, success, error }) => !success && !error && theme.border};
  border-radius: ${({ theme }) => theme.borderRadius};

  padding: 8px;
  transition: height 0.3s ease-in;
`;

export const ProfileInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  img {
    height: 40px;
    width: 40px;
    border-radius: 4px;
  }

  p {
    margin-left: 8px;
    font-size: 18px;
    text-transform: capitalize;
  }
`;

export const ActionLayout = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;

  div {
    display: flex;
  }
`;

export const ActionButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  div {
    border-radius: 4px;
    border: 1px solid #dee3ed;
    padding: 8px;
    justify-content: center;
    align-items: center;
    min-height: 40px;
    min-width: 40px;
  }

  span {
    font-size: 12px;
    margin-top: 4px;
  }
`;

export const ClicksActionButton = styled(ActionButton)`
  margin-left: 8px;
`;

export const DeleteActionButton = styled(ActionButton)``;

export const ActiveSwitch = withStyles((theme) => ({
  root: {
    width: 40,
    height: 22,
    padding: 0,

    // margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(18px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#52d869",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 20,
    height: 20,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});
