import React from "react";

import {
  ActionLayoutContainer,
  ActionLayoutHeader,
  ActionLayoutButton,
  ActionLayoutButtonContainer,
} from "../../themes/globalStyledComponents";

function VisitItem({ handleCancelClick, type, progress, handleVisitLink }) {
  return (
    <ActionLayoutContainer>
      <ActionLayoutHeader>
        <h6>Visit {type}</h6>
        <span onClick={handleCancelClick}>
          <i class="bi bi-x-lg"></i>
        </span>
      </ActionLayoutHeader>

      <p>Do you want to visit this {type} ? </p>

      <ActionLayoutButtonContainer>
        <ActionLayoutButton
          margin="0px"
          onClick={handleVisitLink}
          variant="visit"
        >
          {!progress && "Visit"}
          {progress && (
            <span id="loader">
              <i class="fas fa-circle-notch"></i>
            </span>
          )}
        </ActionLayoutButton>
      </ActionLayoutButtonContainer>
    </ActionLayoutContainer>
  );
}

export default VisitItem;
