import React, { useState, useEffect } from "react";
import { auth, db, provider } from "../../firebase/firebase";
import firebase from "firebase";
import validator from "validator";
import { useHistory } from "react-router";
import { Footer, VerifyEmail } from "../../components";

import { Container, Content, LeftSide } from "../login/Login.elements";
import {
  ErrorLayout,
  GoogleButton,
  Button,
  NavbarType1,
} from "../../components";
import {
  Heading,
  Input,
  Label,
  SubHeading,
  AccountActionLayout,
  LoginWithGoogle,
} from "../../themes/globalStyledComponents";
import { viewProfileTheme } from "../../themes/theme";
import makeid from "../../utils/functions";

function Signup() {
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, []);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [error, setError] = useState("");
  const [progress, setProgress] = useState(false);
  const [googleProgress, setGoogleProgress] = useState(false);
  const [googleError, setGoogleError] = useState("");

  const [showVerificationLayout, setShowVerificationLayout] = useState(false);
  const [user, setUser] = useState(null);

  const handleNavbarButton = () => {
    history.push("/login");
  };

  const handleNameChange = (e) => {
    e.preventDefault();

    setError("");
    setNameError("");
    setName(e.target.value);

    if (e.target.value === "") {
      setNameError("Name is required.");

      return;
    }

    setNameError("");
  };

  const handleEmailChange = (e) => {
    e.preventDefault();

    setError("");
    setEmailError("");

    e.currentTarget.value = e.currentTarget.value.replace(/\s/g, "");
    setEmail(e.currentTarget.value);

    if (e.currentTarget.value === "") {
      setEmailError("Email is required");
      return;
    }

    if (!validator.isEmail(e.currentTarget.value)) {
      setEmailError("Invalid email");
      return;
    }

    setEmailError("");
  };
  const handlePasswordChange = (e) => {
    e.preventDefault();
    setError("");
    setPasswordError("");
    e.currentTarget.value = e.currentTarget.value.replace(/\s/g, "");
    setPassword(e.currentTarget.value);

    if (e.currentTarget.value === "") {
      setPasswordError("Password is required");

      return;
    }

    if (e.currentTarget.value.length < 6) {
      setPasswordError("Password must be at least 6 characters");
      return;
    }

    setPasswordError("");
  };

  const handleConfirmPasswordChange = (e) => {
    e.preventDefault();
    setError("");
    setConfirmPasswordError("");
    e.currentTarget.value = e.currentTarget.value.replace(/\s/g, "");
    setConfirmPassword(e.currentTarget.value);

    if (e.currentTarget.value === "") {
      setConfirmPasswordError("Confirm your password");
      return;
    }

    if (e.currentTarget.value !== password) {
      setConfirmPasswordError("Password doesnot match");
      return;
    }

    setConfirmPasswordError("");
  };

  const handleCreateAccountButton = (e) => {
    e.preventDefault();

    if (nameError || emailError || passwordError || confirmPasswordError) {
      return;
    }

    setNameError("");
    setEmailError("");
    setPasswordError("");
    setConfirmPasswordError("");
    setError("");
    setGoogleError("");
    setProgress(true);

    if (name === "") {
      setNameError("Name is required");
      setProgress(false);
      return;
    }

    if (email === "") {
      setEmailError("Email is required");
      setProgress(false);
      return;
    }

    if (!validator.isEmail(email)) {
      setEmailError("Invalid email");
      setProgress(false);
      return;
    }

    if (password === "") {
      setPasswordError("Password is required");
      setProgress(false);

      return;
    }

    if (password.length < 6) {
      setPasswordError("Password must be at least 8 characters");
      setProgress(false);
      return;
    }

    if (confirmPassword === "") {
      setConfirmPasswordError("Confirm your password");
      setProgress(false);
      return;
    }

    if (confirmPassword !== password) {
      setConfirmPasswordError("Password doesnot match");
      setProgress(false);
      return;
    }

    createUsername(name.replace(/\s/g, "").toLowerCase());
  };

  const createUsername = (Username) => {
    db.collection("users")
      .where("username", "==", Username)
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.empty) {
          createAccountWithEmailAndPassword(Username);
        } else {
          createUsername(Username + "_");
        }
      })
      .catch((error) => {
        setError(error.message);
        setProgress(false);
      });
  };

  const createAccountWithEmailAndPassword = async (username) => {
    await auth
      .createUserWithEmailAndPassword(email, password)
      .then((userCredential) => {
        const signedInUser = userCredential.user;
        setUser(signedInUser);
        signedInUser.sendEmailVerification();
        const uid = signedInUser.uid;

        var expiryDateValue = new Date();
        expiryDateValue.setDate(expiryDateValue.getDate() + 16);

        db.collection("users")
          .doc(uid)
          .set({
            name,
            username: username,
            email,
            profilePhoto:
              "https://firebasestorage.googleapis.com/v0/b/getmyprofiles-fb7d6.appspot.com/o/data%2FdefaultProfilePictures%2Fcool-g50c40fdd6_1280.png?alt=media&token=eadf1793-398a-44cc-a25c-2375afcc426e",
            bio: "",
            isPro: true,
            dateOfProPurchase: firebase.firestore.FieldValue.serverTimestamp(),
            dateOfProExpire: expiryDateValue,
            deepLink: {
              id: "",
              type: "",
              link: "",
            },
            userId: uid,
            accountCreatedOn: firebase.firestore.FieldValue.serverTimestamp(),
          })
          .then(() => {
            db.collection("users")
              .doc(uid)
              .collection("themes")
              .doc("theme")
              .set(viewProfileTheme)
              .then(() => {
                setShowVerificationLayout(true);
                setProgress(false);
                saveBillingDetailsToDatabase(uid);
                firebase.auth().signOut();
              })
              .catch((error) => {
                setError(error.message);
                setProgress(false);
              });
          });
      })
      .catch((err) => {
        setProgress(false);
        switch (err.code) {
          case "auth/email-already-in-use":
          case "auth/invalid-email":
            setEmailError(err.message);
            break;
          case "auth/weak-password":
            setPasswordError(err.message);
            break;

          default:
            setError(err.message);
        }
      });
  };

  const handleGoogleSignIn = (e) => {
    e.preventDefault();
    setError("");
    setEmailError("");
    setPasswordError("");
    setConfirmPasswordError("");
    setNameError("");
    setGoogleError("");
    setGoogleProgress(true);

    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        var user = result.user;
        handleGoogleSignedInUser(user);
      })
      .catch((error) => {
        setGoogleError(error.message);
        setGoogleProgress(false);
      });
  };

  const createGoogleUsername = (Username, user) => {
    db.collection("users")
      .where("username", "==", Username)
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.empty) {
          var expiryDateValue = new Date();
          expiryDateValue.setDate(expiryDateValue.getDate() + 16);

          db.collection("users")
            .doc(user.uid)
            .set({
              name: user.displayName,
              username: Username,
              email: user.email,
              profilePhoto: user.photoURL,
              bio: "",
              isPro: true,
              dateOfProPurchase:
                firebase.firestore.FieldValue.serverTimestamp(),
              dateOfProExpire: expiryDateValue,
              deepLink: {
                id: "",
                type: "",
                link: "",
              },
              userId: user.uid,
              accountCreatedOn: firebase.firestore.FieldValue.serverTimestamp(),
            })
            .then(() => {
              db.collection("users")
                .doc(user.uid)
                .collection("themes")
                .doc("theme")
                .set(viewProfileTheme)
                .then(() => {
                  saveBillingDetailsToDatabase(user.uid);
                })
                .catch((error) => {
                  setGoogleError(error.message);
                  setGoogleProgress(false);
                });
            })
            .catch((error) => {
              setGoogleError(error.message);
              setGoogleProgress(false);
            });
        } else {
          createGoogleUsername(Username + "_", user);
        }
      })
      .catch((error) => {
        setGoogleError(error.message);
        setGoogleProgress(false);
      });
  };

  const handleGoogleSignedInUser = (user) => {
    db.collection("users")
      .doc(user.uid)
      .get()
      .then((doc) => {
        if (doc.exists) {
          history.replace("/dashboard");
          setGoogleError("");
          setGoogleProgress(false);
        } else {
          createGoogleUsername(user.email.replace("@gmail.com", ""), user);
        }
      })
      .catch((error) => {
        setGoogleError(error.message);
        setGoogleProgress(false);
      });
  };

  const saveBillingDetailsToDatabase = (userId) => {
    const BillingId = makeid();

    var expiryDateValue = new Date();
    expiryDateValue.setDate(expiryDateValue.getDate() + 16);

    db.collection("users")
      .doc(userId)
      .collection("billings")
      .doc(BillingId)
      .set({
        billingId: BillingId,
        amount: 1499,
        plan: "Free Trial",
        status: "Success",
        userId: userId,
        paymentId: "free_trial_001",
        orderId: "",
        dateOfProPurchase: firebase.firestore.FieldValue.serverTimestamp(),
        dateOfProExpire: expiryDateValue,
      })
      .then(() => {
        setGoogleProgress(false);
        setGoogleError("");
        history.push("/dashboard");
      })
      .catch((error) => {
        setGoogleError(error.message);
        setGoogleProgress(false);
      });
  };

  return (
    <Container>
      <NavbarType1 title="Login" handleButtonClick={handleNavbarButton} />

      <Content>
        {showVerificationLayout && (
          <VerifyEmail
            heading="Congrats Account Created Successfully 🥳"
            subHeading="Verify Email to continue."
            name={name}
            email={email}
            user={user}
          />
        )}

        {!showVerificationLayout && (
          <LeftSide>
            <Heading>Create Account 👑</Heading>
            <SubHeading>Create your account for free.</SubHeading>
            <form>
              <Label margin="16px 0px 0px 0px" for="name">
                Name
              </Label>
              <Input
                type="text"
                id="name"
                name="name"
                value={name}
                onChange={handleNameChange}
                placeholder="Full Name"
                autoComplete
                spellCheck="false"
              />
              {nameError && (
                <ErrorLayout
                  margin="4px 0px 0px 0px"
                  errorMessage={nameError}
                />
              )}

              <Label margin="16px 0px 0px 0px" for="email">
                Email
              </Label>
              <Input
                type="text"
                id="email"
                name="email"
                value={email}
                onChange={handleEmailChange}
                placeholder="Email"
                autoComplete
              />
              {emailError && (
                <ErrorLayout
                  margin="4px 0px 0px 0px"
                  errorMessage={emailError}
                />
              )}

              <Label margin="16px 0px 0px 0px" for="password">
                Password
              </Label>
              <Input
                type="password"
                id="password"
                name="password"
                value={password}
                onChange={handlePasswordChange}
                placeholder="Password"
              />

              {passwordError && (
                <ErrorLayout
                  margin="4px 0px 0px 0px"
                  errorMessage={passwordError}
                />
              )}

              <Label margin="16px 0px 0px 0px" for="confirmPassword">
                Confirm Password
              </Label>
              <Input
                type="password"
                id="confirmPassword"
                name="confirmPassword"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                placeholder="Confirm Password"
              />
              {confirmPasswordError && (
                <ErrorLayout
                  margin="4px 0px 0px 0px"
                  errorMessage={confirmPasswordError}
                />
              )}
            </form>
            {error && (
              <ErrorLayout
                margin="16px 0px 0px 0px"
                padding="16px 8px"
                errorMessage={error}
              />
            )}
            <Button
              type="button"
              progress={progress}
              margin="24px 0px 0px 0px"
              title="Create Account"
              progressTitle="Creating Account..."
              handleClick={handleCreateAccountButton}
            />
            <AccountActionLayout margin="16px">
              <p>
                Already have an account?{" "}
                <a onClick={handleNavbarButton}>Login.</a>
              </p>
            </AccountActionLayout>

            <LoginWithGoogle>Or login with your google account</LoginWithGoogle>

            {googleError && (
              <ErrorLayout
                margin="16px 0px 0px 0px"
                padding="16px 8px"
                errorMessage={googleError}
              />
            )}

            <GoogleButton
              progress={googleProgress}
              marginTop="12px"
              handleClick={handleGoogleSignIn}
            />
          </LeftSide>
        )}
      </Content>
      <Footer />
    </Container>
  );
}

export default Signup;
