import React, { useEffect, useState, useContext, useRef } from "react";

import {
  ErrorLayout,
  LinkItem,
  ProfileItem,
  Button,
  SuccessLayout,
  LoadingPage,
  AddProfileItem,
  AddLinkItem,
  AddVideoItem,
  VideoItem,
  ImageItem,
  AddImageItem,
} from "../../../components";
import { storage, db, auth } from "../../../firebase/firebase";
import firebase from "firebase";
import makeid, { openProfile, openURL } from "../../../utils/functions";

import { useLocation, useHistory } from "react-router-dom";

import {
  Container,
  Title,
  ProfilesWrapper,
  CustomLinksWrapper,
  Content,
  EmptyLayout,
  TopActionBarContainer,
  TopActionBarItem,
  VideosWrapper,
  ImagesWrapper,
  LoadingLayout,
  ProfileContentWrapper,
} from "./Profile.elements";

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import { fadeInVariants, moveUpVariants } from "../../../animations/animation";
import { AnimatePresence } from "framer-motion";

function Profile({
  user,
  setLocation,
  progressTimeout,
  setShowProgressLayout,
  setShowUpgradeToProLayout,
  setUpgradeType,
}) {
  const location = useLocation();
  useEffect(() => {
    setLocation(location.pathname);
  }, []);

  const profileRef = useRef(null);
  const [isAllProfileListsFetched, setIsAllProfilesListsFetched] =
    useState(false);
  const [showAddProfileItem, setShowAddProfileItem] = useState(false);
  const [allProfiles, setAllProfiles] = useState([]);
  const [profiles, setProfiles] = useState([]);
  const [profileIds, setProfileIds] = useState([]);
  const [isProfilesFetched, setIsProfilesFetched] = useState(false);

  //Links Item

  const linkRef = useRef(null);

  const [showAddLinkItem, setShowAddLinkItem] = useState(false);
  const [links, setLinks] = useState([]);
  const [isLinksFetched, setIsLinksFetched] = useState(false);
  const [deepLink, setDeepLink] = useState("");
  const [deepLinkId, setDeepLinkId] = useState("");

  //videos
  const videoRef = useRef(null);
  const [showAddVideoItem, setShowAddVideoItem] = useState(false);
  const [videos, setVideos] = useState([]);
  const [isVideosFetched, setIsVideosFetched] = useState(false);

  //images

  const imageRef = useRef(null);

  const [showAddImageItem, setShowAddImageItem] = useState(false);
  const [images, setImages] = useState([]);
  const [isImagesFetched, setIsImagesFetched] = useState(false);

  //Top Action Bar

  const handleAddProfileActionIconClick = () => {
    setShowAddProfileItem(!showAddProfileItem);
    setShowAddLinkItem(false);
    setShowAddVideoItem(false);
    setShowAddImageItem(false);
  };

  const handleAddLinkActionIconClick = () => {
    setShowAddLinkItem(!showAddLinkItem);
    setShowAddProfileItem(false);
    setShowAddVideoItem(false);
    setShowAddImageItem(false);
  };

  const handleAddLinkTextClick = () => {
    setShowAddLinkItem(!showAddLinkItem);
    setShowAddProfileItem(false);
    setShowAddVideoItem(false);
    setShowAddImageItem(false);

    window.scrollTo(0, 200);
  };

  const handleAddImageActionIconClick = () => {
    setShowAddImageItem(!showAddImageItem);
    setShowAddProfileItem(false);
    setShowAddLinkItem(false);
    setShowAddVideoItem(false);
  };

  const handleAddImageTextClick = () => {
    setShowAddImageItem(!showAddImageItem);
    setShowAddProfileItem(false);
    setShowAddLinkItem(false);
    setShowAddVideoItem(false);
    window.scrollTo(0, 200);
  };

  const handleAddVideoActionIconClick = () => {
    setShowAddVideoItem(!showAddVideoItem);
    setShowAddProfileItem(false);
    setShowAddLinkItem(false);
    setShowAddImageItem(false);
  };

  const handleAddVideoTextClick = () => {
    setShowAddVideoItem(!showAddVideoItem);
    setShowAddProfileItem(false);
    setShowAddLinkItem(false);
    setShowAddImageItem(false);
    window.scrollTo(0, 200);
  };

  //Profiles

  const getProfiles = () => {
    let profileLists;
    let profileIdValues;

    db.collection("users")
      .doc(user.userId)
      .collection("profiles")
      .orderBy("order", "asc")
      .get()
      .then((snapshot) => {
        profileLists = snapshot.docs.map((doc) => doc.data());
        setProfiles(profileLists);
        profileIdValues = snapshot.docs.map((doc) => doc.id);
        setProfileIds(profileIdValues);
        setIsProfilesFetched(true);
      });
  };

  const getChangedProfiles = () => {
    db.collection("users")
      .doc(user.userId)
      .collection("profiles")
      .orderBy("order", "asc")
      .onSnapshot((snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
            getProfiles();
          }

          if (change.type === "removed") {
            getProfiles();
          }
        });
      });
  };

  const getAllProfiles = () => {
    setIsAllProfilesListsFetched(false);
    db.collection("app")
      .doc("data")
      .collection("profiles")
      .orderBy("profileId")
      .get()
      .then((querySnapshot) => {
        const allProfiles = [];
        querySnapshot.forEach((doc) => {
          if (!profileIds.includes(doc.id)) {
            allProfiles.push(doc.data());
          }
        });
        setAllProfiles(allProfiles);
        setIsAllProfilesListsFetched(true);
      })
      .catch((error) => {});
  };

  const onProfileDragEnd = (result) => {
    if (!result.destination) return;
    if (
      result.source.droppableId === result.destination.droppableId &&
      result.source.index === result.destination.index
    )
      return;

    const reOrderedProfiles = Array.from(profiles);
    const [reOrderedProfileItem] = reOrderedProfiles.splice(
      result.source.index,
      1
    );
    reOrderedProfiles.splice(result.destination.index, 0, reOrderedProfileItem);

    setProfiles(reOrderedProfiles);

    reOrderedProfiles.map((reOrderedProfile, index) => {
      db.collection("users")
        .doc(user.userId)
        .collection("profiles")
        .doc(reOrderedProfile.profileId)
        .update({
          order: index,
        });
    });

    setShowProgressLayout(true);
    setTimeout(() => {
      setShowProgressLayout(false);
    }, progressTimeout);
  };

  const scrollToProfileView = () => {
    // profileRef.current.scrollIntoView();
  };

  // CustomLinksSection

  const getLinks = () => {
    let linkValues;

    db.collection("users")
      .doc(user.userId)
      .collection("links")
      .orderBy("order")
      .get()
      .then((snapshot) => {
        linkValues = snapshot.docs.map((doc) => doc.data());
        setLinks(linkValues);
        setIsLinksFetched(true);
      });
  };

  const getChangedLinks = () => {
    db.collection("users")
      .doc(user.userId)
      .collection("links")
      .orderBy("order", "asc")
      .onSnapshot((snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
            getLinks();
          }

          if (change.type === "removed") {
            getLinks();
          }
        });
      });
  };

  const onLinksDragEnd = (result) => {
    if (!result.destination) return;
    if (
      result.source.droppableId === result.destination.droppableId &&
      result.source.index === result.destination.index
    )
      return;

    const reOrderedLinks = Array.from(links);
    const [reOrderedLinkItem] = reOrderedLinks.splice(result.source.index, 1);
    reOrderedLinks.splice(result.destination.index, 0, reOrderedLinkItem);

    setLinks(reOrderedLinks);

    reOrderedLinks.map((reOrderedLink, index) => {
      db.collection("users")
        .doc(user.userId)
        .collection("links")
        .doc(reOrderedLink.linkId)
        .update({
          order: index,
        });
    });

    setShowProgressLayout(true);
    setTimeout(() => {
      setShowProgressLayout(false);
    }, progressTimeout);
  };

  const scrollToLinkView = () => {
    linkRef.current.scrollIntoView();
  };

  //Image Section

  const getImages = () => {
    let imageValues;

    db.collection("users")
      .doc(user.userId)
      .collection("images")
      .orderBy("order")
      .get()
      .then((snapshot) => {
        imageValues = snapshot.docs.map((doc) => doc.data());
        setImages(imageValues);
        setIsImagesFetched(true);
      });
  };

  const getChangedImages = () => {
    db.collection("users")
      .doc(user.userId)
      .collection("images")
      .orderBy("order", "asc")
      .onSnapshot((snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
            getImages();
          }

          if (change.type === "removed") {
            getImages();
          }
        });
      });
  };

  const onImagesDragEnd = (result) => {
    if (!result.destination) return;
    if (
      result.source.droppableId === result.destination.droppableId &&
      result.source.index === result.destination.index
    )
      return;

    const reOrderedImages = Array.from(images);
    const [reOrderedImageItem] = reOrderedImages.splice(result.source.index, 1);
    reOrderedImages.splice(result.destination.index, 0, reOrderedImageItem);

    setImages(reOrderedImages);

    reOrderedImages.map((reOrderedImage, index) => {
      db.collection("users")
        .doc(user.userId)
        .collection("images")
        .doc(reOrderedImage.imageId)
        .update({
          order: index,
        });
    });

    setShowProgressLayout(true);
    setTimeout(() => {
      setShowProgressLayout(false);
    }, progressTimeout);
  };

  const scrollToImageView = () => {
    imageRef.current.scrollIntoView();
  };

  // Video Section

  const getVideos = () => {
    let videoValues;

    db.collection("users")
      .doc(user.userId)
      .collection("videos")
      .orderBy("order")
      .get()
      .then((snapshot) => {
        videoValues = snapshot.docs.map((doc) => doc.data());
        setVideos(videoValues);
        setIsVideosFetched(true);
      });
  };

  const onVideosDragEnd = (result) => {
    if (!result.destination) return;
    if (
      result.source.droppableId === result.destination.droppableId &&
      result.source.index === result.destination.index
    )
      return;

    const reOrderedVideos = Array.from(videos);
    const [reOrderedVideoItem] = reOrderedVideos.splice(result.source.index, 1);
    reOrderedVideos.splice(result.destination.index, 0, reOrderedVideoItem);

    setVideos(reOrderedVideos);

    reOrderedVideos.map((reOrderedVideo, index) => {
      db.collection("users")
        .doc(user.userId)
        .collection("videos")
        .doc(reOrderedVideo.videoId)
        .update({
          order: index,
        });
    });

    setShowProgressLayout(true);
    setTimeout(() => {
      setShowProgressLayout(false);
    }, progressTimeout);
  };

  const getChangedVideos = () => {
    db.collection("users")
      .doc(user.userId)
      .collection("videos")
      .orderBy("order", "asc")
      .onSnapshot((snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
            getVideos();
          }

          if (change.type === "removed") {
            getVideos();
          }
        });
      });
  };

  const scrollToVideoView = () => {
    videoRef.current.scrollIntoView();
  };

  //////

  const initializeUseStates = () => {
    if (user) {
      setDeepLink(user.deepLink.link);
      setDeepLinkId(user.deepLink.id);
    }
  };

  useEffect(() => {
    getAllProfiles();
  }, [profileIds]);

  useEffect(() => {
    initializeUseStates();
  }, [user]);

  useEffect(() => {
    getLinks();
    getProfiles();
    getImages();
    getVideos();
    getChangedProfiles();
    getChangedLinks();
    getChangedImages();
    getChangedVideos();
  }, []);

  useEffect(() => {
    document.title = "Profile" + " | " + process.env.REACT_APP_TITLE;
  }, []);

  return (
    <Container variants={moveUpVariants} initial="hidden" animate="visible">
      <Content>
        <AnimatePresence exitBeforeEnter>
          <TopActionBarContainer>
            <TopActionBarItem
              active={showAddProfileItem}
              onClick={handleAddProfileActionIconClick}
            >
              <span>
                <i class="bi bi-person-plus"></i>
              </span>
              <p>Profile</p>
            </TopActionBarItem>
            <TopActionBarItem
              active={showAddLinkItem}
              onClick={handleAddLinkActionIconClick}
            >
              <span>
                <i class="bi bi-link-45deg"></i>
              </span>
              <p>Link</p>
            </TopActionBarItem>
            <TopActionBarItem
              active={showAddImageItem}
              onClick={handleAddImageActionIconClick}
            >
              <span>
                <i class="bi bi-image"></i>
              </span>
              <p>Image</p>
            </TopActionBarItem>
            <TopActionBarItem
              active={showAddVideoItem}
              onClick={handleAddVideoActionIconClick}
            >
              <span>
                <i class="bi bi-camera-reels"></i>
              </span>
              <p>Video</p>
            </TopActionBarItem>
          </TopActionBarContainer>
          {showAddProfileItem && (
            <AddProfileItem
              allProfiles={allProfiles}
              profiles={profiles}
              isAllProfileListsFetched={isAllProfileListsFetched}
              setShowAddProfileItem={setShowAddProfileItem}
              scrollToProfileView={scrollToProfileView}
              setShowUpgradeToProLayout={setShowUpgradeToProLayout}
              setUpgradeType={setUpgradeType}
            />
          )}
          {showAddLinkItem && (
            <AddLinkItem
              links={links}
              setShowAddLinkItem={setShowAddLinkItem}
              scrollToLinkView={scrollToLinkView}
              setShowUpgradeToProLayout={setShowUpgradeToProLayout}
              setUpgradeType={setUpgradeType}
            />
          )}

          {showAddImageItem && (
            <AddImageItem
              images={images}
              setShowAddImageItem={setShowAddImageItem}
              scrollToImageView={scrollToImageView}
              setShowUpgradeToProLayout={setShowUpgradeToProLayout}
              setUpgradeType={setUpgradeType}
            />
          )}

          {showAddVideoItem && (
            <AddVideoItem
              setShowAddVideoItem={setShowAddVideoItem}
              videos={videos}
              scrollToVideoView={scrollToVideoView}
              setShowUpgradeToProLayout={setShowUpgradeToProLayout}
              setUpgradeType={setUpgradeType}
            />
          )}
        </AnimatePresence>
        <ProfileContentWrapper ref={profileRef}>
          <Title marginTop="16px">Profiles</Title>

          {!isProfilesFetched && (
            <LoadingLayout>
              {" "}
              <span id="loader">
                <i class="fas fa-circle-notch"></i>
              </span>
              <p>Loading</p>
            </LoadingLayout>
          )}
          {isProfilesFetched && profiles.length != 0 && (
            <DragDropContext onDragEnd={onProfileDragEnd}>
              <Droppable droppableId="1234567">
                {(provided, snapshot) => (
                  <ProfilesWrapper ref={provided.innerRef}>
                    {profiles.map((profile, index) => (
                      <Draggable
                        draggableId={profile.profileId}
                        key={profile.profileId}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <ProfileItem
                              progressTimeout={progressTimeout}
                              setShowProgressLayout={setShowProgressLayout}
                              user={user}
                              profile={profile}
                              deepLink={deepLink}
                              setDeepLink={setDeepLink}
                              deepLinkId={deepLinkId}
                              setDeepLinkId={setDeepLinkId}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </ProfilesWrapper>
                )}
              </Droppable>
            </DragDropContext>
          )}
          {isProfilesFetched && profiles.length === 0 && (
            <EmptyLayout>
              <p>
                You haven't added any Profiles yet.
                <a onClick={handleAddProfileActionIconClick}>Add Profile</a>
              </p>
            </EmptyLayout>
          )}

          <Title ref={linkRef}>Links</Title>

          {!isLinksFetched && (
            <LoadingLayout>
              {" "}
              <span id="loader">
                <i class="fas fa-circle-notch"></i>
              </span>
              <p>Loading</p>
            </LoadingLayout>
          )}

          {isLinksFetched && links.length !== 0 && (
            <DragDropContext onDragEnd={onLinksDragEnd}>
              <Droppable droppableId="12345678">
                {(provided, snapshot) => (
                  <CustomLinksWrapper ref={provided.innerRef}>
                    {links.map((link, index) => (
                      <Draggable
                        draggableId={link.linkId}
                        key={link.linkId}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <LinkItem
                              progressTimeout={progressTimeout}
                              setShowProgressLayout={setShowProgressLayout}
                              user={user}
                              key={link.linkId}
                              link={link}
                              deepLink={deepLink}
                              setDeepLink={setDeepLink}
                              deepLinkId={deepLinkId}
                              setDeepLinkId={setDeepLinkId}
                              variants={fadeInVariants}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </CustomLinksWrapper>
                )}
              </Droppable>
            </DragDropContext>
          )}

          {isLinksFetched && links.length === 0 && (
            <EmptyLayout>
              <p>
                You haven't added any Links yet.
                <a onClick={handleAddLinkTextClick}>Add Link</a>
              </p>
            </EmptyLayout>
          )}

          <Title ref={imageRef}>Images</Title>
          {isImagesFetched && images.length !== 0 && (
            <DragDropContext onDragEnd={onImagesDragEnd}>
              <Droppable droppableId="123456789">
                {(provided, snapshot) => (
                  <ImagesWrapper ref={provided.innerRef}>
                    {images.map((image, index) => (
                      <Draggable
                        draggableId={image.imageId}
                        key={image.imageId}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <ImageItem
                              progressTimeout={progressTimeout}
                              setShowProgressLayout={setShowProgressLayout}
                              user={user}
                              key={image.imageId}
                              image={image}
                              deepLink={deepLink}
                              setDeepLink={setDeepLink}
                              deepLinkId={deepLinkId}
                              setDeepLinkId={setDeepLinkId}
                              variants={fadeInVariants}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </ImagesWrapper>
                )}
              </Droppable>
            </DragDropContext>
          )}

          {!isImagesFetched && (
            <LoadingLayout>
              {" "}
              <span id="loader">
                <i class="fas fa-circle-notch"></i>
              </span>
              <p>Loading</p>
            </LoadingLayout>
          )}

          {isImagesFetched && images.length === 0 && (
            <EmptyLayout>
              <p>
                You haven't added any Images yet.
                <a onClick={handleAddImageTextClick}>Add Image</a>
              </p>
            </EmptyLayout>
          )}

          <Title ref={videoRef}>Videos</Title>

          {!isVideosFetched && (
            <LoadingLayout>
              {" "}
              <span id="loader">
                <i class="fas fa-circle-notch"></i>
              </span>
              <p>Loading</p>
            </LoadingLayout>
          )}

          {isVideosFetched && videos.length !== 0 && (
            <DragDropContext onDragEnd={onVideosDragEnd}>
              <Droppable droppableId="1234567890">
                {(provided, snapshot) => (
                  <VideosWrapper ref={provided.innerRef}>
                    {videos.map((video, index) => (
                      <Draggable
                        draggableId={video.videoId}
                        key={video.videoId}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <VideoItem
                              progressTimeout={progressTimeout}
                              setShowProgressLayout={setShowProgressLayout}
                              user={user}
                              key={video.videoId}
                              video={video}
                              deepLink={deepLink}
                              setDeepLink={setDeepLink}
                              deepLinkId={deepLinkId}
                              setDeepLinkId={setDeepLinkId}
                              variants={fadeInVariants}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </VideosWrapper>
                )}
              </Droppable>
            </DragDropContext>
          )}

          {isVideosFetched && videos.length === 0 && (
            <EmptyLayout>
              <p>
                You haven't added any Videos yet.
                <a onClick={handleAddVideoTextClick}>Add Video</a>
              </p>
            </EmptyLayout>
          )}
        </ProfileContentWrapper>
      </Content>
    </Container>
  );
}

export default Profile;
