import React, { useEffect } from "react";
import { Footer, Navbar } from "../../components";
import { useHistory } from "react-router-dom";
import {
  Container,
  Content,
  Heading,
  Paragraph,
  TrustCentreList,
  TrustCentreListItem,
  TrustCentreListItemLink,
} from "./TrustCentre.elemets";

function TrustCentre() {
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, []);

  return (
    <Container>
      <Navbar navLocation="/trust-centre" />
      <Content>
        <Heading>What Is Trust Centre?</Heading>
        <Paragraph>
          Trust centers are the one-stop clearinghouses for information about
          company practices, policies, and procedures on privacy, security,
          transparency, and compliance. By giving customers easy access to this
          information, the public can better understand how companies manage
          data and how they keep confidential information secure and protected.
          Also found in trust centers are the procedures for notifying security
          experts if vulnerabilities are discovered.
          <br />
          <br />
          The trust center describes the technologies, procedures, policies, and
          controls for protecting their infrastructure and the customer’s data
          from cyber-attacks and other fraudulent intrusions. Security topics
          may include:
        </Paragraph>

        <Heading>Our Trust Centre</Heading>
        <Paragraph>
          We take data security and privacy seriously at Getmyprofiles. In the
          Trust Center you'll find general information to give you confidence in
          the way we store the data you entrust to us.
        </Paragraph>
        <TrustCentreList>
          <TrustCentreListItem>
            <TrustCentreListItemLink to="/privacy">
              Privacy Policy
            </TrustCentreListItemLink>
          </TrustCentreListItem>
          <TrustCentreListItem>
            <TrustCentreListItemLink to="/terms">
              Terms & Condition
            </TrustCentreListItemLink>
          </TrustCentreListItem>
        </TrustCentreList>
      </Content>
      <Footer />
    </Container>
  );
}

export default TrustCentre;
