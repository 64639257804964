import React, { useState, useContext, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Profile, Appearance, Settings, Analytics, Billing, Promote } from ".";
import { AuthContext } from "../../contexts/AuthContext";

import {
  DashboardNavbar,
  LoadingPage,
  Navbar,
  BuyPro,
  MobileScreen,
  SuccessLayout,
  BottomNavigation,
  ChatWidget,
} from "../../components";
import {
  Container,
  Content,
  LeftSide,
  MobileScreenLayoutContainer,
  RightSide,
  UsernameHeading,
  UsernameHeadingLight,
  UsernameOverflowContainer,
  UsernameOverflowMenuItem,
  UsernameSection,
  UsernameWrapper,
} from "./Dashboard.elements";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { auth, db } from "../../firebase/firebase";
import useComponentVisible from "../../hooks/useComponentVisible";
import { openProfile } from "../../utils/functions";
import { AnimatePresence } from "framer-motion";
import ScrollToTop from "../../components/ScrollToTop";
import { popOutVariants } from "../../animations/animation";

function Dashboard() {
  const { user } = useContext(AuthContext);
  const [location, setLocation] = useState("");

  const history = useHistory();

  const listenScrollEvent = (event) => {
    if (window.scrollY < 136) {
      setNavbarBackground("#f1f5f8");
    } else {
      setNavbarBackground("#ffffff");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
    return () => window.removeEventListener("scroll", listenScrollEvent);
  });

  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, []);

  const [navbarBackground, setNavbarBackground] = useState("#f1f5f8");
  const [dbUsername, setDbUsername] = useState("");
  const [usernameSuccessMessage, setUsernameSuccessMessage] = useState("");
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);
  const [showUpgradeToProLayout, setShowUpgradeToProLayout] = useState(false);
  const [upgradeType, setUpgradeType] = useState("");
  const [showProgressLayout, setShowProgressLayout] = useState(false);
  const progressTimeout = 2000;

  useEffect(() => {
    setTimeout(() => {
      auth.onAuthStateChanged((user) => {
        if (!user && window.location.pathname !== "/signup") {
          history.push("/login");
        }
      });
    }, 2000);
  }, []);

  useEffect(() => {
    initializeUserStates();
  }, [user]);

  const initializeUserStates = () => {
    if (user) {
      setDbUsername(user.username);
    }
  };

  const handleUsernameOverflowIconClick = () => {
    setIsComponentVisible(!isComponentVisible);
  };

  const handleCopyUsername = () => {
    setIsComponentVisible(false);
    setUsernameSuccessMessage("Profile Link copied to clipboard");
    const profileURL = "getmyprofiles.in/" + dbUsername;

    setTimeout(() => {
      setUsernameSuccessMessage("");
    }, 10000);

    if (navigator.clipboard && window.isSecureContext) {
      return navigator.clipboard.writeText(profileURL);
    } else {
      let textArea = document.createElement("textarea");
      textArea.value = profileURL;

      textArea.style.position = "fixed";
      textArea.style.left = "-999999px";
      textArea.style.top = "-999999px";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      return new Promise((res, rej) => {
        // here the magic happens
        document.execCommand("copy") ? res() : rej();
        textArea.remove();
      });
    }
  };

  const handleVisitProfileClick = () => {
    setIsComponentVisible(false);
    const profileURL = "getmyprofiles.in/" + dbUsername;
    openProfile(profileURL);
  };

  return (
    <Container>
      <Navbar navLocation="/dashboard" background={navbarBackground} />
      {user ? (
        <Content>
          <LeftSide>
            <UsernameSection>
              <UsernameHeading>
                Hi <span id="name">{user.name} </span> 👋
              </UsernameHeading>
              <UsernameHeadingLight>Welcome Back!</UsernameHeadingLight>

              <UsernameWrapper>
                <div>
                  <p onClick={handleVisitProfileClick}>
                    getmyprofiles.in/<span>{dbUsername}</span>
                  </p>

                  <span onClick={handleUsernameOverflowIconClick} id="share">
                    <i class="bi bi-sliders"></i>
                  </span>
                </div>
              </UsernameWrapper>

              <AnimatePresence>
                {isComponentVisible && (
                  <UsernameOverflowContainer
                    variants={popOutVariants}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                    ref={ref}
                  >
                    <UsernameOverflowMenuItem onClick={handleCopyUsername}>
                      <span>
                        <i class="bi bi-files"></i>
                      </span>
                      <p>Copy Profile Link</p>
                    </UsernameOverflowMenuItem>
                    <UsernameOverflowMenuItem onClick={handleVisitProfileClick}>
                      <span>
                        <i class="bi bi-box-arrow-up-right"></i>
                      </span>
                      <p>Visit Profile Link</p>
                    </UsernameOverflowMenuItem>
                    <UsernameOverflowMenuItem onClick={handleCopyUsername}>
                      <span>
                        <i class="bi bi-share"></i>
                      </span>
                      <p>Share Profile Link</p>
                    </UsernameOverflowMenuItem>
                  </UsernameOverflowContainer>
                )}
              </AnimatePresence>
            </UsernameSection>
            {usernameSuccessMessage && (
              <SuccessLayout
                margin="-8px 16px 16px 16px"
                successMessage={usernameSuccessMessage}
              />
            )}

            <Router>
              <DashboardNavbar location={location} />
              <ScrollToTop />
              <Switch>
                <Route path="/dashboard" exact>
                  <Profile
                    setShowProgressLayout={setShowProgressLayout}
                    progressTimeout={progressTimeout}
                    setLocation={setLocation}
                    user={user}
                    setUpgradeType={setUpgradeType}
                    setShowUpgradeToProLayout={setShowUpgradeToProLayout}
                  />
                </Route>
                <Route path="/dashboard/analytics" exact>
                  <Analytics user={user} setLocation={setLocation} />
                </Route>
                <Route path="/dashboard/appearance" exact>
                  <Appearance
                    setShowProgressLayout={setShowProgressLayout}
                    progressTimeout={progressTimeout}
                    user={user}
                    setLocation={setLocation}
                    setUpgradeType={setUpgradeType}
                    setShowUpgradeToProLayout={setShowUpgradeToProLayout}
                    setDbUsername={setDbUsername}
                    dbUsername={dbUsername}
                  />
                </Route>

                <Route path="/dashboard/billing" exact>
                  <Billing
                    setShowProgressLayout={setShowProgressLayout}
                    progressTimeout={progressTimeout}
                    user={user}
                    setLocation={setLocation}
                    setUpgradeType={setUpgradeType}
                    setShowUpgradeToProLayout={setShowUpgradeToProLayout}
                  />
                </Route>

                <Route path="/dashboard/promote" exact>
                  <Promote
                    setShowProgressLayout={setShowProgressLayout}
                    progressTimeout={progressTimeout}
                    user={user}
                    setLocation={setLocation}
                    setUpgradeType={setUpgradeType}
                    setShowUpgradeToProLayout={setShowUpgradeToProLayout}
                  />
                </Route>

                <Route path="/dashboard/settings" exact>
                  <Settings user={user} setLocation={setLocation} />
                </Route>
              </Switch>
              <BottomNavigation location={location} />
            </Router>
            <AnimatePresence>
              <ChatWidget variant="dashboard" progress={showProgressLayout} />
              {showUpgradeToProLayout && (
                <BuyPro
                  setShowUpgradeToProLayout={setShowUpgradeToProLayout}
                  type={upgradeType}
                />
              )}
            </AnimatePresence>
          </LeftSide>
          <RightSide>
            <MobileScreenLayoutContainer>
              <MobileScreen userId={user.userId} />
            </MobileScreenLayoutContainer>
          </RightSide>
        </Content>
      ) : (
        <LoadingPage />
      )}
    </Container>
  );
}

export default Dashboard;
