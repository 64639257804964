import styled from "styled-components";
import { motion } from "framer-motion";

export const Container = styled(motion.div)``;

export const Content = styled(motion.div)`
  background-color: white;
  min-height: 80vh;
  padding: 16px;
`;

export const Heading = styled(motion.h4)`
  font-size: 24px;
  margin: 16px 0px;
`;

export const Paragraph = styled(motion.p)`
  color: #121212;
  line-height: 1.5;
`;

export const TermsList = styled(motion.ul)`
  margin: 16px 24px;
`;

export const TermsListItem = styled(motion.li)`
  padding: 8px;
`;
