import { motion } from "framer-motion";
import styled from "styled-components";
import { ReactComponent as RenewProIcon } from "../../../images/renew_pro.svg";

export const Container = styled(motion.div)`
  background-color: #f1f5f8;
`;

export const Content = styled.div`
  background-color: #ffffff;
  border-radius: 24px 24px 0px 0px;
  margin-top: 16px;
  padding: 24px 0px 125px 0px;
`;

export const Heading = styled.h5``;

export const RenewContainer = styled.div`
  display: block;
  padding: 0px 16px;
  margin-bottom: 24px;
`;

export const SvgWrapper = styled.div`
  background-color: rgba(255, 143, 80, 0.05);
  width: 100%;
  border-radius: 4px;
  margin-bottom: 16px;
  svg {
    width: 100%;
    height: 200px;
    padding: 24px;
  }
`;

export const ExpiryHeading = styled.h6`
  text-align: center;
  font-size: 18px;
  color: #2b2e4a;
  margin-bottom: 8px;

  #expiryDays {
    color: red;
  }
`;

export const ExpiryMessage = styled.p`
  color: #6c6d7f;
  text-align: center;
  #expiryDays {
    color: red;
  }
`;

// Style the component (treated like styled.svg in this case)
export const RenewProSvg = styled(RenewProIcon)`
  height: 200px;
`;

export const BillingContainer = styled.div`
  padding: 0px 16px;
`;

export const EmptyLayout = styled.div`
  display: flex;
  border: ${({ theme }) => theme.border};
  border-radius: 4px;
  min-height: 150px;
  margin: 16px 0px 24px 0px;
  padding: 16px;
  align-items: center;
  justify-content: center;

  p {
    text-align: center;
    letter-spacing: 1.2px;
  }

  a {
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.pro};
    font-weight: 500;
    cursor: pointer;
    margin-left: 4px;
  }
`;
