import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import AuthContextProvider from "./contexts/AuthContext";
import ReactGA from "react-ga";
import {
  Home,
  Dashboard,
  Pricing,
  Contactus,
  Login,
  Signup,
  ViewProfile,
  Privacy,
  Terms,
  TrustCentre,
  Report,
} from "./pages";
import { theme } from "./themes/theme";
import GlobalStyle from "./themes/globalStyles";
import { ErrorBoundary } from "react-error-boundary";
import ErrorPage from "./components/error-page/ErrorPage";
import ScrollToTop from "./components/ScrollToTop";

function App() {




 

  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <ErrorBoundary FallbackComponent={ErrorPage}>
          <AuthContextProvider>
            <ScrollToTop />
            <GlobalStyle />
            <Switch>
              <Route path="/" exact component={Home} />
              <Route path="/dashboard" exact component={Dashboard} />
              <Route path="/dashboard/settings" exact component={Dashboard} />
              <Route path="/dashboard/analytics" exact component={Dashboard} />
              <Route path="/dashboard/appearance" exact component={Dashboard} />
              <Route path="/dashboard/billing" exact component={Dashboard} />
              <Route path="/dashboard/promote" exact component={Dashboard} />
              <Route path="/pricing" exact component={Pricing} />
              <Route path="/contact" exact component={Contactus} />
              <Route path="/login" exact component={Login} />
              <Route path="/signup" exact component={Signup} />
              <Route path="/report" exact component={Report} />
              <Route path="/trust-centre" exact component={TrustCentre} />
              <Route path="/privacy" exact component={Privacy} />
              <Route path="/terms" exact component={Terms} />
              <Route path="/:username" exact component={ViewProfile} />
            </Switch>
          </AuthContextProvider>
        </ErrorBoundary>
      </Router>
    </ThemeProvider>
  );
}

export default App;
