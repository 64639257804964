import styled from "styled-components";

export const Container = styled.div`
  background-color: white;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  & > h6 {
    text-transform: capitalize;
  }
  & > span {
    margin-top: 12px;
    font-size: 16px;
    color: ${({ theme }) => theme.colors.delete};
  }
`;

export const ActionLayout = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 24px;
`;

export const CloseIconWrapper = styled.div`
  position: absolute;
  right: -6px;
  top: -6px;
`;

export const Section = styled.div`
  position: relative;
`;

export const AnalyticsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  background-color: white;
  filter: ${({ isPro }) => (!isPro ? "blur(4px)" : "")};
`;

export const BlurWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  justify-content: center;
  background: transparent;
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 0px 16px;
  z-index: 222;

  & > p {
    text-align: center;
    letter-spacing: 1.2px;
    & > a {
      color: red;
      margin-left: 4px;
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

export const Analytics = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
  align-items: center;

  & > p {
    font-size: 18px;
    font-weight: 600;
  }

  & > span {
    font-size: 14px;
    color: ${({ theme }) => theme.textColors.secondary};
    text-transform: capitalize;
    margin-top: 4px;
  }
`;

export const ActionNextLayout = styled.div`
  display: flex;
  flex-direction: row;
  font-weight: 600;
  margin-top: 8px;
  font-size: 14px;
  text-transform: capitalize;
  align-items: center;
  justify-content: flex-end;
  color: ${({ theme }) => theme.colors.analytics};

  filter: ${({ isPro }) => (!isPro ? "blur(4px)" : "")};
  & > span {
    font-size: 18px;
    margin-left: 8px;
  }
`;
