import React from "react";
import styled from "styled-components";

function LinkStyleItem({ active, linkStyle, handleClick }) {
  return (
    <Container active={active}>
      <LinkShape
        onClick={() => handleClick(linkStyle)}
        linkStyle={linkStyle}
      ></LinkShape>
    </Container>
  );
}

export const Container = styled.div`
  padding: 8px;
  margin: 8px;
  border: ${({ active, theme }) =>
    active ? theme.appearance.active.border : "1px solid transparent"};
  width: fit-content;
  background-color: ${({ active, theme }) =>
    active ? theme.appearance.active.background : ""};
  border-radius: 4px;
  cursor: pointer;
`;

export const LinkShape = styled.div`
  width: 100px;
  height: 40px;
  border: ${({ theme, active }) =>
    active
      ? theme.appearance.active.itemBorder
      : theme.appearance.border.itemBorder};
  background-color: ${({ linkStyle }) =>
    linkStyle.background ? " #f2f2f2" : "#FFFFFF"};
  border-radius: ${({ linkStyle }) => linkStyle.borderRadius};
`;

export default LinkStyleItem;
