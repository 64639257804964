import React from "react";
import { NavLogoContainer } from "./NavbarLogo.elements";

function NavbarLogo({ navTheme }) {
  return (
    <NavLogoContainer to="/" navTheme={navTheme}>
      <p>Getmyprofiles.in</p>
    </NavLogoContainer>
  );
}

export default NavbarLogo;
