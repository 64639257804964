import styled, { keyframes } from "styled-components";

import { motion } from "framer-motion";

export const rotate = keyframes`
from {
  transform: rotate(0deg);
  transform-origin: center;
}
to {
  transform: rotate(360deg);
  transform-origin: center;
}
`;

export const Container = styled(motion.div)`
  display: flex;
  flex-direction: column;
  margin-top: ${({ marginTop }) => marginTop};
  margin: ${({ margin }) => margin};
  box-sizing: border-box;
  font-family: ${({ theme }) => theme.fontFamily};
`;

export const ButtonLayout = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ theme, height }) => (height ? height : theme.button.height)};
  background-color: ${({ theme, disabled, variant }) =>
    (disabled && theme.button.disabledBackground) ||
    (variant === "delete" && theme.colors.delete) ||
    (variant === "pro" && theme.colors.pro) ||
    (variant === "cancel" && theme.colors.buttonSecondary) ||
    (variant === "dashboardAdd" && "#ee5253") ||
    (variant === "dashboardPromote" && "#130f40") ||
    theme.button.background};
  border-radius: ${({ theme }) => theme.borderRadius};
  font-size: ${({ theme }) => theme.button.fontSize};
  border: none;
  outline: none;
  color: ${({ theme, variant, disabled }) =>
    (variant === "cancel" && "#666666") ||
    (variant === "dashboardAdd" && "#f1f5f8") ||
    (variant === "dashboardPromote" && "#ffec78") ||
    (disabled && theme.button.disabledColor) ||
    theme.button.color};
  cursor: pointer;

  #loader {
    height: 26px;
    width: 24px;
    font-size: 24px;
    color: white;
    animation: ${rotate} 0.8s linear infinite;
  }
`;
