import React, { useEffect, useState } from "react";
import firebase from "firebase";

import {
  Container,
  SvgWrapper,
  EmailSentSvg,
  Heading,
  Title,
  Message,
} from "./VerifyEmail.elements";

import { Button, ErrorLayout, SuccessLayout } from "../";
import { useHistory } from "react-router-dom";
import {
  ActionBackLayout,
  CounterLayout,
} from "../../themes/globalStyledComponents";

function VerifyEmail({ email, name, user, from, setShowVerificationLayout }) {
  const history = useHistory();
  const [counter, setCounter] = useState(60);
  const [disabled, setDisabled] = useState(true);
  const [progress, setProgress] = useState(false);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const onEmailVerificationButtonClick = () => {
    if (disabled) {
      return;
    }
    setProgress(true);
    setError("");
    setSuccessMessage("");
    user
      .sendEmailVerification()
      .then(() => {
        setDisabled(true);
        setCounter(60);
        setProgress(false);
        setSuccessMessage(`Verification email sent successfully to ${email}`);
      })
      .catch((error) => {
        setError(error.Message);
        setProgress(false);
        setSuccessMessage("");
      });
  };

  const onActionBackLayoutClick = () => {
    if (from === "login") {
      setShowVerificationLayout(false);
      return;
    }

    history.replace("/login");
  };

  useEffect(() => {
    var firebaseUser = firebase.auth().currentUser;
    if (firebaseUser) {
      firebase.auth().signOut();
    }
  });

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    if (counter === 0) {
      setDisabled(false);
    }
  }, [counter]);

  return (
    <Container>
      <Heading>Verify your email address</Heading>
      <SvgWrapper>
        <EmailSentSvg />
      </SvgWrapper>

      <Title>Hey {name} !</Title>

      <Message>
        Thanks for registering an account with Getmyprofiles! We had sent an
        verification link to <span id="email">{email}</span>. Please click on
        the link to verify your email address and login back to get started.
      </Message>

      {error && (
        <ErrorLayout
          margin="16px 0px 0px 0px"
          padding="16px 8px"
          errorMessage={error}
        />
      )}

      {successMessage && (
        <SuccessLayout
          margin="16px 0px 0px 0px"
          padding="16px 8px"
          successMessage={successMessage}
        />
      )}

      <Button
        margin="16px 0px 0px 0px"
        title="Resend Email Verification Link"
        progressTitle="Sending email verification link..."
        progress={progress}
        disabled={disabled}
        handleClick={onEmailVerificationButtonClick}
      />
      {counter !== 0 && (
        <CounterLayout margin="16px 0px">
          Didn`t recieved email ? Resened Verification email in {counter}{" "}
          seconds.
        </CounterLayout>
      )}

      <ActionBackLayout
        margin="24px 16px 16px 16px "
        onClick={onActionBackLayoutClick}
      >
        <span>
          <i class="fas fa-long-arrow-alt-left" />
        </span>
        Go back to login page
      </ActionBackLayout>
    </Container>
  );
}

export default VerifyEmail;
