export const whileHover = {
  scale: 1.05,
};

export const whileTap = {
  scale: 0.95,
};

export const fadeInVariants = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
};

export const moveRightContainerVariants = {
  initial: {
    x: "-100%",
    opacity: 0,
  },
  animate: {
    x: 0,
    opacity: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.1,
    },
  },
};

export const moveUpContainerVariants = {
  initial: {
    y: -5,
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      type: "inertia",
    },
  },
};

export const animationvariants = {
  flash: {
    opacity: [1, 0, 1, 0, 1],
    transition: {
      repeat: Infinity,
      repeatDelay: 1,
      repeatType: "reverse",
    },
  },

  pulse: {
    opacity: 1,
    scale: [1, 0.95, 1.05, 0.95, 1.05, 1],
    transition: {
      repeat: Infinity,
      repeatDelay: 1,
      repeatType: "reverse",
    },
  },
  wobble: {
    opacity: 1,
    x: [0, -10, 10, -10, 10, -10, 10, 0],

    transition: {
      type: "spring",
      stiffness: 5000,
      repeat: Infinity,
      repeatDelay: 1,
      repeatType: "reverse",
    },
  },

  buzz: {
    opacity: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
    rotate: [0, 1, -1, 1, -1, 1, -1, 1, -1, 0],
    transition: {
      type: "spring",

      repeat: Infinity,
      repeatDelay: 1,
      repeatType: "reverse",
    },
  },
};

export const dropInVariants = {
  initial: {
    y: "-100vh",
    opacity: 1,
  },
  animate: {
    y: "0",
    opacity: 1,
    transition: {
      duration: 0.1,
      type: "spring",
      damping: 25,
      stiffness: 200,
    },
  },
  exit: {
    y: "100vh",
    opacity: 0,
  },
};

export const dropDownVariants = {
  initial: {
    y: 0,
    opacity: 1,
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      type: "spring",
    },
  },
  exit: {
    y: -100,
    opacity: 0,
  },
};

export const moveUpVariants = {
  hidden: {
    y: 10,
    opacity: 1,
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      type: "spring",
      damping: 50,
      stiffness: 800,
    },
  },
  exit: {
    y: 10,
    opacity: 0,
  },
};

export const moveRightVariants = {
  hidden: {
    x: "-100%",
    opacity: 1,
  },
  visible: {
    x: 0,
    opacity: 1,
    transition: {
      type: "spring",
      duration: 0.2,
      // damping: 60,
      // stiffness: 900,
    },
  },
  exit: {
    x: -100,
    opacity: 0,
  },
};

export const popOutVariants = {
  hidden: {
    scale: 0,
    opacity: 0,
  },
  visible: {
    scale: 1,
    opacity: 1,
  },
  exit: {
    scale: 0,
    opacity: 0,
  },
};
