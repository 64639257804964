import React from "react";
import { fadeInVariants } from "../../animations/animation";

import { ErrorLayout } from "./Error.elements";
function Error({ padding, margin, errorMessage, borderRadius, variant }) {
  return (
    <ErrorLayout
      variant={variant}
      borderRadius={borderRadius}
      padding={padding}
      margin={margin}
      variants = {fadeInVariants}
      initial = "initial"
      animate = "animate"
    >
      <p>{errorMessage}</p>
    </ErrorLayout>
  );
}

export default Error;
