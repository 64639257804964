import React from "react";
import { Container, Title, VideoURL } from "./MobileViewVideoItem.elements";
import { openURL } from "../../utils/functions";
import { ViewResponsivePlayer } from "../index";
import { animationvariants } from "../../animations/animation";

function MobileViewVideoItem({ video }) {
  const handleVideoClick = () => {
    if (video.videoURL === "") {
      return;
    }

    openURL(video.videoURL);
  };

  const handleOnPlayerStart = () => {};

  return (
    <Container
      variants={animationvariants}
      animate={video.animation}
      onClick={handleVideoClick}
    >
      <ViewResponsivePlayer
        videoURL={video.videoURL}
        handleOnPlayerStart={handleOnPlayerStart}
      />
      <Title>{video.title}</Title>
      <VideoURL>{video.videoURL}</VideoURL>
    </Container>
  );
}

export default MobileViewVideoItem;
