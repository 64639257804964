import React, { useContext } from "react";
import { useState, useEffect } from "react";
import { Button, ErrorLayout, ResponsivePlayer } from "..";
import validator from "validator";
import {
  Container,
  Content,
  Input,
  MessageLayout,
} from "./AddVideoItem.elements";

import makeid from "../../utils/functions";
import { AuthContext } from "../../contexts/AuthContext";
import { db } from "../../firebase/firebase";
import firebase from "firebase";
import { dropDownVariants } from "../../animations/animation";

function AddVideoItem({
  videos,
  setShowAddVideoItem,
  scrollToVideoView,
  setShowUpgradeToProLayout,
  setUpgradeType,
}) {
  const { user } = useContext(AuthContext);
  const [title, setTitle] = useState("");
  const [videoURL, setVideoURL] = useState("");
  const [showMessageLayout, setShowMessageLayout] = useState(false);
  const [videoURLError, setVideoURLError] = useState("");
  const [finalVideoURL, setFinalVideoURL] = useState("");
  const [progress, setProgress] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const delayRequestFunction = setTimeout(() => {
      if (!/^https?:\/\//i.test(videoURL) && !/^http?:\/\//i.test(videoURL)) {
        setFinalVideoURL("https://" + videoURL);
        console.log("Without https");
      } else {
        setFinalVideoURL(videoURL);
        console.log("with https");
      }
    }, 500);
    return () => {
      clearTimeout(delayRequestFunction);
    };
  }, [videoURL]);

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleVideoURLChange = (e) => {
    setVideoURL(e.target.value);
    setVideoURLError("");
    setError("");
  };

  const handleAddNewVideoButtonClick = (e) => {
    if (videoURLError) {
      return;
    }

    if (videoURL === "") {
      setVideoURLError("Video url cannot be empty");
      return;
    }

    if (!validator.isURL(videoURL)) {
      setVideoURLError("Invalid Video url");
      return;
    }

    if (videos.length >= 1 && !user.isPro) {
      setShowUpgradeToProLayout(true);
      setUpgradeType("videos");
      return;
    }

    setError("");
    setProgress(true);

    const documentId = makeid();

    db.collection("users")
      .doc(user.userId)
      .collection("videos")
      .doc(documentId)
      .set({
        videoURL: finalVideoURL,
        videoId: documentId,
        title: title,
        order: videos.length ? videos[0].order - 0.095 : 0,
        isActive: true,
        userId: user.userId,
        animation: "",
        type: "video",
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      })
      .then(() => {
        setProgress(false);
        setShowAddVideoItem(false);
        scrollToVideoView();
      })
      .catch((error) => {
        setError(error.message);
        setProgress(false);
      });
  };

  return (
    <Container
      variants={dropDownVariants}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <Content>
        <ResponsivePlayer margin="0px 0px 16px 0px" videoURL={finalVideoURL} />
        <label for="title">Video Title</label>
        <Input
          id="title"
          value={title}
          placeholder="Enter title here"
          onChange={handleTitleChange}
        />
        <label for="url">Video URL</label>
        <Input
          id="url"
          value={videoURL}
          placeholder="Paste your video url here"
          onChange={handleVideoURLChange}
        />

        {videoURLError && (
          <ErrorLayout
            margin="-8px 0px 16px 0px"
            errorMessage={videoURLError}
          />
        )}

        <MessageLayout>
          <span
            onClick={() => setShowMessageLayout(!showMessageLayout)}
            id="icon"
          >
            <i class="bi bi-info-circle"></i>
          </span>
          {showMessageLayout && (
            <span id="message">
              {" "}
              Currently we support only videos of YouTube, Facebook, Twitch,
              SoundCloud, Streamable, Vimeo, Wistia, Mixcloud, DailyMotion and
              Kaltura.
            </span>
          )}
        </MessageLayout>
        {error && <ErrorLayout margin="8px 0px" errorMessage={error} />}
        <Button
          variant={"dashboardAdd"}
          margin="16px 0px "
          title={"Add new Video"}
          handleClick={handleAddNewVideoButtonClick}
          progress={progress}
        />
      </Content>
    </Container>
  );
}

export default AddVideoItem;
