import styled, { keyframes } from "styled-components";
import { motion } from "framer-motion";


export const Content = styled(motion.div)``;

export const VideoInformationWrapper = styled(motion.div)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justifycontent: space-between;
`;

export const VideoInformation = styled(motion.div)`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  justify-content: center;
`;

export const TitleInputWrapper = styled(motion.div)`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 2px;

  & > input {
    min-width: 0;
    min-height: 0;
    width: 100%;
    outline: none;
    border: none;
    font-weight: 500;
    padding: 0px;
    font-size: 16px;
    ::placeholder,
    ::-webkit-input-placeholder {
      color: #b3b3b3;
    }
    :-ms-input-placeholder {
      color: red;
    }
  }

  & > span {
    color: ${({ theme }) => theme.colors.iconLight};
    font-size: 14px;
  }
`;
