import React from "react";

import {
  Button,
  Container,
  Heading,
  Message,
} from ".././user-not-found/UserNotFound.elements";

import { SvgWrapper, ErrorSvg } from "./ErrorPage.elements";

import { fadeInVariants, whileTap } from "../../animations/animation";
import { useHistory } from "react-router-dom";

function ErrorPage() {
  const history = useHistory();

  const handleHomeClick = () => {
    history.push("/");
    history.go(0);
  };

  return (
    <Container initial="initial" animate="animate" variants={fadeInVariants}>
      <SvgWrapper>
        <ErrorSvg />
      </SvgWrapper>
      <Heading>Thanks! you Just Reported New Bug</Heading>
      <Message>
        The page you are looking for just broked. Try reloading the page.If the
        issues continues give us some more time to fix It.
      </Message>

      <Button whileTap={whileTap} onClick={handleHomeClick}>
        <p>Reload Page</p>
      </Button>
    </Container>
  );
}

export default ErrorPage;
