import React from "react";
import { Button } from "./NavbarButton.elements";

function NavbarButton({ title, handleButtonClick, margin, navTheme }) {
  return (
    <Button margin={margin} onClick={handleButtonClick} navTheme={navTheme}>
      {title}
    </Button>
  );
}

export default NavbarButton;
