import React from "react";
import { Container, Title, VideoURL } from "./ViewVideoItem.elements";
import { openURL } from "../../utils/functions";
import { ViewResponsivePlayer } from "../index";
import { animationvariants } from "../../animations/animation";

function ViewVideoItem({ video, reportVideoClicks }) {
  const handleVideoClick = () => {
    if (video.videoURL === "") {
      return;
    }

    openURL(video.videoURL);
    reportVideoClicks(video);
  };

  const handleOnPlayerStart = () => {
    reportVideoClicks(video);
  };

  return (
    <Container
      variants={animationvariants}
      animate={video.animation}
      onClick={handleVideoClick}
    >
      <ViewResponsivePlayer
        videoURL={video.videoURL}
        handleOnPlayerStart={handleOnPlayerStart}
      />
      <Title>{video.title}</Title>
      <VideoURL>{video.videoURL}</VideoURL>
    </Container>
  );
}

export default ViewVideoItem;
