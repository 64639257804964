import styled from "styled-components";
import { motion } from "framer-motion";

export const Container = styled(motion.div)`
  margin-bottom: 16px;
  padding: 8px;
  border-radius: ${({ theme }) => theme.image.style.borderRadius};
  background-color: ${({ theme }) =>
    theme.image.style.type === "filled"
      ? theme.image.style.backgroundColor
      : "transparent"};
  border: ${({ theme }) => `1px solid ${theme.image.style.borderColor}`};
  cursor: pointer;

  img {
    width: 100%;
    min-height: 150px;
    background-color: #ededed;
    border-radius: ${({ theme }) => theme.image.style.borderRadius};
  }
`;

export const Title = styled(motion.p)`
  display: ${({ theme }) => (theme.image.isImageTitle ? "block" : "none")};
  color: ${({ theme }) => theme.image.imageTitleColor};
  margin-top: 8px;
  font-size: 18px;
  word-break: break-all;
`;

export const Description = styled(motion.p)`
  display: ${({ theme }) =>
    theme.image.isImageDescription ? "block" : "none"};
  color: ${({ theme }) => theme.image.imageDescriptionColor};
  margin-top: ${({ theme }) => (theme.image.isImageTitle ? "2px" : "8px")};
  font-size: 14px;
  word-break: break-all;
`;

export const ImageVisitURL = styled(motion.span)`
  display: ${({ theme }) => (theme.image.isImageURL ? "block" : "none")};
  color: ${({ theme }) => theme.image.imageURLColor};
  font-size: 12px;
  margin-top: ${({ theme }) =>
    theme.image.isImageDescription ? "2px" : "8px"};
  word-break: break-all;
`;
