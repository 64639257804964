import React from "react";
import { fadeInVariants } from "../../animations/animation";

import { SuccessLayout } from "./Success.elements";
function Success({ padding, margin, successMessage, borderRadius }) {
  return (
    <SuccessLayout
      borderRadius={borderRadius}
      padding={padding}
      margin={margin}
      variants={fadeInVariants}
      initial="initial"
      animate="animate"
    >
      <p>{successMessage}</p>
    </SuccessLayout>
  );
}

export default Success;
