import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { animationvariants } from "../animations/animation";
import { openURL } from "../utils/functions";

function MobileScreenViewLinkItem({ link }) {
  const handleLinkClick = () => {
    const URL = link.link;
    openURL(URL);
  };

  return (
    <Container
      variants={animationvariants}
      animate={link.animation}
      onClick={handleLinkClick}
    >
      <Content>
        {link.image && <img src={link.image} alt="linkImage" />}

        <div>
          <p>{link.title}</p>
          <span>{link.link}</span>
        </div>
      </Content>
    </Container>
  );
}

const Container = styled(motion.div)`
  display: flex;
  min-height: 54px;
  margin-bottom: 8px;
  border-radius: ${({ theme }) => theme.link.style.borderRadius};
  background-color: ${({ theme }) =>
    theme.link.style.type === "filled"
      ? theme.link.style.backgroundColor
      : "transparent"};
  border: ${({ theme }) => `1px solid ${theme.link.style.borderColor}`};
  width: 100%;
  cursor: pointer;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  padding: 12px;
  width: 100%;

  img {
    display: ${({ theme }) => (theme.link.isLinkImage ? "block" : "none")};
    height: 30px;
    width: 30px;
    margin-right: 12px;
    min-width: 30px;
    object-fit: cover;
    border-radius: ${({ theme }) => theme.link.image.borderRadius};
  }

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    p {
      font-size: 12px;
      line-height: 1.1;
      word-break: break-all;
      color: ${({ theme }) => theme.link.linkTitleColor};
    }

    span {
      display: ${({ theme }) => (theme.link.isLinkURL ? "block" : "none")};
      font-size: 10px;
      margin-top: 4px;
      color: ${({ theme }) => theme.link.linkURLColor};
      word-break: break-all;
    }
  }
`;

export default MobileScreenViewLinkItem;
