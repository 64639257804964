import React, { useState, useEffect } from "react";
import styled, { css, keyframes } from "styled-components";
import { SketchPicker } from "react-color";
import useComponentVisible from "../../hooks/useComponentVisible";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";

function BackgroundColorItem({
  currentColor,
  currentSecondColor,
  currentPattern,
  handleColorChange,
  handlePatternChange,
  handleImageChange,
  handleDeleteImage,
  image,
  showBackgroundImageProgressBar,
  backgroundImageProgress,
}) {
  const [textColor, setTextColor] = useState("#000");
  const [secondColor, setSecondColor] = useState(currentSecondColor);

  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  const PatternType = ({ pattern }) => {
    return (
      <PatternTypeWrapper
        active={pattern.type === currentPattern}
        type={pattern.type}
        color={currentColor}
        secondColor={secondColor}
        textColor={textColor}
        onClick={() => handlePatternChange(pattern.type)}
      >
        <div>
          <span>{pattern.type}</span>
        </div>
      </PatternTypeWrapper>
    );
  };

  useEffect(() => {
    setSecondColor(currentSecondColor);
  }, [currentSecondColor]);

  useEffect(() => {
    getContrastYIQ(currentColor);
  }, [currentColor]);

  const getContrastYIQ = (color) => {
    if (color) {
      color = color.replace("#", "");
      var r = parseInt(color.substr(0, 2), 16);
      var g = parseInt(color.substr(2, 2), 16);
      var b = parseInt(color.substr(4, 2), 16);
      var yiq = (r * 299 + g * 587 + b * 114) / 1000;

      if (yiq >= 128) {
        setTextColor("#000000");
      } else {
        setTextColor("#ffffff");
      }
    }
  };

  const adjust = (color, amount) => {
    return (
      "#" +
      color
        .replace(/^#/, "")
        .replace(/../g, (color) =>
          (
            "0" +
            Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(
              16
            )
          ).substr(-2)
        )
    );
  };

  const handleBackgroundColorChange = (updatedColor) => {
    const updatedSecondColor = adjust(updatedColor.hex, 95);
    setSecondColor(updatedSecondColor);
    handleColorChange(updatedColor.hex, updatedSecondColor);
  };

  const backgroundPatterns = [
    {
      type: "Flat",
    },
    {
      type: "Up",
    },
    {
      type: "Down",
    },
    {
      type: "Left",
    },
    {
      type: "Right",
    },
  ];

  return (
    <Container>
      <BackgroundColorWrapper
        ref={ref}
        onClick={(e) => setIsComponentVisible(true)}
      >
        <Title>Background Color</Title>

        <ColorBlock
          onClick={(e) => {
            e.stopPropagation();
            setIsComponentVisible(!isComponentVisible);
          }}
          color={currentColor}
          textColor={textColor}
        >
          <span>{currentColor}</span>
        </ColorBlock>

        {isComponentVisible && (
          <ColorPickerLayout>
            <SketchPicker
              color={currentColor}
              disableAlpha
              onChangeComplete={handleBackgroundColorChange}
            />
          </ColorPickerLayout>
        )}
      </BackgroundColorWrapper>

      <BackgroundPatternWrapper>
        <Title>Pattern</Title>
        <BackgroundPatternTypesWrapper>
          {backgroundPatterns.map((pattern) => (
            <PatternType key={pattern.type} pattern={pattern} />
          ))}
        </BackgroundPatternTypesWrapper>
      </BackgroundPatternWrapper>

      <BackgroundImageWrapper>
        <Title>Image</Title>
        {image && (
          <span onClick={handleDeleteImage} id="close">
            <i class="fas fa-times-circle"></i>
          </span>
        )}
        <ImageLayout
          showBackgroundImageProgressBar={showBackgroundImageProgressBar}
        >
          <label htmlFor="backgroundImage">
            {image ? (
              <img src={image} alt="" />
            ) : (
              <AddAPhotoIcon style={{ color: "gray", fontSize: "20px" }} />
            )}
          </label>

          <input
            id="backgroundImage"
            type="file"
            style={{ display: "none" }}
            accept="image/*"
            onChange={handleImageChange}
          />

          {showBackgroundImageProgressBar && (
            <span id="loader">
              <i class="fas fa-spinner"></i>
            </span>
          )}

          {showBackgroundImageProgressBar && (
            <span id="progressText">{backgroundImageProgress}</span>
          )}
        </ImageLayout>

        <span id="pro">PRO</span>
      </BackgroundImageWrapper>
    </Container>
  );
}

const rotate = keyframes`
  from {
    transform: rotate(0deg);
    transform-origin: center;
  }
  to {
    transform: rotate(360deg);
    transform-origin: center;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 8px;
`;

export const Title = styled.span`
  font-size: 14px;
  font-weight: 500;
`;

export const BackgroundColorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 16px;
`;

const ColorBlock = styled.div`
  width: 190px;
  height: 52px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => theme.borderRadius};
  margin: 11px 0px 0px 0px;
  padding: 5px;
  border: ${({ theme, color }) =>
    (color === "#ffffff" && "1px solid #000000") || ""};
  color: ${({ textColor }) => (textColor ? textColor : "#000")};
  background-color: ${({ color }) => (color ? color : "#000")};
  text-transform: uppercase;
  cursor: pointer;
`;

const ColorPickerLayout = styled.div`
  display: flex;
  position: absolute;
  top: 70px;
  right: 0;
  left: 0;
  z-index: 2;
`;

export const BackgroundPatternWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 16px;
`;

export const BackgroundPatternTypesWrapper = styled.div`
  display: flex;
  margin-top: 8px;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const PatternTypeWrapper = styled.div`
  display: flex;
  margin: 8px;
  flex-direction: column;
  align-items: center;
  border: ${({ active, theme }) =>
    active ? theme.appearance.active.border : "1px solid transparent"};
  background-color: ${({ active, theme }) =>
    active ? theme.appearance.active.background : ""};
  padding: 8px;
  border-radius: ${({ theme }) => theme.borderRadius};
  cursor: pointer;

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 50px;
    border-radius: ${({ theme }) => theme.borderRadius};
    font-size: 12px;
    color: ${({ textColor }) => (textColor ? textColor : "#000")};
    border: ${({ theme, color }) =>
      (color === "#ffffff" && theme.appearance.border.itemBorder) || ""};

    ${({ type }) =>
      (type === "Up" &&
        css`
          background: linear-gradient(
            to top,
            ${(props) => props.color},
            ${(props) => props.secondColor}
          ); ;
        `) ||
      (type === "Down" &&
        css`
          background: linear-gradient(
            to bottom,
            ${(props) => props.color},
            ${(props) => props.secondColor}
          ); ;
        `) ||
      (type === "Right" &&
        css`
          background: linear-gradient(
            to right,
            ${(props) => props.color},
            ${(props) => props.secondColor}
          ); ;
        `) ||
      (type === "Left" &&
        css`
          background: linear-gradient(
            to left,
            ${(props) => props.color},
            ${(props) => props.secondColor}
          ); ;
        `) ||
      (type === "Flat" &&
        css`
          background: ${({ color }) => color};
        `)}
  }
`;

export const BackgroundImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;
  #close {
    position: absolute;
    top: 16px;
    right: -8px;
    font-size: 18px;
    color: black;
    z-index: 2;
  }

  #pro {
    font-size: 10px;
    display: flex;
    text-align: center;
    align-self: center;
    padding: 4px 12px;
    background-color: ${({ theme }) => theme.colors.pro};
    border-radius: 4px;
    color: #ffffff;
    letter-spacing: 1.2px;
    font-weight: 600;
  }
`;

const ImageLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 60px;
  min-width: 60px;
  margin-top: 8px;
  padding: 8px;

  label {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 52px;
    width: 52px;
    border-radius: ${({ theme }) => theme.borderRadius};
    border: ${({ theme }) => theme.border};
    cursor: pointer;
    position: relative;
    background-color: ${({ showBackgroundImageProgressBar }) =>
      showBackgroundImageProgressBar ? "lightgray" : "white"};
    & > img {
      height: 52px;
      width: 52px;
      border-radius: 4px;
      object-fit: cover;
    }
  }

  #loader {
    height: 24px;
    width: 24px;
    font-size: 24px;
    position: absolute;
    color: white;
    animation: ${rotate} 0.8s linear infinite;
  }

  #progressText {
    position: absolute;
    font-size: 10px;
    color: white;
  }
`;

export default BackgroundColorItem;
