import styled, { keyframes } from "styled-components";
import { motion } from "framer-motion";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
    transform-origin: center;
  }
  to {
    transform: rotate(360deg);
    transform-origin: center;
  }
`;

export const Container = styled(motion.div)`
  background: #f1f5f8;
  color: #172126;
`;

export const Content = styled(motion.div)`
  padding: 16px;

  label {
    display: inline-block;
    margin-bottom: 4px;
    font-size: 14px;
  }
`;

export const ImageLayout = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 184px;
  padding: 0px;
  margin: 0px 0px 16px 0px;
  border: ${({ theme }) => theme.border};
  border-radius: 4px;
  position: relative;
  background-color: #ffffff;

  label {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    cursor: pointer;
    padding: 0px;
    margin: 0px;
    border-radius: 4px;

    #image-preview {
      display: none;
      height: 100%;
      width: 100%;
      border-radius: 4px;
      object-fit: cover;
      margin: 0;
      padding: 0;
    }
  }

  #loader {
    height: 42px;
    width: 40px;
    font-size: 40px;
    position: absolute;
    color: #ffffff;
    animation: ${rotate} 0.8s linear infinite;
  }

  #progressText {
    position: absolute;
    font-size: 14px;
    color: #ffffff;
  }
`;

export const ImagEmptyWrapper = styled(motion.div)`
  text-align: center;
  color: #ee5253;
  span {
    height: 46px;
    width: 44px;
    font-size: 44px;
  }

  p {
    margin: 8px 16px 0px 16px;
    text-align: center;
    font-size: 14px;
    color: #676b6f;
  }
`;

export const Input = styled.input`
  height: 50px;
  width: 100%;
  padding-left: 4px;
  outline: none;
  border: none;
  font-size: 16px;
  border-radius: 4px;
  border: ${({ theme }) => theme.border};
  margin: ${({ margin }) => margin};
`;

export const MessageLayout = styled.p`
  margin-top: 8px;
  font-size: 14px;
  #icon {
    color: #e91e63;
    font-size: 16px;
  }

  #message {
    margin-left: 2px;
    color: #676b6f;
  }
`;
