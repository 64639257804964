import styled from "styled-components";
import { motion } from "framer-motion";
import { ReactComponent as ReportIcon } from "../../images/report_violation.svg";

export const Container = styled(motion.div)`
  background-color: #ffffff;
  min-height: 100vh;
`;

export const Content = styled(motion.div)`
  @media screen and (min-width: 768px) {
    display: flex;
    flex-direction: row;
  }
`;

export const Leftside = styled(motion.div)`
  background-color: #ffffff;
  min-height: 100vh;
  padding: 16px;

  @media screen and (min-width: 768px) {
    flex: 0.5;
    padding: 24px;
  }
`;

export const Rightside = styled(motion.div)`
  width: 100%;
  display: none;
  @media screen and (min-width: 768px) {
    display: block;
    flex: 0.5;
    padding: 24px;
  }

  h1 {
    font-size: 58px;
    font-weight: 500;
  }
`;

export const Heading = styled.h1`
  font-size: 40px;
  margin-bottom: 16px;
  color: rgb(19, 20, 21);
  font-weight: 500;
`;

export const HeadingMessage = styled.h6`
  line-height: 1.3;
  margin-bottom: 16px;
  font-size: 24px;
  font-weight: 300;
  color: #666666;
`;

export const MobileSvgWrapper = styled(motion.section)`
  display: block;
  @media screen and (min-width: 768px) {
    display: none;
  }
`;

export const SvgWrapper = styled(motion.div)`
  background-color: #ff2626;
  width: 100%;
  height: 200px;
  border-radius: 4px;
  margin: 16px 0px 24px 0px;
  svg {
    width: 100%;
    height: 200px;
    padding: 16px;
  }

  @media screen and (min-width: 768px) {
    height: 350px;
    svg {
      height: 350px;
    }
  }
`;

export const ReportViolationSvg = styled(ReportIcon)``;

export const InputContainer = styled(motion.div)`
  display: block;
  margin-bottom: 16px;
`;

export const Label = styled(motion.label)``;

export const Input = styled(motion.input)`
  width: 100%;
  margin-top: 8px;
  height: 50px;
  font-size: 16px;
  border-radius: 4px;
  outline-width: 0px;
  border-width: 0px;
  padding: 0px 8px 0px 8px;
  background-color: transparent;
  border: ${({ theme }) => theme.border};
`;

export const TextArea = styled(motion.textarea)`
  width: 100%;
  margin-top: 8px;
  min-height: 200px;
  resize: none;
  font-size: 16px;
  border-radius: 4px;
  outline-width: 0px;
  border-width: 0px;
  padding: 8px;
  background-color: transparent;
  border: ${({ theme }) => theme.border};
`;

export const Information = styled(motion.p)`
  margin: 24px 0px;
  line-height: 1.3;
  color: #666666;
  a {
    color: #000000;
    font-weight: 500;
  }
`;
