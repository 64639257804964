import React from "react";

import {
  Container,
  CommunityUserInfoContainer,
  Content,
} from "./CommunityUserCard.elements";
import { motion } from "framer-motion";
import { whileHover, whileTap } from "../../animations/animation";
import { openURL } from "../../utils/functions";

function CommunityUserCard({ communityUser }) {
  const handleVisitProfileClick = () => {
    if (communityUser.username === "") {
      return;
    }
    const profileURL = "getmyprofiles.in/" + communityUser.username;
    openURL(profileURL);
  };

  return (
    <Container whileTap={whileTap} onClick={handleVisitProfileClick}>
      <Content>
        <div className="imageContainer">
          <img src={communityUser.coverPhoto} alt="coverphoto" />
        </div>

        <CommunityUserInfoContainer>
          <img
            className="profilePhoto"
            src={communityUser.profilePhoto}
            alt="profilePhoto"
          />
          <div>
            <p>{communityUser.name}</p>
            <span>{communityUser.role}</span>
          </div>
        </CommunityUserInfoContainer>
      </Content>
    </Container>
  );
}

export default CommunityUserCard;
