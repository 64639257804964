import styled from "styled-components";
import { motion } from "framer-motion";
import { ReactComponent as UserNotFoundIcon } from "../../images/user_not_found.svg";

export const Container = styled(motion.div)`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  padding: 16px;
  max-width: 600px;
  margin: 0px auto;
  background-color: white;
  font-family: "Varela Round", sans-serif;
`;

export const SvgWrapper = styled(motion.div)`
  margin: 32px;
  svg {
    width: 100%;
    height: 150px;
  }
`;

export const UserNotFoundSvg = styled(UserNotFoundIcon)`
  height: 200px;
`;

export const Heading = styled(motion.h3)`
  margin-top: 32px;
  font-size: 24px;
  text-align: center;
`;

export const Message = styled(motion.p)`
  margin: 8px 0px;
  text-align: center;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.8);
`;

export const Button = styled(motion.button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  margin-top: 24px;
  color: #ffffff;
  outline: none;
  border: none;
  font-weight: 600;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  border-radius: 4px;
  cursor: pointer;
  background-color: #4d80e4;
  font-family: "Varela Round", sans-serif;
`;
