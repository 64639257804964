import styled, { keyframes } from "styled-components";
import { motion } from "framer-motion";

export const rotate = keyframes`
from {
  transform: rotate(0deg);
  transform-origin: center;
}
to {
  transform: rotate(360deg);
  transform-origin: center;
}
`;

export const Container = styled(motion.div)`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
  flex: 1;
  width: 100%;
  background-color: white;
  align-items: center;
  justify-content: center;

  #loader {
    height: 34px;
    width: 32px;
    font-size: 32px;
    color: rgba(0, 0, 0, 0.8);
    animation: ${rotate} 0.8s linear infinite;
  }
`;

export const LoadingText = styled.p`
  text-transform: uppercase;
  letter-spacing: 1.2px;
  font-weight: 550;
  margin-top: 16px;
  text-align: center;
`;
