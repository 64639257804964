import React from "react";
import { whileTap } from "../../animations/animation";

import { Container, ButtonLayout } from "./Button.elements";

function Button({
  marginTop,
  title,
  margin,
  progress,
  disabled,
  handleClick,
  secondary,
  height,
  variant,
}) {
  return (
    <Container whileTap={whileTap} margin={margin} marginTop={marginTop}>
      <ButtonLayout
        height={height}
        secondary={secondary}
        onClick={handleClick}
        disabled={disabled}
        variant={variant}
      >
        {!progress && <p>{title}</p>}

        {progress && (
          <span id="loader">
            <i class="fas fa-circle-notch"></i>
          </span>
        )}
      </ButtonLayout>
    </Container>
  );
}

export default Button;
