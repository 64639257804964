import React, { useState } from "react";
import validator from "validator";

import {
  Container,
  Content,
  ContentWrapper,
  DragIconWrapper,
  InfoWrapper,
  InputWrapper,
  SwitchWrapper,
  ActiveSwitch,
  ImageWrapper,
  ActionLayoutWrapper,
  MainContainer,
  ActionProceedLayout,
  ActionIconWrapper,
} from "./ProfileItem.elements";
import { ErrorLayout, AnimationItem, DeleteItem } from "..";

import { db } from "../../firebase/firebase";

import { useHistory } from "react-router";
import { openProfile } from "../../utils/functions";
import VisitItem from "../visit-item-layout/VisitItem";
import DeepLinkItem from "../deep-link-item-layout/DeepLinkItem";
import { moveRightVariants } from "../../animations/animation";

function ProfileItem({
  profile,
  user,
  setShowProgressLayout,
  progressTimeout,
  setDeepLink,
  deepLinkId,
  setDeepLinkId,
}) {

  const [profileId, setProfileId] = useState(profile.profileId);

  const [value, setValue] = useState(profile.value);
  const [isActive, setIsActive] = useState(profile.isActive);
  const [valueError, setValueError] = useState("");
  const [activeError, setActiveError] = useState("");
  const [isValueChanged, setIsValueChanged] = useState(false);

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const [deepLinkProgress, setDeeplinkProgress] = useState(false);
  const [deleteProfileProgress, setDeleteProfileProgress] = useState(false);

  const [showValueEditIcon, setShowValueEditIcon] = useState(true);
  const [showDeleteLayout, setShowDeleteLayout] = useState(false);
  const [showAnimationLayout, setShowAnimationLayout] = useState(false);
  const [profileAnimation, setProfileAnimation] = useState(profile.animation);

  const [showDeepLinkLayout, setShowDeepLinkLayout] = useState(false);
  const [showVisitLayout, setShowVisitLayout] = useState(false);
  //Regex

  const handleValueChange = (e) => {
    e.preventDefault();
    setValue(e.target.value);

    if (e.target.value === "") {
      setValueError("Field cannot be empty");
      return;
    }

    setIsValueChanged(true);
    setValueError("");
  };

  const updateValueChange = () => {
    setShowValueEditIcon(true);

    if (valueError || !isValueChanged) {
      return;
    }

    if (profile.profileId === "gmail" && !validator.isEmail(value)) {
      setValueError("Invalid email address");
      return;
    }

    if (profile.profileId === "outlook" && !validator.isEmail(value)) {
      setValueError("Invalid email address");
      return;
    }

    if (profile.profileId === "yahoo" && !validator.isEmail(value)) {
      setValueError("Invalid email address");
      return;
    }

    if (profile.profileId === "whatsapp" && !validator.isMobilePhone(value)) {
      setValueError("Invalid phone number");
      return;
    }

    if (profile.profileId === "signal" && !validator.isMobilePhone(value)) {
      setValueError("Invalid phone number");
      return;
    }

    var modifiedValue = value;

    if (profile.placeholder === "@username" && !modifiedValue.startsWith("@")) {
      modifiedValue = "@" + value;
      setValue(modifiedValue);
    }

    if (
      profile.profileId === "whatsapp" &&
      modifiedValue.length === 10 &&
      !modifiedValue.startsWith("+91")
    ) {
      modifiedValue = "+91" + value;
      setValue(modifiedValue);
    }

    setShowProgressLayout(true);

    db.collection("users")
      .doc(user.userId)
      .collection("profiles")
      .doc(profile.profileId)
      .update({
        value: modifiedValue,
      })
      .then(() => {
        setIsValueChanged(false);

        if (profile.profileId === deepLinkId) {
          handleAddDeepLink();
        } else {
          setSuccess(true);
          setShowProgressLayout(false);
          setTimeout(() => {
            setSuccess(false);
          }, progressTimeout);
        }
      })
      .catch((error) => {
        setActiveError(true);
        setShowProgressLayout(false);
      });
  };

  const handleActiveStatusChange = (e) => {
    e.preventDefault();
    setIsActive(e.target.checked);
    setActiveError("");
    setShowProgressLayout(true);

    db.collection("users")
      .doc(user.userId)
      .collection("profiles")
      .doc(profile.profileId)
      .update({
        isActive: e.target.checked,
      })
      .then(() => {
        setSuccess(true);
        setShowProgressLayout(false);
        setTimeout(() => {
          setSuccess(false);
        }, progressTimeout);
      })
      .catch((error) => {
        setActiveError(error.message);
        setShowProgressLayout(false);
      });
  };

  const onDeleteIconClick = () => {
    setShowDeleteLayout(!showDeleteLayout);
    setShowVisitLayout(false);
    setShowAnimationLayout(false);
    setShowDeepLinkLayout(false);
    blurInputs();
  };

  const handleDeleteProfile = (e) => {
    setError(false);
    setDeleteProfileProgress(true);
    setShowProgressLayout(true);
    db.collection("users")
      .doc(user.userId)
      .collection("profiles")
      .doc(profileId)
      .delete()
      .then(() => {
        handleCancelClick();
        setDeleteProfileProgress(false);
        setShowProgressLayout(false);
        if (profile.profileId === deepLinkId) {
          handleRemoveDeepLink();
        } else {
          setShowProgressLayout(false);
        }
      })
      .catch((error) => {
        setActiveError(true);
        setShowProgressLayout(false);
      });
  };

  const onAnimationIconClick = () => {
    setShowAnimationLayout(!showAnimationLayout);
    setShowDeleteLayout(false);

    setShowVisitLayout(false);
    setShowDeepLinkLayout(false);
    blurInputs();
  };

  const handleAddAnimation = (animation) => {
    if (animation !== profileAnimation) {
      setProfileAnimation(animation);
      handleCancelClick();
      setShowProgressLayout(true);
      db.collection("users")
        .doc(user.userId)
        .collection("profiles")
        .doc(profileId)
        .update({
          animation: animation,
        })
        .then(() => {
          setSuccess(true);
          setShowProgressLayout(false);
          setTimeout(() => {
            setSuccess(false);
          }, progressTimeout);
        })
        .catch((error) => {
          setError(error.message);
          setShowProgressLayout(false);
        });
    } else {
      setProfileAnimation("");
      handleCancelClick();
      setShowProgressLayout(true);
      db.collection("users")
        .doc(user.userId)
        .collection("profiles")
        .doc(profileId)
        .update({
          animation: "",
        })
        .then(() => {
          setSuccess(true);
          setShowProgressLayout(false);
          setTimeout(() => {
            setSuccess(false);
          }, progressTimeout);
        })
        .catch((error) => {
          setError(error.message);
        });
    }
  };

  const onDeepLinkIconClick = () => {
    setShowDeepLinkLayout(!showDeepLinkLayout);
    setShowVisitLayout(false);
    setShowDeleteLayout(false);

    setShowAnimationLayout(false);
    blurInputs();
  };

  const handleAddDeepLink = () => {
    setDeeplinkProgress(true);
    setShowProgressLayout(true);
    db.collection("users")
      .doc(user.userId)
      .update({
        "deepLink.link": profile.URL + value,
        "deepLink.type": "profile",
        "deepLink.id": profile.profileId,
      })
      .then(() => {
        setSuccess(true);
        setShowProgressLayout(false);
        handleCancelClick();
        setIsValueChanged(false);
        setDeepLink(profile.URL + value);
        setDeepLinkId(profileId);
        setTimeout(() => {
          setSuccess(false);

          setDeeplinkProgress(false);
          handleCancelClick();
        }, progressTimeout);
      })
      .catch((error) => {
        setError(error.message);
        setDeeplinkProgress(false);
      });
  };

  const handleRemoveDeepLink = () => {
    setDeeplinkProgress(true);
    setShowProgressLayout(true);
    db.collection("users")
      .doc(user.userId)
      .update({
        "deepLink.link": "",
        "deepLink.type": "",
        "deepLink.id": "",
      })
      .then(() => {
        setSuccess(true);
        setShowProgressLayout(false);

        setDeepLink("");
        setDeepLinkId("");
        handleCancelClick();
        setTimeout(() => {
          setSuccess(false);
          setDeeplinkProgress(false);
        }, progressTimeout);
      })
      .catch((error) => {
        setError(error.message);
        setDeeplinkProgress(false);
      });
  };

  const onVisitIconClick = () => {
    setShowVisitLayout(!showVisitLayout);
    setShowDeepLinkLayout(false);
    setShowDeleteLayout(false);

    setShowAnimationLayout(false);
    blurInputs();
  };

  const handleVisitLink = () => {
    const URL = profile.URL + value;

    if (URL === "") {
      setValueError("Invalid link");
      document.getElementById(profile.profileId).focus();
      return;
    }

    openProfile(URL);
  };

  const handleCancelClick = () => {
    setShowDeleteLayout(false);

    setShowAnimationLayout(false);
    setShowDeepLinkLayout(false);
    setShowVisitLayout(false);
  };

  const blurInputs = () => {
    document.getElementById(profile.profileId).blur();
  };

  return (
    <MainContainer
      variants={moveRightVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
      success={success}
      error={valueError || activeError || error}
    >
      <Container success={success} error={valueError || activeError || error}>
        <DragIconWrapper onClick={blurInputs}>
          <i class="bi bi-grip-vertical"></i>
        </DragIconWrapper>

        <ContentWrapper>
          <Content>
            <ImageWrapper onClick={blurInputs}>
              <img id="img" src={profile.image} alt="" />
            </ImageWrapper>

            <InfoWrapper>
              <p onClick={blurInputs}>{profile.name}</p>

              <InputWrapper>
                <input
                  id={profile.profileId}
                  value={value}
                  onChange={handleValueChange}
                  onBlur={updateValueChange}
                  onFocus={() => setShowValueEditIcon(false)}
                  placeholder={profile.placeholder}
                  spellCheck="false"
                />
                {showValueEditIcon && (
                  <span>
                    <i class="bi bi-pencil"></i>
                  </span>
                )}
              </InputWrapper>
            </InfoWrapper>
            <SwitchWrapper>
              <ActiveSwitch
                checked={isActive}
                onChange={handleActiveStatusChange}
              />
            </SwitchWrapper>
          </Content>
          {/* {valueError && false && (
            <ErrorLayout
              variant="transparent"
              margin="2px 8px 0px 56px"
              padding="0px"
              errorMessage={valueError}
            />
          )} */}
          <ActionLayoutWrapper onClick={blurInputs}>
            <ActionIconWrapper
              active={showVisitLayout}
              onClick={onVisitIconClick}
              type="visit"
            >
              <i class="bi bi-box-arrow-up-right"></i>
            </ActionIconWrapper>

            <ActionIconWrapper
              active={showDeepLinkLayout || profileId === deepLinkId}
              onClick={onDeepLinkIconClick}
              type="deepLink"
            >
              <i class="bi bi-lightning"></i>
            </ActionIconWrapper>

            <ActionIconWrapper
              onClick={onAnimationIconClick}
              active={showAnimationLayout || profileAnimation !== ""}
              type="animation"
            >
              <i class="bi bi-bounding-box-circles"></i>
            </ActionIconWrapper>

            <ActionIconWrapper
              type="delete"
              active={showDeleteLayout}
              onClick={onDeleteIconClick}
            >
              <i class="bi bi-trash2"></i>
            </ActionIconWrapper>
          </ActionLayoutWrapper>
        </ContentWrapper>
      </Container>

      {showDeleteLayout && (
        <ActionProceedLayout
          success={success}
          error={valueError || activeError || error}
        >
          <DeleteItem
            type={"Profile"}
            progress={deleteProfileProgress}
            handleCancelClick={handleCancelClick}
            handleDeleteClick={handleDeleteProfile}
          />
        </ActionProceedLayout>
      )}

      {showAnimationLayout && (
        <ActionProceedLayout
          success={success}
          error={valueError || activeError || error}
        >
          <AnimationItem
            type={"link"}
            handleCancelClick={handleCancelClick}
            linkAnimation={profileAnimation}
            setLinkAnimation={setProfileAnimation}
            handleAddAnimation={handleAddAnimation}
          />
        </ActionProceedLayout>
      )}

      {showDeepLinkLayout && (
        <ActionProceedLayout
          success={success}
          error={valueError || activeError || error}
        >
          <DeepLinkItem
            handleCancelClick={handleCancelClick}
            active={profileId === deepLinkId}
            handleAddDeepLink={handleAddDeepLink}
            handleRemoveDeepLink={handleRemoveDeepLink}
            progress={deepLinkProgress}
            type={"profile"}
          />
        </ActionProceedLayout>
      )}

      {showVisitLayout && (
        <ActionProceedLayout
          success={success}
          error={valueError || activeError || error}
        >
          <VisitItem
            type="Profile"
            handleCancelClick={handleCancelClick}
            handleVisitLink={handleVisitLink}
          />
        </ActionProceedLayout>
      )}
    </MainContainer>
  );
}

export default ProfileItem;
