import React from "react";

import {
  Button,
  Container,
  Heading,
  Message,
  SvgWrapper,
  UserNotFoundSvg,
} from "./UserNotFound.elements";



import { fadeInVariants, whileTap } from "../../animations/animation";
import { useHistory } from "react-router-dom";

function UserNotFound() {
  const history = useHistory();

  const handleHomeClick = () => {
    history.push("/");
  };

  return (
    <Container initial="initial" animate="animate" variants={fadeInVariants}>
      <SvgWrapper>
        <UserNotFoundSvg />
      </SvgWrapper>
      <Heading>Oops! User Not Found.</Heading>
      <Message>
        The user you are looking for may have changed thier Profile Link. Please
        contact the user and ask for new Profile Link.
      </Message>

      <Button whileTap={whileTap} onClick={handleHomeClick}>
        <p>Go To Home</p>
      </Button>
    </Container>
  );
}

export default UserNotFound;
