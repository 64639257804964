import styled from "styled-components";
import { ReactComponent as HappyeNewsIcon } from "../../../images/happy_news.svg";

export const BoostContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 16px;
  margin-bottom: 24px;

  h3 {
    font-size: 24px;
    color: #130f40;
  }
`;

export const SvgWrapper = styled.div`
  background-color: rgba(19, 15, 64, 0.05);
  width: 100%;
  border-radius: 8px;
  margin-bottom: 16px;
  svg {
    width: 100%;
    height: 200px;
    padding: 24px;
  }
`;

export const HappyNewsSvg = styled(HappyeNewsIcon)`
  height: 200px;
`;

export const CollaborateContainer = styled.div`
  padding: 0px 16px;
`;

export const CollaborateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: ${({ theme }) => theme.border};
  border-radius: 4px;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  font-weight: 550;
  text-align: center;
  margin-bottom: 8px;
  color: #ee5253;

  #cross {
    margin: 4px 0px;
    color: #ffec78;
  }

  #brand {
    color: #130f40;
  }
`;

export const CollaborateMessage = styled.p`
  text-align: center;
  color: #6c6d7f;
  a {
    color: #130f40;
  }
`;
