import React, { useState } from "react";
import validator from "validator";

import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import { storage, db } from "../../firebase/firebase";
import { useHistory } from "react-router";
import {
  AnimationItem,
  DeleteItem,
  UploadLinkPhotoItem,
  DeepLinkItem,
  VisitItem,
} from "..";

import {
  MainContainer,
  Container,
  DragIconWrapper,
  ContentWrapper,
  Content,
  SwitchWrapper,
  ActiveSwitch,
  ActionLayoutWrapper,
  ActionIconWrapper,
  InputWrapper,
  ActionProceedLayout,
} from "../profile-item/ProfileItem.elements";
import {
  ImageLayout,
  LinkInformation,
  TitleInputWrapper,
} from "./LinkItem.elements";
import { moveRightVariants } from "../../animations/animation";
import { openURL } from "../../utils/functions";

function LinkItem({
  link,
  user,
  setShowProgressLayout,
  progressTimeout,
  deepLink,
  setDeepLink,
  deepLinkId,
  setDeepLinkId,
}) {
  const history = useHistory();

  const [title, setTitle] = useState(link.title);
  const [titleError, setTitleError] = useState("");

  const [linkId, setLinkId] = useState(link.linkId);
  const [linkURL, setLinkURL] = useState(link.link);
  const [linkURLError, setLinkURLError] = useState("");

  const [image, setImage] = useState(link.image);
  const [imageUploadProgress, setImageUploadProgress] = useState(false);
  const [imageUploadProgressValue, setImageUploadProgressValue] = useState(0);

  const [isActive, setIsActive] = useState(link.isActive);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");
  const [activeError, setActiveError] = useState("");
  const [isTitleChanged, setIsTitleChanged] = useState(false);
  const [isLinkURLChanged, setIsLinkURLChanged] = useState(false);

  const [deepLinkProgress, setDeeplinkProgress] = useState(false);
  const [deleteLinkProgress, setDeleteLinkProgress] = useState(false);

  const [showTitleEditIcon, setShowTitleEditIcon] = useState(true);
  const [showURLEditIcon, setShowURLEditIcon] = useState(true);

  const [showUploadLinkPhotoLayout, setShowUploadLinkPhotoLayout] =
    useState(false);
  const [showDeleteLayout, setShowDeleteLayout] = useState(false);

  const [showAnimationLayout, setShowAnimationLayout] = useState(false);

  const [linkAnimation, setLinkAnimation] = useState(link.animation);

  const [showDeepLinkLayout, setShowDeepLinkLayout] = useState(false);
  const [showVisitLayout, setShowVisitLayout] = useState(false);

  const handleLinkImageClick = (e) => {
    if (image) {
      setShowUploadLinkPhotoLayout(!showUploadLinkPhotoLayout);
      setShowDeleteLayout(false);

      setShowVisitLayout(false);
      setShowAnimationLayout(false);
      setShowDeepLinkLayout(false);
    }
  };

  const handleDeleteLinkImage = () => {
    if (image) {
      setError(false);
      setImageUploadProgress(true);
      setImageUploadProgressValue(0);
      setShowProgressLayout(true);

      var imageRef = storage.refFromURL(image);
      imageRef
        .delete()
        .then(() => {
          db.collection("users")
            .doc(user.userId)
            .collection("links")
            .doc(link.linkId)
            .update({
              image: "",
            })
            .then(() => {
              setImageUploadProgress(false);
              setImageUploadProgressValue(100);
              setImage("");
              setError(false);
              setSuccess(true);
              setShowProgressLayout(false);
              handleCancelClick();
              setTimeout(() => {
                setSuccess(false);
              }, progressTimeout);
            })
            .catch((error) => {
              setError(true);
              setImageUploadProgress(false);
              setImageUploadProgressValue(0);
            });
        })
        .catch((error) => {
          setError(true);
          setImageUploadProgress(false);
          setImageUploadProgressValue(0);
        });
    } else {
      handleCancelClick();
    }
  };

  const handleLinkImageChange = (e) => {
    e.preventDefault();
    const changedLinkImage = e.target.files[0];

    if (changedLinkImage) {
      setError(false);
      setImageUploadProgress(true);
      setImageUploadProgressValue(0);
      setShowProgressLayout(true);

      const uploadTask = storage
        .ref(`users/${user.userId}/linkImages/${link.linkId}.jpg`)
        .put(changedLinkImage);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setImageUploadProgressValue(progress);
        },
        (error) => {
          setError(true);
          setImageUploadProgress(false);
          setImageUploadProgressValue(0);
        },
        () => {
          storage
            .ref(`users/${user.userId}/linkImages/${link.linkId}.jpg`)
            .getDownloadURL()
            .then((imageUrl) => {
              setImage(imageUrl);
              db.collection("users")
                .doc(user.userId)
                .collection("links")
                .doc(link.linkId)
                .update({
                  image: imageUrl,
                })
                .then(() => {
                  setError(false);
                  setSuccess(true);
                  setShowProgressLayout(false);
                  handleCancelClick();
                  setTimeout(() => {
                    setSuccess(false);
                    setImageUploadProgress(false);
                  }, progressTimeout);
                })
                .catch((error) => {
                  setImageUploadProgress(false);
                  setError(true);
                  setImageUploadProgressValue(0);
                });
            });
        }
      );
    }
  };

  const handleTitleChange = (e) => {
    e.preventDefault();
    setTitle(e.target.value);
    setIsTitleChanged(true);

    if (e.target.value === "") {
      setTitleError("title cannot be empty");
      return;
    }

    setTitleError("");
  };

  const updateNewTitle = () => {
    setShowTitleEditIcon(true);
    if (titleError || !isTitleChanged) {
      return;
    }

    setShowProgressLayout(true);

    db.collection("users")
      .doc(user.userId)
      .collection("links")
      .doc(link.linkId)
      .update({
        title: title,
      })
      .then(() => {
        setSuccess(true);
        setShowProgressLayout(false);
        setIsTitleChanged(false);
        handleCancelClick();
        setTimeout(() => {
          setSuccess(false);
        }, progressTimeout);
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  const handleActiveStatusChange = (e) => {
    e.preventDefault();
    setIsActive(e.target.checked);
    setActiveError("");
    setShowProgressLayout(true);
    db.collection("users")
      .doc(user.userId)
      .collection("links")
      .doc(link.linkId)
      .update({
        isActive: e.target.checked,
      })
      .then(() => {
        setSuccess(true);
        setShowProgressLayout(false);
        setTimeout(() => {
          setSuccess(false);
        }, progressTimeout);
      })
      .catch((error) => {
        setActiveError(error.message);
      });
  };

  const handleLinkURLChange = (e) => {
    e.preventDefault();
    setLinkURL(e.target.value);
    setIsLinkURLChanged(true);

    if (e.target.value === "") {
      setLinkURLError("link cannot be empty");
      return;
    }

    setLinkURLError("");
  };

  const updateNewLinkURL = () => {
    setShowURLEditIcon(true);
    if (linkURLError || !isLinkURLChanged) {
      return;
    }

    if (!validator.isURL(linkURL)) {
      setLinkURLError("Invalid URL");
      return;
    }

    setShowProgressLayout(true);

    db.collection("users")
      .doc(user.userId)
      .collection("links")
      .doc(link.linkId)
      .update({
        link: linkURL,
      })
      .then(() => {
        setIsLinkURLChanged(false);
        if (deepLinkId === linkId) {
          handleAddDeepLink();
        } else {
          setSuccess(true);
          setShowProgressLayout(false);
          handleCancelClick();
          setTimeout(() => {
            setSuccess(false);
          }, progressTimeout);
        }
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  const onDeleteIconClick = () => {
    setShowDeleteLayout(!showDeleteLayout);

    setShowVisitLayout(false);
    setShowAnimationLayout(false);
    setShowDeepLinkLayout(false);
    setShowUploadLinkPhotoLayout(false);
    blurInputs();
  };

  const handleDeleteLink = () => {
    setError(false);
    setDeleteLinkProgress(true);
    setShowProgressLayout(true);
    if (image) {
      setImageUploadProgress(true);
      setImageUploadProgressValue(0);

      var imageRef = storage.refFromURL(image);
      imageRef
        .delete()
        .then(() => {
          db.collection("users")
            .doc(user.userId)
            .collection("links")
            .doc(linkId)
            .delete()
            .then(() => {
              if (deepLinkId === linkId) {
                handleRemoveDeepLink();
              }
              setImageUploadProgress(false);
              setImageUploadProgressValue(100);
              setShowProgressLayout(false);
              setDeleteLinkProgress(false);
              handleCancelClick();
            })
            .catch((error) => {
              setError(true);
              setDeleteLinkProgress(false);
              setImageUploadProgress(false);
              setImageUploadProgressValue(0);
            });
        })
        .catch((error) => {
          setError(true);
          setDeleteLinkProgress(false);
          setImageUploadProgress(false);
          setImageUploadProgressValue(0);
          handleCancelClick();
        });
    } else {
      db.collection("users")
        .doc(user.userId)
        .collection("links")
        .doc(link.linkId)
        .delete()
        .then(() => {
          if (deepLinkId === linkId) {
            handleRemoveDeepLink();
          }

          setShowProgressLayout(false);
          handleCancelClick();
          setDeleteLinkProgress(false);
         
        })
        .catch((error) => {
          setDeleteLinkProgress(false);
          setError(true);
          handleCancelClick();
        });
    }
  };

  const onAnimationIconClick = () => {
    setShowAnimationLayout(!showAnimationLayout);
    setShowDeleteLayout(false);

    setShowVisitLayout(false);
    setShowDeepLinkLayout(false);
    setShowUploadLinkPhotoLayout(false);
    blurInputs();
  };

  const handleAddAnimation = (animation) => {
    setShowProgressLayout(true);
    if (animation !== linkAnimation) {
      setLinkAnimation(animation);
      handleCancelClick();
      db.collection("users")
        .doc(user.userId)
        .collection("links")
        .doc(link.linkId)
        .update({
          animation: animation,
        })
        .then(() => {
          setSuccess(true);
          setShowProgressLayout(false);

          setTimeout(() => {
            setSuccess(false);
          }, progressTimeout);
        })
        .catch((error) => {
          setError(error.message);
        });
    } else {
      setLinkAnimation("");
      handleCancelClick();
      db.collection("users")
        .doc(user.userId)
        .collection("links")
        .doc(link.linkId)
        .update({
          animation: "",
        })
        .then(() => {
          setSuccess(true);
          setShowProgressLayout(false);
          setTimeout(() => {
            setSuccess(false);
          }, progressTimeout);
        })
        .catch((error) => {
          setError(error.message);
        });
    }
  };

  const onDeepLinkIconClick = () => {
    setShowDeepLinkLayout(!showDeepLinkLayout);
    setShowVisitLayout(false);
    setShowDeleteLayout(false);

    setShowAnimationLayout(false);
    setShowUploadLinkPhotoLayout(false);
    blurInputs();
  };

  const handleAddDeepLink = () => {
    setDeeplinkProgress(true);
    setShowProgressLayout(true);
    db.collection("users")
      .doc(user.userId)
      .update({
        "deepLink.link": linkURL,
        "deepLink.type": "link",
        "deepLink.id": link.linkId,
      })
      .then(() => {
        setSuccess(true);
        handleCancelClick();
        setShowProgressLayout(false);
        setDeepLink(linkURL);
        setDeepLinkId(linkId);
        setTimeout(() => {
          setSuccess(false);
          setDeeplinkProgress(false);
          handleCancelClick();
        }, progressTimeout);
      })
      .catch((error) => {
        setError(error.message);
        setDeeplinkProgress(false);
      });
  };

  const handleRemoveDeepLink = () => {
    setDeeplinkProgress(true);
    setShowProgressLayout(true);
    db.collection("users")
      .doc(user.userId)
      .update({
        "deepLink.link": "",
        "deepLink.type": "",
        "deepLink.id": "",
      })
      .then(() => {
        setSuccess(true);
        setShowProgressLayout(false);
        setIsLinkURLChanged(false);
        setDeepLink("");
        setDeepLinkId("");
        handleCancelClick();
        setTimeout(() => {
          setSuccess(false); 
          setDeeplinkProgress(false);
        }, progressTimeout);
      })
      .catch((error) => {
        setError(error.message);
        setDeeplinkProgress(false);
      });
  };

  const onVisitIconClick = () => {
    setShowVisitLayout(!showVisitLayout);
    setShowDeepLinkLayout(false);
    setShowDeleteLayout(false);

    setShowAnimationLayout(false);
    setShowUploadLinkPhotoLayout(false);
    blurInputs();
  };

  const handleVisitLink = () => {
    if (linkURL === "") {
      setLinkURLError("Invalid link");
      document.getElementById(`${link.linkId}URL`).focus();
      return;
    }

    openURL(linkURL);
  };

  const handleCancelClick = () => {
    setShowDeleteLayout(false);

    setShowAnimationLayout(false);
    setShowUploadLinkPhotoLayout(false);
    setShowDeepLinkLayout(false);
    setShowVisitLayout(false);
  };

  const blurInputs = () => {
    document.getElementById(`${link.linkId}title`).blur();
    document.getElementById(`${link.linkId}URL`).blur();
  };

  return (
    <MainContainer
      success={success}
      error={titleError || linkURLError || error || activeError}
      variants={moveRightVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
      drag={false}
      dragConstraints={{ left: 0, right: 0, top: 0, bottom: 0 }}
    >
      <Container
        success={success}
        error={titleError || linkURLError || error || activeError}
      >
        <DragIconWrapper onClick={blurInputs}>
          <i class="bi bi-grip-vertical"></i>
        </DragIconWrapper>
        <ContentWrapper>
          <Content>
            <ImageLayout>
              {!image && !imageUploadProgress && (
                <label htmlFor={link.linkId}>
                  <AddAPhotoIcon style={{ color: "gray", fontSize: "16px" }} />
                </label>
              )}

              {image && (
                <img onClick={handleLinkImageClick} src={image} alt="" />
              )}

              {imageUploadProgress && (
                <span id="loader">
                  <i class="fas fa-spinner"></i>
                </span>
              )}

              {imageUploadProgress && (
                <span id="progressText">{imageUploadProgressValue}</span>
              )}
              <input
                id={link.linkId}
                type="file"
                style={{ display: "none" }}
                accept="image/*"
                onChange={handleLinkImageChange}
              />
            </ImageLayout>
            <LinkInformation>
              <TitleInputWrapper>
                <input
                  id={`${link.linkId}title`}
                  value={title}
                  onChange={handleTitleChange}
                  type="text"
                  placeholder="My title"
                  onBlur={updateNewTitle}
                  spellCheck="false"
                  onFocus={() => setShowTitleEditIcon(false)}
                />
                {showTitleEditIcon && (
                  <span>
                    <i class="bi bi-pencil"></i>
                  </span>
                )}
              </TitleInputWrapper>

              <InputWrapper>
                <input
                  id={`${link.linkId}URL`}
                  value={linkURL}
                  onChange={handleLinkURLChange}
                  type="text"
                  placeholder="www.mylink.com"
                  onBlur={updateNewLinkURL}
                  spellCheck="false"
                  onFocus={() => setShowURLEditIcon(false)}
                />
                {showURLEditIcon && (
                  <span>
                    <i class="bi bi-pencil"></i>
                  </span>
                )}
              </InputWrapper>
            </LinkInformation>
            <SwitchWrapper>
              <ActiveSwitch
                checked={isActive}
                onChange={handleActiveStatusChange}
              />
            </SwitchWrapper>
          </Content>
          {/* {titleError && !linkURLError && (
            <ErrorLayout
              variant="transparent"
              margin="2px 8px 0px 50px"
              padding="0px"
              errorMessage={titleError}
            />
          )} */}

          {/* {linkURLError && (
            <ErrorLayout
              variant="transparent"
              margin="2px 8px 0px 50px"
              padding="0px"
              errorMessage={linkURLError}
            />
          )} */}

          <ActionLayoutWrapper onClick={blurInputs}>
            <ActionIconWrapper
              active={showVisitLayout}
              onClick={onVisitIconClick}
              type="visit"
            >
              <i class="bi bi-box-arrow-up-right"></i>
            </ActionIconWrapper>

            <ActionIconWrapper
              active={showDeepLinkLayout || linkId === deepLinkId}
              onClick={onDeepLinkIconClick}
              type="deepLink"
            >
              <i class="bi bi-lightning"></i>
            </ActionIconWrapper>

            <ActionIconWrapper
              onClick={onAnimationIconClick}
              active={showAnimationLayout || linkAnimation !== ""}
              type="animation"
            >
              <i class="bi bi-bounding-box-circles"></i>
            </ActionIconWrapper>

            <ActionIconWrapper
              type="delete"
              active={showDeleteLayout}
              onClick={onDeleteIconClick}
            >
              <i class="bi bi-trash2"></i>
            </ActionIconWrapper>
          </ActionLayoutWrapper>
        </ContentWrapper>
      </Container>

      {showDeleteLayout && (
        <ActionProceedLayout
          success={success}
          error={titleError || linkURLError || error || activeError}
        >
          <DeleteItem
            type={"link"}
            progress={deleteLinkProgress}
            handleCancelClick={handleCancelClick}
            handleDeleteClick={handleDeleteLink}
            handleLinkImageChange={handleLinkImageChange}
          />
        </ActionProceedLayout>
      )}

      {showAnimationLayout && (
        <ActionProceedLayout
          success={success}
          error={titleError || linkURLError || error || activeError}
        >
          <AnimationItem
            type={"link"}
            handleCancelClick={handleCancelClick}
            linkAnimation={linkAnimation}
            setLinkAnimation={setLinkAnimation}
            handleAddAnimation={handleAddAnimation}
          />
        </ActionProceedLayout>
      )}

      {showUploadLinkPhotoLayout && (
        <ActionProceedLayout
          success={success}
          error={titleError || linkURLError || error || activeError}
        >
          <UploadLinkPhotoItem
            handleCancelClick={handleCancelClick}
            handleDeleteLinkImage={handleDeleteLinkImage}
            handleLinkImageChange={handleLinkImageChange}
            linkId={link.linkId}
          />
        </ActionProceedLayout>
      )}

      {showDeepLinkLayout && (
        <ActionProceedLayout
          success={success}
          error={titleError || linkURLError || error || activeError}
        >
          <DeepLinkItem
            handleCancelClick={handleCancelClick}
            active={linkId === deepLinkId}
            handleAddDeepLink={handleAddDeepLink}
            handleRemoveDeepLink={handleRemoveDeepLink}
            progress={deepLinkProgress}
            type={"link"}
          />
        </ActionProceedLayout>
      )}

      {showVisitLayout && (
        <ActionProceedLayout
          success={success}
          error={titleError || linkURLError || error || activeError}
        >
          <VisitItem
            type="Link"
            handleCancelClick={handleCancelClick}
            handleVisitLink={handleVisitLink}
          />
        </ActionProceedLayout>
      )}
    </MainContainer>
  );
}

export default LinkItem;
