import styled, { keyframes } from "styled-components";

import { motion } from "framer-motion";

export const rotate = keyframes`
from {
  transform: rotate(0deg);
  transform-origin: center;
}
to {
  transform: rotate(360deg);
  transform-origin: center;
}
`;

export const Heading = styled(motion.h6)`
  font-size: 32px;
  font-weight: 500;
`;

export const SubHeading = styled(motion.p)`
  color: ${({ theme }) => theme.textColors.secondary};
`;

export const Label = styled.label`
  font-size: 14px;
  margin: ${({ margin }) => margin};
`;

export const Input = styled(motion.input)`
  width: 100%;
  margin-top: 8px;
  height: 50px;
  font-size: 16px;
  border-radius: 4px;
  outline-width: 0px;
  border-width: 0px;
  padding: 0px 8px 0px 8px;
  background-color: #ffffff;
  border: ${({ theme }) => theme.border};
`;

export const TextArea = styled(motion.textarea)`
  width: 100%;
  margin-top: 4px;
  min-height: 200px;
  resize: none;
  font-size: 16px;
  border-radius: 4px;
  outline-width: 0px;
  border-width: 0px;
  padding: 8px;
  background-color: #f2f2f2;
  font-family: "Hind", Arial, Helvetica, sans-serif;
`;

export const AccountActionLayout = styled(motion.div)`
  margin: ${({ margin }) => margin};
  font-size: 14px;

  & > p {
    text-align: center;

    & > a {
      color: ${({ theme }) => theme.colors.primary};
      font-weight: 600;
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

export const LoginWithGoogle = styled(motion.span)`
  font-size: 14px;
  margin: 16px 0px 0px 0px;
  text-align: center;
  line-height: 1.5;
  color: ${({ theme }) => theme.textColors.secondary};
`;

export const ActionBackLayout = styled(motion.div)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 14px;
  margin: ${({ margin }) => margin};
  text-align: center;
  align-items: center;
  color: ${({ theme }) => theme.button.background};
  cursor: pointer;

  & > span {
    font-size: 18px;
    margin-right: 8px;
  }
  & > .MuiSvgIcon-root {
    color: ${({ theme }) => theme.button.background};
    margin-right: 4px;
  }
`;

export const CounterLayout = styled(motion.span)`
  font-size: 14px;
  text-align: center;
  color: ${({ theme }) => theme.textColors.secondary};
  margin: ${({ margin }) => margin};
`;

export const Message = styled(motion.p)`
  color: ${({ theme }) => theme.textColors.secondary};
`;

export const ActionLayoutContainer = styled(motion.div)`
  position: relative;
  & > p {
    line-height: 1.5;
  }
`;

export const ActionLayoutHeader = styled(motion.div)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

export const ActionLayoutButtonContainer = styled(motion.div)`
  display: flex;
  flex-direction: row;
  margin: 16px 8px 8px 8px;
`;

export const ActionLayoutButton = styled(motion.div)`
  display: flex;
  background-color: ${({ theme, variant }) =>
    (variant === "delete" && theme.colors.delete) ||
    (variant === "cancel" && theme.colors.cancel) ||
    (variant === "deepLink" && theme.colors.deepLink) ||
    (variant === "visit" && theme.colors.visit)};
  height: 40px;
  width: 100%;
  margin: ${({ margin }) => margin};
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  color: ${({ variant }) => (variant === "cancel" && "#000000") || "#ffffff"};
  text-transform: capitalize;
  cursor: pointer;

  #loader {
    height: 24px;
    width: 24px;
    font-size: 24px;
    position: absolute;
    color: white;
    animation: ${rotate} 0.8s linear infinite;
  }
`;

export const HorizontalBreak = styled(motion.div)`
  height: 0.5px;
  color: #ededed;
  background-color: #ededed;
  margin: 2px 0px;
`;
