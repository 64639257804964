import styled from "styled-components";
import { motion } from "framer-motion";

export const SuccessLayout = styled(motion.div)`
  padding: ${({ padding }) => (padding ? padding : "8px")};
  margin: ${({ margin }) => (margin ? margin : "0px")};
  border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : "4px")};
  min-height: 30px;
  background-color: rgba(66, 186, 150, 0.1);

  p {
    font-size: 14px;
    color: rgb(66, 186, 150);
    word-wrap: break-word;
  }
`;
